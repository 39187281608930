import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompanyLoad,
  loadPageUser,
  selectCurrentPage,
  selectPages,
  selectTableStructure,
  selectUsers,
  selectListCompany,
  setFilterSearch,
  deleteUserCall,
  closeDeleteModal,
  setDeleteModal,
} from './usersSlice';
import Table from '../../../../components/shared/table/table/Table';
import { TemplateTable } from '../../../../components/shared/table/models/TableProps';
import { setTitle } from '../../../../app/page/pageSlice';
import FilterUsers from './components/FiltersUsers';
import { useRoute } from '../../../../utils/useRoute';
import DeleteModal from './components/DeleteModal';
import Icon from '../../../../components/shared/icon/Icon';
import { forgotPasswordCall } from '../../../public/forgot-password/forgot-password.service';
import {
  errorSlice,
  addSuccessMessage,
} from '../../../../app/errors/errorSlice';
import IsPermission from '../../../../components/IsPermission';
import { permissionType } from '../../../../app/rolePermissions';

const Users = () => {
  const { t } = useTranslation();
  const users = useSelector(selectUsers);
  const pages = useSelector(selectPages);
  const page = useSelector(selectCurrentPage);
  const companyList = useSelector(selectListCompany);
  const tableStructure = useSelector(selectTableStructure);
  const dispatch = useDispatch();
  const { changeRoute } = useRoute();
  dispatch(setTitle(t('users.list.title')));
  useEffect(() => {
    dispatch(getCompanyLoad());
  }, []);
  const func: { [key: string]: () => void } = {
    deleteAction: () => dispatch(deleteUserCall()),
    closeAction: () => dispatch(closeDeleteModal()),
  };
  const resetPsw = (row: any) => {
    forgotPasswordCall(row.email).then(res => {
      dispatch(addSuccessMessage('RESTORE_PWD_SUCCESS'));
    });
  };
  const templates: TemplateTable = {
    modify: (value, row) => (
      <>
        <div className="d-flex">
          <Icon
            icon="edit"
            size="22"
            title="Modifica utente"
            onClick={() => changeRoute('users.edit', { id: row.id })}
          ></Icon>
          <Icon
            icon="delete-profile"
            size="22"
            title="Cancella utente"
            onClick={() => dispatch(setDeleteModal(row))}
          ></Icon>
          {!row.enabled && (
            <IsPermission permissions={[permissionType.MANAGEMENT_UNLOCK_USER]}>
              <Icon
                icon="key"
                size="22"
                title="Riabilita utente"
                onClick={() => resetPsw(row)}
              ></Icon>
            </IsPermission>
          )}
        </div>
      </>
    ),
  };
  const changePage = (page: number) => {
    dispatch(loadPageUser(page));
  };

  const deleteAction = (action: string) => {
    func && func[action] && func[action]();
  };

  return (
    <div>
      <DeleteModal
        close={() => dispatch(closeDeleteModal())}
        action={deleteAction}
      />
      <FilterUsers
        companyList={companyList}
        searchSubmit={data => dispatch(setFilterSearch(data))}
      />
      <Table
        columns={tableStructure}
        data={users}
        templates={templates}
        goPage={changePage}
        page={page}
        pages={pages}
        labelEmpty="Nessun utente trovato"
      ></Table>
    </div>
  );
};

export default Users;
