import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../app/store';

import { getUser } from '../services/users.service';
import { User } from '../../../../app/auth/model/User';
import {
  getListRole,
  setCompanyId,
  setRoleId,
  setAuthorityId,
} from '../userCreateSlice';

const initialState: {
  user: Partial<User>;
} = {
  user: {},
};

export const userEditSlice = createSlice({
  name: 'userEdit',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Partial<User>>) => {
      state.user = action.payload;
    },
  },
});
export const { setUser } = userEditSlice.actions;

export const loadUserInitData =
  (id: string): AppThunk =>
  (dispatch, getState) => {
    dispatch(getListRole());
    getUser(id).then(res => {
      const user = res;
      dispatch(setUser(user));
      dispatch(setCompanyId(user.company));
      dispatch(setRoleId(user.role));
      dispatch(setAuthorityId(user.authorityId));
    });
  };

export const selectUserEdit = (state: RootState) => state.userEdit.user;

export default userEditSlice.reducer;
