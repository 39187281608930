import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setTitle } from '../../../../app/page/pageSlice';
import AssetForm from '../AssetForm';
import {
  loadAssetInitData,
  resetAssetSlice,
} from '../asset-create/assetCreateSlice';
import {
  selectAssetId,
  selectPeriodId,
  selectPeriods,
  setDisabledAll,
} from '../../../../components/core/filter-dashboard/filterDashboardSlice';
import { useRoute } from '../../../../utils/useRoute';
import * as routeName from '../../../../routes/routeNameAuthType';

const AssetEdit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const assetId = useSelector(selectAssetId);
  const periodId = useSelector(selectPeriodId);
  const periods = useSelector(selectPeriods);
  const { changeRoute } = useRoute();
  useEffect(() => {
    if (assetId) {
      dispatch(setTitle(t('Modifica fondo')));
      dispatch(resetAssetSlice());
      dispatch(loadAssetInitData(assetId));
      dispatch(setDisabledAll(true));
    } else {
      changeRoute(routeName.home);
    }
    return () => {
      dispatch(setDisabledAll(false));
      dispatch(resetAssetSlice());
    };
  }, [assetId, periodId, periods]);

  return <AssetForm />;
};

export default AssetEdit;
