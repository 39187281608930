import { loadMessages, locale } from 'devextreme/localization';
import itMessages from 'devextreme/localization/messages/it.json';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import {
  autoLogin,
  logoutAction,
  selectIsLogged,
  selectUser,
} from './app/auth/authSlice';
import AppLayout from './components/core/app-layout/AppLayout';
import AuthRoute from './components/routes/AuthRoute';
import PublicRoute from './components/routes/PublicRoute';
import Loader from './components/shared/Loader';
import LoaderV2 from './components/shared/loader/loaderV2/LoaderV2';
import {
  selectActiveLoad,
  selectActiveManualLoad,
} from './components/shared/loader/redux/loadSlice';

function App() {
  const user = useSelector(selectUser);
  const isLogged = useSelector(selectIsLogged);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const show = useSelector(selectActiveLoad);
  const showManual = useSelector(selectActiveManualLoad);
  useEffect(() => {
    loadMessages(itMessages);
    locale(navigator.language);
    stableDispatch(autoLogin());
  }, [stableDispatch]);
  return (
    <Router>
      <LoaderV2 loading={show || showManual} />
      <AppLayout user={user} logout={() => dispatch(logoutAction())}>
        <Switch>
          {isLogged === undefined || (isLogged && !user) ? (
            <Loader />
          ) : (
            <>
              <Route path="/">
                <PublicRoute isLogged={isLogged} />
              </Route>

              <Route path="/">
                <AuthRoute isLogged={!!user} />
              </Route>
            </>
          )}
          )
        </Switch>
      </AppLayout>
    </Router>
  );
}

export default App;
