import React from 'react';
import { Row, Col } from 'react-bootstrap';
import FilterDashboard from './filter-dashboard/FilterDashboard';

const HeaderFilterAssets = () => {
  return (
    <Row className="justify-content-end w-100">
      <Col xs="12" md="11">
        <FilterDashboard />
      </Col>
    </Row>
  );
};

export default HeaderFilterAssets;
