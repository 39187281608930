import React from 'react';
import { Col, Container } from 'react-bootstrap';
import Row from 'react-bootstrap/esm/Row';
import logoOnewelf from '../../../../assets/logo-onewelf.png';
import useVersion from '../../../../hooks/useVersion';
const FooterLayout = () => {
  const version = useVersion();
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col
            xs="12"
            className="d-flex justify-content-between align-items-center"
          >
            <img className="logo" src={logoOnewelf} alt="user img" />
            <span className="caption sm">Version v.{version}</span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FooterLayout;
