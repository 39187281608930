import React, { useState } from 'react';
import { tableObjPropsWithAction } from '../models/TableProps';
import Table from './Table';

export type SearchProps = {
  onChange(value: { [key in string]: any }): void;
  values: { [key in string]: any };
  onSearch(value?: { [key in string]: any }): void;
};
export type OtherProps = {
  searchContent?: (value: SearchProps) => React.ReactNode;
};

const TableWithAction = (props: tableObjPropsWithAction & OtherProps) => {
  const {
    objProps = {},
    changeObjProps,
    templates,
    searchContent,
    ...rest
  } = props;

  const [searchValues, setSearchValues] = useState<{ [key in string]: any }>();

  const changePage = (page: number) => {
    changeObjProps &&
      changeObjProps({ ...objProps, search: { ...searchValues }, page });
  };
  const changeOrder = (field: string) => {
    changeObjProps &&
      changeObjProps({
        ...objProps,
        order: { field, order: isAscOrder(field) ? 'DESC' : 'ASC' },
      });
  };

  function handleSearch(value?: { [key in string]: any }) {
    const values = { ...searchValues, ...value };
    setSearchValues(values);
    changeObjProps &&
      changeObjProps({ ...objProps, search: { ...values }, page: 1 });
  }

  function handleSearchChange(value: { [key in string]: any }) {
    setSearchValues(prev => ({ ...prev, ...value }));
  }

  const SearchContentComponent = searchContent ? (
    searchContent({
      onSearch: handleSearch,
      values: searchValues || {},
      onChange: handleSearchChange,
    })
  ) : (
    <> </>
  );

  const isAscOrder = (field: string): boolean => {
    return !!(
      objProps &&
      objProps.order &&
      objProps.order.order &&
      objProps.order.field &&
      objProps.order.field === field &&
      objProps.order.order === 'ASC'
    );
  };
  return (
    <>
      {SearchContentComponent}
      <Table
        page={objProps.page}
        pages={objProps.pages}
        goPage={changePage}
        clickOrder={changeOrder}
        templates={templates}
        order={objProps.order}
        data={objProps.data || []}
        {...rest}
      />
    </>
  );
};
export default TableWithAction;
