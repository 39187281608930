import React from 'react';
import ModalSuccess from '../../../../../../components/shared/modal/modalSuccess/ModalSuccess';

const ModalInfoLoad = ({
  showModal,
  closeAction,
}: {
  showModal: boolean;
  closeAction: () => void;
}) => {
  return (
    <ModalSuccess
      closeAction={closeAction}
      clickAction={closeAction}
      text={{ title: 'Attenzione' }}
      templateBody={'I File da scaricare saranno pronti a breve'}
      showModal={showModal}
      btnList={[
        {
          label: 'Chiudi',
          action: 'close',
          type: 'primary',
        },
      ]}
    />
  );
};

export default ModalInfoLoad;
