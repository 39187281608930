import React from 'react';
import SelectorToggle from '../../../../../components/shared/form/buttons/selectorToggle/SelectorToggle';
import Table from '../../../../../components/shared/table/table/Table';
import {
  addRemoveSelectionAction,
  deselectAllIndexSelected,
  selectAllIndexSelected,
  setSelectedVdsWithError,
} from './errorDashboardSlice';
import { useErrorDashboard } from './useErrorDashboard';
import { useTranslation } from 'react-i18next';
import ErrorModel from './models/ErrorModel';
import { useDispatch } from 'react-redux';
interface ErrorTableDashboardProp {
  errorList: ErrorModel[];
  isSelectedAllRow: boolean;
  indexSelected: (string | number)[];
}
const ErrorTableDashboard = ({
  errorList,
  isSelectedAllRow,
}: ErrorTableDashboardProp) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tableStructure } = useErrorDashboard(t, isSelectedAllRow);
  // const selectAllRow = () => {
  //   dispatch(selectAllIndexSelected());
  // };
  // const deselectAllRow = () => {
  //   dispatch(deselectAllIndexSelected());
  // };

  // const deleteAll = () => {
  //   console.log(indexSelected);
  // };

  // const func: { [key: string]: (data?: any) => void } = {
  //   selectAll: () => selectAllRow(),
  //   deselectAll: () => deselectAllRow(),
  //   deleteAll: () => deleteAll(),
  // };

  // const addRemoveSelection = (id: number, index: number, e: any) => {
  //   e.stopPropagation && e.stopPropagation();
  //   dispatch(addRemoveSelectionAction(id, index));
  // };

  // const templates = {
  //   selector: (value: string, row: any, index: number) => (
  //     <SelectorToggle
  //       check={indexSelected.includes(row.id)}
  //       clickAction={(event: any) => addRemoveSelection(row.id, index, event)}
  //     ></SelectorToggle>
  //   ),
  // };
  // const headerTemplates = {
  //   selector: (name: string) => {
  //     return (
  //       <SelectorToggle
  //         check={isSelectedAllRow}
  //         disabled={errorList.length === 0}
  //         clickAction={() => {
  //           errorList && errorList.length && func && func[name] && func[name]();
  //         }}
  //       ></SelectorToggle>
  //     );
  //   },
  // };

  return (
    <Table
      columns={tableStructure}
      data={errorList}
      clickRow={(event, row, index) =>
        dispatch(setSelectedVdsWithError(row.vds))
      }
    />
  );
};

export default ErrorTableDashboard;
