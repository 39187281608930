import http from '../../../../utils/http';

const urlTable = `table`;

export const getFact = (selectedTable: string = '') =>
  http.get(`${urlTable}?factId=${selectedTable}`);

export const saveStateCall = (payload: any) =>
  http.post(`${urlTable}/query`, payload);

export const loadStateCall = (userId: number = 0, selectedTable: string = '') =>
  http.get(`${urlTable}/query?userId=${userId}&factId=${selectedTable}`);

export const deleteQuery = (queryId: number = 0) =>
  http.delete(`${urlTable}/query?id=${queryId}`);
