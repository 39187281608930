export const validationsEditUserForm = {
  firstName: {
    required: {
      params: { name: 'nome' },
    },
  },
  lastName: {
    required: {
      params: { name: 'cognome' },
    },
  },
  assets: {
    required: {
      params: { name: 'fondi' },
    },
  },
  role: {
    required: {
      params: { name: 'Ruolo' },
    },
  },
};
