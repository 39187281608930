import React from 'react';

import { useTranslation } from 'react-i18next';
import { useForm } from '../../../../../utils/forms/useForm';
import { useFiltersUsers } from './useFiltersUsers';

import { Col, Form, Row } from 'react-bootstrap';
import FormElements from '../../../../../utils/forms/FormElements';
import PrimaryButton from '../../../../../components/shared/form/buttons/primary-button/PrimaryButton';
import { UseFormSubmitModel } from '../../../../../utils/forms/models/UseFormModel';

const FilterUsers = ({
  companyList = [],
  searchSubmit,
}: {
  companyList: any[];
  searchSubmit?: (data: any) => void;
}) => {
  const { t } = useTranslation();
  const submitHandler: UseFormSubmitModel = (event, data) => {
    searchSubmit && searchSubmit(data);
  };
  const { validations, data } = useFiltersUsers(t, companyList);
  const { isSubmit, errors, submitAction, changeValue, dataForm } = useForm(
    submitHandler,
    validations
  );
  return (
    <Form className="pt-2" onSubmit={submitAction}>
      <FormElements
        data={data}
        errors={errors}
        dataForm={dataForm}
        isSubmit={isSubmit}
        changeValue={changeValue}
      />
      <Row>
        <Col sm={{ span: 3, offset: 9 }}>
          <PrimaryButton>Search</PrimaryButton>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterUsers;
