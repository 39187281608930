import React, { useEffect } from 'react';

import FormalError from './FormalError';
import LogicalError from './LogicalError';
import { useDispatch, useSelector } from 'react-redux';
import {
  DownloadFileErrorExportAction,
  getDetailError,
  getLogicError,
  selectDetailErrorList,
  selectField,
  selectLogicalView,
  selectLogicErrorList,
  setLogicalView,
  setSelectFields,
} from '../errorDashboardSlice';
import SelectErrorDetailDashboard from './SelectErrorDetailDashboard';
import SelectFilter from '../components/SelectFilter';
import { Row, Col } from 'react-bootstrap';
import { ToolbarIconProps } from '../../../../../../components/shared/toolbarWithDrop/models/ToolbarIconProps';
import { setToolBarIconFlussi } from '../../DashboardSlice';
import toolBarEvent from '../../components/toolbarEvents';
import toolbarEvents from '../../components/toolbarEvents';

const ErrorDetailDashboard = ({
  id,
  vds,
  autoSelectTab,
}: {
  id: number;
  vds: string;
  autoSelectTab: (tab: string) => void;
}) => {
  const dispatch = useDispatch();
  const logicalView = useSelector(selectLogicalView);
  const errorsLogic = useSelector(selectLogicErrorList);
  const detailError = useSelector(selectDetailErrorList);
  const selectFieldElement = useSelector(selectField);

  useEffect(() => {
    dispatch(getLogicError(id));
    dispatch(getDetailError(id));
  }, [id, vds]);
  const exportAction = () => {
    dispatch(DownloadFileErrorExportAction());
    console.log('esporta file');
  };

  const listTabs = [
    ...(errorsLogic?.data.length
      ? [
          {
            label: 'Errori Logici',
            isActive: logicalView,
            isSelected: true,
          },
        ]
      : []),
    ...(detailError?.length
      ? [
          {
            label: 'Errori Formali',
            isActive: !logicalView,
            isSelected: false,
          },
        ]
      : []),
  ];
  useEffect(() => {
    const toolBarIconFlussi: ToolbarIconProps[] = [
      {
        icon: 'export',
        name: 'Esporta',
        action: 'export',
        visible: true,
      },
    ];
    dispatch(setToolBarIconFlussi(toolBarIconFlussi));
    toolBarEvent.events = exportAction;
    return () => {
      toolbarEvents.events = () => {};
      dispatch(setToolBarIconFlussi([]));
    };
  }, []);

  useEffect(() => {
    !detailError.length &&
      errorsLogic?.data?.length &&
      dispatch(setLogicalView(true));
    return () => {
      dispatch(setLogicalView(false));
    };
  }, [detailError, errorsLogic]);
  return (
    <>
      <Row className="mb-2 align-items-center">
        <Col>
          {!logicalView && (
            <SelectFilter
              value={selectFieldElement}
              changeAction={(value: string) =>
                dispatch(setSelectFields(value, id))
              }
            />
          )}
        </Col>
        <Col xs="auto">
          <SelectErrorDetailDashboard
            tabs={listTabs}
            changeView={data => dispatch(setLogicalView(data))}
          />
        </Col>
      </Row>
      {logicalView ? (
        <LogicalError
          id={id}
          vds={vds}
          errors={errorsLogic}
          autoSelectTab={autoSelectTab}
        />
      ) : (
        <FormalError
          id={id}
          vds={vds}
          errors={detailError}
          autoSelectTab={autoSelectTab}
        />
      )}
    </>
  );
};

export default ErrorDetailDashboard;
