import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';

import {
  getAssetsList,
  getAssetsListByAuthority,
  getCompanyList,
  getListAuthority,
  getRolesList,
} from './services/users.service';
import { CompanyModel } from '../../../models/CompanyModel';
import { AuthorityModel } from '../../../models/AuthorityModel';
import { RoleModel } from '../../../models/RoleModel';
import { AssetModel } from '../../../models/AssetModel';
interface lastChangeModel {
  name: string;
  value?: any;
}
const initialState: {
  roleId: number | undefined;
  roleList: RoleModel[];
  companyId: number | undefined;
  companyList: CompanyModel[];
  assets: AssetModel[];
  authorityList: AuthorityModel[];
  authorityId?: number;
  lastChange?: lastChangeModel;
} = {
  roleId: undefined,
  roleList: [],
  companyId: undefined,
  authorityList: [],
  assets: [],
  companyList: [],
};

export const userCreateSlice = createSlice({
  name: 'userCreate',
  initialState,
  reducers: {
    setLastChange: (
      state,
      action: PayloadAction<lastChangeModel | undefined>
    ) => {
      state.lastChange = action.payload;
    },
    setRoleId: (state, action: PayloadAction<number | string | undefined>) => {
      state.roleId = action.payload ? +action.payload : undefined;
    },
    setAuthorityId: (
      state,
      action: PayloadAction<number | string | undefined>
    ) => {
      state.authorityId = action.payload ? +action.payload : undefined;
    },
    setAutorityList: (state, action: PayloadAction<AuthorityModel[]>) => {
      state.authorityList = action.payload;
    },
    setRoleList: (state, action: PayloadAction<RoleModel[]>) => {
      state.roleList = action.payload;
    },
    setCompanyId: (
      state,
      action: PayloadAction<string | number | undefined>
    ) => {
      state.companyId = action.payload ? +action.payload : undefined;
    },
    setCompanyList: (state, action: PayloadAction<CompanyModel[]>) => {
      state.companyList = action.payload;
    },
    setAssets: (state, action: PayloadAction<AssetModel[]>) => {
      state.assets = action.payload;
    },
  },
});
export const {
  setRoleId,
  setLastChange,
  setRoleList,
  setAssets,
  setCompanyId,
  setCompanyList,
  setAutorityList,
  setAuthorityId,
} = userCreateSlice.actions;

export const getListCompany = (): AppThunk => dispatch => {
  getCompanyList().then(res => {
    dispatch(setCompanyList(res));
  });
};

export const getListRole = (): AppThunk => dispatch => {
  getRolesList().then(res => {
    dispatch(setRoleList(res));
  });
};

export const resetCreateSlice = (): AppThunk => dispatch => {
  dispatch(setAssets([]));
  dispatch(setRoleId());
  dispatch(setCompanyId());
  dispatch(setCompanyList([]));
  dispatch(setAutorityList([]));
  dispatch(setAuthorityId());
  dispatch(setLastChange(undefined));
};

export const setLastChangeAction =
  (value: any = undefined, name?: string): AppThunk =>
  dispatch => {
    name
      ? dispatch(setLastChangeActionSuccess({ name, value }))
      : dispatch(setLastChange(undefined));
  };

export const setLastChangeActionSuccess =
  (data: lastChangeModel): AppThunk =>
  dispatch => {
    dispatch(setLastChange(data));
    const obj: { [key: string]: () => void } = {
      company: () => dispatch(setCompanyId(data.value)),
      role: () => {
        dispatch(setRoleId(data.value));
        dispatch(setAssets([]));
      },
      authorityId: () => dispatch(setAuthorityId(data.value)),
    };
    obj && data && data.name && obj[data.name] && obj[data.name]();
  };

export const loadAssetsActions =
  (
    companyId: string | number | undefined,
    fullAccess: boolean = false
  ): AppThunk =>
  dispatch => {
    if (companyId) {
      dispatch(loadAssets(+companyId, fullAccess));
    } else {
      dispatch(setAssets([]));
    }
  };

export const loadAssets =
  (companyId: number, fullAccess: boolean = false): AppThunk =>
  (dispatch, getState) => {
    let service = getAssetsList({ companyId, fullAccess });
    const authorityId = getState().userCreate.authorityId;
    if (authorityId) {
      service = getAssetsListByAuthority(authorityId);
    }
    service.then(res => {
      dispatch(setAssets(res));
    });
  };

export const loadAuthorities =
  (companyId: number): AppThunk =>
  dispatch => {
    getListAuthority(companyId).then(
      res => {
        dispatch(setAutorityList(res));
      },
      err => {
        dispatch(setAutorityList([]));
      }
    );
  };

export const selectCompanyList = (state: RootState) =>
  state.userCreate.companyList;
export const selectAssets = (state: RootState) => state.userCreate.assets;
export const selectLastChange = (state: RootState) =>
  state.userCreate.lastChange;
export const selectCompanyId = (state: RootState) => state.userCreate.companyId;
export const selectAuthorityId = (state: RootState) =>
  state.userCreate.authorityId;
export const selectRoleList = (state: RootState) => state.userCreate.roleList;
export const selectAuthorityList = (state: RootState) =>
  state.userCreate.authorityList;
export const selectRoleId = (state: RootState) => state.userCreate.roleId;

export default userCreateSlice.reducer;
