import React from 'react';
interface IconProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  icon: string;
  size?: string;
  color?: string;
  disabled?: boolean;
  pointer?: boolean;
  label?: string;
}

const Icon = ({
  icon,
  size = '',
  color = '',
  children,
  className = '',
  disabled = false,
  label = '',
  pointer = true,
  ...rest
}: IconProps) => {
  const classDisabled = disabled ? 'disabled' : '';

  return (
    <div
      className={
        'shared-icon ' +
        className +
        ' ' +
        (!disabled && pointer ? 'pointer' : '')
      }
      {...rest}
    >
      <span
        style={{ fontSize: size + 'px' }}
        className={
          'icon ' +
          'icon-' +
          icon +
          ' ' +
          size +
          ' ' +
          color +
          ' ' +
          classDisabled +
          ' '
        }
      ></span>
      {label ? (
        <span className={classDisabled + ' iconLabel'}>{label}</span>
      ) : null}
    </div>
  );
};

export default Icon;
