import React from 'react';
import StateIcon from '../form/stateLabel/stateIcon/StateIcon';
import Caption from '../headings/caption/Caption';
import { LegendModel } from './models/LegendModel';
interface LegendProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  list: LegendModel[];
}

const Legend = ({ children, className, list, ...rest }: LegendProps) => {
  const itemList = list.map((ele, index) => {
    return (
      <StateIcon
        key={index}
        color={ele.color}
        colorStyle={ele.colorStyle}
        label={ele.label}
      ></StateIcon>
    );
  });
  return (
    <div className="d-flex align-items-center">
      <Caption className="medium"> Legenda: </Caption>
      {itemList}
    </div>
  );
};

export default Legend;
