import React from 'react';
import Tag from '../../../../../../components/shared/tag/Tag';

const HeadingError = ({ label, value }: { label: string; value: string }) => {
  const isNotValue: boolean =
    value === undefined || value === null || value === '';
  return (
    <div className="sharedHeadingError">
      <span>{label}</span>
      <Tag label={isNotValue ? '-' : value}></Tag>
    </div>
  );
};

export default HeadingError;
