import React from 'react';
import { FormControl } from 'react-bootstrap';
import { mergeClass } from '../../functions/function';
import { TextAreaType } from '../../../../utils/forms/models/FormElementModel';

const TextArea: React.FC<TextAreaType> = ({
  children,
  className,
  error = false,
  ...rest
}) => {
  const classMerge: string = mergeClass(
    'shared-textArea',
    error ? ' error' : ''
  );

  return <FormControl as="textarea" rows={3} {...rest} />;
};

export default TextArea;
