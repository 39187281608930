import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../../app/store';
import { httpBase } from '../../../../../utils/http';
import { saveAs } from 'file-saver';
import { resetErrors } from '../../../../../app/errors/errorSlice';
import { GeneratedFileListRes } from '../models/ReportsModel';

const initialState: {
  drawerId?: string;
  fileLoadedList: string[];
  fileErrorList: string[];
  modalErrors: any[];
  generatedFilesList: string[];
} = {
  fileLoadedList: [],
  fileErrorList: [],
  modalErrors: [],
  generatedFilesList: [],
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setDrawerId: (state, action: PayloadAction<string>) => {
      state.drawerId = action.payload;
    },
    setModalErrors: (state, action: PayloadAction<any>) => {
      state.modalErrors = action.payload;
    },
    resetDrawerId: state => {
      state.drawerId = undefined;
    },
    setFileLoadedList: (state, action: PayloadAction<string>) => {
      state.fileLoadedList = [...state.fileLoadedList, action.payload];
    },
    setFileErrorList: (state, action: PayloadAction<string>) => {
      state.fileErrorList = [...state.fileErrorList, action.payload];
    },
    resetFileLoadedList: state => {
      state.fileLoadedList = [];
    },
    resetFileErrorList: state => {
      state.fileErrorList = [];
    },
    resetModalErrors: state => {
      state.modalErrors = [];
    },
    setGeneratedFilesList: (state, action: PayloadAction<string[]>) => {
      state.generatedFilesList = action.payload;
    },
  },
});
export const {
  setDrawerId,
  setModalErrors,
  resetDrawerId,
  setFileLoadedList,
  setFileErrorList,
  resetFileErrorList,
  resetFileLoadedList,
  resetModalErrors,
  setGeneratedFilesList,
} = reportSlice.actions;

export const exportTableAction =
  (table: string, surveyType: string, surveySubType: string): AppThunk =>
  (dispatch, getState) => {
    const assetId = getState().filterDashboard.assetId;
    const periodId = getState().filterDashboard.periodId;
    const loadedId = getState().filterDashboard.loadedFileId;
    const tenantId = getState().filterDashboard.tenant;
    httpBase
      .get(`survey/download/${table}/${assetId}/${periodId}`, {
        headers: { 'X-TenantID': tenantId },
      })
      .then(res => {
        const fileName = res.headers['content-disposition']
          ? res.headers['content-disposition'].split('filename=')[1]
          : 'undefined.xml';

        const blob = new Blob([res.data], {
          type: res.headers['content-type'],
        });
        saveAs(blob, fileName);
      });
  };

export const exportTableStreamAction =
  (
    table: string,
    success: () => void,
    error?: (err: Error) => void
  ): AppThunk =>
  (dispatch, getState) => {
    const assetId = getState().filterDashboard.assetId;
    const periodId = getState().filterDashboard.periodId;
    const tenantId = getState().filterDashboard.tenant;
    httpBase
      .post(
        `survey/stream/${table}/${assetId}/${periodId}`,
        {},
        {
          headers: { 'X-TenantID': tenantId },
        }
      )
      .then(
        () => success(),
        err => error && error(err)
      );
  };

export const exportCovipAction =
  (surveyType: string): AppThunk =>
  (dispatch, getState) => {
    const assetId = getState().filterDashboard.assetId;
    const periodId = getState().filterDashboard.periodId;
    const tenantId = getState().filterDashboard.tenant;
    httpBase
      .get(`survey/download/agg/${surveyType}/${assetId}/${periodId}`, {
        headers: { 'X-TenantID': tenantId },
      })
      .then(res => {
        const fileName = res.headers['content-disposition']
          ? res.headers['content-disposition'].split('filename=')[1]
          : 'undefined.xml';

        const blob = new Blob([res.data], {
          type: res.headers['content-type'],
        });
        saveAs(blob, fileName);
      });
  };

export const exportCovipStreamAction =
  (
    surveyType: string,
    success: () => void,
    error?: (err: Error) => void
  ): AppThunk =>
  (dispatch, getState) => {
    const assetId = getState().filterDashboard.assetId;
    const periodId = getState().filterDashboard.periodId;
    const tenantId = getState().filterDashboard.tenant;
    httpBase
      .post(
        `survey/stream/agg/${surveyType}/${assetId}/${periodId}`,
        {},
        {
          headers: { 'X-TenantID': tenantId },
        }
      )
      .then(
        () => success(),
        err => error && error(err)
      );
  };

export const exportEmptyAction =
  (surveyType: string): AppThunk =>
  (dispatch, getState) => {
    const assetId = getState().filterDashboard.assetId;
    const periodId = getState().filterDashboard.periodId;
    const tenantId = getState().filterDashboard.tenant;
    httpBase
      .get(`survey/download/agg/empty/${surveyType}/${assetId}/${periodId}`, {
        headers: { 'X-TenantID': tenantId },
      })
      .then(res => {
        const fileName = res.headers['content-disposition']
          ? res.headers['content-disposition'].split('filename=')[1]
          : 'undefined.xml';

        const blob = new Blob([res.data], {
          type: res.headers['content-type'],
        });
        saveAs(blob, fileName);
      });
  };

export const generateAuthorityFile =
  (
    surveyType: string,
    success: () => void,
    error?: (err: Error) => void
  ): AppThunk =>
  (dispatch, getState) => {
    const assetId = getState().filterDashboard.assetId;
    const periodId = getState().filterDashboard.periodId;
    const tenantId = getState().filterDashboard.tenant;
    const assetQuery = assetId ? '/' + assetId : '';
    httpBase
      .post(
        `survey/stream/authority/${surveyType}/${periodId}${assetQuery}`,
        {},

        {
          ...(tenantId && { headers: { 'X-TenantID': tenantId } }),
        }
      )
      .then(
        () => success(),
        err => error && error(err)
      );
  };

export const getGeneratedFilesList =
  (authority: boolean): AppThunk =>
  (dispatch, getState) => {
    const tenantId = getState().filterDashboard.tenant;
    if (!authority) {
      httpBase
        .get<GeneratedFileListRes>(`survey/stream`, {
          headers: { 'X-TenantID': tenantId },
        })
        .then(({ data }) => {
          dispatch(setGeneratedFilesList(data.payload));
        });
    } else {
      const assetId = getState().filterDashboard.assetId;
      const periodId = getState().filterDashboard.periodId;
      const assetQuery = assetId ? '/' + assetId : '';
      httpBase
        .get<GeneratedFileListRes>(
          `survey/stream/authority/${periodId}${assetQuery}`,
          {
            ...(tenantId && { headers: { 'X-TenantID': tenantId } }),
          }
        )
        .then(({ data }) => {
          dispatch(setGeneratedFilesList(data.payload));
        });
    }
  };

export const downloadFileZip =
  (authority: boolean, fileName: string): AppThunk =>
  (dispatch, getState) => {
    const tenantId = getState().filterDashboard.tenant;
    if (!authority) {
      httpBase
        .get(`survey/stream/download`, {
          params: { fileName },
          headers: { 'X-TenantID': tenantId },
          responseType: 'blob',
        })
        .then(res => {
          const _fileName = res.headers['content-disposition']
            ? res.headers['content-disposition'].split('filename=')[1]
            : fileName;

          const blob = new Blob([res.data], {
            type: res.headers['content-type'],
          });
          saveAs(blob, _fileName);
        });
    } else {
      const assetId = getState().filterDashboard.assetId;
      const periodId = getState().filterDashboard.periodId;
      const assetQuery = assetId ? '/' + assetId : '';
      httpBase
        .get(
          `survey/stream/authority/download/${periodId}/${assetQuery}/${fileName}`,
          {
            ...(tenantId && { headers: { 'X-TenantID': tenantId } }),
            responseType: 'blob',
          }
        )
        .then(res => {
          const _fileName = res.headers['content-disposition']
            ? res.headers['content-disposition'].split('filename=')[1]
            : fileName;

          const blob = new Blob([res.data], {
            type: res.headers['content-type'],
          });
          saveAs(blob, _fileName);
        });
    }
  };

export const resetReport = (): AppThunk => (dispatch, getState) => {
  dispatch(resetDrawerId());
  dispatch(resetFileLoadedList());
};

const callData = (file: any, tenantId?: string, drawerId?: string) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append('file', file);
    httpBase
      .post(
        `survey/merge/upload${drawerId ? `?drawerId=${drawerId}` : ''}`,
        formData,
        { ...(tenantId && { headers: { 'X-TenantID': tenantId } }) }
      )
      .then(
        res => {
          resolve(res.data);
        },
        err => reject(err)
      );
  });
};

export const callDataFile =
  (index: number = 0, listFile: any[], callBack?: () => void): AppThunk =>
  (dispatch, getState) => {
    const tenantId = getState().filterDashboard.tenant;
    const drawerId = getState().report.drawerId;
    if (listFile && listFile.length) {
      callData(listFile[index], tenantId, drawerId).then(
        (res: any) => {
          dispatch(setFileLoadedList(listFile[index].name));
          if (listFile.length - 1 > index) {
            !drawerId && dispatch(setDrawerId(res.payload.drawerId));
            dispatch(callDataFile(index + 1, listFile, callBack));
          } else {
            dispatch(exportMergeXml(callBack));
          }
        },
        err => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.code
          ) {
            dispatch(setModalErrors([{ code: err.response.data.code }]));
            dispatch(setFileErrorList(listFile[index].name));
          }
          dispatch(resetErrors());
        }
      );
    } else {
      dispatch(exportMergeXml(callBack));
    }
  };

export const exportMergeXml =
  (callBack?: () => void): AppThunk =>
  (dispatch, getState) => {
    const tenantId = getState().filterDashboard.tenant;
    const drawerId = getState().report.drawerId;
    httpBase
      .get(`survey/merge/download/${drawerId}`, {
        responseType: 'blob',
        ...(tenantId && { headers: { 'X-TenantID': tenantId } }),
      })
      .then(res => {
        const blob = new Blob([res.data], {
          type: res.headers['content-type'],
        });
        saveAs(blob, `${drawerId}.zip`);
        dispatch(resetErrors());
        callBack && callBack();
      });
  };

export const selectDrawerId = (state: RootState) => state.report.drawerId;
export const selectModalErrors = (state: RootState) => state.report.modalErrors;
export const selectFileLoadedList = (state: RootState) =>
  state.report.fileLoadedList;
export const selectFileErrorList = (state: RootState) =>
  state.report.fileErrorList;
export const selectGeneratedFilesList = (state: RootState) =>
  state.report.generatedFilesList;

export default reportSlice.reducer;
