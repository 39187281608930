import React from 'react';
import {
  TableData,
  tableObjPropsWithAction,
} from '../../../../../../../components/shared/table/models/TableProps';
import TableWithAction from '../../../../../../../components/shared/table/table/TableWithAction';

export interface LogicalTableErrorProps
  extends Partial<tableObjPropsWithAction> {
  setSelectedLogicalError: (data: any) => void;
}
const LogicalTableError = ({
  setSelectedLogicalError,
  ...rest
}: LogicalTableErrorProps) => {
  const tableStructure: TableData[] = [
    {
      name: 'guuid',
      label: 'Id errore',
      width: '25%',
    },
    {
      name: 'errorDescription',
      label: 'Descrizione Errore',
    },
    {
      name: 'fieldsInError',
      label: 'Campi in errore',
      width: '1%',
    },
  ];

  return (
    <TableWithAction
      columns={tableStructure}
      clickRow={(row, data) => setSelectedLogicalError(data)}
      {...rest}
    />
  );
};

export default LogicalTableError;
