import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SecondaryButton from '../../../../../components/shared/form/buttons/secondary-button/SecondaryButton';
import PrimaryButton from '../../../../../components/shared/form/buttons/primary-button/PrimaryButton';
import ModalSwitchTable1 from './ModalSwitchTable1';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectListLoadQuery,
  selectShowModalQuery,
  setShowModal,
  resetShowModal,
} from '../table1Slice';
interface SaveLoadQueryTable1Props {
  loadState: (data: any) => void;
  saveState: (name: string) => void;
}

const SaveLoadQueryTable1 = ({
  loadState,
  saveState,
}: SaveLoadQueryTable1Props) => {
  const listLoad = useSelector(selectListLoadQuery);
  const showModal = useSelector(selectShowModalQuery);
  const dispatch = useDispatch();
  return (
    <>
      {showModal ? (
        <ModalSwitchTable1
          list={listLoad}
          saveState={saveState}
          loadState={loadState}
          view={showModal}
          resetModal={() => dispatch(resetShowModal())}
        />
      ) : null}

      <Row className="mt-3 justify-content-center">
        <Col xs="6" md="3">
          <SecondaryButton
            onClick={() => dispatch(setShowModal('load'))}
            disabled={!listLoad || !listLoad.length}
          >
            Carica
          </SecondaryButton>
        </Col>
        <Col xs="6" md="3">
          <PrimaryButton onClick={() => dispatch(setShowModal('save'))}>
            Salva
          </PrimaryButton>
        </Col>
      </Row>
    </>
  );
};

export default SaveLoadQueryTable1;
