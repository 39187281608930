import { TableData } from '../../../../../../components/shared/table/models/TableProps';

export const tableSearchStructure: TableData[] = [
  {
    name: 'name',
    label: 'Campo',
  },
  {
    name: 'description',
    label: 'Descrizione',
  },
  {
    name: 'condition',
    label: 'Condizione',
  },
  {
    name: 'valueBy',
    label: 'Valore da',
  },
];
