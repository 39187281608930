import React from 'react';
import ListLoadTable from './ListLoadTable';
import ChooseLoadName from './ChooseLoadName';
import { loadQuery } from '../models/LoadQuery';

const ModalSwitchTable1 = ({
  view,
  saveState,
  resetModal,
  list,
  loadState,
}: {
  view: string;
  saveState: (data: string) => void;
  resetModal: () => void;
  list: loadQuery[];
  loadState: (data: any) => void;
}) => {
  const listTemplate = (
    <ListLoadTable list={list} action={loadState} closeAction={resetModal} />
  );
  const saveTemplate = (
    <ChooseLoadName saveState={saveState} closeAction={resetModal} />
  );
  return view === 'load' ? listTemplate : saveTemplate;
};

export default ModalSwitchTable1;
