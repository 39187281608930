export const getQuery = () => {
  return decodeURI(window.location.search)
    .replace('?', '')
    .split('&')
    .map(param => param.split('='))
    .reduce(
      (
        values: { [key: string]: string },
        [key, value]: string[]
      ): {
        [key: string]: string;
      } => {
        values[key] = value;
        return values;
      },
      {}
    );
};

export const getParameterQuery = (parameter: string): string | null => {
  return getQuery()[parameter] ? getQuery()[parameter] : null;
};
