import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BackBtn from '../../../../../components/shared/backBtn/BackBtn';
import {
  selectResultMoreVds,
  resetResultMoreVds,
  selectSelectedRowFile,
  resetSelectedRowFile,
} from './vdsSlice';
import vdsEvent from './vdsEvent';

const VdsBackDashboard = () => {
  const dispatch = useDispatch();
  const selectedRowFile = useSelector(selectSelectedRowFile);
  const resultMoreVds = useSelector(selectResultMoreVds);

  const goBackAction = () => {
    vdsEvent && vdsEvent.events && vdsEvent.events.backVds
      ? vdsEvent.events.backVds()
      : selectedRowFile
      ? dispatch(resetSelectedRowFile())
      : dispatch(resetResultMoreVds());
  };
  return <>{resultMoreVds && <BackBtn onClick={goBackAction}></BackBtn>}</>;
};

export default VdsBackDashboard;
