import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { onceSub } from '../../../app/clientSubscribe';
import { addError } from '../../../app/errors/errorSlice';
import { AppThunk, RootState } from '../../../app/store';
import {
  decrementN,
  incrementN,
} from '../../../components/shared/loader/redux/loadSlice';
import { TableObjProps } from '../../../components/shared/table/models/TableProps';
import {
  historyDatabaseCall,
  runUpdateCall,
  statusDatabaseCall,
} from './database.service';

export type TableObjPropsDatabase = TableObjProps<any, { fundCode: string }>;
export interface DashboardDatabaseSliceModel {
  listDatabase?: TableObjPropsDatabase;
  historyDatabase: any[];
}
const initialState: DashboardDatabaseSliceModel = {
  historyDatabase: [],
};

export const databaseSlice = createSlice({
  name: 'database',
  initialState,
  reducers: {
    setHistory: (state, action: PayloadAction<any[]>) => {
      state.historyDatabase = action.payload;
    },
    resetHistory: state => {
      state.historyDatabase = [];
    },
    setListDatabase: (state, action: PayloadAction<TableObjPropsDatabase>) => {
      state.listDatabase = action.payload;
    },
  },
});
export const { setHistory, setListDatabase, resetHistory } =
  databaseSlice.actions;

export const databaseStatusAction =
  (objPage?: TableObjPropsDatabase): AppThunk =>
    (dispatch, getState) => {
      statusDatabaseCall({
        page: objPage && objPage.page,
        search: objPage && objPage.search,
      }).then(res =>
        dispatch(
          setListDatabase({
            data: res.results.map((ele: any) => ({
              ...ele,
              period: `${String(ele.period).slice(0, 4)}-${String(ele.period).slice(4, -2)}`,
              dateLastVersion: ele.dateLastVersion?.substring(0, 10),
            })),
            page: objPage?.page || 1,
            pages: res.pages || objPage?.pages,
            count: res.count || objPage?.count,
          })
        )
      );
    };

export const databaseHistoryAction =
  (name: string): AppThunk =>
    (dispatch, getState) => {
      historyDatabaseCall(name).then(res => {
        dispatch(setHistory(res));
      });
    };

type DbResponse = {
  nomeDb: string;
  dbType: number;
  userId: number;
  uuid: string;
  status: string;
  exception: string;
  operation: string;
};

export const databaseUpdateAction =
  (tenantId: string, state?: TableObjPropsDatabase): AppThunk =>
    (dispatch, getState) => {
      dispatch(incrementN());
      runUpdateCall(tenantId)
        .then(() => onceSub<DbResponse>(`/id/${tenantId}/db`))
        .then(res => {
          if (
            !isEmpty(res) &&
            res.status !== 'Error' &&
            res.operation === 'UPDATE_DB'
          ) {
            dispatch(
              databaseStatusAction(state?.search?.fundCode ? state : undefined)
            );
          } else {
            const error = `Errore durante l'aggiornamento del db ${res?.nomeDb}. Se il problema persiste, contattare l'assistenza!`;
            console.error(error, res);
            throw new Error(error);
          }
        })
        .catch(err => {
          dispatch(addError(err.message));
        })
        .finally(() => dispatch(decrementN()));
    };

export const selectHistoryDatabase = (state: RootState) =>
  state.database.historyDatabase;
export const selectListDatabase = (state: RootState) =>
  state.database.listDatabase;

export default databaseSlice.reducer;
