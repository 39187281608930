import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ErrorStateProps } from '../../components/shared/form/models/FormModule';

const initialState: { errors: ErrorStateProps[] } = {
  errors: [],
};

export const errorSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    addError: (
      state,
      action: PayloadAction<string | Partial<ErrorStateProps>>
    ) => {
      if (!state.errors.find(ele => action.payload === ele.code)) {
        const data: Partial<ErrorStateProps> =
          typeof action.payload === 'string'
            ? { code: action.payload }
            : { ...action.payload };
        state.errors.push(getErrorObj(data));
      }
    },
    addSuccessMessage: (
      state,
      action: PayloadAction<string | Partial<ErrorStateProps>>
    ) => {
      const data: Partial<ErrorStateProps> =
        typeof action.payload === 'string'
          ? { code: action.payload, success: true }
          : { ...action.payload, success: true };
      state.errors.push(getErrorObj(data));
    },
    setErrorToRemove: state => {
      state.errors = state.errors
        .filter(ele => ele.remove < 1)
        .map(ele => ({ ...ele, remove: ele.remove + 1 }));
    },
    resetErrors: state => {
      state.errors = [];
    },
    removeError: (state, action: PayloadAction<number>) => {
      state.errors = state.errors.filter(el => el.id !== action.payload);
    },
  },
});

export const {
  addError,
  resetErrors,
  removeError,
  setErrorToRemove,
  addSuccessMessage,
} = errorSlice.actions;

export const getErrorObj = ({
  success = false,
  remove = 0,
  code = '',
}: Partial<ErrorStateProps>) => {
  const id: number = Date.now() * Math.round(Math.random() * 100);
  return { code, id, success, remove: remove };
};
export const selectErrors = (state: RootState) => state.errors.errors;

export default errorSlice.reducer;
