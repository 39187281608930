import { ConfigModel } from './models/SliceVdsModel';

export const configInitList: ConfigModel[] = [
  {
    description: 'Codice  ISIN titolo/OICR/DERIVATO',
    name: 'c3823',
    type: 'K',
  },
  {
    description: 'Codice fiscale COVIP',
    name: 'c3090',
    type: 'K',
  },
  {
    description: 'Codice iscritto',
    name: 'c3920',
    type: 'D',
  },
];
