import React from 'react';
import { TableProps } from '../../../../models/TableProps';
import TableCol from './TableCol';
import { classByArray } from '../../../../../functions/function';

export interface TablePropsRow extends TableProps {
  row: any;
  indexRow: number;
}

const TableRow = ({
  columns,
  data = [],
  templates = {},
  templateRow,
  rowIndexActive = [],
  rowIndexTemplate = [],
  clickRow,
  row,
  indexRow,
  disabledRow,
}: TablePropsRow) => {
  const listClass = classByArray([
    ...(rowIndexActive.includes(row.id) ? ['rowActive'] : []),
    ...(clickRow ? ['row-pointer'] : []),
    ...(disabledRow && disabledRow(row) ? ['row-disabled'] : []),
  ]);
  return (
    <>
      <tr
        onClick={(event: React.MouseEvent) =>
          clickRow && clickRow(event, row, indexRow)
        }
        className={listClass}
      >
        {columns.map(col => (
          <TableCol
            key={col.name}
            col={col}
            row={row}
            template={templates[col.name]}
            indexRow={indexRow}
          />
        ))}
      </tr>
      {templateRow && rowIndexTemplate.includes(indexRow) && (
        <tr className={rowIndexActive.includes(row.id) ? 'rowActive' : ''}>
          <td colSpan={columns.length} className="contentRow">
            {templateRow(row)}
          </td>
        </tr>
      )}
    </>
  );
};

export default TableRow;
