import React from 'react';
import ContentBoxEmpty from '../../../../../components/shared/contentBox/contentBoxEmpty/ContentBoxEmpty';

const EmptyDashboard = ({
  isPermissionFundCombo,
  issetAssetAndPeriod,
}: {
  isPermissionFundCombo: boolean;
  issetAssetAndPeriod: boolean;
}) => {
  const label = isPermissionFundCombo
    ? issetAssetAndPeriod
      ? 'Nessun risultato'
      : 'Selezionare fondo e periodo'
    : 'Selezionare Periodo';
  return (
    <div>
      <ContentBoxEmpty label={label} />
    </div>
  );
};

export default React.memo(EmptyDashboard);
