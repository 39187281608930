import React from 'react';
import FormGroup from '../../../../../../components/shared/form/form-group/FormGroup';
import Select from '../../../../../../components/shared/form/select/Select';
import { useSelector } from 'react-redux';
import { selectFields } from '../errorDashboardSlice';

const SelectFilter = ({
  value = '-1',
  changeAction,
}: {
  value?: string;
  changeAction: (value: any) => void;
}) => {
  const fields = useSelector(selectFields);
  return (
    <div>
      <FormGroup
        display="inlineForm"
        label={{
          label: 'Scegli il campo per effettuare una correzione massiva',
        }}
      >
        <Select
          value={value}
          options={[
            { label: 'tutti', value: '-1' },
            ...fields.map(ele => ({ label: ele, value: ele })),
          ]}
          changeSelect={changeAction}
        ></Select>
      </FormGroup>
    </div>
  );
};

export default SelectFilter;
