import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'react-bootstrap';
import { HeaderFlowProps } from '../../models/FlowProps';
import LoadFlowFile from '../loadFlowFile/LoadFlowFile';
import { resetErrors } from '../../../../../../app/errors/errorSlice';
import Legend from '../../../../../../components/shared/legend/Legend';
import {
  deleteDatabase,
  exportDashboardAction,
  runStepAction,
  selectShowFlowNextStepModal,
  setDeleteModal,
  setShowFlowNextStepModal,
  setToolBarIconFlussi,
} from '../../DashboardSlice';
import ListStepHeaderFlow from './modal-step-flow/ListStepHeaderFlow';
import { selectUserPermissions } from '../../../../../../app/auth/authSlice';
import {
  issetPermissions,
  permissionType,
} from '../../../../../../app/rolePermissions';
import toolbarEvents from '../../components/toolbarEvents';
import { ToolbarIconProps } from '../../../../../../components/shared/toolbarWithDrop/models/ToolbarIconProps';

const HeaderFlow = ({
  flowNextStep = [],
  listLegend,
  loadedFile,
}: HeaderFlowProps) => {
  const dispatch = useDispatch();
  const showFlowNextStepModal = useSelector(selectShowFlowNextStepModal);

  const userPermissions = useSelector(selectUserPermissions);
  const hasPermission = issetPermissions(userPermissions, [
    permissionType.EDIT_FLOWS,
  ]);

  const hasPermissionDelete = issetPermissions(userPermissions, [
    permissionType.DELETE_PERIODS,
  ]);
  useEffect(() => {
    const toolBarIconFlussi: ToolbarIconProps[] = [
      {
        icon: 'play',
        name: 'Esegui flussi',
        action: 'run',
        disabled: !(flowNextStep && flowNextStep.length),
        visible: hasPermission,
      },
      {
        icon: 'trash',
        name: 'Elimina',
        action: 'deleteAll',
        visible: hasPermissionDelete,
      },
      {
        icon: 'export',
        name: 'Esporta',
        action: 'export',
        disabled: false,
        visible: true,
      },
      {
        icon: 'upload-f',
        name: 'Carica flusso',
        action: 'import',
        visible: hasPermission,
      },
    ];
    dispatch(setToolBarIconFlussi(toolBarIconFlussi));
  }, [flowNextStep, hasPermission, loadedFile]);

  useEffect(() => {
    return () => {
      toolbarEvents.events = () => {};
      dispatch(setToolBarIconFlussi([]));
    };
  }, []);

  const runStep = () => {
    dispatch(setShowFlowNextStepModal(true));
  };
  const exportDashboard = () => {
    dispatch(exportDashboardAction());
  };
  toolbarEvents.events = (action: string) => {
    func && func[action] && func[action]();
  };
  const func: { [key: string]: (data?: any) => void } = {
    deleteAll: () => deleteAll(),
    import: () => showDropzoneAction(),
    export: () => exportDashboard(),
    run: () => runStep(),
  };
  const [showDropZone, setShowDropZone] = useState(false);
  const deleteAll = () => {
    dispatch(
      setDeleteModal({
        text: {
          title: 'Eliminazione DataBese Fondo',
          body: `Sei sicuro di voler eliminare i dati caricati nel periodo fondo?`,
        },
        action: 'deleteDBAction',
      })
    );
  };
  const showDropzoneAction = () => {
    dispatch(resetErrors());
    setShowDropZone(true);
  };

  const closeDropzone = () => setShowDropZone(false);
  const closeModal = () => {
    dispatch(setShowFlowNextStepModal(false));
  };
  const startRun = (req: any) => {
    dispatch(runStepAction(req, closeModal));
  };

  return (
    <>
      {showDropZone && <LoadFlowFile closeAction={closeDropzone} />}
      {showFlowNextStepModal && (
        <ListStepHeaderFlow
          list={flowNextStep}
          closeAction={() => dispatch(setShowFlowNextStepModal(false))}
          action={startRun}
        />
      )}
      <Row className="align-items-end justify-content-end">
        <Col>
          <Legend list={listLegend}></Legend>
        </Col>
      </Row>
    </>
  );
};

export default HeaderFlow;
