import { TableData } from '../../../../../../components/shared/table/models/TableProps';

const tableDetailRowBase: TableData[] = [
  {
    name: 'name',
    label: 'Campo',
  },
  {
    name: 'description',
    label: 'Descrizione',
  },
];

export const tableDetailRowStructure: TableData[] = [
  ...tableDetailRowBase,
  {
    name: 'value',
    label: 'Valore',
  },
];

export const tableDetailRowEditStructure: TableData[] = [
  ...tableDetailRowBase,
  {
    name: 'valueData',
    label: 'Valore',
  },
];

export const tableDetailRowDuplicateStructure: TableData[] = [
  ...tableDetailRowBase,
  {
    name: 'value',
    label: 'Valore',
  },
  {
    name: 'valueData',
    label: 'Valore',
  },
];

export const dataTableRow = {
  base: tableDetailRowStructure,
  edit: tableDetailRowEditStructure,
  duplicate: tableDetailRowDuplicateStructure,
  insert: tableDetailRowEditStructure,
};

export const fixedData = {
  loadedId: 'loadedId',
  codeFound: 'c3468',
  vds: 'c0001',
};
