import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  DownloadFileExportAction,
  GetListExportAction,
  getListVds,
  resetVdsSlice,
  searchVdsAction,
  selectConfigListVds,
  selectListFileVds,
  selectListVds,
  selectResultMoreVds,
  selectSelectedRowFile,
  selectSelectedVds,
  VdsExportPrepareAction,
} from './vdsSlice';

import SearchVds from './SearchVds';
import ResultSearch from './ResultSearch';
import headerData from '../../../../../components/core/HeaderData';
import VdsBackDashboard from './VdsBackDashboard';
import { setToolBarIconFlussi } from '../DashboardSlice';
import ListFileExportVds from './components/ListFileExportVds';
import toolBarEvent from '../components/toolbarEvents';
import ModalInfoLoad from './components/ModalInfoLoad';
import { ToolbarIconProps } from '../../../../../components/shared/toolbarWithDrop/models/ToolbarIconProps';

const ManagementVds = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const listVds = useSelector(selectListVds);
  const listConfigVds = useSelector(selectConfigListVds);
  const selectedVds = useSelector(selectSelectedVds);
  const resultMoreVds = useSelector(selectResultMoreVds);
  const listfileVds = useSelector(selectListFileVds);
  const selectedRowFile = useSelector(selectSelectedRowFile);
  const [listFileShow, setListFileShow] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    const toolBarIconFlussi: ToolbarIconProps[] = [
      {
        icon: 'file-text',
        name: 'List File',
        action: 'files',
        disabled: listfileVds.length === 0,
        visible: true,
        info:
          listfileVds && listfileVds.length
            ? listfileVds.length.toString()
            : undefined,
      },
      {
        icon: 'export',
        name: 'Esporta',
        action: 'export',
        disabled: !!selectedRowFile || !resultMoreVds,
        visible: true,
        dropdownData: [
          { labelDrop: 'CSV', iconDrop: 'setting', actionDrop: 'exportCsv' },
          { labelDrop: 'TXT', iconDrop: 'setting', actionDrop: 'exportTxt' },
        ],
      },
    ];
    dispatch(setToolBarIconFlussi(toolBarIconFlussi));
    toolBarEvent.events = switchToolbarAction;
    headerData.headers = <VdsBackDashboard />;
    return () => {
      headerData.headers = undefined;
    };
  }, [resultMoreVds, selectedRowFile, listfileVds]);

  useEffect(() => {
    dispatch(getListVds());
    dispatch(GetListExportAction());
    return () => {
      dispatch(resetVdsSlice());
      dispatch(setToolBarIconFlussi([]));
    };
  }, []);

  const exportData = (fileType: string) => {
    dispatch(VdsExportPrepareAction(fileType, showModalInfo));
  };
  const showModalInfo = () => setShowModal(true);

  const switchToolbarAction = (action: string) => {
    action && action === 'exportCsv' && exportData('CSV');
    action && action === 'exportTxt' && exportData('TXT');
    action && action === 'files' && setListFileShow(true);
  };
  const downloadFile = (fileData: any) => {
    dispatch(DownloadFileExportAction(fileData));
  };

  const closeAction = () => {
    setShowModal(false);
  };

  return (
    <>
      <ModalInfoLoad
        showModal={showModal}
        closeAction={closeAction}
      ></ModalInfoLoad>
      {listFileShow && (
        <ListFileExportVds
          list={listfileVds}
          action={downloadFile}
          closeAction={() => setListFileShow(false)}
        />
      )}
      {resultMoreVds ? (
        <ResultSearch resultMoreVds={resultMoreVds} />
      ) : (
        <SearchVds
          search={data => dispatch(searchVdsAction(data))}
          listConfigVds={listConfigVds}
          listVds={listVds}
          selectedVds={selectedVds}
        />
      )}
    </>
  );
};

export default ManagementVds;
