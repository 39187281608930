import React from 'react';
import { User } from '../../../../app/auth/model/User';
import { useRoute } from '../../../../utils/useRoute';
import { resetErrors } from '../../../../app/errors/errorSlice';
import { useDispatch } from 'react-redux';
import HeaderLayout from './HeaderLayout';
import MenuLogged from './MenuLogged';
interface HeaderProps {
  user: User | undefined;
  logout: () => void;
}
const Header = ({ logout, user }: HeaderProps) => {
  const { changeRoute } = useRoute();
  const dispatch = useDispatch();
  const changeRouteWithReset = (name: string) => {
    dispatch(resetErrors());
    changeRoute(name);
  };
  return (
    <HeaderLayout
      menuLogged={
        <MenuLogged
          changeRoute={changeRouteWithReset}
          logout={logout}
          title={user?.lastName + ' ' + user?.firstName}
        />
      }
    />
  );
};

export default Header;
