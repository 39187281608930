import React, { useEffect, useRef, useState } from 'react';
import BigIconBtn from '../icon/bigIconBtn/BigIconBtn';
import InfoNumber from '../infoNumber/InfoNumber';
import DropToolbar from './DropToolbar';
import { ToolbarIconProps } from './models/ToolbarIconProps';

interface ToolbarIconItemProps extends ToolbarIconProps {
  clickAction: (action: string) => void;
}

const ToolbarIconItem = ({
  visible,
  info,
  disabled,
  name,
  dropdownData,
  action,
  clickAction,
  ...rest
}: ToolbarIconItemProps) => {
  const [showDrop, setShowDrop] = useState(false);

  const clickHandler = (action: string) => {
    clickAction && clickAction(action);
  };
  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: Event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDrop(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return visible ? (
    <>
      <div>
        {info && <InfoNumber info={info}></InfoNumber>}
        <BigIconBtn
          disabled={disabled}
          title={name}
          key={name}
          {...rest}
          onClick={() => {
            !disabled &&
              (dropdownData && dropdownData.length
                ? setShowDrop(!showDrop)
                : clickHandler(action || ''));
          }}
        ></BigIconBtn>
      </div>
      {showDrop && dropdownData && dropdownData.length && (
        <div ref={dropdownRef}>
          <DropToolbar
            dropList={dropdownData}
            actionDrop={(action: string) => clickHandler(action)}
          ></DropToolbar>
        </div>
      )}
    </>
  ) : null;
};

export default ToolbarIconItem;
