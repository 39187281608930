import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getListConfig,
  removeVdsAction,
  setInsertModeAction,
  setSelectVdsAction,
} from './vdsSlice';
import { FormGroupCustomModel } from '../../../../../utils/forms/models/FormElementModel';
import SelectVds from './components/SelectVds';
import TableSearchVds from './components/TableSearchVds';
import { ConfigModel, VdsModel } from './models/SliceVdsModel';
import { setTitle } from '../../../../../app/page/pageSlice';
interface SearchVds {
  search: (data: any) => void;
  listVds: VdsModel[];
  listConfigVds: ConfigModel[];
  selectedVds?: string;
}
const SearchVds = ({
  search,
  listVds,
  listConfigVds,
  selectedVds,
}: SearchVds) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getListConfig());
  }, [selectedVds]);

  useEffect(() => {
    dispatch(setTitle(t('vds.title')));
  }, []);

  const data: FormGroupCustomModel = {
    typeElement: 'select',
    label: t('vds.search.vds'),
    name: 'vds',
    isSubmit: false,
    errors: {},
    dataElement: {},
    options: [
      { label: t('vds.search.vds_placeholder'), value: '-1' },
      ...listVds.map(el => ({
        label: el.name,
        value: el.name,
      })),
    ],
  };

  const changeValueVds = (value: any) => {
    dispatch(setSelectVdsAction(value));
  };
  const vdsCreate = () => {
    dispatch(setInsertModeAction());
  };
  const vdsRemove = () => {
    dispatch(removeVdsAction());
  };

  return (
    <div>
      <SelectVds
        data={data}
        changeValueVds={changeValueVds}
        selectedVds={selectedVds}
        addVds={vdsCreate}
        removeVds={vdsRemove}
      />
      <TableSearchVds
        listConfigVds={listConfigVds}
        search={search}
        selectedVds={selectedVds}
      />
    </div>
  );
};

export default SearchVds;
