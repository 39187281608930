import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../app/auth/authSlice';
import {
  getListCompany,
  getListRole,
  loadAssetsActions,
  loadAuthorities,
  selectAssets,
  selectAuthorityId,
  selectAuthorityList,
  selectCompanyId,
  selectCompanyList,
  selectRoleId,
  selectRoleList,
  setCompanyId,
  setAuthorityId,
  setRoleId,
  setAutorityList,
  setLastChangeAction,
  selectLastChange,
  resetCreateSlice,
} from './userCreateSlice';
import { UseFormSubmitModel } from '../../../utils/forms/models/UseFormModel';
import { User } from '../../../app/auth/model/User';
import { UserModel } from '../../../models/UserModel';
import { useForm } from '../../../utils/forms/useForm';
import { validationsEditUserForm } from './user-edit/dataUserEditForm';
import { validationsCreateUserForm } from './user-create/dataUserCreateForm';
import RoleMap from '../../../models/RoleMap';
import FormElements from '../../../utils/forms/FormElements';
import ButtonList from '../../../components/shared/form/buttons/button-list/ButtonList';
import { useUserCreate } from './user-create/useUserCreate';
import { isArray } from '../../../utils/arrayUtils';
import { copyObject } from '../../../utils/objUtils';
import HeadingH4 from '../../../components/shared/headings/headingH4/HeadingH4';
import { resetErrors } from '../../../app/errors/errorSlice';

const UserForm = ({
  user,
  submitForm,
}: {
  user?: Partial<User>;
  submitForm: (data: Partial<UserModel>) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userLogged = useSelector(selectUser);
  const companyId = useSelector(selectCompanyId);
  const companyList = useSelector(selectCompanyList);
  const authorityId = useSelector(selectAuthorityId);
  const assets = useSelector(selectAssets);
  const authorities = useSelector(selectAuthorityList);
  const roleList = useSelector(selectRoleList);
  const roleId = useSelector(selectRoleId);
  const lastChange = useSelector(selectLastChange);
  const submitHandler: UseFormSubmitModel = (event, data) => {
    const d = copyObject(data);
    d.assets = isArray(d.assets) ? d.assets : [d.assets];
    dispatch(resetErrors());
    submitForm && submitForm(d);
  };

  const validation = user ? validationsEditUserForm : validationsCreateUserForm;

  const { isSubmit, errors, submitAction, changeValue, dataForm, setData } =
    useForm(submitHandler, validation, {
      company: userLogged?.companyId,
    });

  const { dataFirstRow, dataSecondRow, buttons } = useUserCreate(
    t,
    companyList,
    assets,
    roleList,
    userLogged,
    dataForm,
    authorities,
    user
  );

  useEffect(() => {
    user && setData(user);
  }, [user]);

  useEffect(() => {
    roleId && companyId && roleId == RoleMap.ROLE_AUTHORITY_USER.id
      ? dispatch(loadAuthorities(companyId))
      : dispatch(setAutorityList([]));
  }, [roleId, companyId]);

  useEffect(() => {
    dispatch(getListRole());
    dispatch(getListCompany());
    if (userLogged?.companyId) dispatch(setCompanyId(userLogged?.companyId));
    return () => {
      dispatch(resetCreateSlice());
    };
  }, []);

  useEffect(() => {
    if (
      roleId &&
      companyId &&
      (roleId != RoleMap.ROLE_AUTHORITY_USER.id || authorityId)
    ) {
      dispatch(
        loadAssetsActions(
          companyId,
          !!(roleId && roleId == RoleMap.ROLE_USER_FULL_ACCESS.id)
        )
      );
    }
  }, [companyId, authorityId, roleId]);

  useEffect(() => {
    if (lastChange && lastChange.name) {
      if (lastChange.name === 'company') {
        if (!lastChange.value) {
          setData({ ...dataForm, role: undefined, assets: undefined });
        }
      }
    }
  }, [lastChange]);

  useEffect(() => {
    if (assets && lastChange && lastChange.name === 'role') {
      setData({
        ...dataForm,
        assets:
          lastChange.value && lastChange.value == RoleMap.ROLE_ADMIN.id
            ? assets.map(ele => ele.id)
            : undefined,
        ...(lastChange.value != RoleMap.ROLE_AUTHORITY_USER.id
          ? { authorityId: undefined }
          : {}),
      });
    }
  }, [assets, lastChange]);

  const changeValueHandler = (value: any, name: string) => {
    dispatch(setLastChangeAction(value, name));
    changeValue(value, name);
  };

  return (
    <div>
      <Form className="pt-2" onSubmit={submitAction}>
        <FormElements
          data={dataFirstRow}
          errors={errors}
          dataForm={dataForm}
          isSubmit={isSubmit}
          changeValue={changeValueHandler}
        />
        <HeadingH4>Dati Anagrafici</HeadingH4>
        <FormElements
          data={dataSecondRow}
          errors={errors}
          dataForm={dataForm}
          isSubmit={isSubmit}
          changeValue={changeValueHandler}
        />
        <ButtonList buttons={buttons} />
      </Form>
    </div>
  );
};

export default UserForm;
