import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import SecondaryButton from '../../../../../../../components/shared/form/buttons/secondary-button/SecondaryButton';
import ListElement from '../../../../../../../components/shared/listElement/ListElement';
import { ListElementProps } from '../../../../../../../components/shared/listElement/models/ListElementProps';
import ModalSuccess from '../../../../../../../components/shared/modal/modalSuccess/ModalSuccess';
import { flowNextStepModel } from '../../../models/FlowNextStepModel';
import FormStep6Flow from './FormStep6Flow';

const ListStepHeaderFlow = ({
  list = [],
  action,
  closeAction,
}: Omit<ListElementProps, 'property' | 'list'> & {
  list: flowNextStepModel[];
}) => {
  const [selectStep, setSelectStep] = useState<flowNextStepModel | undefined>();
  const actionHandler = (data: flowNextStepModel) => {
    if (data.etlStepId === 6) {
      setSelectStep(data);
    } else {
      const req = {
        etlStepId: data.etlStepId,
      };
      action && action(req);
    }
  };

  const actionHandlerTable = ({ store2LoadList }: any) => {
    const req = {
      etlStepId: selectStep?.etlStepId,
      store2LoadList,
    };
    action && action(req);
  };

  return (
    <ModalSuccess
      text={{ title: 'Scegli lo step da avviare' }}
      templateBody={
        <>
          {selectStep ? (
            <FormStep6Flow
              selectedElement={selectStep}
              action={actionHandlerTable}
              backAction={() => setSelectStep(undefined)}
            />
          ) : (
            <ListElement
              list={list.filter(el => el.etlStepId !== 1)} //escludo "sniffer file da ETL"
              property="etlStepDescription"
              action={actionHandler}
            />
          )}
          <Row className="px-5 mt-2">
            <Col>
              <SecondaryButton onClick={closeAction}>Annulla</SecondaryButton>
            </Col>
          </Row>
        </>
      }
      showModal={true}
    />
  );
};

export default ListStepHeaderFlow;
