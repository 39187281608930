import React from 'react';
import { Col, Row } from 'react-bootstrap';
import CardWithDropdown from '../../../../../../components/shared/cards/cardWithDropdown/CardWithDropdown';
import ListCardWithDropdown from '../../../../../../components/shared/cards/cardWithDropdown/models/ListCardWithDropdown';
import ListActionTableRow from '../../../../../../components/shared/form/buttons/dropdown/models/ListActionTableRow';

const Boards = ({
  list,
  clickAction,
}: {
  list: ListCardWithDropdown[];
  clickAction?: (action: ListActionTableRow, all: ListCardWithDropdown) => void;
}) => {
  return (
    <>
      <Row>
        {list.map((ele, index) => (
          <Col xs="12" md="6" lg="6" xl="4" key={index}>
            <CardWithDropdown
              cardData={ele}
              clickAction={action => clickAction && clickAction(action, ele)}
            ></CardWithDropdown>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Boards;
