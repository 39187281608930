import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import { CompanyModel } from '../../../models/CompanyModel';
import { CovipCodeModel } from '../../../models/CovipCodeModel';
import {
  getAsset,
  getAssetConfig,
  getCompanyList,
  getListAuthority,
} from '../asset/services/asset.service';
type AssetEPSliceType = {
  assetEPEdit?: any;
  companyList: CompanyModel[];
  covipList: CovipCodeModel[];
};

const initialState: AssetEPSliceType = {
  assetEPEdit: undefined,
  companyList: [],
  covipList: [],
};
export const assetEPSlice = createSlice({
  name: 'assetEP',
  initialState,
  reducers: {
    setAssetEPEdit: (state, action: PayloadAction<any>) => {
      state.assetEPEdit = action.payload;
    },
    setCompanyList: (state, action: PayloadAction<CompanyModel[]>) => {
      state.companyList = action.payload;
    },
    setCovipList: (state, action: PayloadAction<CovipCodeModel[]>) => {
      state.covipList = action.payload;
    },
    reset: state => {
      state = initialState;
    },
  },
});
export const { setAssetEPEdit, setCompanyList, reset, setCovipList } =
  assetEPSlice.actions;

export const loadAssetEPInitData =
  (assetId: string | number): AppThunk =>
  (dispatch, getState) => {
    const data: any = { assetId };
    if (getState().filterDashboard.periodId) {
      const selectedPeriod = getState().filterDashboard.periods.find(
        p => p.id === getState().filterDashboard.periodId
      );
      if (selectedPeriod) {
        data.month = selectedPeriod.month;
        data.year = selectedPeriod.year;
      }
    }
    const service = data.month
      ? getAssetConfig(assetId, data.month, data.year)
      : getAsset(assetId);
    service.then((res: any) => {
      const asset = res;
      if (data.month) {
        asset.period = {
          month: data.month,
          year: data.year,
        };
      }
      console.log(asset, 'save');
      dispatch(setAssetEPEdit(asset));
      dispatch(getListCompany(2));
      dispatch(getListCovipCode(asset.companyId));
    });
  };

export const getListCompany =
  (assetType: 1 | 2): AppThunk =>
  dispatch => {
    getCompanyList({ assetType }).then(res => {
      dispatch(setCompanyList(res));
    });
  };

export const getListCovipCode =
  (companyId: number): AppThunk =>
  dispatch => {
    companyId
      ? getListAuthority(companyId).then(res => dispatch(setCovipList(res)))
      : setCovipList([]);
  };

export const selectAssetEPEdit = (state: RootState) =>
  state.assetEP.assetEPEdit;
export const selectCompanyList = (state: RootState) =>
  state.assetEP.companyList;
export const selectCovipList = (state: RootState) => state.assetEP.covipList;
const assetEPReducer = assetEPSlice.reducer;
export default assetEPReducer;
