import React, { useEffect } from 'react';
import { RequestVds, ResultVdsModel } from './models/SliceVdsModel';
import ResultSearchVds from './ResultSearchVds';
import Accordion from '../../../../../components/shared/accordion/Accordion';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setTitle } from '../../../../../app/page/pageSlice';

interface ResultSearchMoreVdsModel {
  resultMoreVds: ResultVdsModel[];
  lastRequest?: RequestVds;
}

const ResultSearchMoreVds = ({
  resultMoreVds,
  lastRequest,
}: ResultSearchMoreVdsModel) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(setTitle(t('vds.result')));
  }, []);
  return (
    <>
      {resultMoreVds.map((row, index) =>
        row.count && row.count > 0 ? (
          <Accordion
            key={index}
            title={row.vds}
            nElement={row.count || 0}
            show={index === 0}
            className="not-padding"
          >
            <ResultSearchVds resultVds={row} lastRequest={lastRequest} />
          </Accordion>
        ) : null
      )}
    </>
  );
};

export default ResultSearchMoreVds;
