import React from 'react';

export interface ButtonTabProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  label: string;
}
const ButtonTab = ({ label, ...rest }: ButtonTabProps) => {
  return (
    <button className="shared-button-tab btn btn-white btn-sm" {...rest}>
      {label}
    </button>
  );
};

export default ButtonTab;
