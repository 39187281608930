import React, { useCallback, useEffect } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import SecondaryButton from '../../../components/shared/form/buttons/secondary-button/SecondaryButton';
import PrimaryButton from '../../../components/shared/form/buttons/primary-button/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { getParameterQuery } from '../../../utils/location';
import { useDispatch } from 'react-redux';
import { changePassword, setToken } from './changePasswordSlice';
import { useForm } from '../../../utils/forms/useForm';
import { useRoute } from '../../../utils/useRoute';
import { useChangePassword } from './useChangePassword';
import FormGroupCustom from '../../../utils/forms/FormGroupCustom';
import { UseFormSubmitModel } from '../../../utils/forms/models/UseFormModel';
import { changePasswordControl } from './change-password.service';

const ChangePassword = () => {
  const { changeRoute } = useRoute();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const distatchStable = useCallback(dispatch, []);
  const submitHandler: UseFormSubmitModel = (event, data) => {
    dispatch(changePassword(data, changeRoute));
  };
  const { validations, data } = useChangePassword(t);
  const { isSubmit, errors, submitAction, changeValue, dataForm } = useForm(
    submitHandler,
    validations
  );
  useEffect(() => {
    const tokenData = getParameterQuery('token');
    if (tokenData) {
      changePasswordControl(tokenData).then(
        res => {
          distatchStable(setToken(tokenData));
        },
        err => {
          changeRoute('ForgotPassword');
        }
      );
    } else {
      changeRoute('login');
    }
  }, [distatchStable]);

  return (
    <Form className="pt-2" onSubmit={submitAction}>
      {data.map(({ label, name, dataElement, typeElement, options, value }) => (
        <FormGroupCustom
          key={name}
          isSubmit={isSubmit}
          name={name}
          errors={errors}
          label={label}
          dataElement={dataElement}
          typeElement={typeElement}
          value={dataForm[name]}
          options={options}
          changeValue={value => changeValue(value, name)}
        />
      ))}
      <Row className="pt-4">
        <Col>
          <SecondaryButton href="/">{t('changePsw.cancel')}</SecondaryButton>
        </Col>
        <Col>
          <PrimaryButton>{t('changePsw.send')}</PrimaryButton>
        </Col>
      </Row>
    </Form>
  );
};

export default ChangePassword;
