import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useRoute } from '../../../../utils/useRoute';
import logo from './../../../../assets/logo-pass.svg';
const HeaderLayout = ({
  nav,
  menuLogged,
}: {
  nav?: React.ReactNode;
  menuLogged: React.ReactNode;
}) => {
  const { changeRoute } = useRoute();
  return (
    <header>
      <Navbar
        fixed="top"
        collapseOnSelect
        expand="lg"
        bg="primary"
        variant="dark"
        className="navGradient"
      >
        <Container>
          <Navbar.Brand onClick={() => changeRoute('home')}>
            <img className="logoBrand" src={logo} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="menuItems">{nav}</Nav>
            <Nav className="w-100">{menuLogged}</Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default HeaderLayout;
