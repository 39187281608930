import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ListElement from '../../../../../components/shared/listElement/ListElement';
import { ListElementProps } from '../../../../../components/shared/listElement/models/ListElementProps';
import ModalSuccess from '../../../../../components/shared/modal/modalSuccess/ModalSuccess';
import { setDeleteLoadQuery } from '../table1Slice';

const ListLoadTable = ({
  list = [],
  action,
  closeAction,
}: Omit<ListElementProps, 'property'>) => {
  const dispatch = useDispatch();

  const onDelete = (ele: any) => {
    dispatch(setDeleteLoadQuery(ele.id));
  };
  return (
    <ModalSuccess
      closeAction={closeAction}
      text={{ title: 'Scegli la query' }}
      templateBody={
        <ListElement
          list={list}
          property="label"
          action={action}
          onDelete={ele => onDelete(ele)}
        />
      }
      showModal={true}
    />
  );
};

export default ListLoadTable;
