import React from 'react';

const PublicLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div id="publicLayout">
      <div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default PublicLayout;
