import React from 'react';
import logo from '../../assets/logo-pass-positive.gif';
import publicRoute from '../../routes/public';
import ErrorView from '../core/ErrorView';
import DynamicRoute from './DynamicRoute';
import useVersion from '../../hooks/useVersion';

interface PublicRouteProps {
  isLogged: boolean;
}
const PublicRoute = ({ isLogged }: PublicRouteProps) => {
  const version = useVersion();
  return (
    <>
      {!isLogged ? (
        <div className="public">
          <div>
            <div className="d-flex justify-content-center mb-3">
              <img className="logo" src={logo} alt="logo" height="120" />
            </div>
            <ErrorView />

            <DynamicRoute routes={publicRoute} />
            <div className="d-flex justify-content-center align-items-center pt-3">
              <span className="caption sm">Version v.{version}</span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PublicRoute;
