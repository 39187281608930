import React, { useEffect, useState } from 'react';
import Boards from '../components/boards/Boards';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setTitle } from '../../../../../app/page/pageSlice';
import { exportGridAction } from './TablesSlice';
import headerData from '../../../../../components/core/HeaderData';
import {
  setSelectedTable,
  selectSelectedTable,
  resetSelectedTable,
} from '../../../tables/table1/table1Slice';
import Table1 from '../../../tables/table1/Table1';
import TablesBackDashboard from './TablesBackDashboard';
import Caption from '../../../../../components/shared/headings/caption/Caption';
import Icon from '../../../../../components/shared/icon/Icon';
import TableLog from '../tablevds/TableLogVds';
import {
  loadDashboardTables,
  selectDashboardTables,
} from '../../../../../components/core/filter-dashboard/filterDashboardSlice';
import ListCardWithDropdown from '../../../../../components/shared/cards/cardWithDropdown/models/ListCardWithDropdown';
import { createTavoleList } from './TablesDashboardData';
import ListActionTableRow from '../../../../../components/shared/form/buttons/dropdown/models/ListActionTableRow';

const TablesDashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [logView, setLogView] = useState<boolean>(false);
  const dashboardTables = useSelector(selectDashboardTables);
  const selectedTable = useSelector(selectSelectedTable);

  const [listBoardsTavole, setListBoardsTavole] =
    useState<ListCardWithDropdown[]>();

  useEffect(() => {
    dispatch(setTitle(t('tables.title')));
    dispatch(loadDashboardTables());
    headerData.headers = <TablesBackDashboard />;
    return () => {
      dispatch(resetSelectedTable());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setListBoardsTavole(createTavoleList(dashboardTables));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardTables]);

  const routeTable = (data: ListActionTableRow) => {
    if (data.action === 'grid') {
      dispatch(exportGridAction(data.surveyType!));
    } else {
      const title = `${data.label} ${
        data.description ? `- ${data.description}` : ''
      }`;
      dispatch(setSelectedTable(data.id!));
      dispatch(setTitle(title));
    }
  };

  return !selectedTable ? (
    <>
      <div className="d-flex justify-content-end align-items-center mb-3">
        <Caption
          className="link medium lg pointer"
          onClick={() => setLogView(!logView)}
        >
          {logView ? 'Scelta tavole' : 'Tavole Generate'}
        </Caption>
        <Icon icon="schema" color="default" size="x18"></Icon>
      </div>
      {logView ? (
        <TableLog />
      ) : (
        <>
          {listBoardsTavole && (
            <Boards
              list={listBoardsTavole}
              clickAction={table => routeTable(table)}
            ></Boards>
          )}
        </>
      )}
    </>
  ) : (
    <Table1></Table1>
  );
};

export default TablesDashboard;
