import React from 'react';
import { ErrorListProps } from '../models/FormModule';
import Error from '../error/Error';
import { useTranslation } from 'react-i18next';

const ErrorList = ({ isVisible = false, errors, ...rest }: ErrorListProps) => {
  const { t } = useTranslation();

  return (
    <>
      {errors && errors.length ? (
        errors.map(err => (
          <Error
            success={err.success}
            {...rest}
            key={err.id}
            className="pt-1 pb-4"
            isVisible={!!err}
            message={t('errors.be.' + err?.code)}
          />
        ))
      ) : (
        <Error message="" className="pt-1 pb-4"></Error>
      )}
    </>
  );
};

export default ErrorList;
