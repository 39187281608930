import http from '../../../../utils/http';

export const passwordChangerById = ({
  id,
  newPassword,
  oldPassword,
}: {
  id?: number;
  newPassword?: string;
  oldPassword?: string;
}) => http.put<any>(`user/changePassword`, { id, newPassword, oldPassword });
