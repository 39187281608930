import React from 'react';
import Icon from '../icon/Icon';
import { DropListProp } from './models/DropListProp';

interface DropToolbarProps {
  dropList: DropListProp[];
  actionDrop: (action: string) => void;
}

const DropToolbar = ({ dropList, actionDrop }: DropToolbarProps) => {
  const clickHandler = (action: string) => {
    actionDrop && actionDrop(action);
  };

  return (
    <div className="shared-dropToolbar">
      {dropList.map((drop, index) => (
        <div className="itemDrop" key={index}>
          <Icon icon="file-text" color="white"></Icon>
          <div onClick={() => clickHandler(drop.actionDrop)}>
            {drop.labelDrop}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DropToolbar;
