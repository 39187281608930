import http from '../../../../utils/http';
import { CompanyModel } from '../../../../models/CompanyModel';
import { CovipCodeModel } from '../../../../models/CovipCodeModel';

export const listAssets = (id: any) => http.get(`assets?userId=${id}`);

export const savePeriod = (
  data: any,
  userId: number | undefined,
  role: string | undefined
) =>
  http.post(`dashboard/period`, {
    ...data,
    userId,
    role,
  });

export const listLoaded = (periodId: number, assetId: number) =>
  http.get(`dashboard/fileLoaded?periodId=${periodId}&assetId=${assetId}`);

export const listLoadedFile = (id: number, tenantId: string) =>
  http.get(`dashboard/jobdashboard?fileLoadedId=${id}`, {
    headers: { 'X-TenantID': tenantId },
  });

//----------------
export const getCompanyList = (params: { assetType: 1 | 2 }) =>
  http.get<CompanyModel[]>(`user/company/load`, { params });

export const getAsset = (assetId: string | number) =>
  http.get(`asset/${assetId}`);
export const getAssetConfig = (
  assetId: string | number,
  month: string | number,
  year: string | number
) => http.get(`asset/${assetId}/${month}/${year}`);
export const getListAuthority = (companyId: number) =>
  http.get<CovipCodeModel[]>(`asset/authorities?companyId=${companyId}`);

export const saveAsset = (asset: any, params: { assetType: 1 | 2 }) =>
  http.post(
    `asset`,
    {
      ...asset,
    },
    { params }
  );
export const updateAsset = (asset: any) =>
  http.put(`asset`, {
    ...asset,
  });
