import React, { useEffect } from 'react';
import { FlowProps } from '../models/FlowProps';
import FlowTable from './FlowTable';
import HeaderFlow from './header-flow/HeaderFlow';
import { setTitle } from '../../../../../app/page/pageSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Flow = ({
  assetId,
  periodId,
  isSelectedAllRow,
  flowNextStep,
  indexSelected,
  loadedFile,
  listLegend,
  indexRow,
}: FlowProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(setTitle(t('flow.title')));
  }, []);
  return (
    <>
      <HeaderFlow
        loadedFile={loadedFile}
        listLegend={listLegend}
        flowNextStep={flowNextStep}
      />
      <FlowTable
        isSelectedAllRow={isSelectedAllRow}
        indexSelected={indexSelected}
        loadedFile={loadedFile}
        indexRow={indexRow}
      />
    </>
  );
};

export default Flow;
