import {
  Dashboard,
  DashboardInput,
  Report,
} from '../../../../models/ReportModel';
import http from '../../../../utils/http';

const BASE_PATH = 'reports';

export function getReports(): Promise<Report[]> {
  return http.get(`${BASE_PATH}`);
}
export function getReportUrl(params: {
  reportId: number;
}): Promise<{ url: string }> {
  return http.get(`${BASE_PATH}/url`, { params });
}
export function getDashboards(dashboardId: number): Promise<Dashboard[]> {
  return http.get(`${BASE_PATH}/dashboards`, { params: { dashboardId } });
}
export function addDashboard(input: DashboardInput) {
  return http.post(`${BASE_PATH}/dashboards`, input);
}
export function deleteDashboard(params: DashboardInput) {
  return http.delete(`${BASE_PATH}/dashboards`, { params });
}

export function executeDashboard(dashboardId: number) {
  return http.post(
    `${BASE_PATH}/dashboards/exec`,
    {},
    {
      params: { dashboardId },
    }
  );
}
