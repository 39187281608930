import { TFunction } from 'i18next';
import { FormElementModelNew } from '../../../../utils/forms/models/FormElementModel';
import { User } from '../../../../app/auth/model/User';
import { ButtonModel } from '../../../../components/shared/form/buttons/models/ButtonModel';
import { useHistory } from 'react-router-dom';
import { createStructureForm } from '../../../../utils/forms/useStructureForm';

import { CompanyModel } from '../../../../models/CompanyModel';
import { CovipCodeModel } from '../../../../models/CovipCodeModel';
export function useAssetEPCreate(
  t: TFunction,
  user: Partial<User> = {},
  companyList: CompanyModel[],
  covipList: CovipCodeModel[],
  foundCode: string,
  edit: boolean = false
) {
  const history = useHistory();

  const data: FormElementModelNew[] = createStructureForm([
    {
      asset: [
        {
          typeElement: 'select',
          label: t('users.create.company'),
          name: 'companyId',
          col: { sm: 4 },
          options: [
            { label: t('users.create.company_placeholder'), value: '-1' },
            ...companyList.map(el => ({
              label: el.name,
              value: (el.id as number).toString(),
            })),
          ],
          dataElement: {
            disabled: user && !!user.companyId,
          },
        },
        {
          typeElement: 'input',
          label: t('ID Fondo'),
          name: 'fundCode',
          col: { sm: 4 },
          dataElement: {
            placeholder: t('ID Fondo'),
            type: 'text',
            disabled: edit,
            maxLength: 4,
          },
        },
        {
          typeElement: 'select',
          label: t('users.create.covip'),
          name: 'authorityId',
          col: { sm: 4 },
          options: [
            { label: t('users.create.covip_placeholder'), value: '-1' },
            ...covipList.map(el => ({
              label: el.covipCode,
              value: el.id?.toString(),
            })),
          ],
          dataElement: {
            disabled: !covipList.length,
          },
        },
        {
          typeElement: 'input',
          label: t('Descrizione'),
          name: 'description',
          col: { md: 6 },
          dataElement: {
            placeholder: t('Descrizione'),
            type: 'text',
          },
        },
        {
          typeElement: 'input',
          label: t('Descrizione breve'),
          name: 'shortDescription',
          col: { md: 6 },
          dataElement: {
            placeholder: t('Descrizione breve'),
            type: 'text',
            firstLabel: foundCode,
          },
        },
      ],
    },
  ]);

  const buttons: ButtonModel[] = [
    {
      typeButton: 'secondary',
      label: t('changePsw.cancel'),
      onClick: () => history.goBack(),
    },
    {
      typeButton: 'primary',
      label: t('changePsw.send'),
    },
  ];

  return {
    data,
    buttons,
  };
}
