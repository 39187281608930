import React from 'react';
import Input from '../form/input/Input';

const SearchFilter = ({
  searchFilter,
  inputValue,
}: {
  searchFilter: (val: string) => void;
  inputValue: string;
}) => {
  return (
    <Input
      onChangeAction={val => searchFilter(val)}
      placeholder="Filtra query"
      name="name"
      type="input"
      value={inputValue}
      buttonIcon="eye"
    />
  );
};

export default SearchFilter;
