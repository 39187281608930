import React from 'react';
import { ModalPropsBtn, ModalPropsStyle } from '../models/ModalProps';
import ModalMsg from '../ModalMsg';

interface ModalProps {
  className?: string;
  text?: { title: string; body?: string };
  btnList?: ModalPropsBtn[];
  showModal?: boolean;
  templateHeader?: React.ReactNode;
  templateBody?: React.ReactNode;
  templateFooter?: React.ReactNode;
  clickAction?: (action: string) => void;
  closeAction?: () => void;
  styleSuccess?: ModalPropsStyle;
}

/* const btnSuccess: ModalPropsBtn[] = [
	{
		label: 'Scegli',
		action: 'scegliAction',
		type: 'primary',
		dataType: { size: 'sm' },
	},
	{
		label: 'inserisci',
		action: 'inserisciAction',
		type: 'secondary',
		dataType: { size: 'sm' },
	},
]; */

function ModalSuccess({
  className,
  text,
  templateHeader,
  btnList = [],
  showModal,
  clickAction = () => {},
  closeAction = () => {},
  styleSuccess = {
    style: { color: '#565B6F', bgColor: '#fff' },
    icon: { color: 'green', ico: 'covip' },
  },
  ...rest
}: ModalProps) {
  return (
    <ModalMsg
      className="shared-modalSuccess"
      modalStyle={styleSuccess}
      btn={btnList}
      text={text}
      templateHeader={templateHeader}
      clickAction={clickAction}
      closeAction={closeAction}
      showModal={showModal}
      {...rest}
    ></ModalMsg>
  );
}

export default ModalSuccess;
