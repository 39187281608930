import React from 'react';
import { funcTemplate, TableData } from '../../../../models/TableProps';
import { format } from '../../../../../../../utils/format';
import { Popover } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const TableCol = ({
  row,
  col,
  template,
  indexRow,
}: {
  row: { [key: string]: any };
  col: TableData;
  template: funcTemplate;
  indexRow: number;
}) => {
  const element = template
    ? template(row[col.name], row, indexRow)
    : row[col.name] !== undefined
    ? col.format
      ? format(col.format, row[col.name])
      : row[col.name]
    : '-';

  const popover = (
    <Popover id={'popover-' + col.name + indexRow}>
      <Popover.Title as="h3">{col.label}</Popover.Title>
      <Popover.Content>{element}</Popover.Content>
    </Popover>
  );

  const tdElement = (
    <td
      width={col.width}
      style={col.styleCol}
      title={typeof element === 'string' ? element : ''}
    >
      {element}
    </td>
  );
  return (
    <>
      {typeof element === 'string' && element.length > 20 ? (
        <OverlayTrigger
          transition={false}
          trigger="click"
          placement="auto"
          overlay={popover}
          rootClose={true}
        >
          {tdElement}
        </OverlayTrigger>
      ) : (
        tdElement
      )}
    </>
  );
};
export default TableCol;
