import client from './clientConnect';

const clientSubscribe: {
  list: { sub: any; id: number; topic: string; func: () => void }[];
} = {
  list: [],
};
export const addSub = (topic: string, func: (data: any) => void) => {
  if (!client.connected) {
    console.log('errore connessione ws');
  }
  const data: any = {
    topic,
    func,
    sub:
      client &&
      client.connected &&
      client.subscribe &&
      client.subscribe(topic, func),
    id: Date.now(),
  };
  clientSubscribe.list.push(data);
  return data;
};
export const restartSubscribe = () => {
  clientSubscribe.list = clientSubscribe.list.map(ele => {
    return { ...ele, sub: client.subscribe(ele.topic, ele.func) };
  });
};

export const removeSub = (data: any) => {
  data && data.sub && data.sub.unsubscribe();
  if (data && data.id) {
    clientSubscribe.list = clientSubscribe.list.filter(
      ele => ele.id !== data.id
    );
  }
};

export const onceSub = async <T = any>(
  topic: string
): Promise<T | undefined> => {
  const [sub, data] = await new Promise<[string, any]>(resolve => {
    const sub = addSub(topic, (res: any) => resolve([sub, res]));
  });
  removeSub(sub);
  return data && data.body ? JSON.parse(data.body) : undefined;
};

export default clientSubscribe;
