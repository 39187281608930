import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setToolBarIconFlussi } from '../DashboardSlice';
import { ToolbarIconProps } from '../../../../../components/shared/toolbarWithDrop/models/ToolbarIconProps';
import { getTableDashboard } from '../../services/dashboard-table.service';
import {
  downloadListLogVds,
  getListLogVds,
  selectListLogVds,
} from './tableVdsSlice';
import { selectTenantId } from '../../../../../components/core/filter-dashboard/filterDashboardSlice';
import Table from '../../../../../components/shared/table/table/Table';
import { TableData } from '../../../../../components/shared/table/models/TableProps';
import { tableStructureLog } from './TableLogData';
import toolBarEvent from '../components/toolbarEvents';
import { setTitle } from '../../../../../app/page/pageSlice';
import { resetErrorList } from '../errors/errorDashboardSlice';

const TableLog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const listLog = useSelector(selectListLogVds);
  const tenantId = useSelector(selectTenantId);
  console.log(listLog);
  useEffect(() => {
    const toolBarIconFlussi: ToolbarIconProps[] = [
      {
        icon: 'export',
        name: 'Export Log',
        action: 'download',
        visible: true,
        disabled: !listLog.length,
      },
    ];
    dispatch(setTitle(t('tableLog.title')));
    dispatch(resetErrorList());
    toolBarEvent.events = action => {
      dispatch(downloadListLogVds());
    };
    dispatch(setToolBarIconFlussi(toolBarIconFlussi));
    return () => {
      dispatch(setToolBarIconFlussi([]));
    };
  }, []);

  useEffect(() => {
    dispatch(getListLogVds());
  }, [tenantId]);
  console.log(listLog);

  return (
    <>
      <Table columns={tableStructureLog} data={listLog} />
    </>
  );
};

export default TableLog;
