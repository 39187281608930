import { TableData } from '../../../components/shared/table/models/TableProps';

export const tableStructureDatabase: TableData[] = [
  {
    name: 'databaseName',
    label: 'Nome Database',
  },
  {
    name: 'fundCode',
    label: 'Codice Fondo',
  },
  {
    name: 'period',
    label: 'Periodo',
  },
  {
    name: 'dateLastVersion',
    label: 'Ultimo Aggiornamento',
  },
  {
    name: 'refresh',
    label: '',
  },
];

export const tableStructureDatabaseDetail: TableData[] = [
  {
    name: 'databaseName',
    label: 'Nome DB',
  },
  {
    name: 'dateInsert',
    label: 'Data inserimento',
  },
  {
    name: 'message',
    label: 'Messaggio',
  },
];
