import React from 'react';
import { mergeClass } from '../../functions/function';
import Icon from '../../icon/Icon';
import Caption from '../../headings/caption/Caption';
import Dropdown from '../../form/buttons/dropdown/Dropdown';
import ListCardWithDropdown from './models/ListCardWithDropdown';
import ListActionTableRow from '../../form/buttons/dropdown/models/ListActionTableRow';

interface CardWithDropdownProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string;
  cardData: ListCardWithDropdown;
  clickAction?: (action: ListActionTableRow) => void;
}
const CardWithDropdown = ({
  className,
  cardData,
  clickAction,
  ...rest
}: CardWithDropdownProps) => {
  const clickActionHandler = (action: ListActionTableRow) => {
    clickAction && clickAction(action);
  };

  return (
    <div
      className={mergeClass(
        'shared-cardWithDropdown',
        cardData.disabled ? 'disabled' : ''
      )}
    >
      <div className="left-side">
        {cardData.icon && (
          <Icon
            icon={cardData.icon}
            color="white"
            size="32"
            pointer={false}
          ></Icon>
        )}
        <Caption className="reverse text-uppercase">{cardData.label}</Caption>
      </div>
      {cardData.dropdown && (
        <div className="right-side">
          {cardData.dropdown.map((ele, index) => (
            <div className="d-flex align-items-center" key={index}>
              <span className="labelDrop">{ele.labelBtn}</span>
              <Dropdown
                iconDrop={ele.icon ? ele.icon : 'schema'}
                iconDropColor="white"
                drop="left"
                listMenu={ele.listDropdown}
                display="wrap2Col"
                clickAction={ele => {
                  clickActionHandler(ele);
                }}
              ></Dropdown>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CardWithDropdown;
