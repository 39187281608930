import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../../../../../components/shared/icon/Icon';
import Table from '../../../../../../components/shared/table/table/Table';
import ListHeadingErrors from '../components/ListHeadingErrors';
import {
  selectErrorList,
  selectField,
  addListChangeValueDetailError,
  removeListChangeValueDetailError,
  selectListChangeValueDetailError,
  saveDetailErrorTraice,
  selectPagesDetailError,
  selectCurrentPageDetailError,
  loadDetailsError,
  resetFilterPage,
  setOrderFilterAction,
  selectOrderFilter,
} from '../errorDashboardSlice';

import { useErrorDetailDashboard } from './useErrorDetailDashboard';
import InputErrorDetailDashboard from './InputErrorDetailDashboard';
import DropdownErrorAction from '../components/dropdownErrorAction/DropdownErrorAction';
import { goVdsDetailAction } from '../../vds/vdsSlice';
import { tabsDataType } from '../../dashboardData';

const FormalError = ({
  id,
  vds,
  errors,
  autoSelectTab,
}: {
  id: number;
  vds: string;
  errors: any;
  autoSelectTab: (tab: string) => void;
}) => {
  const { t } = useTranslation();
  const { tableStructure, headingStructure } = useErrorDetailDashboard(t);
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetFilterPage());
    };
  }, [id, vds]);
  const errorList = useSelector(selectErrorList);
  const pages = useSelector(selectPagesDetailError);
  const page = useSelector(selectCurrentPageDetailError);
  const listChangeValueDetailError = useSelector(
    selectListChangeValueDetailError
  );
  const errorVds: any = errorList.find(ele => ele.vds === vds);
  const orderFilter = useSelector(selectOrderFilter);
  const selectFieldElement = useSelector(selectField);

  const checkSaveDisabled = (row: any) =>
    listChangeValueDetailError.find(ele => ele.id === row.tracingId)
      ? false
      : true;

  const goDetailVds = (row: any) => {
    dispatch(goVdsDetailAction(row.idVds, vds));
    autoSelectTab(tabsDataType.vds.name);
  };

  const templates = {
    newValue: (value: string, row: any, index: number) => {
      return (
        <InputErrorDetailDashboard
          value={value}
          disabled={
            row.editable &&
            (!selectFieldElement || (selectFieldElement && index === 0))
              ? false
              : true
          }
          setActive={(active, val) =>
            active
              ? dispatch(
                  addListChangeValueDetailError({
                    id: row.tracingId,
                    value: val,
                  })
                )
              : dispatch(removeListChangeValueDetailError(row.tracingId))
          }
        />
      );
    },
    actionSave: (value: string, row: any, index: number) => {
      return (
        <>
          <Icon
            icon="save"
            pointer={!checkSaveDisabled(row)}
            color={`${checkSaveDisabled(row) ? 'gray' : 'green'}`}
            disabled={checkSaveDisabled(row)}
            onClick={() =>
              !checkSaveDisabled(row) &&
              dispatch(saveDetailErrorTraice(row.tracingId, id))
            }
          ></Icon>
        </>
      );
    },
    detailVds: (value: string, row: any, index: number) => {
      return (
        <>
          {row.idVds && (
            <DropdownErrorAction clickActionRow={() => goDetailVds(row)} />
          )}
        </>
      );
    },
  };

  const changePage = (page: number) => {
    dispatch(loadDetailsError(page, id));
  };

  return (
    <>
      <ListHeadingErrors
        list={headingStructure}
        data={errorVds}
      ></ListHeadingErrors>
      <Table
        columns={tableStructure}
        data={errors}
        templates={templates}
        goPage={changePage}
        page={page}
        pages={pages}
        clickOrder={field => dispatch(setOrderFilterAction({ field }, id))}
        order={orderFilter}
      />
    </>
  );
};

export default FormalError;
