import React from 'react';
import { ToolbarIconProps } from './models/ToolbarIconProps';
import ToolbarIconItem from './ToolbarIconItem';

interface BtnGroupProps {
  listBtn: ToolbarIconProps[];
  clickAction?: (action: string) => void;
}
const ToolbarIcons = ({ listBtn, clickAction }: BtnGroupProps) => {
  const listItems = listBtn.map((ele, index) => (
    <ToolbarIconItem
      key={index}
      {...ele}
      clickAction={action => clickAction && clickAction(action)}
    ></ToolbarIconItem>
  ));
  return (
    <div className="d-flex justify-content-end shared-toolbar">{listItems}</div>
  );
};

export default ToolbarIcons;
