import { TFunction } from 'i18next';
import { TableData } from '../../../../../../components/shared/table/models/TableProps';

export function useErrorDetailDashboard(t: TFunction) {
  const tableStructure: TableData[] = [
    {
      name: 'field',
      label: 'Campo',
      showFilterOrder: true,
    },
    {
      name: 'descriptionField',
      label: 'Descrizione Campo',
    },
    {
      name: 'descriptionError',
      label: 'Descrizione errore',
    },
    {
      name: 'value',
      label: 'Contenuto',
    },
    {
      name: 'newValue',
      label: 'Contenuto modificato',
    },
    {
      name: 'actionSave',
      label: '',
      width: '1%',
    },
    {
      name: 'detailVds',
      label: '',
      width: '1%',
    },
  ];

  const headingStructure = [
    {
      name: 'vds',
      label: 'Vds',
    },
    {
      name: 'totalError',
      label: 'Errori',
    },
    {
      name: 'resolvedError',
      label: 'Evasi',
    },
    {
      name: 'unsolvedError',
      label: 'Rimanenti',
    },
    {
      name: 'inProgressError',
      label: 'Lavorati',
    },
  ];
  return {
    tableStructure,
    headingStructure,
  };
}
