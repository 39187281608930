export const stringDateToTimestamp = (stringDate: any): any =>
  typeof stringDate === 'string' ? new Date(stringDate).getTime() : stringDate;

export const getTodayDate = () => new Date();

export const getTodayTimestamp = () => stringDateToTimestamp(getTodayDate());

export const minArrayDate = (dates: any[]) => {
  const arr = dates ? dates.filter((ele: any) => ele) : [];
  return arr.reduce((data: any, ele) => {
    return data ? minDate(data, ele) : ele;
  }, undefined);
};

export const maxArrayDate: (
  dates: any[]
) => string | number | undefined = dates => {
  const arr = dates ? dates.filter((ele: any) => ele) : [];
  return arr.reduce((data: any, ele) => {
    return data ? maxDate(data, ele) : ele;
  }, undefined);
};

export const maxDate: (
  date1: any,
  date2: any
) => number | string | undefined = (date1, date2) => {
  return stringDateToTimestamp(date2) > stringDateToTimestamp(date1)
    ? date2
    : date1;
};

export const minDate: (
  date1: any,
  date2: any
) => number | string | undefined = (date1, date2) => {
  return stringDateToTimestamp(date2) < stringDateToTimestamp(date1)
    ? date2
    : date1;
};

export const localdateString: (date: string | number) => string = date => {
  return new Date(date).toLocaleDateString('it-IT');
};

export const toEnDateString: (date: string | number) => string = date => {
  const d = new Date(date);
  return `${d.getFullYear()}-${padNumber(d.getMonth() + 1)}-${padNumber(
    d.getDate()
  )}`;
};

export const padNumber = (value: number) => `0${value}`.slice(-2);
