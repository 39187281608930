import React from 'react';
import { mergeClass } from '../../functions/function';
import { Table as Tbl } from 'react-bootstrap';

import { TablePropsWithOrder } from '../models/TableProps';
import TableHeader from './components/table-header/TableHeader';
import TableBody from './components/table-body/TableBody';
import Pagination from './components/pagination/Pagination';

const Table = ({
  data = [],
  styleTable = '',
  columns,
  templates = {},
  templatesHeader = {},
  page = 1,
  pages = 1,
  showPagination = true,
  goPage = () => {},
  templateRow,
  rowIndexActive,
  rowIndexTemplate,
  clickRow,
  labelEmpty,
  templateEmpty,
  hideHeaderInEmptyTable = false,
  clickOrder,
  disabledRow,
  order,
}: TablePropsWithOrder) => {
  const columnsFilter = columns.filter(col => col.show !== false);

  return (
    <>
      <Tbl responsive className={mergeClass('table-custom', styleTable)}>
        {!hideHeaderInEmptyTable ? (
          <TableHeader
            columns={columnsFilter}
            templatesHeader={templatesHeader}
            clickOrder={clickOrder}
            order={order}
          />
        ) : null}
        <TableBody
          disabledRow={disabledRow}
          clickRow={clickRow}
          columns={columnsFilter}
          data={data}
          templates={templates}
          templateRow={templateRow}
          rowIndexActive={rowIndexActive}
          rowIndexTemplate={rowIndexTemplate}
          labelEmpty={labelEmpty}
          templateEmpty={templateEmpty}
        />
      </Tbl>
      {data && data.length && pages > 1 && showPagination ? (
        <Pagination page={page} pages={pages} goPage={goPage}></Pagination>
      ) : null}
    </>
  );
};
export default Table;
