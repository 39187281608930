import React, { useEffect, useState } from 'react';

import Input from '../../../../../../components/shared/form/input/Input';

const InputErrorDetailDashboard = ({
  value,
  setActive,
  disabled,
  ...rest
}: {
  value: string;
  setActive?: (active: boolean, val: string) => void;
  disabled?: boolean;
}) => {
  const [valueInput, setValueInput] = useState(value);
  useEffect(() => {
    setValueInput(value);
  }, [value]);
  const changeElement = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setActive && setActive(val !== value, val);
    setValueInput(val);
  };

  return (
    <Input
      value={valueInput || ''}
      onChange={changeElement}
      disabled={disabled}
      className="sm"
      {...rest}
    ></Input>
  );
};

export default InputErrorDetailDashboard;
