import { TFunction } from 'i18next';

export function useChangePassword(t: TFunction) {
  const validations = {
    newPassword: {
      required: {
        params: { name: 'password' },
      },
    },
    confirmPassword: {
      required: {
        params: { name: 'conferma password' },
      },
      confirm: {
        moreData: {
          fieldCompare: 'newPassword',
        },
        params: { name: 'password', fieldCompare: 'conferma password' },
      },
    },
  };
  const data: {
    typeElement?: 'input' | 'select' | 'textarea';
    label: string;
    name: string;
    value?: any;
    options?: { label: string; value: string; disabled?: boolean }[];
    dataElement: Partial<{
      placeholder: string;
      type: string;
      multiply?: boolean;
    }>;
  }[] = [
    {
      typeElement: 'input',
      label: t('changePsw.newPassword'),
      name: 'newPassword',
      value: '',
      dataElement: {
        placeholder: t('changePsw.newPassword'),
        type: 'password',
      },
    },
    {
      typeElement: 'input',
      label: t('changePsw.confirmPassword'),
      name: 'confirmPassword',
      value: '',
      dataElement: {
        placeholder: t('changePsw.confirmPassword'),
        type: 'password',
      },
    },
  ];

  return {
    validations,
    data,
  };
}
