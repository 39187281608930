import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetErrors } from '../../app/errors/errorSlice';
import { checkValidation } from './utils/checkValidations';
import React from 'react';
import {
  LastChangeModel,
  useFormDataInitModel,
  UseFormOthersModel,
  useFormRoleValidationsModel,
  UseFormSubmitModel,
} from './models/UseFormModel';
import { getNameField, getObjForm, setObjForm } from './useStructureForm';
import { isObject } from '../objUtils';
export function useForm(
  submitCallback: UseFormSubmitModel,
  validationRole: useFormRoleValidationsModel = {},
  dataInit: useFormDataInitModel = {},
  other?: UseFormOthersModel
) {
  const [dataForm, setDataForm] = useState(setObjForm(dataInit));
  const [isSubmit, setIsSubmit] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: any }>({});
  const [valid, setValid] = useState(false);
  const [lastChange, setLastChange] = useState<LastChangeModel>({});
  const dispatch = useDispatch();
  const resetForm = (init: { [key: string]: any } = {}) => {
    dispatch(resetErrors);
    setErrors({});
    setValid(false);
    setIsSubmit(false);
    setDataForm(init);
  };
  const setData = (data: { [key: string]: any } = {}) => {
    setLastChange({
      newValue: data,
      newValueRaw: setObjForm(data),
    });
    setDataForm(setObjForm(data));
  };
  const getDataFormatted = () => {
    return getObjForm(dataForm);
  };

  const getNameFieldForm = (data: string) => {
    return getNameField(data);
  };
  const submitAction = (event?: React.FormEvent<HTMLFormElement> | any) => {
    event && event.preventDefault && event.preventDefault();
    setIsSubmit(true);
    if (issetErrors(dataForm))
      submitCallback(event, getDataFormatted(), dataForm);
  };

  const issetErrors = (data: { [key: string]: any }) => {
    let valid = !Object.keys(getErrors(data)).length;
    setValid(valid);
    return valid;
  };

  const getErrors = (data: any) => {
    let errors: {
      [key: string]: { code: string; params: { [key: string]: string } };
    } = Object.keys(validationRole).reduce((obj: any, validation) => {
      const value = data[validation];
      Object.keys(validationRole[validation]).forEach(role => {
        if (
          !checkValidation(
            role,
            value,
            data,
            validationRole[validation][role].moreData
          ) &&
          !obj[validation]
        ) {
          obj[validation] = {
            code: 'fe.' + role,
            params: validationRole[validation][role].params,
          };
        }
      });
      return obj;
    }, {});
    setErrors(errors);
    return errors;
  };

  const changeValue = (value: any, name: string) => {
    let copyObj = { ...dataForm };
    if (isObject(value)) {
      setLastChange({ ...lastChange, newValue: value });
      copyObj = { ...copyObj, ...value };
    } else {
      setLastChange({ ...lastChange, newValue: { [name]: value } });
      copyObj[name] = value;
    }
    getErrors(copyObj);
    setDataForm(copyObj);
  };
  useEffect(() => {
    other &&
      other.changeValueEnd &&
      other.changeValueEnd(getObjForm(dataForm), {
        ...lastChange,
        ...(lastChange.newValue
          ? { newValue: getObjForm(lastChange.newValue) }
          : {}),
      });
  }, [dataForm]);
  const isValid = () => {
    return issetErrors(dataForm);
  };
  return {
    dataFormatted: getObjForm(dataForm),
    getNameFieldForm,
    isSubmit,
    dataForm,
    errors,
    valid,
    isValid,
    setData,
    getDataFormatted,
    resetForm,
    submitAction,
    changeValue,
  };
}
