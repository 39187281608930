import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../app/store';
import { TableData } from '../../../../components/shared/table/models/TableProps';
import {
  deleteUser,
  getCompanyList,
  listUsers,
} from '../services/users.service';
import { addSuccessMessage } from '../../../../app/errors/errorSlice';
import { UserModel } from '../../../../models/UserModel';

const tableStructure: TableData[] = [
  {
    name: 'firstName',
    label: 'Nome',
  },
  {
    name: 'lastName',
    label: 'Cognome',
  },
  {
    name: 'email',
    label: 'E-mail',
  },
  {
    name: 'username',
    label: 'Username',
  },
  {
    name: 'modify',
    label: '',
  },
];

const initialState: {
  users: UserModel[];
  tableStructure: TableData[];
  count: number | undefined;
  pages: number | undefined;
  currentPage: number | undefined;
  deleteModal: any | undefined;
  filters: {
    email?: string | undefined;
    companyId?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
  };
  listCompany: any[];
} = {
  users: [],
  count: undefined,
  pages: undefined,
  currentPage: undefined,
  deleteModal: undefined,
  filters: {},
  listCompany: [],
  tableStructure,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<UserModel[]>) => {
      state.users = action.payload;
    },
    removeUser: (state, action: PayloadAction<number>) => {
      state.users = state.users.filter(ele => ele.id !== action.payload);
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setPages: (state, action: PayloadAction<number>) => {
      state.pages = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number | undefined>) => {
      state.currentPage = action.payload;
    },
    setDeleteModal: (state, action: PayloadAction<any>) => {
      state.deleteModal = action.payload;
    },
    closeDeleteModal: state => {
      state.deleteModal = undefined;
    },
    setListCompany: (
      state,
      action: PayloadAction<{ label: string; value: string | number }[]>
    ) => {
      state.listCompany = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<{
        email?: string | undefined;
        companyId?: string | undefined;
        firstName?: string | undefined;
        lastName?: string | undefined;
        page?: string | undefined;
      }>
    ) => {
      state.filters = action.payload;
    },
  },
});

export const {
  setUsers,
  setCount,
  setPages,
  setCurrentPage,
  setListCompany,
  setDeleteModal,
  closeDeleteModal,
  setFilter,
  removeUser,
} = usersSlice.actions;

export const getUsers = (): AppThunk => (dispatch, getState) => {
  const { currentPage: page, filters = {} } = getState().users;
  const req: { [key: string]: any } = {
    page,
    ...filters,
  };
  listUsers(req).then(res => {
    dispatch(setUsers(res.users));
    if (res.count) {
      dispatch(setCount(res.count.count));
      dispatch(setPages(res.count.pages));
    }
  });
};

export const getCompanyLoad = (): AppThunk => dispatch => {
  getCompanyList().then(res => {
    dispatch(
      setListCompany(res.map(ele => ({ label: ele.name, value: ele.id })))
    );
  });
};

export const loadPageUser =
  (page: number = 1): AppThunk =>
  dispatch => {
    dispatch(setCurrentPage(page));
    let p = new Promise(() => {
      dispatch(getUsers());
    });

    return p;
  };

export const deleteUserCall = (): AppThunk => (dispatch, getState) => {
  const idUser: number = getState().users.deleteModal.id;
  deleteUser(idUser).then(res => {
    dispatch(removeUser(idUser));
    dispatch(closeDeleteModal());
    dispatch(addSuccessMessage('USER_DELETED'));
  });
};

export const setFilterSearch =
  (data: any = {}): AppThunk =>
  dispatch => {
    dispatch(setCurrentPage(undefined));
    dispatch(
      setFilter(
        Object.keys(data).reduce((obj: any, ele) => {
          if (data[ele] !== '') obj[ele] = data[ele];
          return obj;
        }, {})
      )
    );
    let p = new Promise(() => {
      dispatch(getUsers());
    });

    return p;
  };

export const selectUsers = (state: RootState) => state.users.users;
export const selectDeleteModal = (state: RootState) => state.users.deleteModal;
export const selectListCompany = (state: RootState) => state.users.listCompany;
export const selectPages = (state: RootState) => state.users.pages;
export const selectCurrentPage = (state: RootState) => state.users.currentPage;
export const selectTableStructure = (state: RootState) =>
  state.users.tableStructure;

export default usersSlice.reducer;
