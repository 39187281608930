import React from 'react';
import { mergeClass } from '../../functions/function';
interface LabelProps extends React.HTMLAttributes<HTMLOrSVGElement> {}
const HeadingH4 = ({ children, className, ...rest }: LabelProps) => {
  return (
    <h4 className={mergeClass('heading-4', className)} {...rest}>
      {children}
    </h4>
  );
};

export default HeadingH4;
