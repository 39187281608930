import React from 'react';

const InfoNumber = (props: { info: string; style?: React.CSSProperties }) => {
  const { info, style = {} } = props;
  return (
    <>
      <div className="shared-infoNumber" style={style}>
        {info}
      </div>
    </>
  );
};
export default InfoNumber;
