import ListCardWithDropdown from '../../../../../components/shared/cards/cardWithDropdown/models/ListCardWithDropdown';
import ListActionTableRow from '../../../../../components/shared/form/buttons/dropdown/models/ListActionTableRow';
import { TableData } from '../../../../../components/shared/table/models/TableProps';
import { ToolbarIconProps } from '../../../../../components/shared/toolbarWithDrop/models/ToolbarIconProps';
import { DashboardTable } from '../../services/dashboard.service';

export const tableStructureSegnalazioni: TableData[] = [
  {
    name: 'fundCode',
    label: 'Identificativo Fondo',
  },
  {
    name: 'timeProduction',
    label: 'Data Generazione Segnalazione',
  },
  {
    name: 'assetTypeDesc',
    label: 'Tipologia fondo',
  },
];

export const toolBarIconXML: ToolbarIconProps[] = [
  {
    icon: 'file-xml',
    name: 'XML aggregato',
    action: 'aggregate',
    disabled: false,
    visible: true,
  },
];
export const toolBarIconDownload: ToolbarIconProps[] = [
  {
    icon: 'export',
    name: 'Download',
    action: 'download',
    disabled: false,
    visible: true,
  },
];
export const toolBarIconGenerator: ToolbarIconProps[] = [
  {
    icon: 'play',
    name: 'Genera file zip',
    action: 'generate',
    disabled: false,
    visible: true,
  },
];
export const actionTable = 'table';
export const actionTableStream = 'tableStream';
export const actionCovip = 'covip';
export const actionCovipStream = 'covipStream';
export const actionEmpty = 'empty';

const sagnalazioniBase: ReadonlyArray<Partial<ListCardWithDropdown>> = [
  {
    icon: 'cal-month',
    surveyType: '1',
    surveySubType: '1',
    dropdown: [
      {
        labelBtn: 'Segnalazioni vuote',
        icon: 'shield-off',
        listDropdown: [
          {
            label: 'Scarica',
            action: actionEmpty,
          },
        ],
      },
      {
        labelBtn: 'Segnalazione Covip',
        icon: 'alert-circle',
        listDropdown: [
          {
            label: 'Scarica',
            action: actionCovip,
          },
        ],
      },
    ],
  },
  {
    icon: 'cal-trim',
    surveyType: '2',
    surveySubType: '2',
    dropdown: [
      {
        labelBtn: 'Segnalazioni vuote',
        icon: 'shield-off',
        listDropdown: [
          {
            label: 'Scarica',
            action: actionEmpty,
          },
        ],
      },
      {
        labelBtn: 'Segnalazione Covip',
        icon: 'alert-circle',
        listDropdown: [
          {
            label: 'Scarica',
            action: actionCovip,
          },
        ],
      },
    ],
  },
  {
    icon: 'cal-trim',
    surveyType: '2',
    surveySubType: '3',
    dropdown: [
      {
        labelBtn: 'Segnalazioni vuote',
        icon: 'shield-off',
        listDropdown: [
          {
            label: 'Scarica',
            action: actionEmpty,
          },
        ],
      },
      {
        labelBtn: 'Segnalazione Covip',
        icon: 'alert-circle',
        listDropdown: [
          {
            label: 'Scarica',
            action: actionCovip,
          },
        ],
      },
    ],
  },
  {
    icon: 'cal-annual',
    surveyType: '3',
    surveySubType: '2',
    dropdown: [
      {
        labelBtn: 'Segnalazioni vuote',
        icon: 'shield-off',
        listDropdown: [
          {
            label: 'Scarica',
            action: actionEmpty,
          },
        ],
      },
      {
        labelBtn: 'Segnalazione Covip',
        icon: 'alert-circle',
        listDropdown: [
          {
            label: 'Scarica',
            action: actionCovip,
          },
        ],
      },
    ],
  },
  {
    icon: 'cal-annual',
    surveyType: '3',
    surveySubType: '3',
    dropdown: [
      {
        labelBtn: 'Segnalazioni vuote',
        icon: 'shield-off',
        listDropdown: [
          {
            label: 'Scarica',
            action: actionEmpty,
          },
        ],
      },
      {
        labelBtn: 'Segnalazione Covip',
        icon: 'alert-circle',
        listDropdown: [
          {
            label: 'Scarica',
            action: actionCovipStream,
          },
        ],
      },
    ],
  },
  {
    icon: 'cal-annual',
    surveyType: '3',
    surveySubType: '4',
    dropdown: [
      {
        labelBtn: 'Segnalazioni vuote',
        icon: 'shield-off',
        listDropdown: [
          {
            label: 'Scarica',
            action: actionEmpty,
          },
        ],
      },
      {
        labelBtn: 'Segnalazione Covip',
        icon: 'alert-circle',
        listDropdown: [
          {
            label: 'Scarica',
            action: actionCovip,
          },
        ],
      },
    ],
  },
];

export const createSegnalazioniList = (
  dashboardTables: DashboardTable[],
  disabled: boolean = false
) => {
  return dashboardTables.map(data => {
    const index = sagnalazioniBase.findIndex(
      s =>
        s.surveyType === data.aggrId.toString() &&
        s.surveySubType === data.subAggrId.toString()
    );
    if (index < 0) {
      throw new Error('Segnalazione non trovata');
    }
    const segnalazione = { ...sagnalazioniBase[index] } as ListCardWithDropdown;
    segnalazione.label = data.fullLabel;
    segnalazione.disabled = disabled;
    segnalazione.dropdown = [
      {
        labelBtn: 'Segnalazioni',
        icon: 'alert-circle',
        listDropdown: data.dropdown.map(
          ({ id, label, tableCode }) =>
            ({
              id: `${id}`,
              label,
              tableCode,
              action: actionTable,
              surveyType: data.surveyType,
            } as ListActionTableRow)
        ),
      },
      ...(segnalazione.dropdown || []).map(d => ({
        ...d,
        listDropdown: d.listDropdown.map(ld => ({
          ...ld,
          surveyType: data.surveyType,
        })),
      })),
    ];

    return segnalazione;
  });
};
