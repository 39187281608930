import React, { useState } from 'react';
import FormGroupCustom from '../../../../../utils/forms/FormGroupCustom';
import { ModalPropsBtn } from '../../../../../components/shared/modal/models/ModalProps';
import ModalSuccess from '../../../../../components/shared/modal/modalSuccess/ModalSuccess';

const ChooseLoadName = ({
  saveState,
  closeAction,
}: {
  saveState: (name: string) => void;
  closeAction: () => void;
}) => {
  const [name, setName] = useState<string>('');
  const btnList: ModalPropsBtn[] = [
    {
      label: 'Annulla',
      action: 'closeAction',
      type: 'secondary',
      dataType: {},
    },
    {
      label: 'Salva',
      action: 'saveAction',
      type: 'primary',
      dataType: { disabled: name.length < 1 },
    },
  ];

  const saveQuery = () => {
    saveState && saveState(name);
  };
  const clickActionHandler = (action: string) => {
    action === 'saveAction' && saveQuery();
    action === 'closeAction' && closeAction();
  };

  const formData = (
    <div>
      <FormGroupCustom
        isSubmit={false}
        errors={{}}
        dataElement={{}}
        name="name"
        label="Nome"
        typeElement="input"
        value={name}
        changeValue={setName}
      />
    </div>
  );

  return (
    <ModalSuccess
      closeAction={closeAction}
      btnList={btnList}
      clickAction={clickActionHandler}
      text={{ title: 'Salva query' }}
      templateBody={formData}
      showModal={true}
    />
  );
};

export default ChooseLoadName;
