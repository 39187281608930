import i18n from 'i18next';
import { createValidationsForm } from '../../../utils/forms/useStructureForm';
import {
  isNumber,
  length,
  required,
} from '../../../utils/forms/utils/validationType';

export const AssetFormValidations = createValidationsForm([
  {
    asset: [
      {
        name: 'fundCode',
        validations: [
          {
            name: required,
            params: {
              name: 'ID Fondo',
            },
          },
          {
            name: isNumber,
            params: {
              name: 'ID Fondo',
            },
          },
          {
            name: length,
            moreData: {
              length: 4,
            },
            params: {
              name: 'ID Fondo',
              moreData: {
                length: 4,
              },
            },
          },
        ],
      },
      {
        name: 'companyId',
        validations: [
          {
            name: required,
            params: {
              name: 'ID Azienda',
            },
          },
        ],
      },
      {
        name: 'description',
        validations: [
          {
            name: required,
            params: {
              name: 'descrizione',
            },
          },
        ],
      },
      {
        name: 'shortDescription',
        validations: [
          {
            name: required,
            params: {
              name: 'Descrizione Breve',
            },
          },
        ],
      },
    ],
  },
  {
    configuration: [
      {
        name: 'c_9102',
        validations: [
          {
            name: required,
            params: {
              name: '',
            },
          },
        ],
      },

      {
        name: 'c_9106',
        validations: [
          {
            name: required,
            params: {
              name: '',
            },
          },
        ],
      },
      {
        name: 'c_9108',
        validations: [
          {
            name: required,
            params: {
              name: '',
            },
          },
        ],
      },
    ],
  },
]);

export const configNotBoolean = ['c_9102', 'c_9106', 'c_9108'];
export const configurationCheck = [
  {
    typeElement: 'switch',
    label: i18n.t('Sistema della contabilità per quote nei comparti'),
    name: 'c_9101',
    col: { sm: 12, md: 6, lg: 6, xl: 4 },
    dataElement: {
      type: 'checkbox',
      display: 'inlineForm',
    },
  },
  {
    typeElement: 'switch',
    label: i18n.t(
      'Forma pensionistica articolata in più sezioni in fase di accumulo'
    ),
    name: 'c_9103',
    col: { sm: 12, md: 6, lg: 6, xl: 4 },
    dataElement: {
      type: 'checkbox',
      display: 'inlineForm',
    },
  },
  {
    typeElement: 'switch',
    label: i18n.t(
      'Forma pensionistica articolata in più sezioni in fase di erogazione'
    ),
    name: 'c_9112',
    col: { sm: 12, md: 6, lg: 6, xl: 4 },
    dataElement: {
      type: 'checkbox',
      display: 'inlineForm',
    },
  },
  {
    typeElement: 'switch',
    label: i18n.t(
      'Forma pensionistica articolata in più linee di investimento'
    ),
    name: 'c_9104',
    col: { sm: 12, md: 6, lg: 6, xl: 4 },
    dataElement: {
      type: 'checkbox',
      display: 'inlineForm',
    },
  },
  {
    typeElement: 'switch',
    label: i18n.t('Forma pensionistica articolata in classi di quota'),
    name: 'c_9113',
    col: { sm: 12, md: 6, lg: 6, xl: 4 },
    dataElement: {
      type: 'checkbox',
      display: 'inlineForm',
    },
  },
  {
    typeElement: 'switch',
    label: i18n.t(
      'Fondo comune di investimento aperto e istituito in Italia o SICAV istituita in Italia'
    ),
    name: 'c_9105',
    col: { sm: 12, md: 6, lg: 6, xl: 4 },
    dataElement: {
      type: 'checkbox',
      display: 'inlineForm',
    },
  },

  {
    typeElement: 'switch',
    label: i18n.t(
      'Fondo interno di patrimoni di società, comparti configurati come poste contabili'
    ),
    name: 'c_9107',
    col: { sm: 12, md: 6, lg: 6, xl: 4 },
    dataElement: {
      type: 'checkbox',
      display: 'inlineForm',
    },
  },

  {
    typeElement: 'switch',
    label: i18n.t('Generazione da ECO.02'),
    name: 'c_9109',
    col: { sm: 12, md: 6, lg: 6, xl: 4 },
    dataElement: {
      type: 'checkbox',
      display: 'inlineForm',
    },
  },
  {
    typeElement: 'switch',
    label: i18n.t('Generazione da PEN.02'),
    name: 'c_9110',
    col: { sm: 12, md: 6, lg: 6, xl: 4 },
    dataElement: {
      type: 'checkbox',
      display: 'inlineForm',
    },
  },
  {
    typeElement: 'switch',
    label: i18n.t('Generazione da COI.02'),
    name: 'c_9114',
    col: { sm: 12, md: 6, lg: 6, xl: 4 },
    dataElement: {
      type: 'checkbox',
      display: 'inlineForm',
    },
  },
  {
    typeElement: 'switch',
    label: i18n.t('Escludi Derivati (DER.01)'),
    name: 'c_9111',
    col: { sm: 12, md: 6, lg: 6, xl: 4 },
    dataElement: {
      type: 'checkbox',
      display: 'inlineForm',
    },
  },
];
