import React, { useState } from 'react';
import {
  OrderFilter as OrderFilterProps,
  TableData,
  TemplateTableHeader,
} from '../../../models/TableProps';
import OrderFilter from '../../../orderFilter/OrderFilter';
import SearchFilterBtn from '../../../searchFilterBtn/SearchFilterBtn';
import SearchFilterInput from '../../../searchFilterInput/SearchFilterInput';

const TableHeader = ({
  columns,
  templatesHeader,
  clickOrder,
  order,
}: {
  columns: TableData[];
  templatesHeader: TemplateTableHeader;
  clickOrder?: (field: string) => void;
  order?: OrderFilterProps;
}) => {
  const [showSearchInput, setShowSearchInput] = useState(false);
  return (
    <thead>
      <tr>
        {columns.map(ele => {
          return (
            <th style={{ width: ele?.width }} key={ele.name}>
              <div className="d-flex justify-content-between">
                {templatesHeader && templatesHeader[ele.name]
                  ? templatesHeader[ele.name](ele)
                  : ele.label}
                <OrderFilter
                  clickOrder={() => clickOrder && clickOrder(ele.name)}
                  isFilterVisible={ele.showFilterOrder}
                  order={order?.field === ele.name ? order.order : undefined}
                />
              </div>
              {ele.showFilterSearch && (
                <SearchFilterInput isFilterVisible={showSearchInput} />
              )}
            </th>
          );
        })}
        <th style={{ position: 'absolute', right: '6px' }}>
          {columns.find(ele => ele.showFilterSearch) && (
            <SearchFilterBtn
              isFilterVisible={true}
              clickShowFilter={() => setShowSearchInput(!showSearchInput)}
            ></SearchFilterBtn>
          )}
        </th>
      </tr>
    </thead>
  );
};
export default TableHeader;
