import React, { useEffect, useState } from 'react';
import Icon from '../../../../../components/shared/icon/Icon';
import FormGroup from '../../../../../components/shared/form/form-group/FormGroup';
import Input from '../../../../../components/shared/form/input/Input';
import { useFormRoleValidationsModel } from '../../../../../utils/forms/models/UseFormModel';
import { useForm } from '../../../../../utils/forms/useForm';
import { useTranslation } from 'react-i18next';

const InputEditWithIcon = ({
  value = '',
  name = '',
  label = '',
  iconClick = () => {},
  validations,
}: {
  value?: string;
  label?: string;
  name: string;
  validations?: useFormRoleValidationsModel;
  iconClick: (value: string) => void;
}) => {
  const [valueInput, setValueInput] = useState(value);
  const { t } = useTranslation();
  const { errors, changeValue, setData } = useForm(() => {}, validations, {
    [name]: value,
  });
  useEffect(() => {
    setData({ [name]: value });
    setValueInput(value);
  }, [value]);
  const changeValueAction = (val: any) => {
    setValueInput(val);
    changeValue(val, name);
  };
  const iconClickHandler = () => {
    value !== valueInput && iconClick && iconClick(valueInput);
  };
  return (
    <div className="">
      <FormGroup
        label={{ label }}
        error={{
          isVisible: !!errors && !!errors[name],
          message: t('errors.' + errors[name]?.code, errors[name]?.params),
        }}
      >
        <div className="d-flex align-items-center">
          <Input
            error={!errors}
            name="username"
            value={valueInput}
            onChangeAction={changeValueAction}
          />
          <Icon
            disabled={value === valueInput || (!!errors && !!errors[name])}
            style={{ position: 'absolute', zIndex: 5, right: '20px' }}
            icon="save"
            color="green"
            onClick={iconClickHandler}
          ></Icon>
        </div>
      </FormGroup>
    </div>
  );
};

export default InputEditWithIcon;
