import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Form, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../../app/auth/authSlice';
import { addSub, removeSub } from '../../../../app/clientSubscribe';
import {
  addError,
  addSuccessMessage,
  resetErrors,
} from '../../../../app/errors/errorSlice';
import { setTitle } from '../../../../app/page/pageSlice';
import { selectAssetId } from '../../../../components/core/filter-dashboard/filterDashboardSlice';
import ButtonList from '../../../../components/shared/form/buttons/button-list/ButtonList';
import Icon from '../../../../components/shared/icon/Icon';
import {
  Dashboard,
  DashboardExecute,
  Report,
} from '../../../../models/ReportModel';
import FormElements from '../../../../utils/forms/FormElements';
import { UseFormSubmitModel } from '../../../../utils/forms/models/UseFormModel';
import { useForm } from '../../../../utils/forms/useForm';
import { listPeriods } from '../../periods/services/periods.service';
import {
  addDashboard,
  deleteDashboard,
  executeDashboard,
  getDashboards,
  getReports,
  getReportUrl,
} from '../services/report.service';
import { useReportCreate } from './useReportCreate';
const ReportCreate = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const assetId = useSelector(selectAssetId);

  dispatch(setTitle(t('reporting.create.title')));

  const user = useSelector(selectUser);

  const [reports, setReports] = useState<Report[]>([]);

  const [reportUrl, setReportUrl] = useState<string>();

  const [filterOpen, setFilterOpen] = useState<boolean>(true);

  const [tableData, setTableData] = useState<Dashboard[]>();

  useEffect(() => {
    dispatch(resetErrors());
    getReports().then(res => setReports(res));
  }, []);

  const submitHandler: UseFormSubmitModel = (event, data) => {
    const dashboardId = Number(data.reportId);

    addDashboard({
      assetId: Number(data.assetId),
      dashboardId,
      periodId: Number(data.periodId),
    }).then(res => {
      setData({
        ...data,
        assetId: undefined,
        periodId: undefined,
      });
      refreshTable(dashboardId);
    });
  };
  const { validations, filtersData, buttons, reportData, setPeriods } =
    useReportCreate(t, user, reports);

  const { isSubmit, errors, submitAction, changeValue, dataForm, setData } =
    useForm(submitHandler, validations, { assetId });

  function refreshTable(dashboardId: number, reportChanged?: boolean) {
    getDashboards(dashboardId).then(res => {
      dispatch(resetErrors());
      setTableData(res);
      setReportUrl(undefined);
      if (res && res.length > 0 && reportChanged) {
        handleGenerate(dashboardId);
      }
    });
  }

  function handleChange(value: any, name: string) {
    setData({
      [name]: value,
      assetId: undefined,
      periodId: undefined,
    });
    setReportUrl(undefined);
    value && value !== '-1' && refreshTable(Number(value), true);
  }

  function handleChangeAsset(value: any, name: string) {
    if (name === 'assetId') {
      setData({ ...dataForm, periodId: undefined, [name]: value });
      listPeriods(undefined, undefined, Number(value)).then(res =>
        setPeriods(res)
      );
    } else {
      changeValue(value, name);
    }
  }

  function handleDeleteDashboardElement(dash: Dashboard) {
    const dashboardId = Number(dataForm.reportId);
    deleteDashboard({
      assetId: dash.assetId,
      dashboardId,
      periodId: dash.periodId,
    }).then(() => refreshTable(dashboardId));
  }

  function handleGenerate(dashboardId: number) {
    dispatch(resetErrors());
    executeDashboard(dashboardId).then(() => {
      let queue = addSub(`/id/${dashboardId}/report`, (res: any) => {
        const bodyObj: DashboardExecute = JSON.parse(res.body);
        removeSub(queue);
        if (bodyObj.status === 'ok' && dashboardId === bodyObj.dashboardId) {
          reloadReport(dashboardId);
        } else {
          dispatch(addError('REPORT_ERROR'));
        }
      });
    });
  }

  function reloadReport(dashboardId: number) {
    return getReportUrl({ reportId: dashboardId }).then(({ url }) => {
      setReportUrl(url);
      return;
    });
  }

  return (
    <div className="px-5">
      <Form className="2" onSubmit={submitAction}>
        <FormElements
          data={reportData}
          errors={errors}
          isSubmit={isSubmit}
          dataForm={dataForm}
          changeValue={handleChange}
        />
        {dataForm.reportId && (
          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className={`shared-label p-3`}
                onClick={() => setFilterOpen(p => !p)}
              >
                <div className="d-flex justify-content-between">
                  <span>{t('reporting.create.filters')}</span>
                  <>
                    {filterOpen && <Icon icon="up" color="primary" />}
                    {!filterOpen && <Icon icon="down" color="primary" />}
                  </>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <FormElements
                    data={filtersData}
                    errors={errors}
                    isSubmit={isSubmit}
                    dataForm={dataForm}
                    changeValue={handleChangeAsset}
                  />
                  <ButtonList buttons={buttons} />
                  {tableData && (
                    <>
                      <hr className={'my-4'} />
                      <Table striped bordered>
                        <thead>
                          <tr>
                            <th>{t('reporting.create.assets')}</th>
                            <th>{t('reporting.create.year')}</th>
                            <th>{t('reporting.create.month')}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map(tr => (
                            <tr>
                              <td>
                                {tr.fundCode} - {tr.fundDescription}
                              </td>
                              <td>{tr.year}</td>
                              <td>{tr.month}</td>
                              <td>
                                <div className="d-flex justify-content-center">
                                  <Button
                                    onClick={() =>
                                      handleDeleteDashboardElement(tr)
                                    }
                                  >
                                    {t('reporting.create.delete')}
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="d-flex justify-content-end">
                        <Button
                          disabled={tableData.length === 0}
                          onClick={() =>
                            handleGenerate(Number(dataForm.reportId))
                          }
                        >
                          {t('reporting.create.generate')}
                        </Button>
                      </div>
                    </>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}
      </Form>
      {dataForm.reportId && reportUrl && (
        <div className={`my-2`}>
          <Button onClick={() => reloadReport(Number(dataForm.reportId))}>
            🔄 Reload Report
          </Button>
          <div className={`my-2 border`}>
            <iframe
              src={reportUrl}
              title={'report'}
              frameBorder={0}
              scrolling="yes"
              allowFullScreen
              seamless={true}
              style={{ height: '100vh', width: '100%' }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportCreate;
