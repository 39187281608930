import { Routes } from '../components/routes/models/routes';
import * as routeName from './routeNameAuthType';
import { permissionType } from '../app/rolePermissions';
import ReportCreate from '../features/auth/reports/create-reports/ReportCreate';
import Home from '../features/auth/home/Home';
import ChangeAuthPassword from '../features/auth/change-auth-password/ChangeAuthPassword';
import PeriodCreate from '../features/auth/periods/period-create/PeriodCreate';
import AssetCreate from '../features/auth/asset/asset-create/AssetCreate';
import AssetEdit from '../features/auth/asset/asset-edit/AssetEdit';
import Users from '../features/auth/users/list/Users';
import UserCreate from '../features/auth/users/user-create/UserCreate';
import Database from '../features/auth/database/Database';
import DatabaseDetail from '../features/auth/database/DatabaseDetail';
import UserEdit from '../features/auth/users/user-edit/UserEdit';
import Table1 from '../features/auth/tables/table1/Table1';
import CheckSubscription from '../features/auth/check-subscription/CheckSubscription';
import AssetEPCreate from '../features/auth/asset-ep/create/AssetEPCreate';
import AssetEPEdit from '../features/auth/asset-ep/edit/AssetEPEdit';

const routes: Routes = {
  list: [
    {
      path: '/home',
      component: Home,
      name: routeName.home,
    },
    {
      path: '/change-password',
      component: ChangeAuthPassword,
      name: routeName.changePassword,
    },
    {
      path: '/periods/create',
      component: PeriodCreate,
      name: routeName.periodsCreate,
    },
    {
      path: '/assets/create',
      component: AssetCreate,
      name: routeName.assetCreate,
    },
    {
      path: '/assets/edit',
      component: AssetEdit,
      name: routeName.assetEdit,
    },
    {
      path: '/users',
      component: Users,
      name: routeName.usersIndex,
      permissions: [permissionType.MANAGEMENT_USERS],
    },
    {
      path: '/users/create',
      component: UserCreate,
      name: routeName.usersCreate,
      permissions: [permissionType.MANAGEMENT_USERS],
    },
    {
      path: '/database',
      component: Database,
      name: routeName.database,
      permissions: [permissionType.MANAGEMENT_VERSIONING_DB],
    },
    {
      path: '/database/:name',
      component: DatabaseDetail,
      name: routeName.databaseDetail,
      permissions: [permissionType.MANAGEMENT_VERSIONING_DB],
    },
    {
      path: '/users/:id/edit',
      component: UserEdit,
      name: routeName.usersEdit,
      permissions: [permissionType.MANAGEMENT_USERS],
    },
    {
      path: '/table1',
      component: Table1,
      name: routeName.table1Index,
    },
    {
      path: '/enable/user',
      component: CheckSubscription,
      name: 'CheckSubscriptionAuth',
    },
    {
      path: '/reports/create',
      component: ReportCreate,
      name: routeName.reportCreate,
    },
    {
      path: '/assetsep/create',
      component: AssetEPCreate,
      name: routeName.assetEPCreate,
    },
    {
      path: '/assetsep/edit',
      component: AssetEPEdit,
      name: routeName.assetEPEdit,
    },
  ],
  redirect: routeName.redirectPath,
};
export default routes;
