import React from 'react';
import Icon from '../icon/Icon';

const BackBtn = ({ label = 'Torna indietro', ...rest }) => {
  return (
    <div>
      <Icon icon="arrow-left" label={label} color="primary" {...rest}></Icon>
    </div>
  );
};

export default BackBtn;
