import { TFunction } from 'i18next';

export function useLogin(t: TFunction) {
  const validations = {
    username: {
      required: {
        params: { name: 'username' },
      },
    },
    password: {
      required: {
        params: { name: 'password' },
      },
    },
  };
  const data: {
    typeElement?: 'input' | 'select' | 'textarea';
    label: string;
    name: string;
    value?: any;
    options?: { label: string; value: string; disabled?: boolean }[];
    dataElement: Partial<{
      placeholder: string;
      type: string;
      multiply?: boolean;
    }>;
  }[] = [
    {
      typeElement: 'input',
      label: t('login.username'),
      name: 'username',
      value: '',
      dataElement: {
        placeholder: t('login.username'),
        type: 'text',
      },
    },
    {
      typeElement: 'input',
      label: t('login.password'),
      name: 'password',
      value: '',
      dataElement: {
        placeholder: t('login.password'),
        type: 'password',
      },
    },
  ];

  return {
    validations,
    data,
  };
}
