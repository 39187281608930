export default {
  app: {
    logout: 'Logout',
    profile: 'Cambio password',
    title: 'Nome app',
    reportCreate: 'Report',
  },
  home: {
    title: 'Dashboard',
    welcome: 'Welcome {{name}}',
  },
  login: {
    username: 'Username',
    password: 'Password',
    email: 'Email',
    username_message: 'Username di test è admin',
    password_message: 'Password di test è admin',
    cancel: 'Annulla',
    login: 'Login',
  },
  forgotPsw: {
    email: 'Email',
    cancel: 'Annulla',
    send: 'Invia',
  },
  changePsw: {
    oldPassword: 'Vecchia password',
    title: 'Modifica password',
    newPassword: 'Nuova password',
    confirmPassword: 'Conferma password',
    cancel: 'Annulla',
    send: 'Invia',
  },
  periods: {
    create: {
      title: 'Crea nuovo Periodo',
      month: 'Mese',
      year: 'Anno',
      assets: 'Fondi',
    },
  },
  dashboard: {
    filter: {
      asset: 'Fondo',
      assets_placeholder: 'Seleziona il Fondo',
      period: 'Periodo di Segnalazione',
    },
  },
  users: {
    list: {
      title: 'Lista Utenti',
    },
    edit: {
      title: 'Modifica Utente',
    },
    create: {
      title: 'Crea nuovo Utente',
      firstName: 'Nome',
      lastName: 'Cognome',
      username: 'Username',
      password: 'Password',
      email: 'E-mail',
      authorityId: 'Codice Covip',
      company: 'Azienda',
      company_placeholder: "Seleziona un'azienda",
      covip: 'Codice Covip',
      covip_placeholder: 'Seleziona un codice covip',
      assets: 'Fondi',
      assets_placeholder: 'Seleziona i fondi',
      role: 'Ruolo',
      role_placeholder: 'Seleziona il ruolo',
      roles: {
        ROLE_ADMIN: 'Amministratore di Dominio',
        ROLE_AUTHORITY_USER: 'Ente Segnalante',
        ROLE_USER_FULL_ACCESS: 'Utente di Dominio / Ente Segnalante',
        ROLE_USER_READ: 'Utente di Dominio Sola Visualizzazione',
        ROLE_SUPER_USER: 'Super Utente',
        ROLE_USER: 'Utente',
      },
    },
    vds: {
      select_vds_placeholder: 'Seleziona condizione',
    },
  },
  flow: {
    title: 'Gestione flussi',
  },
  vds: {
    title: 'Gestione Vds',
    detail: 'Dettaglio Vds',
    result: 'Risultati ricerca Vds',
    search: {
      vds: 'Filtra VdS',
      vds_placeholder: 'Tutti i VdS',
    },
  },
  errorPage: {
    title: 'Gestione Errori',
  },
  tableLog: {
    title: 'Gestione Log',
  },
  tables: {
    title: 'Gestione Tavole',
  },
  reports: {
    title: 'Gestione Segnalazioni',
  },
  errors: {
    be: {
      ERR_LAUNCH_ETL: 'Si è verificato un errore nel lancio ETL',
      ERR_DELETE_FILE: 'Eliminazione file non riuscita',
      RESTORE_PWD_SUCCESS: 'Utente riabilitato',
      BAD_CREDENTIALS: 'Credenziali non valide',
      USER_NOT_FOUND: 'Utente non trovato',
      USER_NOT_ENABLED: "Utente non abilitato. Contattare l'amministratore",
      PWD_EXPIRED:
        "Password scaduta. È stata inviata una email all'indirizzo associato a questo account per poter reimpostare la password",
      RESET_TOKEN_NOT_FOUND: 'Riavviare la procedura token scaduto',
      CHANGE_PWD_SEND_EMAIL_SUCCESS:
        'Controlla la tua email per cambiare password',
      CHANGE_PWD_SUCCESS: 'Password cambiata con successo',
      USER_ACTIVE_SUCCESS: 'Utente registrato con successo',
      USER_CREATE: 'Utente creato con successo',
      USER_DELETED: 'Utente eliminato con successo',
      PERIOD_ALREADY_EXISTS: 'Il periodo già esiste',
      USER_EDIT_SUCCESS: 'Utente modificato con successo',
      MAIL_SEND_EXCEPTION: "Errore nell'invio dell'email",
      GENERIC_ERROR: 'Si è verificato un errore inaspettato',
      FILE_NAME_ERROR:
        'Il file non può essere caricato, errato il nome del file',
      FILE_SUCCESS_LOAD: 'Il file è stato caricato correttamente',
      FILE_ERROR_LOAD: 'Errore nel caricamento del file, riprovare',
      ASSET_NOT_FOUND: 'Fondo non trovato',
      ASSET_ALREADY_EXISTS: "L'asset già esiste",
      CREATE_ASSET: 'Fondo inserito correttamente',
      CREATE_ASSET_ERROR: "Errore nell'inserimento del Fondo",
      CREATE_ASSET_EP: 'Ente previdenziale inserito correttamente',
      CREATE_ASSET_EP_ERROR: "Errore nell'inserimento dell'Ente previdenziale ",
      EDIT_ASSET: 'Fondo modificato correttamente',
      EDIT_ASSET_ERROR: 'Errore nella modifica del Fondo',
      CREATE_PERIOD: 'Periodo inserito correttamente',
      CREATE_PERIOD_ERROR: "Errore nell'inserimento del Periodo",
      XML_NOT_VALID: 'Xml non valido',
      FILE_SIZE_EXCEEDED: 'Il file caricato è troppo grande',
      EDIT_VDS_SUCCESS: 'Vds modificata con successo',
      REMOVE_VDS_SUCCESS: 'La Vds selezionata è stata eliminata',
      PASSWORD_EQUALS_OLD:
        'La password deve essere diversa dalle ultime 5 usate',
      EMPTY_SURVEY: 'Segnalazione non prodotta per mancanza di dati',
      REPORT_SUCCESS: 'Report generato con successo',
      REPORT_ERROR: 'Errore durante la generazione del Report',
    },
    fe: {
      required: 'Il campo {{name}} è obbligatorio',
      email: "Il campo {{name}} non contiene un'e-mail valida",
      confirm: 'Il campo {{fieldCompare}} deve essere uguale al campo {{name}}',
      minLength: 'Il campo {{name}} deve essere almeno di {{min}} caratteri',
      regex: 'Il campo {{name}} deve rispettare la seguente regex: {{regex}}',
      length: 'Il campo {{name}} deve essere di {{moreData.length}} caratteri',
      isNumber: 'Il campo {{name}} deve essere un numero',
      upperCharacter:
        'Il campo {{name}} deve avere almeno una lettera maiuscola',
      oneNumber: 'Il campo {{name}} deve contenere almeno un numero',
      specialCharacter:
        'Il campo {{name}} deve contenere almeno un carattere speciale tra @#!~$%^&*()-+/:.,<>?|',
    },
  },
  reporting: {
    create: {
      title: 'Gestione report',
      filters: 'Filtri',
      id: 'Report',
      assets: 'Fondi',
      year: 'Anno',
      month: 'Mese',
      add: 'Aggiungi',
      delete: 'Rimuovi',
      generate: 'Genera Report',
      periods: 'Periodi',
    },
  },
  database: {
    fundCode: 'Codice Fondo',
  },
};
