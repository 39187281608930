import http from '../../../../utils/http';

export const listAssets = (userId: any) =>
  http.get(`user/assets?userId=${userId}`);

export const listPeriods = (
  userId: number | undefined,
  role: string | undefined,
  assetId: number
) =>
  assetId && assetId !== null
    ? http.get(`dashboard/periods?assetId=${assetId}`)
    : http.get(`dashboard/periods`);

export const savePeriod = (
  data: any,
  userId: number | undefined,
  role: string | undefined
) =>
  http.post(`dashboard/period`, {
    ...data,
    userId,
    role,
  });
