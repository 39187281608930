import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BackBtn from '../../../../../components/shared/backBtn/BackBtn';
import {
  selectSelectedTable,
  resetSelectedTable,
} from '../../../tables/table1/table1Slice';
import { setTitle } from '../../../../../app/page/pageSlice';

const TablesBackDashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedTable = useSelector(selectSelectedTable);

  const goBackAction = () => {
    dispatch(resetSelectedTable());
    dispatch(setTitle(t('tables.title')));
  };
  return <>{selectedTable && <BackBtn onClick={goBackAction}></BackBtn>}</>;
};

export default TablesBackDashboard;
