import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../../app/store';
import ErrorModel from './models/ErrorModel';
import {
  detailErrorDashboard,
  detailErrorLogicDashboard,
  downloadFileErrors,
  listErrorDashboard,
  listFieldDashboard,
  listLogicErrorDashboard,
  updateMultiVdsDashboard,
  updateVdsDashboard,
} from './services/errorDashboard.service';
import DetailErrorModel from './models/DetailErrorModel';
import {
  OrderFilter,
  TableObjProps,
} from '../../../../../components/shared/table/models/TableProps';
import { resetErrors } from '../../../../../app/errors/errorSlice';
import LogicErrorModel from './models/LogicErrorModel';
import { addSub, removeSub } from '../../../../../app/clientSubscribe';
import {
  incrementN,
  decrementN,
} from '../../../../../components/shared/loader/redux/loadSlice';
import { FileVds } from '../vds/models/SliceVdsModel';
import { downloadFileVds } from '../../services/vds.service';

type TableObjPropsLogical = TableObjProps<LogicErrorModel>;
type TableObjPropsLogicalDetail = TableObjProps<DetailErrorModel>;

const initialState: {
  list: ErrorModel[];
  selectVds?: string;
  countDetailError?: number | undefined;
  pagesDetailError?: number | undefined;
  currentPageDetailError?: number | undefined;
  detailError: DetailErrorModel[];
  logicalErrors?: TableObjPropsLogical;
  logicalErrorsDetail?: TableObjPropsLogicalDetail;
  selectedLogicalError?: LogicErrorModel;
  logicalView: boolean;
  listChangeValueDetailError: { id: string; value: string }[];
  indexSelected: (string | number)[];
  listFields: string[];
  selectedField?: string;
  orderFilter: OrderFilter;
} = {
  list: [],
  detailError: [],
  logicalView: false,
  indexSelected: [],
  listFields: [],
  listChangeValueDetailError: [],
  orderFilter: {},
};

export const errorDashboardSlice = createSlice({
  name: 'errorDashboard',
  initialState,
  reducers: {
    addListChangeValueDetailError: (
      state,
      action: PayloadAction<{ id: string; value: string }>
    ) => {
      state.listChangeValueDetailError = [
        ...state.listChangeValueDetailError.filter(
          ele => ele.id !== action.payload.id
        ),
        action.payload,
      ];
    },
    removeListChangeValueDetailError: (
      state,
      action: PayloadAction<string>
    ) => {
      state.listChangeValueDetailError =
        state.listChangeValueDetailError.filter(
          ele => ele.id !== action.payload
        );
    },
    resetListChangeValueDetailError: state => {
      state.listChangeValueDetailError = [];
    },
    setErrorList: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setListFields: (state, action: PayloadAction<string[]>) => {
      state.listFields = action.payload;
    },
    setSelectedField: (state, action: PayloadAction<string>) => {
      state.selectedField = action.payload;
    },
    resetSelectedField: state => {
      state.selectedField = undefined;
    },
    resetListFields: state => {
      state.listFields = [];
    },
    setCountDetailError: (state, action: PayloadAction<number>) => {
      state.countDetailError = action.payload;
    },
    setPagesDetailError: (state, action: PayloadAction<number>) => {
      state.pagesDetailError = action.payload;
    },
    setCurrentPageDetailError: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.currentPageDetailError = action.payload;
    },
    resetCountDetailError: state => {
      state.countDetailError = undefined;
    },
    resetPagesDetailError: state => {
      state.pagesDetailError = undefined;
    },
    resetDetailError: state => {
      state.detailError = [];
    },
    resetCurrentPageDetailError: state => {
      state.currentPageDetailError = undefined;
    },
    setOrderFilter: (state, action: PayloadAction<OrderFilter>) => {
      state.orderFilter = {
        field: action.payload.field,
        order: state.orderFilter?.order === 'DESC' ? 'ASC' : 'DESC',
      };
    },
    setSelectVds: (state, action: PayloadAction<string>) => {
      state.selectVds = action.payload;
    },
    resetSelectVds: state => {
      state.selectVds = undefined;
    },
    resetErrorList: state => {
      state.list = [];
    },
    setSelectedLogicalError: (
      state,
      action: PayloadAction<LogicErrorModel>
    ) => {
      state.selectedLogicalError = action.payload;
    },
    resetSelectedLogicalError: state => {
      state.selectedLogicalError = undefined;
    },
    setDetailError: (state, action: PayloadAction<any[]>) => {
      state.detailError = action.payload;
    },
    setlogicalError: (state, action: PayloadAction<TableObjPropsLogical>) => {
      state.logicalErrors = action.payload;
    },
    setlogicalErrorsDetail: (
      state,
      action: PayloadAction<TableObjPropsLogicalDetail>
    ) => {
      state.logicalErrorsDetail = action.payload;
    },
    resetLogicalErrorsDetail: state => {
      state.logicalErrorsDetail = undefined;
    },

    setNewValueDetailError: (
      state,
      action: PayloadAction<{ newValue: string; id: number }>
    ) => {
      state.detailError = state.detailError.map(ele =>
        ele.tracingId === action.payload.id
          ? { ...ele, newValue: action.payload.newValue }
          : ele
      );
    },
    setNewValueDetailErrorByField: (
      state,
      action: PayloadAction<{ newValue: string; field: string }>
    ) => {
      state.detailError = state.detailError.map(ele =>
        ele.field === action.payload.field
          ? { ...ele, newValue: action.payload.newValue }
          : ele
      );
    },
    setSelection: (state, action: PayloadAction<number[]>) => {
      state.indexSelected = action.payload;
    },
    addSelection: (state, action: PayloadAction<number>) => {
      state.indexSelected = [...state.indexSelected, action.payload];
    },
    removeSelection: (state, action: PayloadAction<number>) => {
      state.indexSelected = state.indexSelected.filter(
        ele => ele !== action.payload
      );
    },
    selectAllIndexSelected: state => {
      state.indexSelected = state.list.map(ele => ele.vds);
    },
    deselectAllIndexSelected: state => {
      state.indexSelected = [];
    },
    setLogicalView: (state, action: PayloadAction<boolean>) => {
      state.logicalView = action.payload;
    },
  },
});

export const {
  setErrorList,
  setSelectVds,
  resetSelectVds,
  resetErrorList,
  setDetailError,
  deselectAllIndexSelected,
  selectAllIndexSelected,
  setListFields,
  setSelectedField,
  setCountDetailError,
  setPagesDetailError,
  setCurrentPageDetailError,
  resetDetailError,
  setOrderFilter,
  resetListFields,
  setSelection,
  setlogicalError,
  setSelectedLogicalError,
  resetSelectedLogicalError,
  resetSelectedField,
  resetCountDetailError,
  resetPagesDetailError,
  resetCurrentPageDetailError,
  setNewValueDetailErrorByField,
  setNewValueDetailError,
  addSelection,
  removeSelection,
  addListChangeValueDetailError,
  removeListChangeValueDetailError,
  resetListChangeValueDetailError,
  setLogicalView,
  setlogicalErrorsDetail,
  resetLogicalErrorsDetail,
} = errorDashboardSlice.actions;

export const addRemoveSelectionAction =
  (id: number, index: number): AppThunk =>
  (dispatch, getState) => {
    getState().errorDashboard.indexSelected.includes(id)
      ? dispatch(removeSelectionAction(id, index))
      : dispatch(addSelection(id));
  };

export const saveDetailErrorTraice =
  (id: number, idVds: number): AppThunk =>
  (dispatch, getState) => {
    const copy: any = {
      ...getState().errorDashboard.detailError.find(
        ele => ele.tracingId === id
      ),
    };
    const changeValue =
      getState().errorDashboard.listChangeValueDetailError.find(
        ele => +ele.id === id
      );
    if (getState().errorDashboard.selectedField) {
      dispatch(updateMultiVdsDashboardAction(copy, idVds, changeValue));
    } else {
      dispatch(updateVdsDashboardAction(copy, idVds, changeValue));
    }

    //dispatch(removeOpenRow(index));
  };

export const updateMultiVdsDashboardAction =
  (
    copy: any,
    idVds: number,
    changeValue: { id: string; value: string } | undefined
  ): AppThunk =>
  (dispatch, getState) => {
    const tenantId = getState().filterDashboard.tenant;
    tenantId &&
      updateMultiVdsDashboard(
        {
          field: copy.field,
          loadedId: copy.idVds.loadedId,
          value: changeValue?.value,
          vds: getState().errorDashboard.selectVds,
        },
        tenantId
      ).then(res => {
        let errorSubscribe: any;
        dispatch(incrementN());
        errorSubscribe = addSub(`/id/${tenantId}/update`, () => {
          dispatch(decrementN());
          removeSub(errorSubscribe);
          dispatch(
            setNewValueDetailErrorByField({
              newValue: changeValue?.value || '',
              field: copy.field,
            })
          );
          dispatch(removeListChangeValueDetailError(copy.tracingId));
          dispatch(getErrorList(idVds));
        });
      });

    //dispatch(removeOpenRow(index));
  };

export const updateVdsDashboardAction =
  (
    copy: any,
    idVds: number,
    changeValue: { id: string; value: string } | undefined
  ): AppThunk =>
  (dispatch, getState) => {
    const tenantId = getState().filterDashboard.tenant;
    copy.id = copy.idVds;

    delete copy.descriptionError;
    delete copy.descriptionField;
    delete copy.editable;
    delete copy.loadedId;
    delete copy.idVds;
    delete copy.severity;
    tenantId &&
      updateVdsDashboard(
        { ...copy, value: changeValue?.value },
        getState().errorDashboard?.selectVds,
        tenantId
      ).then(res => {
        dispatch(
          setNewValueDetailError({
            newValue: changeValue?.value || '',
            id: copy.tracingId,
          })
        );
        dispatch(removeListChangeValueDetailError(copy.tracingId));
        dispatch(getErrorList(idVds));
      });

    //dispatch(removeOpenRow(index));
  };

export const removeSelectionAction =
  (id: number, index: number): AppThunk =>
  dispatch => {
    dispatch(removeSelection(id));
    //dispatch(removeOpenRow(index));
  };

export const setSelectFields =
  (value: string, id: number): AppThunk =>
  dispatch => {
    dispatch(setCurrentPageDetailError(undefined));
    dispatch(setSelectedField(value));
    dispatch(getDetailError(id));
  };

export const loadDetailsError =
  (page: number, id: number): AppThunk =>
  dispatch => {
    dispatch(setCurrentPageDetailError(page));
    dispatch(getDetailError(id));
  };

export const resetFilterPage = (): AppThunk => dispatch => {
  dispatch(resetSelectedField());
  dispatch(resetDetailError());
  dispatch(resetCountDetailError());
  dispatch(resetPagesDetailError());
  dispatch(resetCurrentPageDetailError());
  dispatch(resetListChangeValueDetailError());
};

export const setOrderFilterAction =
  (data: OrderFilter, id: number): AppThunk =>
  dispatch => {
    dispatch(setOrderFilter(data));
    dispatch(getDetailError(id));
  };

export const getErrorList =
  (id: number, goBack?: () => void): AppThunk =>
  (dispatch, getState) => {
    const tenant = getState().filterDashboard.tenant;
    if (
      (getState().filterDashboard.assetId &&
        getState().filterDashboard.periodId,
      tenant)
    ) {
      listErrorDashboard(id, tenant).then(res => {
        console.log(res);
        dispatch(
          setErrorList(res.map((ele: ErrorModel) => ({ ...ele, id: ele.vds })))
        );
      });
    } else {
      goBack && goBack();
    }
  };

export const getDetailLogicError =
  (loadedId: number, objPage?: TableObjPropsLogical): AppThunk =>
  (dispatch, getState) => {
    if (getState().errorDashboard.selectVds) {
      const tenantId = getState().filterDashboard.tenant;
      tenantId &&
        detailErrorLogicDashboard(
          {
            loadedId,
            vds: getState().errorDashboard.selectVds,
            guuid: getState().errorDashboard.selectedLogicalError?.guuid,
            page: objPage && objPage.page,
          },
          tenantId
        ).then(res => {
          const data = res;
          dispatch(
            setlogicalErrorsDetail({
              data: data.errors,
              page: objPage?.page || 1,
              pages: data.pages || objPage?.pages,
              count: data.count || objPage?.count,
            })
          );
        });
    }
  };

export const getDetailError =
  (id: number): AppThunk =>
  (dispatch, getState) => {
    if (getState().errorDashboard.selectVds) {
      const { currentPageDetailError: page, selectedField } =
        getState().errorDashboard;
      const tenantId = getState().filterDashboard.tenant;
      tenantId &&
        detailErrorDashboard(
          tenantId,
          id,
          getState().errorDashboard.selectVds,
          page,
          selectedField ? { field: selectedField } : undefined,
          getState().errorDashboard?.orderFilter
        ).then(res => {
          dispatch(setDetailError(res.errors));
          if (res.count) {
            dispatch(setCountDetailError(res.count));
            dispatch(setPagesDetailError(res.pages));
          }
        });

      tenantId &&
        listFieldDashboard(
          id,
          getState().errorDashboard.selectVds,
          tenantId
        ).then(res => {
          dispatch(setListFields(res));
        });
    }
  };

export const getLogicError =
  (id: number, objPage?: TableObjPropsLogical): AppThunk =>
  (dispatch, getState) => {
    const tenantId = getState().filterDashboard.tenant;
    if (getState().errorDashboard.selectVds && tenantId) {
      listLogicErrorDashboard(
        {
          vds: getState().errorDashboard.selectVds,
          loadedId: id,
          page: objPage && objPage.page,
        },
        tenantId
      ).then(res => {
        const data = res;
        dispatch(
          setlogicalError({
            data: data.errors,
            page: objPage?.page || 1,
            pages: data.pages || objPage?.pages,
            count: data.count || objPage?.count,
          })
        );
      });
    }
  };

export const setSelectedVdsWithError =
  (vds: string): AppThunk =>
  dispatch => {
    dispatch(resetErrors());
    dispatch(setSelectVds(vds));
  };

export const DownloadFileErrorExportAction =
  (): AppThunk => (dispatch, getState) => {
    const tenant = getState().filterDashboard.tenant;
    const data = {
      assetId: getState().filterDashboard.assetId,
      periodId: getState().filterDashboard.periodId,
      loadedId: getState().filterDashboard.loadedFileId,
      vds: getState().errorDashboard.selectVds,
    };

    const logicalView = getState().errorDashboard.logicalView;
    tenant &&
      data.assetId &&
      data.periodId &&
      downloadFileErrors(data, tenant, logicalView).then(res => {
        const fileName = res.headers['content-disposition']
          ? res.headers['content-disposition'].split('filename=')[1]
          : 'undefined.xml';
        const blob = new Blob([res.data], {
          type: res.headers['content-type'],
        });
        saveAs(blob, fileName);
        dispatch(resetErrors());
      });
  };

export const resetSelectedVdsWithError = (): AppThunk => dispatch => {
  dispatch(resetErrors());
  dispatch(resetSelectVds());
};

export const selectErrorList = (state: RootState) => state.errorDashboard.list;
export const selectListChangeValueDetailError = (state: RootState) =>
  state.errorDashboard.listChangeValueDetailError;
export const selectDetailErrorList = (state: RootState) =>
  state.errorDashboard.detailError;
export const selectLogicErrorList = (state: RootState) =>
  state.errorDashboard.logicalErrors;
export const selectLogicErrorDetailList = (state: RootState) =>
  state.errorDashboard.logicalErrorsDetail;
export const selectVsd = (state: RootState) => state.errorDashboard.selectVds;
export const selectFields = (state: RootState) =>
  state.errorDashboard.listFields;
export const selectField = (state: RootState) =>
  state.errorDashboard.selectedField;
export const selectIndexRow = (state: RootState) => state.dashboard.indexRow;
export const selectIndexSelected = (state: RootState) =>
  state.errorDashboard.indexSelected;
export const selectIsSelectedAllRow = (state: RootState) =>
  !state.errorDashboard.list.find(
    ele => !state.errorDashboard.indexSelected.includes(ele.vds)
  );
export const selectPagesDetailError = (state: RootState) =>
  state.errorDashboard.pagesDetailError;
export const selectCurrentPageDetailError = (state: RootState) =>
  state.errorDashboard.currentPageDetailError;
export const selectOrderFilter = (state: RootState) =>
  state.errorDashboard.orderFilter;
export const selectLogicalView = (state: RootState) =>
  state.errorDashboard.logicalView;
export const selectSelectedLogicalError = (state: RootState) =>
  state.errorDashboard.selectedLogicalError;

export default errorDashboardSlice.reducer;
