import http from '../../../utils/http';

const url = 'database/';

export const statusDatabaseCall = (
  req: { page?: number; search?: { [key in string]: any } } = {}
) =>
  http.post(`${url}status`, {
    ...req.search,
    paging: {
      pageSize: 10,
      page: req.page && req.page > 1 ? req.page : undefined,
    },
    sort: { key: 'fundCode', type: 'ASC' },
  });

export const historyDatabaseCall = (databaseName: string) =>
  http.get(`${url}history/${databaseName}`);

export const runUpdateCall = (tenantId: string) =>
  http.put(`queue/database`, { tenantId });
