import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetErrors } from '../../../../../../app/errors/errorSlice';
import Dropzone from '../../../../../../components/shared/dropzone/Dropzone';
import ModalSuccess from '../../../../../../components/shared/modal/modalSuccess/ModalSuccess';
import PrimaryButton from '../../../../../../components/shared/form/buttons/primary-button/PrimaryButton';
import ListElementCustom from '../../../../../../components/shared/listElement/ListElementCustom';
import Icon from '../../../../../../components/shared/icon/Icon';
import SecondaryButton from '../../../../../../components/shared/form/buttons/secondary-button/SecondaryButton';
import { Col, Row } from 'react-bootstrap';
import {
  callDataFile,
  resetFileErrorList,
  resetModalErrors,
  resetReport,
  selectFileErrorList,
  selectFileLoadedList,
  selectModalErrors,
} from '../ReportSlice';
import ErrorList from '../../../../../../components/shared/form/errorList/ErrorList';

const LoadReportFile = ({ closeAction }: { closeAction: () => void }) => {
  const dispatch = useDispatch();
  const [listFile, setListFile] = useState<any[]>([]);
  const loadedFiles = useSelector(selectFileLoadedList);
  const errorFiles = useSelector(selectFileErrorList);
  const modalErrors = useSelector(selectModalErrors);
  const [showListFile, setShowListFile] = useState<boolean>(false);
  useEffect(() => {
    return () => {
      dispatch(resetReport());
    };
  }, []);
  const onUpload = (files: any) => {
    dispatch(resetErrors());
    setListFile([...listFile, ...files]);
    setShowListFile(true);
  };

  const loadFileData = () => {
    if (listFile && listFile.length) {
      const callBack = () => closeAction && closeAction();
      const listFileFilter = listFile.filter(
        ele => !loadedFiles.includes(ele.name)
      );
      dispatch(callDataFile(0, listFileFilter, callBack));
    }
  };
  const deleteHandler = (event: any, ele: any) => {
    const filterList = listFile.filter(el => el.name !== ele.name);
    setListFile(filterList);
    !filterList.length && setShowListFile(false);
  };
  const templateRight = (ele: any) => (
    <span onClick={(event: React.MouseEvent) => deleteHandler(event, ele)}>
      <Icon
        icon={loadedFiles.includes(ele.name) ? 'confirm' : 'delete'}
        size="16"
      ></Icon>
    </span>
  );

  const closeModal = () => {
    closeAction();
    dispatch(resetModalErrors());
    dispatch(resetFileErrorList());
  };
  return (
    <>
      {showListFile ? (
        <ModalSuccess
          showModal={true}
          templateHeader={
            <div className="d-flex justify-content-between">
              <span>Questi sono i tuoi file da caricare</span>
              <span
                className="d-flex pointer small align-items-center"
                onClick={() => setShowListFile(false)}
              >
                Aggiungi file <Icon icon="upload"></Icon>
              </span>
            </div>
          }
          templateBody={
            <div>
              {modalErrors && modalErrors.length ? (
                <div className="d-flex">
                  <ErrorList errors={modalErrors}></ErrorList>
                </div>
              ) : null}
              <ListElementCustom
                list={listFile}
                error={errorFiles}
                property="name"
                templateRight={templateRight}
              />
              <Row className="mt-3">
                <Col>
                  <SecondaryButton onClick={closeModal}>
                    Annulla
                  </SecondaryButton>
                </Col>
                <Col>
                  <PrimaryButton
                    onClick={loadFileData}
                    disabled={listFile && listFile.length === 1}
                  >
                    Carica File
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          }
        />
      ) : (
        <Dropzone
          onClose={closeAction}
          onDrop={files => {
            onUpload(files);
          }}
          accept="application/xml,.xml"
        />
      )}
    </>
  );
};

export default LoadReportFile;
