import React from 'react';
import { editEmail } from '../../services/users.service';
import InputEditWithIcon from './InputEditWithIcon';

const UserEditEmail = ({
  email,
  id,
  saved,
}: {
  email?: string;
  id?: number;
  saved: () => void;
}) => {
  const validations = {
    email: {
      required: {
        params: { name: 'email' },
      },
      email: {
        params: { name: 'email' },
      },
    },
  };
  const saveEmail = (emailValue: string) => {
    editEmail(id, emailValue).then(res => {
      saved && saved();
    });
  };
  return (
    <InputEditWithIcon
      label="Email"
      name="email"
      value={email}
      iconClick={saveEmail}
      validations={validations}
    />
  );
};

export default UserEditEmail;
