import React from 'react';
import { ButtonProps as BtnProps } from 'react-bootstrap/Button';
import Button from '../../button/Button';
import PrimaryButton from '../../primary-button/PrimaryButton';
import SecondaryButton from '../../secondary-button/SecondaryButton';

interface ButtonProps extends BtnProps {
  label?: string;
  typeButton?: 'primary' | 'secondary' | 'button';
}

const SingleButton = ({
  label = '',
  typeButton = 'primary',
  ...rest
}: ButtonProps) => {
  const buttons = {
    primary: <PrimaryButton {...rest}>{label}</PrimaryButton>,
    secondary: <SecondaryButton {...rest}>{label}</SecondaryButton>,
    button: <Button {...rest}>{label}</Button>,
  };
  return buttons[typeButton];
};

export default SingleButton;
