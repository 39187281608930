import React from 'react';
import { mergeClass } from '../../functions/function';
interface LabelProps extends React.HTMLAttributes<HTMLOrSVGElement> {}
const Caption = ({ children, className, ...rest }: LabelProps) => {
  return (
    <span className={mergeClass('caption', className)} {...rest}>
      {children}
    </span>
  );
};

export default Caption;
