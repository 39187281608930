import React from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { FormControlProps } from 'react-bootstrap/FormControl';
import { mergeClass } from '../../functions/function';

interface FormProps extends FormControlProps {
  error?: boolean;
  value?: string;
  label?: string;
  checked?: boolean;
}

const CheckboxCustom: React.FC<
  FormProps & React.HTMLProps<HTMLInputElement>
> = ({
  children,
  className,
  value = '',
  error = false,
  label,
  checked = false,
  ...rest
}) => {
  const classMerge: string = mergeClass(
    'shared-checkbox',
    error ? ' error' : ''
  );

  return (
    <InputGroup className={classMerge}>
      <div className="d-flex ">
        <FormControl
          type="checkbox"
          checked={checked}
          className="check"
          value={value}
          {...rest}
        />
        {label && <span>{label}</span>}
      </div>
    </InputGroup>
  );
};

export default CheckboxCustom;
