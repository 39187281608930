import React, { useEffect } from 'react';
import { User } from '../../../app/auth/model/User';
import AuthLayout from './components/AuthLayout';
import PublicLayout from './components/publicLayout/PublicLayout';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { setErrorToRemove } from '../../../app/errors/errorSlice';

interface AppLayoutProps {
  children: React.ReactNode;
  user: User | undefined;
  logout: () => void;
}
const AppLayout = ({ children, user, logout }: AppLayoutProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    history.listen(() => {
      dispatch(setErrorToRemove());
    });
  }, []);
  return !!user ? (
    <AuthLayout user={user} logout={logout}>
      {children}
    </AuthLayout>
  ) : (
    <PublicLayout>{children}</PublicLayout>
  );
};

export default AppLayout;
