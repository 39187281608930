import jwt_decode from 'jwt-decode';
import { User } from '../app/auth/model/User';

export const decoded = (
  token: string
):
  | { iss: string; sub: string; user: User; exp: number; iat: number }
  | false => {
  try {
    return isValid(jwt_decode(token));
  } catch (e) {
    return false;
  }
};

export const isValid = (data: {
  iss: string;
  sub: string;
  user: User;
  exp: number;
  iat: number;
}) => {
  return data && data.exp > Date.now() / 1000 ? data : false;
};
