import http from '../../../../utils/http';
import { User } from '../../../../app/auth/model/User';
import { AssetModel } from '../../../../models/AssetModel';
import { RoleModel } from '../../../../models/RoleModel';
import { AuthorityModel } from '../../../../models/AuthorityModel';
import { UserModel } from '../../../../models/UserModel';

export const addUser = (data: any) =>
  http.post(`user`, {
    ...data,
  });
export const editUser = (data: any) =>
  http.put(`user?id=${data.id}`, {
    ...data,
  });
export const editUsername = (id?: number, username?: string) =>
  http.post(`user/changeUsername?Id=${id}&userName=${username}`, {});
export const editEmail = (id?: number, email?: string) =>
  http.post(`user/changeEmail?Id=${id}&email=${email}`, {});

export const getAssetsList = (
  obj: { companyId?: string | number; fullAccess?: boolean } = {}
) => {
  return http.get<AssetModel[]>(
    `asset${
      obj && Object.keys(obj).length
        ? '?' + new URLSearchParams(obj as any).toString()
        : ''
    }`
  );
};

export const getAssetsListByAuthority = (authorityId: string | number) =>
  http.get<AssetModel[]>(`asset/authority/${authorityId}`);

export const getListAuthority = (companyId: any) =>
  http.get<AuthorityModel[]>(
    `user/authorities${companyId ? '?companyId=' + companyId : ''}`
  );

export const getCompanyList = () => http.get<any[]>(`user/company/load`);
export const listUsers = (data: any = {}) =>
  http.post<{ count: any; users: UserModel[] }>(`user/search`, data);

export const getRolesList = () => http.get<RoleModel[]>(`user/roles/load`);

export const getUser = (id: string = '') => http.get<User>(`user?id=${id}`);

export const deleteUser = (id: number) =>
  http.post<User>(`user/deleteUser?userId=${id}`);
