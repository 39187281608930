import http, { httpBase } from '../../../../../../utils/http';
import LogicErrorReqModel from '../models/LogicErrorModel';

export const listErrorDashboard = (id: any, tenantId: string) =>
  http.get(`dashboard/error/aggregate?loadedId=${id}`, {
    headers: { 'X-TenantID': tenantId },
  });

export const listLogicErrorDashboard = (
  req: Partial<LogicErrorReqModel> = {},
  tenantId: string
) =>
  http.post(
    `dashboard/error/logic`,
    {
      ...req,
      pageSize: 5,
    },
    { headers: { 'X-TenantID': tenantId } }
  );

export const downloadFileErrors = (
  data: any,
  tenantId: string,
  logicError: boolean
) =>
  httpBase.post(
    `dashboard/error/${logicError ? 'logic' : 'detail'}/export`,
    data,
    {
      headers: { 'X-TenantID': tenantId },
      responseType: 'blob',
    }
  );

export const listFieldDashboard = (
  id: any,
  vds: string = '',
  tenantId: string
) =>
  http.get(`dashboard/error/fields?loadedId=${id}&vds=${vds}`, {
    headers: { 'X-TenantID': tenantId },
  });

export const detailErrorDashboard = (
  tenantId: string,
  loadedId: any,
  vds: string = '',
  page: number | undefined,
  filter?: any,
  orderFilter?: any
) => {
  let sort =
    orderFilter && orderFilter.field
      ? { key: orderFilter.field.toUpperCase(), type: orderFilter.order }
      : undefined;
  return http.post(
    `dashboard/error/detail`,
    {
      loadedId,
      page,
      pageSize: 5,
      vds,
      filter,
      sort,
    },
    { headers: { 'X-TenantID': tenantId } }
  );
};

export const detailErrorLogicDashboard = (req: any, tenantId: string) => {
  return http.post(
    `dashboard/error/detail`,
    {
      ...req,
      pageSize: 5,
    },
    { headers: { 'X-TenantID': tenantId } }
  );
};

export const updateVdsDashboard = (
  obj: any,
  vds: string = '',
  tenantId: string
) => http.put(`dashboard/${vds}`, obj, { headers: { 'X-TenantID': tenantId } });

export const updateMultiVdsDashboard = (obj: any, tenantId: string) =>
  http.put(`dashboard/errors`, obj, { headers: { 'X-TenantID': tenantId } });
