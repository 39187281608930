import React from 'react';
import HeadingError from './HeadingError';

const ListHeadingErrors = ({ list, data }: { list: any[]; data: any[] }) => {
  return (
    <div className="contentHeadingError">
      {list.map((ele: any, index) => (
        <HeadingError
          key={index}
          label={ele.label}
          value={list && data[ele.name]}
        />
      ))}
    </div>
  );
};

export default ListHeadingErrors;
