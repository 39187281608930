import { TableData } from '../../../../../components/shared/table/models/TableProps';

export const tableStructureLog: TableData[] = [
  {
    name: 'tableName',
    label: 'Nome Tavola',
  },
  {
    name: 'resultDescription',
    label: 'Descrizione',
  },
  {
    name: 'date',
    label: 'Data',
  },
  {
    name: 'startDate',
    label: 'Ora Inizio',
  },
  {
    name: 'endDate',
    label: 'Ora Fine',
  },
];
