import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDetailLogicError,
  selectLogicErrorDetailList,
  resetSelectedLogicalError,
} from '../../errorDashboardSlice';
import TableWithAction from '../../../../../../../components/shared/table/table/TableWithAction';
import LogicErrorModel from '../../models/LogicErrorModel';
import Icon from '../../../../../../../components/shared/backBtn/BackBtn';
import DropdownErrorAction from '../../components/dropdownErrorAction/DropdownErrorAction';
import { goVdsDetailAction } from '../../../vds/vdsSlice';
import { tabsDataType } from '../../../dashboardData';
import { tableStructureErrorDetail } from './logicalErrorDetailData';

const LogicalErrorDetail = ({
  id,
  vds,
  selectedError,
  autoSelectTab,
}: {
  id: number;
  vds: string;
  selectedError: LogicErrorModel;
  autoSelectTab: (data: string) => void;
}) => {
  const dispatch = useDispatch();
  const errors = useSelector(selectLogicErrorDetailList);
  useEffect(() => {
    dispatch(getDetailLogicError(id));
  }, []);

  const goDetailVds = (row: any) => {
    dispatch(goVdsDetailAction(row.idVds, vds));
    autoSelectTab(tabsDataType.vds.name);
  };

  const templates = {
    detailVds: (value: string, row: any) => {
      return (
        <>
          {row.idVds && (
            <DropdownErrorAction clickActionRow={() => goDetailVds(row)} />
          )}
        </>
      );
    },
  };

  return (
    <>
      <div className="mt-4 mb-2">
        <Icon
          icon="arrow-left"
          label={selectedError.errorDescription}
          color="primary"
          onClick={() => dispatch(resetSelectedLogicalError())}
        ></Icon>
      </div>
      <TableWithAction
        columns={tableStructureErrorDetail}
        objProps={errors}
        templates={templates}
        changeObjProps={(obj: any) => dispatch(getDetailLogicError(id, obj))}
      />
    </>
  );
};

export default LogicalErrorDetail;
