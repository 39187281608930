import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import { setLoggedTokenUser } from '../../../app/auth/authSlice';
import { decoded } from '../../../utils/decodeJwt';
import { resetErrors as resetBeErrors } from '../../../app/errors/errorSlice';
import { loginCall } from './login.service';

export interface loginSliceModel {
  isSubmit: boolean;
  isValid: boolean;
  errors: { [key: string]: { code: string; params: string } } | null;
  redirectToken?: string;
}
const initialState: loginSliceModel = {
  isSubmit: false,
  isValid: false,
  errors: null,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setRedirectToken: (state, action: PayloadAction<string>) => {
      state.redirectToken = action.payload;
    },
    resetRedirectToken: state => {
      state.redirectToken = undefined;
    },
  },
});

export const { setRedirectToken, resetRedirectToken } = loginSlice.actions;

export const loginUser =
  (data: any): AppThunk =>
  dispatch => {
    dispatch(resetForm());
    loginCall(data).then(res => {
      localStorage.setItem('token', res.accessToken);
      let data: any = decoded(res.accessToken);
      dispatch(setLoggedTokenUser(res.accessToken, data.user));
    });
  };

export const resetForm = (): AppThunk => dispatch => {
  dispatch(resetBeErrors());
};
export const selectRedirectToken = (state: RootState) =>
  state.login.redirectToken;
export default loginSlice.reducer;
