import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router';
import { setTitle } from '../../../app/page/pageSlice';
import {
  databaseHistoryAction,
  selectHistoryDatabase,
  resetHistory,
} from './DatabaseSlice';
import { tableStructureDatabaseDetail } from './tableStructureDatabase';
import Table from '../../../components/shared/table/table/Table';
import BackBtn from '../../../components/shared/backBtn/BackBtn';
import { useRoute } from '../../../utils/useRoute';
import { database } from '../../../routes/routeNameAuthType';
import headerData from '../../../components/core/HeaderData';

const Database = () => {
  const dispatch = useDispatch();
  const history = useSelector(selectHistoryDatabase);
  const { changeRoute } = useRoute();

  const { t } = useTranslation();
  const { name } = useParams();

  useEffect(() => {
    dispatch(setTitle(t('Dettaglio database')));
    dispatch(databaseHistoryAction(name));
    return () => {
      dispatch(resetHistory());
      headerData.headers = undefined;
    };
  }, [name]);

  headerData.headers = (
    <BackBtn
      label="Lista Database"
      onClick={() => changeRoute(database)}
    ></BackBtn>
  );
  return (
    <div>
      <Table columns={tableStructureDatabaseDetail} data={history} />
    </div>
  );
};

export default Database;
