import React, { useCallback, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAssetCreate } from './asset-create/useAssetCreate';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../app/auth/authSlice';
import {
  getListCovipCode,
  resetAssetSlice,
  selectAssetEdit,
  selectCompanyList,
  selectCovipList,
} from './asset-create/assetCreateSlice';
import {
  ChangeValueEndType,
  UseFormSubmitModel,
} from '../../../utils/forms/models/UseFormModel';
import { useForm } from '../../../utils/forms/useForm';
import FormElements from '../../../utils/forms/FormElements';
import HeadingH4 from '../../../components/shared/headings/headingH4/HeadingH4';
import ButtonList from '../../../components/shared/form/buttons/button-list/ButtonList';
import {
  AssetFormValidations,
  configNotBoolean,
  configurationCheck,
} from './AssetFormData';
import {
  getAssetConfig,
  saveAsset,
  updateAsset,
} from './services/asset.service';
import {
  editAsset,
  loadAssetsList,
} from '../../../components/core/filter-dashboard/filterDashboardSlice';
import { useRoute } from '../../../utils/useRoute';
import {
  addError,
  addSuccessMessage,
  resetErrors,
} from '../../../app/errors/errorSlice';

const AssetForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const covipList = useSelector(selectCovipList);
  const companyList = useSelector(selectCompanyList);
  const assetModify = useSelector(selectAssetEdit);
  const { changeRoute } = useRoute();
  const backCallBack = useCallback(() => changeRoute('home'), []);
  const conf = configurationCheck.reduce(
    (acc, value) => ({
      ...acc,
      [value.name]: false,
    }),
    {}
  );
  const submitHandler: UseFormSubmitModel = (event, data) => {
    let configuration = { ...conf, ...data.configuration };
    const asset = { ...data.asset };
    asset.configuration = Object.keys(configuration).reduce((obj, key) => {
      return {
        ...obj,
        [key.replace('c_', '')]:
          typeof configuration[key] === 'boolean'
            ? configuration[key]
              ? '1'
              : '0'
            : configuration[key],
      };
    }, {});
    asset.shortDescription = asset.fundCode + ' - ' + asset.shortDescription;
    //submitForm && submitForm(asset);
    if (asset.id) {
      updateAsset(asset).then(
        res => {
          dispatch(editAsset(asset));
          changeRoute('home');
          dispatch(addSuccessMessage('EDIT_ASSET'));
        },
        err => {
          dispatch(addError('EDIT_ASSET_ERROR'));
        }
      );
    } else {
      saveAsset(asset, { assetType: 1 }).then(
        res => {
          dispatch(loadAssetsList());
          backCallBack();
          dispatch(addSuccessMessage('CREATE_ASSET'));
        },
        err => {
          dispatch(addError('CREATE_ASSET_ERROR'));
        }
      );
    }
  };
  const changeValueEnd: ChangeValueEndType = (data, name) => {
    if (
      name.newValue &&
      name.newValue.asset &&
      Object.keys(name.newValue.asset).includes('companyId')
    ) {
      dispatch(getListCovipCode(name.newValue.asset.companyId));
    }
    if (
      name.newValue &&
      name.newValue.asset &&
      Object.keys(name.newValue.asset).length === 1 &&
      name.newValue.asset.period &&
      assetModify
    ) {
      const period = { ...dataFormatted.asset.period };
      getAssetConfig(assetModify.id, period.month, period.year).then(res => {
        const configuration = getConfiguration(res.configuration);
        setData({ ...dataFormatted, configuration });
      });
    }
  };

  const {
    isSubmit,
    errors,
    submitAction,
    changeValue,
    dataForm,
    dataFormatted,
    setData,
  } = useForm(
    submitHandler,
    AssetFormValidations,
    {
      asset: {
        companyId: user?.companyId,
      },
      configuration: conf,
    },
    {
      changeValueEnd,
    }
  );
  const {
    data,
    dataConfigurationPeriod,
    dataConfiguration,
    dataConfigurationCheck,
    buttons,
  } = useAssetCreate(
    t,
    user,
    companyList,
    dataForm.asset___fundCode,
    covipList,
    !!assetModify
  );

  const getConfiguration = (configuration: any = {}) => {
    return Object.keys(configuration).reduce((obj: any, ele: string) => {
      obj['c_' + ele] = configNotBoolean.includes('c_' + ele)
        ? configuration[ele]
        : configuration[ele] === '1';
      return obj;
    }, {});
  };
  const getPeriod = () => {
    const date = new Date();
    return {
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  };
  useEffect(() => {
    if (assetModify) {
      dispatch(getListCovipCode(assetModify.companyId));
      const configuration = getConfiguration(assetModify.configuration);
      const asset = { ...assetModify };
      delete asset.configuration;

      const assetCopy = { ...asset };
      const shortDesc = assetCopy.shortDescription;
      asset.shortDescription = shortDesc
        ?.slice(shortDesc?.indexOf('-') + 1, shortDesc.length)
        .trim();
      setData({ configuration, asset: { period: getPeriod(), ...asset } });
    } else {
      setData({
        ...dataForm,
        asset: {
          period: getPeriod(),
        },
      });
    }
  }, [assetModify]);

  return (
    <div className="px-5">
      <Form className="pt-2" onSubmit={submitAction}>
        <FormElements
          data={data}
          errors={errors}
          isSubmit={isSubmit}
          dataForm={dataForm}
          changeValue={changeValue}
        />
        <HeadingH4>Periodo di riferimento</HeadingH4>
        <FormElements
          data={dataConfigurationPeriod}
          errors={errors}
          isSubmit={isSubmit}
          dataForm={dataForm}
          changeValue={changeValue}
        />
        <HeadingH4>Configurazione</HeadingH4>
        <FormElements
          data={dataConfiguration}
          errors={errors}
          isSubmit={isSubmit}
          dataForm={dataForm}
          changeValue={changeValue}
        />
        <FormElements
          data={dataConfigurationCheck}
          errors={errors}
          isSubmit={isSubmit}
          dataForm={dataForm}
          changeValue={changeValue}
        />
        <ButtonList buttons={buttons} />
      </Form>
    </div>
  );
};

export default AssetForm;
