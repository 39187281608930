import React, { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Table from '../../../../../../components/shared/table/table/Table';
import { ConfigModel } from '../models/SliceVdsModel';
import { useForm } from '../../../../../../utils/forms/useForm';
import FormControlCustom from '../../../../../../utils/forms/FormControlCustom';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../../../../components/shared/form/buttons/primary-button/PrimaryButton';
import { listValueSymbolData, ValueSymbolsData } from './valueSymbolsData';
import { tableSearchStructure } from './tableSearchData';

interface TableSearchVdsProps {
  listConfigVds: ConfigModel[];
  selectedVds?: string;
  search: (data: any) => void;
}
const TableSearchVds = ({
  listConfigVds,
  search,
  selectedVds,
}: TableSearchVdsProps) => {
  const { t } = useTranslation();
  const submitHandler = (event: any, data: any) => {
    search(data);
  };
  const {
    isSubmit,
    getNameFieldForm,
    errors,
    submitAction,
    changeValue,
    setData,
    dataForm,
  } = useForm(submitHandler, [], {});

  useEffect(() => {
    setData({});
  }, [selectedVds]);

  const operationNameField = (row: any) =>
    getNameFieldForm(row.name + '.operation');
  const value1NameField = (row: any) => getNameFieldForm(row.name + '.value1');
  const value2NameField = (row: any) => getNameFieldForm(row.name + '.value2');

  const templates = {
    condition: (value: string, row: any) => {
      return (
        <FormControlCustom
          value={dataForm[operationNameField(row)]}
          changeValue={(value: any) =>
            changeValue(value, operationNameField(row))
          }
          errors={errors}
          dataElement={{
            disabled: row.name === 'c0001' && !!selectedVds,
            className: 'sm',
          }}
          name={operationNameField(row)}
          isSubmit={isSubmit}
          typeElement={'select'}
          options={[
            { label: t('users.vds.select_vds_placeholder'), value: '-1' },
            ...ValueSymbolsData,
          ]}
        />
      );
    },
    valueBy: (value: string, row: any) => {
      return (
        <div className="d-flex">
          <FormControlCustom
            value={dataForm[value1NameField(row)]}
            changeValue={(value: any) =>
              changeValue(value, value1NameField(row))
            }
            errors={errors}
            dataElement={{
              placeholder:
                dataForm[operationNameField(row)] ===
                listValueSymbolData.BETWEEN
                  ? 'da'
                  : 'valore',
              disabled: !dataForm[operationNameField(row)],
              className: 'sm',
            }}
            name={value1NameField(row)}
            isSubmit={isSubmit}
            typeElement={'input'}
          />
          {dataForm[operationNameField(row)] ===
            listValueSymbolData.BETWEEN && (
            <FormControlCustom
              value={dataForm[value2NameField(row)]}
              changeValue={(value: any) =>
                changeValue(value, value2NameField(row))
              }
              errors={errors}
              dataElement={{
                placeholder:
                  dataForm[operationNameField(row)] ===
                  listValueSymbolData.BETWEEN
                    ? 'a'
                    : 'valore',
                disabled: !dataForm[row.name + '___' + 'operation'],
              }}
              name={value2NameField(row)}
              isSubmit={isSubmit}
              typeElement={'input'}
            />
          )}
        </div>
      );
    },
  };
  return (
    <div>
      <Form className="pt-2" onSubmit={submitAction}>
        <Table
          columns={tableSearchStructure}
          data={listConfigVds}
          templates={templates}
        />
        <Row className="justify-content-end">
          <Col xs="12" md="4" lg="3">
            <PrimaryButton
              disabled={!selectedVds && !Object.keys(dataForm).length}
            >
              Cerca
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default TableSearchVds;
