import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import BoxFullScreen from '../boxFullScreen/BoxFullScreen';
import Icon from '../icon/Icon';

function Dropzone(_props: {
  onDrop: (acceptedFiles: any) => void;
  onClose: () => void;
  accept?: string;
}) {
  const onDrop = useCallback(
    acceptedFiles => {
      _props.onDrop(acceptedFiles);
    },
    [_props]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: _props.accept,
  });

  return (
    <>
      <BoxFullScreen
        show={true}
        onClick={() => {
          _props.onClose();
        }}
      >
        <div className="content bordered">
          <div className="dropZone" {...getRootProps()}>
            <input {...getInputProps()} />
            <Icon icon="upload" size="x62"></Icon>
            {
              <p className="mt-4">
                Carica oppure trascina qui i tuoi documenti
              </p>
            }
          </div>
        </div>
      </BoxFullScreen>
    </>
  );
}

export default Dropzone;
