import React from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from '../../components/shared/form/form-group/FormGroup';
import FormControlCustom from './FormControlCustom';
import { FormGroupCustomModel } from './models/FormElementModel';

const FormGroupCustom = ({
  isSubmit,
  errors,
  label,
  display,
  name,
  ...rest
}: FormGroupCustomModel) => {
  const { t } = useTranslation();
  return (
    <FormGroup
      display={display}
      label={{ label: label || '' }}
      error={{
        isVisible: isSubmit && !!errors && !!errors[name],
        message: t('errors.' + errors[name]?.code, errors[name]?.params),
      }}
    >
      <FormControlCustom
        isSubmit={isSubmit}
        errors={errors}
        name={name}
        {...rest}
      />
    </FormGroup>
  );
};

export default FormGroupCustom;
