import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { AuthState } from './model/Auth';
import { User } from './model/User';
import { decoded } from '../../utils/decodeJwt';
import { resetDashboard } from '../../features/auth/home/dashboard/DashboardSlice';
import { resetFilterDashboard } from '../../components/core/filter-dashboard/filterDashboardSlice';

const initialState: Partial<AuthState> = {
  user: undefined,
  token: undefined,
  isLogged: undefined,
  permissions: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLogged: (state, action: PayloadAction<boolean>) => {
      state.isLogged = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    destroyUser: state => {
      state.user = undefined;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    destroyToken: state => {
      state.token = undefined;
    },
    setPermissions: (state, action: PayloadAction<string[]>) => {
      state.permissions = action.payload;
    },
  },
});

export const {
  setUser,
  destroyUser,
  setToken,
  destroyToken,
  setPermissions,
  setIsLogged,
} = authSlice.actions;
export const autoLogin = (): AppThunk => dispatch => {
  let token = localStorage.getItem('token');
  if (token) {
    const dataToken: any = decoded(token);
    if (dataToken) {
      dispatch(setLoggedToken(token));
      dispatch(setUserAndPermissions(dataToken.user));
    } else {
      dispatch(logoutAction());
    }
  } else {
    dispatch(setIsLogged(false));
  }
};

export const setUserAndPermissions =
  (user: User): AppThunk =>
  dispatch => {
    dispatch(setPermissions(user.permissions));
    dispatch(setUser(user));
  };

export const setLoggedToken =
  (token: string): AppThunk =>
  dispatch => {
    dispatch(setToken(token));
    dispatch(setIsLogged(true));
  };

export const setLoggedTokenUser =
  (token: string, user: User): AppThunk =>
  dispatch => {
    dispatch(setLoggedToken(token));
    dispatch(setUserAndPermissions(user));
  };

export const logoutAction = (): AppThunk => dispatch => {
  localStorage.removeItem('token');
  dispatch(destroyUser());
  dispatch(setPermissions([]));
  dispatch(destroyToken());
  dispatch(resetDashboard());
  dispatch(setIsLogged(false));
  dispatch(resetFilterDashboard());
};

export const selectUser = (state: RootState) => state.auth.user;
export const selectToken = (state: RootState) => state.auth.token;
export const selectIsLogged = (state: RootState) => state.auth.isLogged;
export const selectUserPermissions = (state: RootState) =>
  state.auth.permissions;

export default authSlice.reducer;
