import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../../app/store';
import { TableVdsSliceModel } from './models/TableVdsSliceModel';
import {
  downloadExportLogVds,
  getTableDashboard,
} from '../../services/dashboard-table.service';
import { resetErrors } from '../../../../../app/errors/errorSlice';

const initialState: TableVdsSliceModel = {
  listLogVds: [],
};

export const tableVdsSlice = createSlice({
  name: 'table-vds',
  initialState,
  reducers: {
    setlistLogVds: (state, action: PayloadAction<any[]>) => {
      state.listLogVds = action.payload;
    },
  },
});
export const { setlistLogVds } = tableVdsSlice.actions;

export const getListLogVds = (): AppThunk => (dispatch, getState) => {
  const tenant = getState().filterDashboard.tenant;
  tenant &&
    getTableDashboard(tenant).then(res => {
      dispatch(
        setlistLogVds(
          res.map((el: any) => ({
            ...el,
            date: el.endDate?.substring(0, 10),
            startDate: el.startDate?.substr(11, 8),
            endDate: el.endDate?.substr(11, 8),
          }))
        )
      );
    });
};

export const downloadListLogVds = (): AppThunk => (dispatch, getState) => {
  const tenant = getState().filterDashboard.tenant;
  tenant &&
    downloadExportLogVds(tenant).then(res => {
      const blob = new Blob([res.data], {
        type: res.headers['content-type'],
      });
      saveAs(blob, `${tenant}.xlsx`);
      dispatch(resetErrors());
    });
};

export const selectListLogVds = (state: RootState) => state.vdsTable.listLogVds;

export default tableVdsSlice.reducer;
