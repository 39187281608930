import { TFunction } from 'i18next';

export function useForgotPassword(t: TFunction) {
  const validations = {
    email: {
      required: {
        params: { name: 'email' },
      },
      email: {
        params: { name: 'email' },
      },
    },
  };
  const data: {
    typeElement?: 'input' | 'select' | 'textarea';
    label: string;
    name: string;
    value?: any;
    options?: { label: string; value: string; disabled?: boolean }[];
    dataElement: Partial<{
      placeholder: string;
      type: string;
      multiply?: boolean;
    }>;
  }[] = [
    {
      typeElement: 'input',
      label: t('forgotPsw.email'),
      name: 'email',
      value: '',
      dataElement: {
        placeholder: t('forgotPsw.email'),
        type: 'text',
      },
    },
  ];

  return {
    validations,
    data,
  };
}
