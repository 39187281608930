import React from 'react';
import { mergeClass } from '../functions/function';
import { TabBtnsPropsModel } from './TabBtns.model';

const TabBtns = ({ tabs, selectedTab, activeTab = '' }: TabBtnsPropsModel) => {
  return (
    <div className="shared-tabs">
      {tabs.map((ele, index) => {
        return (
          <div
            key={index}
            className={
              mergeClass('tab-item', ele.name === activeTab ? 'active' : '') +
              (ele.disabled ? ' disabled' : '')
            }
            onClick={() => !ele.disabled && selectedTab(ele.name)}
          >
            {ele.label}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(TabBtns);
