import i18n from 'i18next';
import { createValidationsForm } from '../../../utils/forms/useStructureForm';
import {
  isNumber,
  length,
  required,
} from '../../../utils/forms/utils/validationType';

export const AssetEPFormValidations = createValidationsForm([
  {
    asset: [
      {
        name: 'fundCode',
        validations: [
          {
            name: required,
            params: {
              name: 'ID Fondo',
            },
          },
          {
            name: isNumber,
            params: {
              name: 'ID Fondo',
            },
          },
          {
            name: length,
            moreData: {
              length: 4,
            },
            params: {
              name: 'ID Fondo',
              moreData: {
                length: 4,
              },
            },
          },
        ],
      },
      {
        name: 'companyId',
        validations: [
          {
            name: required,
            params: {
              name: 'ID Azienda',
            },
          },
        ],
      },
      {
        name: 'description',
        validations: [
          {
            name: required,
            params: {
              name: 'descrizione',
            },
          },
        ],
      },
      {
        name: 'shortDescription',
        validations: [
          {
            name: required,
            params: {
              name: 'Descrizione Breve',
            },
          },
        ],
      },
    ],
  },
]);
