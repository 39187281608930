import React from 'react';
import Input from '../../components/shared/form/input/Input';
import Select from '../../components/shared/form/select/Select';
import TextArea from '../../components/shared/form/textArea/TextArea';
import Switch from '../../components/shared/form/switch/Switch';
import CheckboxList from '../../components/shared/form/checkboxList/CheckboxList';
import { Row, Col } from 'react-bootstrap';
import Radio from '../../components/shared/form/radio/Radio';
import Progress from '../../components/shared/form/progress/Progress';
import { FormControlCustomModel } from './models/FormElementModel';

const FormControlCustom = ({
  isSubmit,
  errors,
  dataElement,
  name,
  value,
  changeValue = () => {},
  typeElement = 'input',
  options = [],
}: FormControlCustomModel) => {
  return (
    <>
      {typeElement === 'input' && (
        <Input
          name={name}
          error={isSubmit && !!errors && !!errors[name]}
          value={value}
          onChangeAction={changeValue}
          {...dataElement}
        />
      )}
      {typeElement === 'select' && (
        <Select
          error={isSubmit && !!errors && !!errors[name]}
          name={name}
          options={options}
          value={value}
          {...dataElement}
          changeSelect={changeValue}
        />
      )}
      {typeElement === 'textarea' && (
        <TextArea
          name={name}
          value={value}
          {...dataElement}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            changeValue(event.target.value);
          }}
        />
      )}
      {typeElement === 'radio' && (
        <Row>
          {options.map((ele, index) => {
            return (
              <Col xs="auto" key={index}>
                <Radio
                  name={name}
                  value={ele.value}
                  label={ele.label}
                  checked={ele.value === value}
                  {...dataElement}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    changeValue(event.target.value)
                  }
                />
              </Col>
            );
          })}
        </Row>
      )}
      {typeElement === 'checkbox' && (
        <CheckboxList
          value={value}
          options={options}
          name={name}
          changeChecked={changeValue}
        />
      )}
      {typeElement === 'progress' && (
        <Progress
          value={value}
          changeProgress={(event: React.ChangeEvent<HTMLInputElement>) => {
            changeValue(event.target.value);
          }}
        />
      )}
      {typeElement === 'switch' && (
        <Switch
          className="ml-auto"
          error={isSubmit && !!errors && !!errors[name]}
          name={name}
          checked={value}
          changeSwitch={(event: React.ChangeEvent<HTMLInputElement>) => {
            changeValue(
              dataElement && dataElement.type === 'checkbox'
                ? event.target.checked
                : event.target.value
            );
          }}
        ></Switch>
      )}
    </>
  );
};

export default FormControlCustom;
