import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { listPeriods } from '../../../features/auth/periods/services/periods.service';
import { AppThunk, RootState } from '../../../app/store';
import { User } from '../../../app/auth/model/User';
import { getAssetsList } from '../../../features/auth/users/services/users.service';
import {
  DashboardTable,
  getSessionSearchDashboard,
  getTablesDashboard as getDashboardTables,
  getTenant,
  loadedFileCall,
  putSessionSearchDashboard,
} from '../../../features/auth/home/services/dashboard.service';
import {
  deselectAllIndexSelected,
  setFlowNextStep,
  setLoadedFile,
  deleteLoadedFile,
  removeDeleteFileList,
} from '../../../features/auth/home/dashboard/DashboardSlice';
import { AssetModel } from '../../../models/AssetModel';
import { PeriodModel } from '../../../models/PeriodModel';
import { getLegendColor } from '../../../features/auth/home/dashboard/dashboardData';
import client from '../../../app/clientConnect';
import { addError, resetErrors } from '../../../app/errors/errorSlice';
import { addSub, removeSub } from '../../../app/clientSubscribe';
import { setLoadManual } from '../../shared/loader/redux/loadSlice';
import {
  FactModel,
  flowNextStepModel,
  TableFactModel,
} from '../../../features/auth/home/dashboard/models/FlowNextStepModel';
let tenantSubscribe: any;
const initialState: {
  assetId?: number;
  periodId?: number;
  loadedFileId?: number;
  tenant?: string;
  assets: AssetModel[];
  periods: PeriodModel[];
  disabledAll: boolean;
  listTables: DashboardTable[];
} = {
  assets: [],
  periods: [],
  disabledAll: false,
  listTables: [],
};

export const filterDashboardSlice = createSlice({
  name: 'filterDashboard',
  initialState,
  reducers: {
    setTenant: (state, action: PayloadAction<string | undefined>) => {
      state.tenant = action.payload;
    },
    resetTenant: state => {
      state.tenant = undefined;
    },
    setAssetId: (state, action: PayloadAction<number | undefined>) => {
      state.assetId = action.payload;
    },
    setPeriodId: (
      state,
      action: PayloadAction<string | number | undefined>
    ) => {
      state.periodId = action.payload ? +action.payload : undefined;
    },
    setLoadedFileId: (state, action: PayloadAction<number>) => {
      state.loadedFileId = action.payload;
    },
    resetLoadedFileId: state => {
      state.loadedFileId = undefined;
    },
    setAssets: (state, action: PayloadAction<AssetModel[]>) => {
      state.assets = action.payload;
    },
    setPeriods: (state, action: PayloadAction<PeriodModel[]>) => {
      state.periods = action.payload;
    },

    addPeriod: (state, action: PayloadAction<PeriodModel>) => {
      state.periods = [...state.periods, action.payload];
    },
    addAsset: (state, action: PayloadAction<AssetModel>) => {
      state.assets = [...state.assets, action.payload];
    },
    editAsset: (state, action: PayloadAction<AssetModel>) => {
      state.assets = state.assets.map(ele =>
        ele.id === action.payload.id ? action.payload : ele
      );
    },
    setDisabledAll: (state, action: PayloadAction<boolean>) => {
      state.disabledAll = action.payload;
    },
    setDashboardTables: (state, action: PayloadAction<DashboardTable[]>) => {
      state.listTables = action.payload;
    },
    resetFilterDashboard: (state: any) => {
      Object.keys(state).forEach((ele: string) => {
        state[ele] =
          (initialState as any)[ele] !== undefined
            ? (initialState as any)[ele]
            : undefined;
      });
    },
  },
});

export const {
  setAssetId,
  setPeriodId,
  setAssets,
  setPeriods,
  setDisabledAll,
  addAsset,
  editAsset,
  setLoadedFileId,
  resetLoadedFileId,
  setTenant,
  addPeriod,
  resetTenant,
  resetFilterDashboard,
  setDashboardTables,
} = filterDashboardSlice.actions;

export const loadInitData =
  (hasPermission: boolean): AppThunk =>
  (dispatch, getState) => {
    if (hasPermission) {
      dispatch(loadAssetsList());
      getSessionSearchDashboard(getState().auth.user?.id).then(res => {
        if (res && res && res.dashboard) {
          dispatch(setAssetId(res.dashboard.assetId));
          dispatch(setPeriodId(res.dashboard.periodId));
        }
      });
    } else {
      const user: Partial<User> = getState().auth.user || {};
      dispatch(setPeriods([]));
      listPeriods(
        user.id,
        user.roles && user.roles[0],
        +(getState().filterDashboard.assetId || 0)
      ).then(res => {
        dispatch(setPeriods(res));
      });
    }
  };

export const loadAssetsList = (): AppThunk => (dispatch, getState) => {
  getAssetsList().then(
    res => {
      dispatch(setAssets(res));
    },
    err => {
      console.log('errore');
    }
  );
};

export const loadPeriods = (): AppThunk => (dispatch, getState) => {
  if (getState().filterDashboard.assetId && getState().auth.user?.id) {
    const user: Partial<User> = getState().auth.user || {};
    dispatch(setPeriods([]));
    listPeriods(
      user.id,
      user.roles && user.roles[0],
      +(getState().filterDashboard.assetId || 0)
    ).then(
      res => {
        dispatch(setPeriods(res));
      },
      err => {
        dispatch(setPeriods([]));
      }
    );
  }
};

export const setTenantAction =
  (tenantId?: string): AppThunk =>
  (dispatch, getState) => {
    tenantSubscribe && removeSub(tenantSubscribe);
    if (tenantId) {
      tenantSubscribe = addSub(`/id/${tenantId}/queue`, (res: any) => {
        const body = res && res.body ? JSON.parse(res.body) : {};
        console.log(body.operation);
        if (body.operation === 'LAUNCH_ETL') {
          dispatch(deselectAllIndexSelected());
          dispatch(loadLoadedFileCall());
          if (body.status !== 'ok') {
            dispatch(addError('ERR_LAUNCH_ETL'));
          }
        }
        if (body.operation === 'DELETE_DB') {
          dispatch(setLoadManual(false));
          dispatch(setPeriodId(undefined));
          dispatch(loadPeriods());
          dispatch(resetSessionDashboard());
          if (body.status !== 'ok') {
            dispatch(addError('ERR_LAUNCH_ETL'));
          }
        }
        if (body.operation === 'DELETE_FILE') {
          if (body.status === 'ok') {
            dispatch(deleteLoadedFile([body.fileLoadedId]));
            dispatch(removeDeleteFileList(body.fileLoadedId));
          } else {
            dispatch(removeDeleteFileList(body.fileLoadedId));
            dispatch(addError('ERR_DELETE_FILE'));
          }
        }
      });
    }
    dispatch(setTenant(tenantId));
  };
export const putSessionDashboard = (): AppThunk => (dispatch, getState) => {
  const periodId = getState().filterDashboard.periodId;
  const assetId = getState().filterDashboard.assetId;
  periodId &&
    assetId &&
    putSessionSearchDashboard(getState().auth?.user?.id, {
      assetId,
      periodId,
    }).then(res => console.log('Memorizzate informazioni in sessione'));
};

export const resetSessionDashboard = (): AppThunk => (dispatch, getState) => {
  putSessionSearchDashboard(getState().auth?.user?.id, {}).then(res =>
    console.log('Resettate informazioni in sessione')
  );
};
export const loadLoadedFile = (): AppThunk => (dispatch, getState) => {
  dispatch(resetErrors());
  dispatch(setLoadedFile([]));
  dispatch(setTenantAction(undefined));
  dispatch(resetLoadedFileId());
  dispatch(setFlowNextStep([]));
  dispatch(resetTenant());
  const periodId = getState().filterDashboard.periodId;
  const assetId = getState().filterDashboard.assetId;
  periodId &&
    assetId &&
    getTenant(periodId, assetId).then(res => {
      dispatch(setTenantAction(res.tenantId));
      dispatch(putSessionDashboard());
      dispatch(loadLoadedFileCall());
    });
};

export const loadLoadedFileCall = (): AppThunk => (dispatch, getState) => {
  const periodId = getState().filterDashboard.periodId;
  const assetId = getState().filterDashboard.assetId;
  const tenantId = getState().filterDashboard.tenant;
  periodId &&
    assetId &&
    tenantId &&
    loadedFileCall(periodId, assetId, tenantId).then(res => {
      const flowNextStep = (res?.flowNextStep as any[])?.map(
        item =>
          ({
            ...item,
            tables: (item.tables as DashboardTable[]).map(
              table =>
                ({
                  facts: table.dropdown.map(
                    drop =>
                      ({
                        id: drop.id,
                        name: drop.name,
                        stored2Load: drop.stored2Load,
                        description: '',
                      } as FactModel)
                  ),
                  tableSubType: table.subAggrId,
                  tableSubTypeLabel: table.subAggrIdLabel,
                  tableType: table.aggrId,
                  tableTypeLabel: table.aggrIdLabel,
                } as TableFactModel)
            ),
          } as flowNextStepModel)
      );
      dispatch(setFlowNextStep(flowNextStep || []));
      if (res && res.filesLoaded) {
        const listLoaded = res.filesLoaded.map((loaded: any) => ({
          ...loaded,
          ico: loaded.status?.id,
          date: loaded.endDate?.substring(0, 10),
          startDate: loaded.startDate?.substring(11),
          endDate: loaded.endDate?.substring(11),
          state: loaded.status?.name,
          color: getLegendColor(loaded.result?.id),
        }));
        dispatch(setLoadedFile(listLoaded));
        if (listLoaded && listLoaded.length)
          dispatch(setLoadedFileId(listLoaded[0].loadedId));
      }
    });
};

export const loadDashboardTables = (): AppThunk => (dispatch, getState) => {
  const tenantId: string = getState().filterDashboard.tenant as string;
  //dispatch(setDashboardTables(mockData));
  getDashboardTables(tenantId).then(
    res => {
      dispatch(setDashboardTables(res));
    },
    err => {
      console.log('errore');
    }
  );
};

export const selectAssets = (state: RootState) => state.filterDashboard.assets;
export const selectTenantId = (state: RootState) =>
  state.filterDashboard.tenant;
export const selectPeriods = (state: RootState) =>
  state.filterDashboard.periods;
export const selectDisabledAll = (state: RootState) =>
  state.filterDashboard.disabledAll;
export const selectLoadedFileId = (state: RootState) =>
  state.filterDashboard.loadedFileId;
export const selectAssetId = (state: RootState) =>
  state.filterDashboard.assetId;
export const selectFundCode = createSelector(
  selectAssetId,
  selectAssets,
  (assetId: number | undefined, assets: AssetModel[]) => {
    return assets.find((ele: any) => ele.id == assetId)?.fundCode;
  }
);
export const selectPeriodId = (state: RootState) =>
  state.filterDashboard.periodId;
export const selectDashboardTables = (state: RootState) =>
  state.filterDashboard.listTables;

export default filterDashboardSlice.reducer;
