import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../../../app/page/pageSlice';
import {
  selectLoadedFileId,
  selectTenantId,
} from '../../../../../components/core/filter-dashboard/filterDashboardSlice';
import {
  getErrorList,
  selectErrorList,
  selectVsd,
  selectIsSelectedAllRow,
  selectIndexSelected,
  deselectAllIndexSelected,
  resetSelectVds,
  resetErrorList,
} from './errorDashboardSlice';

import { useRoute } from '../../../../../utils/useRoute';
import { home } from '../../../../../routes/routeNameAuthType';
import ErrorDetailDashboard from './errorDetail/ErrorDetailDashboard';
import headerData from '../../../../../components/core/HeaderData';
import ErrorBackDashboard from './ErrorBackDashboard';
import ErrorTableDashboard from './ErrorTableDashboard';

const ErrorDashboard = ({
  autoSelectTab,
}: {
  autoSelectTab: (tab: string, vds: string) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const id = useSelector(selectLoadedFileId);
  const errorList = useSelector(selectErrorList);
  const selectVds = useSelector(selectVsd);
  const indexSelected = useSelector(selectIndexSelected);
  const isSelectedAllRow = useSelector(selectIsSelectedAllRow);
  const tenantId = useSelector(selectTenantId);

  const { changeRoute } = useRoute();
  const goBack = () => {
    changeRoute(home);
  };

  useEffect(() => {
    return () => {
      dispatch(resetSelectVds());
    };
  }, []);

  useEffect(() => {
    dispatch(setTitle(t('errorPage.title')));
    dispatch(resetErrorList());
    if (tenantId && id) {
      dispatch(getErrorList(id as number, goBack));
    }
  }, [id]);
  useEffect(() => {
    dispatch(setTitle(t('errorPage.title')));
    return () => {
      // dispatch(deselectAllIndexSelected());
      headerData.headers = undefined;
    };
  }, [selectVds]);

  headerData.headers = <ErrorBackDashboard />;
  return (
    <>
      {id ? (
        errorList && errorList.length && selectVds ? (
          <ErrorDetailDashboard
            id={id}
            vds={selectVds}
            autoSelectTab={(tab: string) => autoSelectTab(tab, selectVds)}
          />
        ) : (
          <ErrorTableDashboard
            errorList={errorList}
            indexSelected={indexSelected}
            isSelectedAllRow={isSelectedAllRow}
          />
        )
      ) : null}
    </>
  );
};

export default ErrorDashboard;
