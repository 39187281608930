import React, { useCallback, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../app/auth/authSlice';
import { addError, addSuccessMessage } from '../../../app/errors/errorSlice';
import {
  editAsset,
  loadAssetsList,
} from '../../../components/core/filter-dashboard/filterDashboardSlice';
import ButtonList from '../../../components/shared/form/buttons/button-list/ButtonList';
import FormElements from '../../../utils/forms/FormElements';
import {
  ChangeValueEndType,
  UseFormSubmitModel,
} from '../../../utils/forms/models/UseFormModel';
import { useForm } from '../../../utils/forms/useForm';
import { useRoute } from '../../../utils/useRoute';
import {
  getListCovipCode,
  selectAssetEPEdit,
  selectCompanyList,
  selectCovipList,
} from './AssetEPReducer';
import { useAssetEPCreate } from './create/useAssetEPCreate';
import { AssetEPFormValidations } from './AssetEPFormData';
import { updateAsset, saveAsset } from '../asset/services/asset.service';

const AssetEPForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const companyList = useSelector(selectCompanyList);
  const covipList = useSelector(selectCovipList);
  const assetEPModify = useSelector(selectAssetEPEdit);
  const { changeRoute } = useRoute();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const backCallBack = useCallback(() => changeRoute('home'), []);

  const submitHandler: UseFormSubmitModel = (event, data) => {
    const asset = { ...data.asset };
    asset.shortDescription = asset.fundCode + ' - ' + asset.shortDescription;

    if (asset.id) {
      updateAsset(asset).then(
        res => {
          dispatch(editAsset(asset));
          changeRoute('home');
          dispatch(addSuccessMessage('EDIT_ASSET_EP'));
        },
        err => {
          dispatch(addError('EDIT_ASSET_EP_ERROR'));
        }
      );
    } else {
      saveAsset(asset, { assetType: 2 }).then(
        res => {
          dispatch(loadAssetsList());
          backCallBack();
          dispatch(addSuccessMessage('CREATE_ASSET_EP'));
        },
        err => {
          dispatch(addError('CREATE_ASSET_EP_ERROR'));
        }
      );
    }
  };
  const changeValueEnd: ChangeValueEndType = (data, name) => {
    if (
      name.newValue &&
      name.newValue.asset &&
      Object.keys(name.newValue.asset).includes('companyId')
    ) {
      dispatch(getListCovipCode(name.newValue.asset.companyId));
    }
    if (
      name.newValue &&
      name.newValue.asset &&
      Object.keys(name.newValue.asset).length === 1 &&
      assetEPModify
    ) {
      setData({ ...dataFormatted });
    }
  };

  const {
    isSubmit,
    errors,
    submitAction,
    changeValue,
    dataForm,
    dataFormatted,
    setData,
  } = useForm(
    submitHandler,
    AssetEPFormValidations,
    {
      asset: {
        companyId: user?.companyId,
      },
    },
    {
      changeValueEnd,
    }
  );
  const { data, buttons } = useAssetEPCreate(
    t,
    user,
    companyList,
    covipList,
    dataForm.asset___fundCode,
    !!assetEPModify
  );

  useEffect(() => {
    if (assetEPModify) {
      const asset = { ...assetEPModify };
      delete asset.configuration;

      const assetCopy = { ...asset };
      const shortDesc = assetCopy.shortDescription;
      asset.shortDescription = shortDesc
        ?.slice(shortDesc?.indexOf('-') + 1, shortDesc.length)
        .trim();
      setData({ asset: { ...asset } });
    } else {
      setData({
        ...dataForm,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetEPModify]);

  return (
    <div className="px-5">
      <Form className="pt-2" onSubmit={submitAction}>
        <FormElements
          data={data}
          errors={errors}
          isSubmit={isSubmit}
          dataForm={dataForm}
          changeValue={changeValue}
        />
        <ButtonList buttons={buttons} />
      </Form>
    </div>
  );
};

export default AssetEPForm;
