import React from 'react';
import { FormGroupCustomModel } from '../../../../../../utils/forms/models/FormElementModel';
import FormGroupCustom from '../../../../../../utils/forms/FormGroupCustom';
import { Row, Col } from 'react-bootstrap';
import Icon from '../../../../../../components/shared/icon/Icon';
import {
  closeDeleteModal,
  selectDeleteModal,
  setDeleteModal,
} from '../../DashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import DeleteModal from '../../flow/components/DeleteModal';

interface SelectVdsProps {
  data: FormGroupCustomModel;
  selectedVds?: string;
  changeValueVds: (value: any) => void;
  addVds?: () => void;
  removeVds?: () => void;
}
const SelectVds = ({
  data,
  changeValueVds,
  selectedVds,
  addVds,
  removeVds,
}: SelectVdsProps) => {
  const dispatch = useDispatch();
  const deleteModal = useSelector(selectDeleteModal);
  const removeVdsHandler = (vds: string) => {
    dispatch(
      setDeleteModal({
        vds,
        text: {
          title: 'Eliminazione Vds',
          body: `Sei sicuro di voler eliminare la vds ${vds}?`,
        },
        action: 'deleteAction',
      })
    );
  };

  const deleteModalActions: { [key in string]: () => void } = {
    deleteAction: () => {
      dispatch(closeDeleteModal());
      removeVds && removeVds();
    },
    closeAction: () => {
      dispatch(closeDeleteModal());
    },
  };

  return (
    <Row className="align-items-center">
      <DeleteModal
        close={() => dispatch(closeDeleteModal())}
        action={action => deleteModalActions[action]()}
        data={deleteModal}
      />
      <Col xs="12" md="4">
        <FormGroupCustom
          {...data}
          changeValue={changeValueVds}
          value={selectedVds}
        />
      </Col>
      {selectedVds && (
        <div>
          <Icon
            icon="plus-circle"
            size="24"
            onClick={addVds}
            title="Aggiungi Vds"
          ></Icon>
          <Icon
            icon="minus-circle"
            size="24"
            onClick={() => removeVdsHandler(selectedVds)}
            title="Rimuovi Vds"
          ></Icon>
        </div>
      )}
    </Row>
  );
};

export default SelectVds;
