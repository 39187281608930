import React from 'react';
import Caption from '../../../../../components/shared/headings/caption/Caption';
import Icon from '../../../../../components/shared/icon/Icon';
import { labelValueSymbolData } from './components/valueSymbolsData';
import { RequestVds } from './models/SliceVdsModel';

const FilterSummary = ({ data }: { data?: RequestVds }) => {
  const summary: { [key: string]: any }[] = data?.propToFilter
    ? Object.keys(data.propToFilter).map((ele: string) => ({
        name: ele,
        ...(data && data.propToFilter[ele] ? data.propToFilter[ele] : {}),
      }))
    : [];

  return (
    <div
      className="d-flex align-items-center justify-content-start"
      style={{
        backgroundColor: '#f5f6f9',
        color: '#212529',
        padding: '2px 6px',
      }}
    >
      <Caption className="mr-3 medium">Ricerca:</Caption>
      <Caption className="mr-2 medium">
        {(data && data.vds) || 'Tutti i VdS'}
      </Caption>
      <Icon icon="arrow-paglast" color="gray" pointer={false}></Icon>
      {summary.map((ele, index) => (
        <div key={index} className="d-flex mr-2 ml-2">
          <span className="mr-1">{ele.name}</span>
          <Caption className="mr-1 medium">
            {labelValueSymbolData && labelValueSymbolData[ele.operation]}
          </Caption>
          <span>{ele.value1}</span>
        </div>
      ))}
    </div>
  );
};

export default FilterSummary;
