import React from 'react';
import { Col, Row } from 'react-bootstrap';
import UserEditUsername from './UserEditUsername';
import UserEditEmail from './UserEditChangeEmail';
import { User } from '../../../../../app/auth/model/User';

const UserEditSingleChange = ({
  userModify,
  successUpdate,
  id,
}: {
  userModify: Partial<User>;
  id: number;
  successUpdate: () => void;
}) => {
  return (
    <div className="mt-3">
      <hr />
      <Row>
        <Col sm="6" md="4">
          <UserEditUsername
            id={id}
            username={userModify.username}
            saved={successUpdate}
          />
        </Col>
        <Col sm="6" md="4">
          <UserEditEmail
            id={id}
            email={userModify.email}
            saved={successUpdate}
          />
        </Col>
      </Row>
    </div>
  );
};

export default UserEditSingleChange;
