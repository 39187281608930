import React from 'react';
import { mergeClass } from '../../functions/function';
import Icon from '../../icon/Icon';

interface TagProps {
  className?: string;
  clickOrder: () => void;
  isFilterVisible?: boolean;
  order?: 'ASC' | 'DESC';
}

const OrderFilter = ({
  className,
  clickOrder,
  isFilterVisible = false,
  order,
}: TagProps) => {
  const clickOrderHandle = () => {
    clickOrder();
  };

  return isFilterVisible ? (
    <div
      className={mergeClass('shared-orderFilter', className)}
      onClick={clickOrderHandle}
    >
      <Icon disabled={!order || order === 'DESC'} icon="up" size="x8"></Icon>
      <Icon disabled={!order || order === 'ASC'} icon="down" size="x8"></Icon>
    </div>
  ) : null;
};
export default OrderFilter;
