import React from 'react';
import MultiSelect from 'react-multi-select-component';
import { FormControlProps } from 'react-bootstrap/FormControl';
import { mergeClass } from '../../../functions/function';

interface FormProps extends FormControlProps {
  error?: boolean;
  options?: { label: string; value: any }[];
  multiply?: boolean;
  changeSelect?: (value?: any) => void;
  value?: any;
}

const MultiSelectCustom: React.FC<
  FormProps & React.HTMLProps<HTMLInputElement>
> = ({
  children,
  className,
  options = [],
  error = false,
  multiply = false,
  changeSelect = () => {},
  value = [],
  disabled,
  ...rest
}) => {
  let valueMulti = options.filter(
    ele =>
      value
        .map((ele: any) => (typeof ele === 'number' ? ele.toString() : ele))
        .indexOf(ele.value) !== -1
  );
  let changeHandler = (data: { label: string; value: any }[]) => {
    changeSelect(data.map(ele => ele.value));
  };
  return (
    <MultiSelect
      className={mergeClass(
        `multi-select ${error ? 'error' : ''}`,
        disabled ? 'disabled' : ''
      )}
      {...rest}
      options={options}
      value={valueMulti}
      onChange={changeHandler}
      labelledBy={'Select'}
      overrideStrings={{
        selectSomeItems: 'Seleziona elementi...',
        allItemsAreSelected: 'Tutti gli elementi sono selezionati',
        selectAll: 'Seleziona Tutto',
        search: 'Cerca',
      }}
    />
  );
};

export default MultiSelectCustom;
