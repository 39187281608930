import React from 'react';
import { mergeClass } from '../../../functions/function';
import Caption from '../../../headings/caption/Caption';

interface StatePropsIcon extends React.HTMLAttributes<HTMLOrSVGElement> {
  color?: string;
  colorStyle?: string;
  size?: string;
  label?: string;
}

const StateIcon = ({
  children,
  color,
  colorStyle,
  size, //TBD
  label,
  className,
  ...rest
}: StatePropsIcon) => {
  return (
    <div className={'shared-stateIcon'}>
      <div
        className={mergeClass(color, size)}
        style={{ backgroundColor: colorStyle }}
      ></div>
      {label && <Caption className="sm">{label}</Caption>}
    </div>
  );
};

export default StateIcon;
