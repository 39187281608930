import React from 'react';
import { mergeClass } from '../../functions/function';
import Icon from '../../icon/Icon';

interface SearchFilterBtnProps {
  className?: string;
  clickShowFilter?: () => void;
  isFilterVisible?: boolean;
}

const SearchFilterBtn = ({
  className,
  clickShowFilter,
  isFilterVisible = false,
}: SearchFilterBtnProps) => {
  const clickHandler = () => {
    clickShowFilter && clickShowFilter();
  };

  return isFilterVisible ? (
    <div
      className={mergeClass('shared-searchFilterBtn', className)}
      onClick={() => clickHandler()}
    >
      <Icon icon="sort" size="x20"></Icon>
    </div>
  ) : null;
};
export default SearchFilterBtn;
