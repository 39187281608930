import React from 'react';
import ModalMsg from '../../../../../../components/shared/modal/ModalMsg';
import {
  ModalPropsBtn,
  ModalPropsStyle,
} from '../../../../../../components/shared/modal/models/ModalProps';

const DeleteModal = ({
  close,
  action,
  data,
  showModal = false,
}: {
  close: () => void;
  action: (action: string) => void;
  data: any;
  showModal: boolean;
}) => {
  const btnSuccess: ModalPropsBtn[] = [
    {
      label: 'Annulla',
      action: 'closeDeleteAction',
      type: 'secondary',
      dataType: { size: 'sm' },
    },
    {
      label: 'Elimina',
      action: 'deleteAction',
      type: 'primary',
      dataType: { size: 'sm' },
    },
  ];
  const style: ModalPropsStyle = {
    style: { color: '#565B6F', bgColor: '#fff' },
    icon: { color: 'primary', ico: 'delete' },
  };

  return (
    <ModalMsg
      className="shared-modalSuccess"
      btn={btnSuccess}
      text={{
        title: 'Eliminazione file',
        body: `Sei sicuro di voler eliminare il VdS ${data}?`,
      }}
      modalStyle={style}
      showModal={showModal}
      closeAction={close}
      clickAction={action}
    ></ModalMsg>
  );
};

export default DeleteModal;
