export const validationsCreateUserForm = {
  firstName: {
    required: {
      params: { name: 'nome' },
    },
  },
  company: {
    required: {
      params: { name: 'Azienda' },
    },
  },
  assets: {
    required: {
      params: { name: 'fondi' },
    },
  },
  role: {
    required: {
      params: { name: 'Ruolo' },
    },
  },
  lastName: {
    required: {
      params: { name: 'cognome' },
    },
  },
  username: {
    required: {
      params: { name: 'username' },
    },
  },
  email: {
    required: {
      params: { name: 'email' },
    },
    email: {
      params: { name: 'email' },
    },
  },
  password: {
    required: {
      params: { name: 'password' },
    },
    minLength: {
      moreData: {
        min: 8,
      },
      params: { name: 'password', min: 8 },
    },
    upperCharacter: {
      params: { name: 'password' },
    },
    oneNumber: {
      params: { name: 'password' },
    },
    specialCharacter: {
      params: { name: 'password' },
    },
  },
  confirmPassword: {
    required: {
      params: { name: 'conferma password' },
    },
    confirm: {
      moreData: {
        fieldCompare: 'password',
      },
      params: { name: 'password', fieldCompare: 'conferma password' },
    },
  },
};
