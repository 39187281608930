import React from 'react';
import { FlowProps } from './models/FlowProps';
import Flow from './flow/Flow';
import ManagementVds from './vds/ManagementVds';
import TablesDashboard from './tables/TablesDashboard';
import ReportsDashboard from './reports/ReportsDashboard';
import ErrorDashboard from './errors/ErrorDashboard';
import { tabObject } from './dashboardData';
interface SwitchTableProps extends FlowProps {
  switchTab: string;
  autoSelectTab: (tab: string, vds: string) => void;
}
const SwitchTable = ({
  switchTab,
  assetId,
  periodId,
  autoSelectTab,
  ...rest
}: SwitchTableProps) => {
  const dataTemplate = {
    [tabObject.flow]: <Flow assetId={assetId} periodId={periodId} {...rest} />,
    [tabObject.vds]: <ManagementVds />,
    [tabObject.tables]: <TablesDashboard />,
    [tabObject.errors]: <ErrorDashboard autoSelectTab={autoSelectTab} />,
    [tabObject.reports]: <ReportsDashboard {...rest} />,
  };
  return <>{dataTemplate && switchTab && dataTemplate[switchTab]}</>;
};

export default SwitchTable;
