import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setTitle } from '../../../../app/page/pageSlice';
import { setDisabledAll } from '../../../../components/core/filter-dashboard/filterDashboardSlice';

import AssetForm from '../AssetForm';
import { getListCompany, resetAssetSlice } from './assetCreateSlice';

const AssetCreate = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetAssetSlice());
    dispatch(setTitle(t('Inserisci fondo')));
    dispatch(getListCompany(1));
    dispatch(setDisabledAll(true));
    return () => {
      dispatch(setDisabledAll(false));
      dispatch(resetAssetSlice());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AssetForm />;
};

export default AssetCreate;
