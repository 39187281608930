import React from 'react';
import loader from '../../../../assets/loader-pass.gif';
type LoaderProps = {
  loading: boolean;
  fullscreen?: boolean;
  size?: number | string;
};

const LoaderV2 = (props: LoaderProps) => {
  const { loading, fullscreen = true, size = 100 } = props;

  return (
    <>
      {loading && (
        <div className={`loader-v2${fullscreen ? '-fullscreen' : ''}`}>
          <div className="loader-v2-inner">
            <img
              src={loader}
              style={{ height: size, width: size }}
              alt="spinner"
            />
          </div>
        </div>
      )}
    </>
  );
};
export default LoaderV2;
