import React from 'react';
import ButtonTab from '../../../../../../components/shared/form/buttons/button-tab/ButtonTab';

const SelectErrorDetailDashboard = ({
  changeView,
  tabs,
}: {
  changeView: (data: boolean) => void;
  tabs: any[];
}) => {
  return (
    <div className="d-flex justify-content-end">
      {tabs.map((ele, index) => (
        <ButtonTab
          key={index}
          label={ele.label}
          disabled={ele.isActive}
          onClick={() => changeView(ele.isSelected)}
        />
      ))}
    </div>
  );
};

export default SelectErrorDetailDashboard;
