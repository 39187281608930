import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from '../../../utils/forms/useForm';
import { useChangeAuthPassword } from './useChangeAuthPassword';
import FormElements from '../../../utils/forms/FormElements';
import ButtonList from '../../../components/shared/form/buttons/button-list/ButtonList';
import { setTitle } from '../../../app/page/pageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { passwordChangerById } from './services/ChangeAuthPassword.service';
import { selectUser } from '../../../app/auth/authSlice';
import { useRoute } from '../../../utils/useRoute';
import { UseFormSubmitModel } from '../../../utils/forms/models/UseFormModel';

const ChangeAuthPassword = () => {
  const user = useSelector(selectUser);
  const { changeRoute } = useRoute();
  const submitHandler: UseFormSubmitModel = (event, data) => {
    passwordChangerById({ id: user?.id, ...data }).then(res => {
      changeRoute('home');
    });
  };

  const { t } = useTranslation();
  const { validations, data, buttons } = useChangeAuthPassword(t);
  const { isSubmit, errors, submitAction, changeValue, dataForm } = useForm(
    submitHandler,
    validations
  );
  const dispatch = useDispatch();
  dispatch(setTitle(t('changePsw.title')));

  return (
    <Row className="justify-content-center">
      <Col xs="12" md="10" lg="8">
        <Form className="pt-2 " onSubmit={submitAction}>
          <FormElements
            data={data}
            errors={errors}
            isSubmit={isSubmit}
            dataForm={dataForm}
            changeValue={changeValue}
          />
          <ButtonList buttons={buttons} />
        </Form>
      </Col>
    </Row>
  );
};

export default ChangeAuthPassword;
