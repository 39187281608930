import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { loginUser, selectRedirectToken } from './loginSlice';

import SecondaryButton from '../../../components/shared/form/buttons/secondary-button/SecondaryButton';
import PrimaryButton from '../../../components/shared/form/buttons/primary-button/PrimaryButton';
import Caption from '../../../components/shared/headings/caption/Caption';
import { useForm } from '../../../utils/forms/useForm';
import { useLogin } from './useLogin';
import FormGroupCustom from '../../../utils/forms/FormGroupCustom';
import { UseFormSubmitModel } from '../../../utils/forms/models/UseFormModel';
import { useRoute } from '../../../utils/useRoute';

const Login = () => {
  const dispatch = useDispatch();
  const redirectToken = useSelector(selectRedirectToken);
  const { t } = useTranslation();

  const submitHandler: UseFormSubmitModel = (event, data) => {
    dispatch(loginUser(data));
  };
  const { changeRoute } = useRoute();
  useEffect(() => {
    if (redirectToken) {
      changeRoute('CheckSubscription', {}, { token: redirectToken });
    }
  }, [redirectToken]);
  const { validations, data } = useLogin(t);
  const { isSubmit, errors, valid, submitAction, changeValue, dataForm } =
    useForm(submitHandler, validations);

  return (
    <Form onSubmit={submitAction} className="pt-2">
      {data.map(({ label, name, dataElement, typeElement, options, value }) => (
        <FormGroupCustom
          key={name}
          isSubmit={isSubmit}
          name={name}
          errors={errors}
          label={label}
          dataElement={dataElement}
          typeElement={typeElement}
          value={dataForm[name]}
          options={options}
          changeValue={value => changeValue(value, name)}
        />
      ))}

      <Row className="pt-3">
        <Col>
          <SecondaryButton>{t('login.cancel')}</SecondaryButton>
        </Col>
        <Col>
          <PrimaryButton>{t('login.login')}</PrimaryButton>
        </Col>
      </Row>
      <Row className="pt-3 text-right">
        <Col>
          <Caption className="medium-link">
            <a href="/forgot-password">Hai dimenticato la password?</a>
          </Caption>
        </Col>
      </Row>
    </Form>
  );
};

export default Login;
