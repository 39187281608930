import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../../app/store';
import {
  configVds,
  deleteVdsRow,
  downloadFileVds,
  editVds,
  insertVdsRow,
  listVds,
  removeVds,
  searchVds,
  vdsExportPrepare,
  vdsListFile,
} from '../../services/vds.service';
import {
  ConfigModel,
  FileVds,
  GroupFileVds,
  RequestVds,
  ResultVdsModel,
  rowVds,
  rowWithHeader,
  rowWithHeaderAndTable,
  SliceVdsModel,
} from './models/SliceVdsModel';
import { configInitList } from './vdsData';
import { copyObject } from '../../../../../utils/objUtils';
import {
  addSuccessMessage,
  resetErrors,
} from '../../../../../app/errors/errorSlice';
import { AnyObjModel } from '../../../../../components/shared/model/AnyObjModel';
import { act } from 'react-dom/test-utils';
import { setShowModal } from '../../../tables/table1/table1Slice';
import { fixedData } from './components/tableDetailRowData';
import { loadLoadedFileCall } from '../../../../../components/core/filter-dashboard/filterDashboardSlice';
import { addSub, removeSub } from '../../../../../app/clientSubscribe';
import {
  decrementN,
  incrementN,
} from '../../../../../components/shared/loader/redux/loadSlice';
import { databaseStatusAction } from '../../../database/DatabaseSlice';

const initialState: SliceVdsModel = {
  listVds: [],
  listFile: [],
  configList: configInitList,
  showDeleteModal: false,
  showEditModal: false,
  modeInsert: false,
};
let tenantSubscribe: any;
let resetLoader: any;
export const vdsSlice = createSlice({
  name: 'vds',
  initialState,
  reducers: {
    setlistVds: (state, action: PayloadAction<{ name: string }[]>) => {
      state.listVds = action.payload;
    },
    setModeInsert: state => {
      state.modeInsert = true;
    },
    resetModeInsert: state => {
      state.modeInsert = false;
    },
    resetlistVds: state => {
      state.listVds = [];
    },
    setSelectedRowFile: (
      state,
      action: PayloadAction<rowWithHeaderAndTable>
    ) => {
      state.selectedRowFile = action.payload;
    },
    resetSelectedRowFile: state => {
      state.selectedRowFile = undefined;
    },
    setExportlistVds: (state, action: PayloadAction<GroupFileVds[]>) => {
      state.listFile = action.payload;
    },
    resetExportlistVds: state => {
      state.listFile = [];
    },
    setConfiglistVds: (state, action: PayloadAction<ConfigModel[]>) => {
      state.configList = action.payload;
    },
    resetConfiglistVds: state => {
      state.configList = configInitList;
    },
    setLastRequest: (state, action: PayloadAction<RequestVds>) => {
      state.lastRequest = action.payload;
    },
    resetLastRequest: state => {
      state.lastRequest = undefined;
    },
    setSelectVds: (state, action: PayloadAction<string | undefined>) => {
      state.selectVds = action.payload;
    },
    resetSelectVds: state => {
      state.selectVds = undefined;
    },
    editResultVds: (state, action: PayloadAction<ResultVdsModel>) => {
      state.resultMoreVds = (state.resultMoreVds || []).map(ele =>
        ele.vds === action.payload.vds
          ? {
              ...ele,
              count:
                action.payload.count !== undefined &&
                action.payload.count !== null
                  ? action.payload.count
                  : ele.count,
              pages:
                action.payload.pages !== undefined &&
                action.payload.pages !== null
                  ? action.payload.pages
                  : ele.pages,
              page: action.payload.page,
              body: action.payload.body,
              sort: action.payload.sort,
            }
          : ele
      );
    },
    setResultMoreVds: (state, action: PayloadAction<ResultVdsModel[]>) => {
      state.resultMoreVds = action.payload;
    },
    resetResultMoreVds: state => {
      state.resultMoreVds = undefined;
    },
    setShowDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.showDeleteModal = action.payload;
    },
    setShowEditModal: (state, action: PayloadAction<boolean>) => {
      state.showEditModal = action.payload;
    },
  },
});
export const {
  setlistVds,
  resetlistVds,
  setConfiglistVds,
  setLastRequest,
  resetLastRequest,
  resetConfiglistVds,
  setSelectVds,
  resetSelectVds,
  editResultVds,
  setResultMoreVds,
  resetResultMoreVds,
  setExportlistVds,
  resetExportlistVds,
  setSelectedRowFile,
  resetSelectedRowFile,
  setShowDeleteModal,
  setShowEditModal,
  setModeInsert,
  resetModeInsert,
} = vdsSlice.actions;

export const getListVds = (): AppThunk => (dispatch, getState) => {
  const tenant = getState().filterDashboard.tenant;
  tenant && listVds(tenant).then(res => dispatch(setlistVds(res)));
};

export const resetVdsSlice = (): AppThunk => (dispatch, getState) => {
  dispatch(resetlistVds());
  dispatch(resetSelectVds());
  dispatch(resetConfiglistVds());
  dispatch(resetResultMoreVds());
  dispatch(resetLastRequest());
  dispatch(resetExportlistVds());
  dispatch(resetModeInsert());
  dispatch(resetSelectedRowFile());
};

export const setInsertModeAction = (): AppThunk => (dispatch, getState) => {
  const config = getState().vds.configList;
  const vds = getState().vds.selectVds;
  const rowBase: any = config.reduce(
    (obj, ele) => ({ ...obj, [ele.name]: '' }),
    {}
  );
  const listAsset = getState().filterDashboard.assets;
  const assetId = getState().filterDashboard.assetId;
  const fixed = {
    [fixedData.loadedId]: getState().filterDashboard.loadedFileId,
    [fixedData.codeFound]: listAsset.find(asset => asset.id === assetId)
      ?.fundCode,
    [fixedData.vds]: getState().vds.selectVds,
  };
  const row: any = {
    ...rowBase,
    id: {
      ...config
        .filter(ele => ele.type === 'K')
        .reduce((obj, ele) => ({ ...obj, [ele.name]: '' }), {}),
      ...fixed,
    },
    ...fixed,
  };
  dispatch(setModeInsert());
  vds &&
    dispatch(
      setSelectedRowFileAction(
        {
          header: config.reduce(
            (obj, ele) => ({ ...obj, [ele.name]: ele.description }),
            {}
          ),
          row,
        },
        vds
      )
    );
  dispatch(searchVdsAction({}));
};

export const getListConfig = (): AppThunk => (dispatch, getState) => {
  const tenant = getState().filterDashboard.tenant;
  const vds = getState().vds.selectVds;
  !vds && dispatch(resetConfiglistVds());
  vds &&
    tenant &&
    configVds(vds, tenant).then(res => {
      dispatch(setConfiglistVds(res));
    });
};
export const setSelectVdsAction =
  (vds: string): AppThunk =>
  (dispatch, getState) => {
    const tenantId = getState().filterDashboard.tenant;
    dispatch(resetErrors());
    tenantSubscribe && removeSub(tenantSubscribe);
    tenantSubscribe = addSub(`/id/${tenantId}/vds`, (res: any) => {
      const loadedId = getState().filterDashboard.loadedFileId;
      const vds = getState().vds.selectVds;
      const body = res && res.body ? JSON.parse(res.body) : {};
      if (loadedId === body.loadedId && vds === body.vds) {
        dispatch(resetVdsSlice());
        dispatch(getListVds());
        dispatch(GetListExportAction(false));
        dispatch(addSuccessMessage('REMOVE_VDS_SUCCESS'));
      }
      resetLoader && resetLoader();
      resetLoader = undefined;
      removeSub(tenantSubscribe);
    });
    dispatch(setSelectVds(vds));
  };

export const removeVdsAction = (): AppThunk => (dispatch, getState) => {
  const tenant = getState().filterDashboard.tenant;
  const vds = getState().vds.selectVds;
  const loadedId = getState().filterDashboard.loadedFileId;
  vds &&
    loadedId &&
    tenant &&
    removeVds(vds, loadedId, tenant).then(res => {
      dispatch(incrementN());
      resetLoader = () => {
        dispatch(decrementN());
      };
    });
};

export const searchVdsAction =
  (propFilter: any = {}): AppThunk =>
  (dispatch, getState) => {
    const tenant = getState().filterDashboard.tenant;
    dispatch(resetErrors());
    const propToFilter = Object.keys(propFilter).reduce((obj: any, prop) => {
      if (propFilter[prop].operation) {
        const configData = getState().vds.configList.find(
          ele => ele.name === prop
        );
        obj[prop] = {
          ...propFilter[prop],
          isKey: configData && configData.type === 'K',
        };
      }
      return obj;
    }, {});

    const data = {
      vds: getState().vds.selectVds,
      propToFilter,
      paging: {
        pageSize: 5,
      },
    };
    dispatch(setLastRequest(data));
    tenant &&
      searchVds(data, tenant).then(res => {
        dispatch(setResultMoreVds(res.map(ele => ({ ...ele, page: 1 }))));
      });
  };

export const updateSearchVdsActionPagination =
  (vds: string, page: number): AppThunk =>
  (dispatch, getState) => {
    const data = copyObject(getState().vds.lastRequest || {});
    data.paging.page = page;
    data.vds = vds;
    dispatch(updateSearchVdsAction(data));
  };

export const setSelectedRowFileAction =
  (data: rowWithHeader, vds: string): AppThunk =>
  (dispatch, getState) => {
    dispatch(
      setSelectedRowFile({
        ...data,
        vds,
        listTable: Object.keys(data.header).map(ele => ({
          name: ele,
          description: data.header[ele],
          value: data.row[ele],
        })),
      })
    );
  };

export const updateSearchVdsActionFilter =
  (vds: string, sort: any): AppThunk =>
  (dispatch, getState) => {
    const data = copyObject(getState().vds.lastRequest || {});
    data.sort = sort;
    data.vds = vds;
    const results = getState().vds.resultMoreVds;
    const result = (results || []).find(ele => ele.vds === data?.vds);
    if (
      result?.body?.rows &&
      result.body.rows[0] &&
      result.body.rows[0].id &&
      Object.keys(result.body.rows[0].id).includes(sort.key)
    ) {
      data.sort.key = 'id.' + data.sort.key;
    }
    dispatch(updateSearchVdsAction(data));
  };

export const updateSearchVdsAction =
  (data?: RequestVds, resetPage: boolean = false): AppThunk =>
  (dispatch, getState) => {
    const tenant = getState().filterDashboard.tenant;
    const results = getState().vds.resultMoreVds;
    const result = (results || []).find(ele => ele.vds === data?.vds);
    dispatch(resetErrors());
    if (data) {
      let copyData: RequestVds = copyObject(data);
      copyData.paging.page = !resetPage
        ? data.paging.page || result?.page
        : undefined;
      copyData.sort = data.sort || result?.sort;
      copyData &&
        tenant &&
        searchVds(copyData, tenant).then(res => {
          dispatch(
            editResultVds({
              ...res[0],
              page: copyData?.paging?.page,
              sort: copyData?.sort,
            })
          );
          dispatch(resetErrors());
        });
    }
  };
export const VdsExportPrepareAction =
  (fileType: string = 'CSV', callback: () => void): AppThunk =>
  (dispatch, getState) => {
    const tenant = getState().filterDashboard.tenant;
    const lastRequest = getState().vds.lastRequest;
    const data = {
      exportAll: { ...lastRequest, paging: {} },
      exportType: 'EXPORT_ALL',
      fileType: fileType,
    };
    tenant &&
      lastRequest &&
      vdsExportPrepare(data, tenant).then(res => {
        console.log(res);
        callback && callback();
        dispatch(resetErrors());
      });
  };

export const GetListExportAction =
  (resetError: boolean = true): AppThunk =>
  (dispatch, getState) => {
    const tenant = getState().filterDashboard.tenant;
    tenant &&
      vdsListFile(tenant).then(res => {
        dispatch(setExportlistVds(res));
        resetError && dispatch(resetErrors());
      });
  };

export const DownloadFileExportAction =
  (file: FileVds): AppThunk =>
  (dispatch, getState) => {
    const tenant = getState().filterDashboard.tenant;
    tenant &&
      file.bucketName &&
      downloadFileVds(file.bucketName, tenant).then(res => {
        const blob = new Blob([res.data], {
          type: res.headers['content-type'],
        });
        saveAs(blob, file.bucketName);
        dispatch(resetErrors());
      });
  };

export const editVdsAction =
  (values: any, vds: string = ''): AppThunk =>
  (dispatch, getState) => {
    dispatch(resetErrors());
    const tenant = getState().filterDashboard.tenant;
    const lastRequest = getState().vds.lastRequest;
    tenant &&
      editVds({ values, vds }, tenant).then(res => {
        lastRequest && dispatch(updateSearchVdsAction({ ...lastRequest, vds }));
        dispatch(addSuccessMessage('EDIT_VDS_SUCCESS'));
        dispatch(resetSelectedRowFile());
        dispatch(setShowEditModal(false));
      });
  };

export const goVdsDetailAction =
  (id: any, vds: string): AppThunk =>
  (dispatch, getState) => {
    dispatch(resetErrors());
    const tenant = getState().filterDashboard.tenant;
    tenant &&
      searchVds(
        {
          vds,
          paging: { pageSize: 5 },
          propToFilter: Object.keys(id)
            .filter(ele => ele !== 'loadedId')
            .reduce(
              (obj, ele) => ({
                ...obj,
                [ele]: {
                  operation: 'EQUAL',
                  value1: id[ele],
                  isKey: true,
                },
              }),
              {}
            ),
        },
        tenant
      ).then(res => {
        dispatch(setResultMoreVds(res.map(ele => ({ ...ele, page: 1 }))));
        dispatch(
          setSelectedRowFileAction(
            { header: res[0].header, row: res[0].body.rows[0] },
            res[0].vds
          )
        );
      });
  };

export const insertVdsAction =
  (row: any, vds: string): AppThunk =>
  (dispatch, getState) => {
    dispatch(resetErrors());
    const data = copyObject(row);
    data.id = Object.keys(data.id).reduce(
      (obj, ele) => ({ ...obj, [ele]: data[ele] }),
      {}
    );
    const tenant = getState().filterDashboard.tenant;
    const lastRequest = getState().vds.lastRequest;
    const assetId = getState().filterDashboard.assetId;
    const assets = getState().filterDashboard.assets;
    const fundCode = assets.find((ele: any) => ele.id == assetId)?.fundCode;
    const periodId = getState().filterDashboard.periodId;

    //replace _ to . for c0001 field
    const fieldToReplace = 'c0001';
    data.id[fieldToReplace] = data.id[fieldToReplace]
      ? data.id[fieldToReplace].replace('_', '.')
      : data.id[fieldToReplace];
    data[fieldToReplace] = data[fieldToReplace]
      ? data[fieldToReplace].replace('_', '.')
      : data[fieldToReplace];

    tenant &&
      insertVdsRow({ row: data, vds, fundCode, periodId }, tenant).then(res => {
        lastRequest &&
          dispatch(updateSearchVdsAction({ ...lastRequest, vds }, true));
        dispatch(loadLoadedFileCall());
        dispatch(addSuccessMessage('INSERT_VDS_SUCCESS'));
        dispatch(resetSelectedRowFile());
      });
  };

export const deleteRowVdsAction =
  (id: any, vds?: string): AppThunk =>
  (dispatch, getState) => {
    dispatch(resetErrors());
    const tenant = getState().filterDashboard.tenant;
    const lastRequest = getState().vds.lastRequest;
    tenant &&
      id &&
      vds &&
      deleteVdsRow({ id, vds }, tenant).then((res: any) => {
        lastRequest &&
          dispatch(updateSearchVdsAction({ ...lastRequest, vds }, true));
        dispatch(resetSelectedRowFile());
        dispatch(setShowDeleteModal(false));
      });
  };

export const selectListVds = (state: RootState) => state.vds.listVds;
export const selectConfigListVds = (state: RootState) => state.vds.configList;
export const selectSelectedVds = (state: RootState) => state.vds.selectVds;
export const selectResultVds = (state: RootState) => state.vds.resultVds;
export const selectResultMoreVds = (state: RootState) =>
  state.vds.resultMoreVds;
export const selectLastRequest = (state: RootState) => state.vds.lastRequest;
export const selectListFileVds = (state: RootState) => state.vds.listFile;
export const selectModeInsert = (state: RootState) => state.vds.modeInsert;
export const selectSelectedRowFile = (state: RootState) =>
  state.vds.selectedRowFile;
export const selectShowDeleteModal = (state: RootState) =>
  state.vds.showDeleteModal;
export const selectShowEditModal = (state: RootState) =>
  state.vds.showEditModal;

export default vdsSlice.reducer;
