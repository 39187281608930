import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../../../app/page/pageSlice';
import SecondaryButton from '../../../../../components/shared/form/buttons/secondary-button/SecondaryButton';
import Error from '../../../../../components/shared/form/error/Error';
import Table from '../../../../../components/shared/table/table/Table';
import FormControlCustom from '../../../../../utils/forms/FormControlCustom';
import { useForm } from '../../../../../utils/forms/useForm';
import DeleteModal from './components/DeleteModal';
import EditModal from './components/EditModal';
import SaveButtonVds from './components/SaveButtonVds';
import { dataTableRow, fixedData } from './components/tableDetailRowData';
import { modeType, rowWithHeaderAndTable } from './models/SliceVdsModel';
import {
  deleteRowVdsAction,
  editVdsAction,
  insertVdsAction,
  resetModeInsert,
  resetSelectedRowFile,
  selectModeInsert,
  selectShowDeleteModal,
  selectShowEditModal,
  setShowDeleteModal,
  setShowEditModal,
} from './vdsSlice';

interface DetailRowVdsModel {
  rowFile?: rowWithHeaderAndTable;
}

const DetailRowVds = ({ rowFile }: DetailRowVdsModel) => {
  const { t } = useTranslation();
  const modeInsert = useSelector(selectModeInsert);
  const [mode, setMode] = useState<modeType>(modeInsert ? 'insert' : 'base');
  const dispatch = useDispatch();
  const showDeleteModal = useSelector(selectShowDeleteModal);
  const showEditModal = useSelector(selectShowEditModal);
  //const tenant = useSelector(selectTenantId);
  //const [config, setConfig] = useState<ConfigModel[]>([]);
  useEffect(() => {
    dispatch(setTitle(`${t('vds.detail')} ${rowFile?.vds}`));
    //rowFile?.vds &&
    //  tenant &&
    //  configVds(rowFile.vds, tenant).then(res => {
    //    setConfig(res);
    //  });
    return () => {
      dispatch(resetSelectedRowFile());
      dispatch(resetModeInsert());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitHandler = (event: any, data: any) => {
    mode === 'edit' && dispatch(setShowEditModal(true));
    //aaa
    //const action = mode === "edit" ? editVdsAction : insertVdsAction;

    mode !== 'edit' &&
      rowFile &&
      rowFile.vds &&
      dispatch(insertVdsAction(data, rowFile.vds));
  };
  // const validations = createValidationsForm(
  //   config.map((ele) => {
  //     return {
  //       name: ele.name,
  //       validations: [
  //         {
  //           name: regex,
  //           moreData: {
  //             regex: ele.regex,
  //           },
  //           params: {
  //             name: ele.name,
  //             regex: ele.regex,
  //           },
  //         },
  //       ],
  //     };
  //   })
  // );
  const {
    isSubmit,
    errors,
    submitAction,
    changeValue,
    setData,
    dataFormatted,
    dataForm,
  } = useForm(submitHandler, {}, modeInsert ? rowFile?.row || {} : {});

  const templates = {
    valueData: (value: string, row: any) => {
      return (
        <div className="d-flex" style={{ minWidth: 'max-content' }}>
          <FormControlCustom
            value={dataForm[row.name]}
            changeValue={(value: any) => changeValue(value, row.name)}
            errors={errors}
            dataElement={{
              disabled:
                (mode === 'edit' &&
                  Object.keys(rowFile?.row.id).includes(row.name)) ||
                Object.values(fixedData).includes(row.name),
              className: 'sm',
            }}
            name={row.name}
            isSubmit={isSubmit}
            typeElement={'input'}
          />
        </div>
      );
    },
  };
  const editModeAction = () => {
    setMode('edit');
    setData(rowFile?.row);
  };
  const duplicateModeAction = () => {
    setMode('duplicate');
    setData(rowFile?.row);
  };

  const func: { [key: string]: () => void } = {
    deleteAction: () =>
      dispatch(deleteRowVdsAction(rowFile?.row.id, rowFile?.vds)),
    editAction: () => dispatch(editVdsAction(dataFormatted, rowFile?.vds)),
    closeDeleteAction: () => dispatch(setShowDeleteModal(false)),
    closeEditAction: () => dispatch(setShowEditModal(false)),
  };

  const closeModalDelete = () => {
    dispatch(setShowDeleteModal(false));
  };
  const closeModalEdit = () => {
    dispatch(setShowEditModal(false));
  };

  const modalsAction = (action: string) => {
    func && func[action] && func[action]();
  };
  /*   const editAction = (action: string) => {
    func && func[action] && func[action]();
  }; */
  return (
    <>
      <DeleteModal
        close={closeModalDelete}
        action={modalsAction}
        showModal={showDeleteModal}
        data={rowFile?.vds}
      />
      <EditModal
        close={closeModalEdit}
        action={modalsAction}
        showModal={showEditModal}
        data={rowFile?.vds}
      />
      <Form className="pt-2" onSubmit={submitAction}>
        <Table
          columns={dataTableRow[mode]}
          data={(rowFile?.listTable || []).filter(
            ele => ele.name !== fixedData.loadedId
          )}
          templates={templates}
        />
        <div>
          {isSubmit &&
            !!Object.keys(errors)?.length &&
            Object.keys(errors).map(ele => (
              <Error
                isVisible={true}
                message={t('errors.' + errors[ele]?.code, errors[ele]?.params)}
              />
            ))}
        </div>
        <Row className="justify-content-end">
          <Col xs="12" md="3" lg="3" className="mb-2">
            {!modeInsert && (
              <SecondaryButton onClick={editModeAction}>
                Modifica
              </SecondaryButton>
            )}
          </Col>
          <Col xs="12" md="3" lg="3" className="mb-2">
            {!modeInsert && (
              <SecondaryButton
                onClick={() => dispatch(setShowDeleteModal(true))}
              >
                Cancella
              </SecondaryButton>
            )}
          </Col>
          <Col xs="12" md="3" lg="3" className="mb-2">
            {!modeInsert && (
              <SecondaryButton onClick={duplicateModeAction}>
                Duplica
              </SecondaryButton>
            )}
          </Col>
          <Col xs="12" md="3" lg="3" className="mb-2">
            <SaveButtonVds mode={mode} dataFormatted={dataFormatted} />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default DetailRowVds;
