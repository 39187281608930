import React from 'react';
import ErrorList from '../shared/form/errorList/ErrorList';
import { useSelector } from 'react-redux';
import { selectErrors } from '../../app/errors/errorSlice';

const ErrorView = () => {
  const errors = useSelector(selectErrors);
  return (
    <>
      {errors && errors.length ? <ErrorList errors={errors}></ErrorList> : null}
    </>
  );
};

export default ErrorView;
