import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SingleButton from './components/SingleButton';
import { ButtonListModel } from '../models/ButtonModel';

const ButtonList = ({ buttons = [] }: ButtonListModel) => {
  return (
    <Row className="justify-content-center">
      {buttons.map((btn, index) => (
        <Col className="pt-4" xs="6" md="4" key={index}>
          <SingleButton {...btn} />
        </Col>
      ))}
    </Row>
  );
};

export default ButtonList;
