import { TFunction } from 'i18next';
import { useHistory } from 'react-router';
import { ButtonModel } from '../../../../components/shared/form/buttons/models/ButtonModel';
import { FormElementModelNew } from '../../../../utils/forms/models/FormElementModel';
import { useEffect, useState } from 'react';
import { User } from '../../../../app/auth/model/User';
import { getAssetsList } from '../../users/services/users.service';
import { Report } from '../../../../models/ReportModel';
import { PeriodModel } from '../../../../models/PeriodModel';

export function useReportCreate(
  t: TFunction,
  user: Partial<User> = {},
  reports: Report[]
) {
  const history = useHistory();

  const [periods, setPeriods] = useState<PeriodModel[]>([]);

  const months = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ];
  const [assets, setAssets] = useState<
    { id: number; shortDescription: string }[]
  >([]);
  useEffect(() => {
    getAssetsList().then(res => {
      setAssets(res);
    });
  }, []);
  const years = () => {
    const now = new Date().getUTCFullYear();
    return Array(now - (now - 10))
      .fill('')
      .map((v, idx) => now - idx);
  };
  const validations = {
    reportId: {
      required: {
        params: { name: 'Report' },
      },
    },
    periodId: {
      required: {
        params: { name: 'Periodo' },
      },
    },
    assetId: {
      required: {
        params: { name: 'Fondo' },
      },
    },
  };

  const reportData: FormElementModelNew[] = [
    {
      typeElement: 'select',
      label: t('reporting.create.id'),
      name: 'reportId',
      col: { xs: 12 },
      options: [
        { label: 'Seleziona Report', value: '-1' },
        ...reports.map(r => ({
          value: r.id.toString(),
          label: r.name,
        })),
      ],
      dataElement: {},
    },
  ];

  const filtersData: FormElementModelNew[] = [
    {
      typeElement: 'select',
      label: t('reporting.create.assets'),
      name: 'assetId',
      col: { md: 8 },
      options: [
        { label: 'Seleziona fondo', value: '-1' },
        ...assets.map(ele => ({
          value: ele.id.toString(),
          label: ele.shortDescription,
        })),
      ],
      dataElement: {},
    },
    {
      typeElement: 'select',
      label: t('reporting.create.periods'),
      name: 'periodId',
      col: { md: 4 },
      options: [
        { label: 'Seleziona Periodo', value: '-1' },
        ...periods
          .map(ele => ({
            value: `${ele.id}`,
            label: `${ele.month < 10 ? '0' : ''}${ele.month}/${ele.year}`,
          }))
          .reverse(),
      ],
      dataElement: {
        disabled: !periods || periods?.length === 0,
      },
    },
  ];

  const buttons: ButtonModel[] = [
    {
      typeButton: 'primary',
      label: t('reporting.create.add'),
    },
  ];

  return {
    setPeriods,
    validations,
    reportData,
    filtersData,
    buttons,
  };
}
