import React from 'react';
import { mergeClass } from '../../../functions/function';
import { Button as Btn } from 'react-bootstrap';
import { ButtonProps as BtnProps } from 'react-bootstrap/Button';

interface ButtonProps extends BtnProps {}
const Button = ({ children, className, ...rest }: ButtonProps) => {
  return (
    <Btn className={mergeClass('shared-button', className)} {...rest}>
      {children}
    </Btn>
  );
};

export default Button;
