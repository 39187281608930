import http from '../../../utils/http';

export const changePasswordCall = (
  newPassword: string,
  resetToken: string = ''
) =>
  http.post(`user/resetPassword`, {
    newPassword,
    resetToken,
  });
export const changePasswordControl = (tokenData: string) =>
  http.get(`user/checkResetToken?token=${tokenData}`);
