import React from 'react';
import PrimaryButton from '../../../../../../components/shared/form/buttons/primary-button/PrimaryButton';
import { modeType } from '../models/SliceVdsModel';

interface DetailRowVdsModel {
  mode: modeType;
  dataFormatted: any;
}

const SaveButtonVds = ({ mode, dataFormatted }: DetailRowVdsModel) => {
  return (
    <PrimaryButton
      disabled={
        mode === 'base' ||
        (mode === 'duplicate' &&
          !Object.keys(dataFormatted.id).filter(
            ele => dataFormatted.id[ele] !== dataFormatted[ele]
          ).length)
      }
    >
      Salva
    </PrimaryButton>
  );
};

export default SaveButtonVds;
