import React from 'react';
import authRoute from '../../routes/auth';
import DynamicRoute from './DynamicRoute';
import CardWithErrors from '../core/CardWithErrors';
import { usePermissions } from '../../features/auth/usePemissions';

const AuthLayout = () => {
  const { issetPermission } = usePermissions();
  const filterRoute = {
    ...authRoute,
    list: authRoute.list.filter(ele =>
      ele.permissions && ele.permissions.length
        ? issetPermission(ele.permissions)
        : true
    ),
  };
  return (
    <div className="auth">
      <CardWithErrors>
        <DynamicRoute routes={filterRoute} />
      </CardWithErrors>
    </div>
  );
};

export default AuthLayout;
