export const listValueSymbolData = {
  EQUAL: 'EQUAL',
  NOT: 'NOT',
  BETWEEN: 'BETWEEN',
  GRATHERTHAN: 'GRATHERTHAN',
  LESSTHAN: 'LESSTHAN',
};

export const labelValueSymbolData: { [key: string]: string } = {
  EQUAL: '=',
  NOT: '!',
  BETWEEN: 'BET',
  GRATHERTHAN: '>',
  LESSTHAN: '<',
};

export const ValueSymbolsData = [
  { value: listValueSymbolData.EQUAL, label: labelValueSymbolData.EQUAL },
  { value: listValueSymbolData.NOT, label: labelValueSymbolData.NOT },
  { value: listValueSymbolData.BETWEEN, label: labelValueSymbolData.BETWEEN },
  {
    value: listValueSymbolData.GRATHERTHAN,
    label: labelValueSymbolData.GRATHERTHAN,
  },
  { value: listValueSymbolData.LESSTHAN, label: labelValueSymbolData.LESSTHAN },
];
