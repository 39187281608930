import { TabBtnModel } from '../../../../components/shared/tab/TabBtns.model';
import { permissionType } from '../../../../app/rolePermissions';
import { LegendModel } from '../../../../components/shared/legend/models/LegendModel';
export type tabObjectKey = 'flow' | 'errors' | 'vds' | 'tables' | 'reports';
export const tabObject: {
  [key in tabObjectKey]: tabObjectKey;
} = {
  flow: 'flow',
  errors: 'errors',
  vds: 'vds',
  tables: 'tables',
  reports: 'reports',
};

export const tabsDataType: {
  [key: string]: { name: tabObjectKey; label: string };
} = {
  [tabObject.flow]: {
    name: tabObject.flow,
    label: 'Flussi',
  },
  [tabObject.errors]: {
    name: tabObject.errors,
    label: 'Errori',
  },
  [tabObject.vds]: {
    name: tabObject.vds,
    label: 'Gestione VdS',
  },
  [tabObject.tables]: {
    name: tabObject.tables,
    label: 'Tavole',
  },
  [tabObject.reports]: {
    name: tabObject.reports,
    label: 'Segnalazioni',
  },
};

export const tabsData: (TabBtnModel & { permissions?: string[] })[] = [
  {
    ...tabsDataType.flow,
    permissions: [permissionType.VIEW_FLOWS],
  },
  {
    ...tabsDataType.errors,
    permissions: [permissionType.EDIT_FLOWS],
  },
  {
    ...tabsDataType.vds,
    permissions: [permissionType.MANAGEMENT_VDS],
  },
  {
    ...tabsDataType.tables,
    permissions: [permissionType.MANAGEMENT_TABLES],
  },
  {
    ...tabsDataType.reports,
    permissions: [permissionType.MANAGEMENT_ALERTS],
  },
];

export const listLegend: LegendModel[] = [
  { colorStyle: '#a10000', label: 'Errore bloccante', id: 1 },
  { colorStyle: '#ffcd00', label: 'Errore logico-formale', id: 2 },
  { colorStyle: '#f5a623', label: 'Warning logico-formale', id: 3 },
  { colorStyle: '#7ed321', label: 'Corretto', id: 4 },
];

export const getLegendColor = (result: number) => {
  return getLegendColorData(listLegend, result);
};

const getLegendColorData = (list: LegendModel[], result: number) => {
  const element = list.find(ele => ele.id === result);
  return element ? element.colorStyle : '#a10000';
};
