import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resetErrors } from '../../../../../app/errors/errorSlice';
import { setTitle } from '../../../../../app/page/pageSlice';
import { permissionType } from '../../../../../app/rolePermissions';
import {
  loadDashboardTables,
  selectAssetId,
  selectDashboardTables,
  selectPeriodId,
  selectTenantId,
} from '../../../../../components/core/filter-dashboard/filterDashboardSlice';
import ListCardWithDropdown from '../../../../../components/shared/cards/cardWithDropdown/models/ListCardWithDropdown';
import ListActionTableRow from '../../../../../components/shared/form/buttons/dropdown/models/ListActionTableRow';
import Caption from '../../../../../components/shared/headings/caption/Caption';
import Icon from '../../../../../components/shared/icon/Icon';
import { ElementList } from '../../../../../components/shared/listElement/models/ListElementProps';
import { httpBase } from '../../../../../utils/http';
import { usePermissions } from '../../../usePemissions';
import Boards from '../components/boards/Boards';
import toolbarEvents from '../components/toolbarEvents';
import { setToolBarIconFlussi } from '../DashboardSlice';
import LoadedFileModel from '../models/LoadedFileModel';
import ListFileExportReport from '../vds/components/ListFileExportReport';
import ModalInfoLoad from '../vds/components/ModalInfoLoad';
import LoadReportFile from './loadReportFile/LoadReportFile';
import {
  actionCovip,
  actionCovipStream,
  actionEmpty,
  actionTable,
  actionTableStream,
  createSegnalazioniList,
  toolBarIconDownload,
  toolBarIconGenerator,
  toolBarIconXML,
} from './reportData';
import {
  downloadFileZip,
  exportCovipAction,
  exportCovipStreamAction,
  exportEmptyAction,
  exportTableAction,
  exportTableStreamAction,
  generateAuthorityFile,
  getGeneratedFilesList,
  selectGeneratedFilesList,
} from './ReportSlice';
import ReportTable from './ReportTable';

const ReportsDashboard = ({
  loadedFile,
}: {
  loadedFile: LoadedFileModel[];
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const periodId = useSelector(selectPeriodId);
  const assetId = useSelector(selectAssetId);
  const tenantId = useSelector(selectTenantId);
  const dashboardTables = useSelector(selectDashboardTables);
  const generatedFilesList = useSelector(selectGeneratedFilesList);
  const { issetPermission } = usePermissions();
  const hasPermission = issetPermission([permissionType.VIEW_ALERTS_TABLE]);
  const viewAuthorityPermission = issetPermission([
    permissionType.VIEW_AUTHORITY,
  ]);
  const [authorityView, setAuthorityView] = useState<boolean>(hasPermission);
  const [listBoardsSegnalazioniFilter, setListBoardsSegnalazioniFilter] =
    useState<ListCardWithDropdown[]>();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showGeneratedFilesListModal, setShowGeneratedFilesListModal] =
    useState<boolean>(false);

  const [showDropZone, setShowDropZone] = useState(false);
  const [selectedType, setSelectedType] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    dispatch(setTitle(t('reports.title')));
    dispatch(loadDashboardTables());
    return () => {
      toolbarEvents.events = () => {};
      dispatch(setToolBarIconFlussi([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dashboardTables && loadedFile) {
      setListBoardsSegnalazioniFilter(
        createSegnalazioniList(
          dashboardTables,
          !(loadedFile && loadedFile.length)
        )
      );
    }
  }, [dashboardTables, loadedFile]);

  useEffect(() => {
    dispatch(
      setToolBarIconFlussi([
        {
          icon: 'file-text',
          name: 'List File',
          action: 'files',
          disabled: generatedFilesList.length === 0,
          visible: !authorityView || (authorityView && selectedType === 'DA'),
          info:
            generatedFilesList && generatedFilesList.length
              ? generatedFilesList.length.toString()
              : undefined,
        },
        ...(hasPermission || (authorityView && viewAuthorityPermission)
          ? selectedType === 'DA'
            ? toolBarIconGenerator
            : toolBarIconDownload
          : toolBarIconXML),
      ])
    );
    toolbarEvents.events = actionTab;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPermission, authorityView, selectedType, generatedFilesList]);

  useEffect(() => {
    dispatch(getGeneratedFilesList(authorityView));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorityView]);

  const showDropzoneAction = () => {
    dispatch(resetErrors());
    setShowDropZone(true);
  };

  const closeDropzone = () => setShowDropZone(false);

  function handlerAction(
    action: ListActionTableRow | string,
    card: ListCardWithDropdown
  ) {
    if (typeof action === 'string') {
      handlerActionCard(action, card);
      return;
    }
    action.action === actionTable &&
      dispatch(
        exportTableAction(
          action.tableCode!,
          card.surveyType,
          card.surveySubType
        )
      );
    action.action === actionCovip &&
      dispatch(exportCovipAction(action.surveyType!));
    action.action === actionEmpty &&
      dispatch(exportEmptyAction(action.surveyType!));

    if (action.action === actionTableStream) {
      dispatch(
        exportTableStreamAction(action.tableCode!, () => setShowModal(true))
      );
    }
    if (action.action === actionCovipStream) {
      dispatch(
        exportCovipStreamAction(action.surveyType!, () => setShowModal(true))
      );
    }
  }
  function handlerActionCard(action: string, card: ListCardWithDropdown) {}

  const downloadAction = () => {
    const assetQuery = assetId ? '/' + assetId : '';
    httpBase
      .get(
        `survey/authority/download/${selectedType}/${periodId}${assetQuery}`,
        {
          ...(tenantId && { headers: { 'X-TenantID': tenantId } }),
          responseType: 'blob',
        }
      )
      .then(res => {
        const blob = new Blob([res.data], {
          type: res.headers['content-type'],
        });
        saveAs(blob, `tavola_1-${periodId}.zip`);
      });
  };

  const actionTab = (action: string) => {
    action === 'aggregate' && showDropzoneAction();
    action === 'download' && downloadAction();
    action === 'files' && setShowGeneratedFilesListModal(true);
    action === 'generate' && generateAuthorityFiles();
  };

  const generateAuthorityFiles = () => {
    selectedType &&
      dispatch(generateAuthorityFile(selectedType, () => setShowModal(true)));
  };

  const closeAction = () => {
    setShowModal(false);
  };

  function handleDownloadZip(file: ElementList) {
    dispatch(downloadFileZip(authorityView, file.name));
    setShowGeneratedFilesListModal(false);
  }

  return (
    <>
      <ModalInfoLoad
        showModal={showModal}
        closeAction={closeAction}
      ></ModalInfoLoad>
      {showGeneratedFilesListModal && (
        <ListFileExportReport
          action={handleDownloadZip}
          closeAction={() => setShowGeneratedFilesListModal(false)}
          list={generatedFilesList.map(
            f => ({ ico: 'file-text', name: f } as ElementList)
          )}
        />
      )}
      {viewAuthorityPermission && (
        <div className="d-flex justify-content-end align-items-center mb-3">
          <Caption
            className="link medium lg pointer"
            onClick={() => setAuthorityView(!authorityView)}
          >
            {authorityView
              ? 'Visualizza come amministratore'
              : 'Visualizza come ente segnalante'}
          </Caption>
          <Icon icon="user" color="default" size="x18"></Icon>
        </div>
      )}
      {showDropZone && <LoadReportFile closeAction={closeDropzone} />}
      {hasPermission || (authorityView && viewAuthorityPermission) ? (
        <ReportTable
          selectedType={selectedType}
          changeType={value => setSelectedType(value)}
          assetId={assetId}
          periodId={periodId}
        ></ReportTable>
      ) : (
        <>
          {listBoardsSegnalazioniFilter && (
            <Boards
              list={listBoardsSegnalazioniFilter}
              clickAction={handlerAction}
            ></Boards>
          )}
        </>
      )}
    </>
  );
};

export default ReportsDashboard;
