import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import loginReducer from '../features/public/login/loginSlice';
import changePwdReducer from '../features/public/change-password/changePasswordSlice';
import usersReducer from '../features/auth/users/list/usersSlice';
import userCreateReducer from '../features/auth/users/userCreateSlice';
import assetCreateReducer from '../features/auth/asset/asset-create/assetCreateSlice';
import userEditReducer from '../features/auth/users/user-edit/userEditSlice';
import authReducer from './auth/authSlice';
import loadReducer from '../components/shared/loader/redux/loadSlice';
import errorsReducer from './errors/errorSlice';
import pageReducer from './page/pageSlice';
import filterDashboardReducer from '../components/core/filter-dashboard/filterDashboardSlice';
import dashboardReducer from '../features/auth/home/dashboard/DashboardSlice';
import errorDashboardReducer from '../features/auth/home/dashboard/errors/errorDashboardSlice';
import table1Reducer from '../features/auth/tables/table1/table1Slice';
import reportReducer from '../features/auth/home/dashboard/reports/ReportSlice';
import vdsReducer from '../features/auth/home/dashboard/vds/vdsSlice';
import databaseReducer from '../features/auth/database/DatabaseSlice';
import vdsTableReducer from '../features/auth/home/dashboard/tablevds/tableVdsSlice';
import assetEPReducer from '../features/auth/asset-ep/AssetEPReducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    changePwd: changePwdReducer,
    userCreate: userCreateReducer,
    assetCreate: assetCreateReducer,
    userEdit: userEditReducer,
    users: usersReducer,
    login: loginReducer,
    errors: errorsReducer,
    page: pageReducer,
    dashboard: dashboardReducer,
    filterDashboard: filterDashboardReducer,
    errorDashboard: errorDashboardReducer,
    load: loadReducer,
    table1: table1Reducer,
    report: reportReducer,
    vds: vdsReducer,
    vdsTable: vdsTableReducer,
    database: databaseReducer,
    assetEP: assetEPReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
