import React, { useEffect, useState } from 'react';
import './table1.scss';
import { ListElementCustomProps } from './models/ListElementProps';
import Caption from '../headings/caption/Caption';
import PaginationList from './paginationList/PaginationList';

const ListElementCustom = ({
  list = [],
  error = [],
  property,
  action,
  templateLeft,
  templateRight,
}: ListElementCustomProps) => {
  const clickHandler = (event: React.MouseEvent, ele: any) => {
    event.preventDefault();
    action && action(ele);
  };

  const [page, setPage] = useState<number>(1);
  const [elementForPage] = useState<number>(8);
  const [filterDataPagination, setFilterDataPagination] =
    useState<{ [key: string]: any }[]>(list);

  useEffect(() => {
    goPage(1);
  }, [list]);

  useEffect(() => {
    setFilterDataPagination(
      list.filter(
        (ele, index) =>
          index >= (page - 1) * elementForPage && index < page * elementForPage
      )
    );
  }, [list, elementForPage, page]);

  const goPage = (number: number) => {
    setPage(number);
  };
  return (
    <div>
      <ul className="load-list mt-3">
        {filterDataPagination.map((ele, index) => (
          <li
            key={index}
            className={`d-flex mb-2 py-2 justify-content-between ${
              action && 'pointer'
            }`}
            onClick={(event: React.MouseEvent) => clickHandler(event, ele)}
          >
            <div className="d-flex">
              {templateLeft && templateLeft(ele)}
              <Caption
                className={`link light ${
                  error.includes(ele.name) ? 'error' : ''
                }`}
              >
                {ele[property] || ''}
              </Caption>
            </div>
            {templateRight && templateRight(ele)}
          </li>
        ))}
      </ul>
      {list.length > elementForPage && (
        <PaginationList
          elements={list.length}
          page={page}
          elementForPage={elementForPage}
          goPage={goPage}
        ></PaginationList>
      )}
    </div>
  );
};

export default ListElementCustom;
