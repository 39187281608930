import { FormElementModelNew } from './models/FormElementModel';
import { isObject } from '../objUtils';
import { isArray } from '../arrayUtils';
const divisor = '___';

const getData = (data: any, pre: string = ''): any[] => {
  return Object.keys(data).reduce((arr: any[], ele: string) => {
    arr = [
      ...arr,
      ...data[ele].reduce((arrInt: any[], el: any) => {
        return el.typeElement && el.name
          ? [
              ...arrInt,
              { ...el, name: getPreString(pre) + ele + divisor + el.name },
            ]
          : [...arrInt, ...getData(el, getPreString(pre) + ele)];
      }, []),
    ];
    return arr;
  }, []);
};

const getPreString = (pre: string = '') => {
  return pre ? pre + divisor : '';
};

export const createStructureForm: (data?: any[]) => FormElementModelNew[] = (
  data = []
) => {
  return data.reduce((arr, ele) => {
    if (ele.typeElement && ele.name) arr = [...arr, ele];
    else arr = [...arr, ...getData(ele)];

    return arr;
  }, []);
};

const validationListToObj = (validations: any[]) => {
  return validations.reduce((objVal: any, singleVal: any) => {
    objVal[singleVal.name] = singleVal;
    return objVal;
  }, {});
};

export const createValidationsForm = (
  validations: any[] = []
): { [key: string]: any } => {
  return validations.reduce((obj, validation) => {
    return { ...obj, ...getObjectValidation(validation) };
  }, {});
};
export const getNameField = (data: string) => {
  return data.replace(/\./g, divisor);
};
export const getObjectValidation = (
  validation: any,
  baseString: string = ''
) => {
  let obj: { [key: string]: any } = {};
  if (validation.name && validation.validations) {
    obj[(baseString ? baseString + divisor : '') + validation.name] =
      validationListToObj(validation.validations);
  } else if (isArray(validation)) {
    validation.forEach((v: any) => {
      obj = { ...obj, ...getObjectValidation(v, baseString) };
    });
  } else {
    Object.keys(validation).forEach(singleInt => {
      validation[singleInt].forEach((val: any) => {
        if (val.name) {
          obj[baseString + singleInt + divisor + val.name] =
            validationListToObj(val.validations);
        } else {
          Object.keys(val).forEach((singleVal: any) => {
            obj = {
              ...obj,
              ...getObjectValidation(
                val[singleVal],
                (baseString ? baseString + divisor : '') +
                  singleInt +
                  divisor +
                  singleVal
              ),
            };
          });
        }
      });
    });
  }
  return obj;
};

const getDataObject = (object: any, pre: string[] = []) => {
  return Object.keys(object).reduce((newObj: any, ele) => {
    if (isObject(object[ele])) {
      newObj = { ...newObj, ...getDataObject(object[ele], [...pre, ele]) };
    } else {
      newObj[[...pre, ele].join(divisor)] = object[ele];
    }
    return newObj;
  }, {});
};

export const setObjForm = (obj: { [key: string]: any }) => {
  return Object.keys(obj).reduce((o: { [key: string]: any }, ele) => {
    if (isObject(obj[ele])) {
      o = { ...o, ...getDataObject(obj[ele], [ele]) };
    } else {
      o[ele] = obj[ele];
    }
    return o;
  }, {});
};

export const getObjForm = (objData: { [key: string]: any }) => {
  return Object.keys(objData).reduce((obj: any, key: string) => {
    if (key.indexOf('_') !== -1) {
      let arr = key.split(divisor);
      let string = 'obj';
      arr.forEach(ele => {
        string = string + '.' + ele;
        if (!eval(string)) {
          eval(string + ' = {}');
        }
      });
      eval('obj.' + arr.join('.') + '=objData[key]');
    } else {
      obj[key] = objData[key];
    }
    return obj;
  }, {});
};

export const decriptStructureForm = () => {};
