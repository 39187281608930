import React, { useEffect, useState } from 'react';
import Table from '../../../../../components/shared/table/table/Table';
import http from '../../../../../utils/http';
import { tableStructureSegnalazioni } from './reportData';
import Select from '../../../../../components/shared/form/select/Select';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectTenantId } from '../../../../../components/core/filter-dashboard/filterDashboardSlice';

const ReportTable = ({
  assetId,
  periodId,
  selectedType,
  changeType,
}: {
  assetId?: number;
  periodId?: number;
  selectedType?: string;
  changeType: (value: string) => void;
}) => {
  const [dataTable, setDataTable] = useState([]);
  const [options, setOptions] = useState([]);
  const tenant = useSelector(selectTenantId);
  console.log(selectedType);
  useEffect(() => {
    if (periodId && selectedType) {
      const assetQuery = assetId ? '/' + assetId : '';
      http
        .get(`survey/authority/${selectedType}/${periodId}${assetQuery}`, {
          headers: { 'X-TenantID': tenant },
        })
        .then(res => {
          if (res) {
            const data = res.surveys.map((ele: any) => ({
              ...ele,
              timeProduction:
                new Date(ele.timeProduction).toLocaleDateString() || '-',
              assetTypeCode: ele.assetTypeCode || '-',
            }));
            setDataTable(data);
          }
        });
    }
  }, [periodId, assetId, selectedType]);
  useEffect(() => {
    http
      .get('survey/type', {
        headers: { 'X-TenantID': tenant },
      })
      .then(r => {
        setOptions(
          r.map((el: any) => ({ value: el.id, label: el.description }))
        );
        changeType(r[0].id);
      });
  }, []);
  const changeValue = (data: any) => {
    changeType(data);
  };

  return (
    <>
      <Row>
        <Col xs={12} md={4}>
          <Select
            name="report"
            options={options}
            value={selectedType}
            changeSelect={changeValue}
          />
        </Col>
      </Row>
      <Table columns={tableStructureSegnalazioni} data={dataTable} />
    </>
  );
};

export default ReportTable;
