import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setTitle } from '../../../../app/page/pageSlice';
import { setDisabledAll } from '../../../../components/core/filter-dashboard/filterDashboardSlice';

import AssetEPForm from '../AssetEPForm';
import { getListCompany, reset } from '../AssetEPReducer';

const AssetEPCreate = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reset());
    dispatch(setTitle(t('Inserisci fondo ente previdenziale')));
    dispatch(getListCompany(2));
    dispatch(setDisabledAll(true));
    return () => {
      dispatch(setDisabledAll(false));
      dispatch(reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AssetEPForm />;
};

export default AssetEPCreate;
