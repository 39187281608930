import { store } from '../../app/store';
import { addError } from '../../app/errors/errorSlice';

export default (err: any) => {
  const dispatch = store.dispatch;
  if (err && err.response && err.response.data && err.response.data.code) {
    dispatch(addError(err.response.data.code));
  }
  return Promise.reject(err);
};
