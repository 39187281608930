const RolePermissions: { [key: string]: string[] } = {
  ROLE_ADMIN: ['listUsers', 'filterForAssets'],
  ROLE_AUTHORITY_USER: [],
  ROLE_SUPER_USER: ['listUsers', 'filterForAssets', 'editAssets'],
  ROLE_USER: ['filterForAssets'],
  ROLE_USER_FULL_ACCESS: ['filterForAssets'],
  ROLE_USER_READ: ['filterForAssets'],
};

export const permissionType = {
  MANAGEMENT_ASSETS: 'MANAGEMENT_ASSETS',
  VIEW_FLOWS: 'VIEW_FLOWS',
  MANAGEMENT_TABLES: 'MANAGEMENT_TABLES',
  AGGREGATION_ALERTS: 'AGGREGATION_ALERTS',
  MERGE_ALERTS: 'MERGE_ALERTS',
  MANAGEMENT_USERS: 'MANAGEMENT_USERS',
  MANAGEMENT_PERIODS: 'MANAGEMENT_PERIODS',
  EDIT_FLOWS: 'EDIT_FLOWS',
  MANAGEMENT_ALERTS: 'MANAGEMENT_ALERTS',
  MANAGEMENT_CONTROL_GRIDS: 'MANAGEMENT_CONTROL_GRIDS',
  MANAGEMENT_VERSIONING_DB: 'MANAGEMENT_VERSIONING_DB',
  MANAGEMENT_VDS: 'MANAGEMENT_VDS',
  VIEW_FUND_COMBO: 'VIEW_FUND_COMBO',
  VIEW_ALERTS_TABLE: 'VIEW_ALERTS_TABLE',
  VIEW_AUTHORITY: 'VIEW_AUTHORITY',
  MANAGEMENT_UNLOCK_USER: 'MANAGEMENT_UNLOCK_USER',
  DELETE_PERIODS: 'DELETE_PERIODS',
  MANAGEMENT_REPORT: 'MANAGEMENT_REPORT',
};

export const getPermissions = (role: string[]) =>
  role.reduce(
    (perms: string[], role: string) => [
      ...perms,
      ...(RolePermissions[role] ? RolePermissions[role] : []),
    ],
    []
  );
export const issetPermissions = (
  permissionsUser: string[] = [],
  permissions: string[] = [],
  or: boolean = false
) => {
  return or
    ? issetOnePermission(permissionsUser, permissions)
    : issetAllPermission(permissionsUser, permissions);
};

const issetAllPermission = (
  permissionsUser: string[] = [],
  permissions: string[] = []
) => {
  return permissions.reduce(
    (control: boolean, perm) =>
      permissionsUser.includes(perm) ? control : false,
    true
  );
};

const issetOnePermission = (
  permissionsUser: string[] = [],
  permissions: string[] = []
) => {
  return permissions.reduce(
    (control: boolean, perm) =>
      permissionsUser.includes(perm) ? true : control,
    false
  );
};
