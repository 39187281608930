import React, { Children, useState } from 'react';
import Input from '../../form/input/Input';
import { mergeClass } from '../../functions/function';
import Icon from '../../icon/Icon';

interface SearchFilterInputProps {
  className?: string;
  changeInput?: () => void;
  isFilterVisible?: boolean;
}

const SearchFilterInput = ({
  className,
  changeInput,
  isFilterVisible = false,
}: SearchFilterInputProps) => {
  const changeHandler = () => {
    changeInput && changeInput();
  };

  return isFilterVisible ? (
    <div className={mergeClass('shared-searchFilterInput', className)}>
      <Input className="mt-2 sm" onChange={() => changeHandler()}></Input>
    </div>
  ) : null;
};
export default SearchFilterInput;
