import React from 'react';
import {
  ModalPropsBtn,
  ModalPropsStyle,
} from '../../../../../components/shared/modal/models/ModalProps';
import ModalMsg from '../../../../../components/shared/modal/ModalMsg';
import { useSelector } from 'react-redux';
import { selectDeleteModal } from '../usersSlice';

const DeleteModal = ({
  close,
  action,
}: {
  close: () => void;
  action: (action: string) => void;
}) => {
  const deleteModal = useSelector(selectDeleteModal);
  const btnSuccess: ModalPropsBtn[] = [
    {
      label: 'Annulla',
      action: 'closeAction',
      type: 'secondary',
      dataType: { size: 'sm' },
    },
    {
      label: 'Elimina',
      action: 'deleteAction',
      type: 'primary',
      dataType: { size: 'sm' },
    },
  ];
  const style: ModalPropsStyle = {
    style: { color: '#565B6F', bgColor: '#fff' },
    icon: { color: 'primary', ico: 'delete-profile' },
  };
  return (
    <ModalMsg
      className="shared-modalSuccess"
      btn={btnSuccess}
      text={{
        title: 'Eliminazione utente',
        body: `Sei sicuro di eliminare l'utente ${deleteModal?.firstName} ${deleteModal?.lastName}?`,
      }}
      modalStyle={style}
      showModal={!!deleteModal}
      closeAction={close}
      clickAction={action}
    ></ModalMsg>
  );
};

export default DeleteModal;
