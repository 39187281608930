import React from 'react';
import { mergeClass } from '../../../functions/function';
import { ButtonProps as BtnProps } from 'react-bootstrap/Button';
import Button from '../button/Button';

interface ButtonProps extends BtnProps {}
const SecondaryButton = ({
  children,
  className,
  variant = 'secondary',
  type = 'button',
  ...rest
}: ButtonProps) => {
  return (
    <Button
      variant={variant}
      type={type}
      className={mergeClass('sh-secondary', className)}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default SecondaryButton;
