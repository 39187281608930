import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLogicError,
  selectSelectedLogicalError,
  setSelectedLogicalError,
  resetSelectedLogicalError,
  selectErrorList,
} from '../errorDashboardSlice';
import LogicalErrorDetail from './logicalError/LogicalErrorDetail';
import LogicalTableError from './logicalError/LogicalTableError';
import ListHeadingErrors from '../components/ListHeadingErrors';
import { useErrorDetailDashboard } from './useErrorDetailDashboard';
import { useTranslation } from 'react-i18next';

const LogicalError = ({
  id,
  vds,
  errors,
  autoSelectTab,
}: {
  id: number;
  vds: string;
  errors: any;
  autoSelectTab: (data: string) => void;
}) => {
  const { t } = useTranslation();

  const errorList = useSelector(selectErrorList);

  const selectedError = useSelector(selectSelectedLogicalError);
  const { headingStructure } = useErrorDetailDashboard(t);
  const errorVds: any = errorList.find(ele => ele.vds === vds);

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetSelectedLogicalError());
    };
  }, []);
  return (
    <>
      {selectedError ? (
        <LogicalErrorDetail
          autoSelectTab={autoSelectTab}
          id={id}
          vds={vds}
          selectedError={selectedError}
        />
      ) : (
        <>
          <ListHeadingErrors
            list={headingStructure}
            data={errorVds}
          ></ListHeadingErrors>
          <LogicalTableError
            objProps={errors}
            setSelectedLogicalError={data =>
              dispatch(setSelectedLogicalError(data))
            }
            changeObjProps={(obj: any) => dispatch(getLogicError(id, obj))}
          />
        </>
      )}
    </>
  );
};

export default LogicalError;
