import React from 'react';
import { TableProps } from '../../../models/TableProps';
import TableDataEmpty from './components/TableDataEmpty';
import TableRow from './components/TableRow';

const TableBody = ({
  columns,
  data = [],
  templates = {},
  templateRow,
  rowIndexActive = [],
  rowIndexTemplate = [],
  clickRow,
  labelEmpty,
  disabledRow,
}: TableProps) => {
  return (
    <tbody>
      {data && data.length ? (
        data.map((row, index) => {
          return (
            <TableRow
              disabledRow={disabledRow}
              key={index}
              row={row}
              indexRow={index}
              clickRow={clickRow}
              columns={columns}
              data={data}
              templateRow={templateRow}
              rowIndexActive={rowIndexActive}
              rowIndexTemplate={rowIndexTemplate}
              templates={templates}
            />
          );
        })
      ) : (
        <TableDataEmpty
          labelEmpty={labelEmpty}
          colWidth={columns.length}
        ></TableDataEmpty>
      )}
    </tbody>
  );
};
export default TableBody;
