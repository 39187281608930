import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setTitle } from '../../../../app/page/pageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { useForm } from '../../../../utils/forms/useForm';
import { usePeriodCreate } from './usePeriodCreate';
import ButtonList from '../../../../components/shared/form/buttons/button-list/ButtonList';
import FormElements from '../../../../utils/forms/FormElements';
import { savePeriod } from '../services/periods.service';
import { selectUser } from '../../../../app/auth/authSlice';
import { useRoute } from '../../../../utils/useRoute';
import { UseFormSubmitModel } from '../../../../utils/forms/models/UseFormModel';
import {
  loadLoadedFileCall,
  selectAssetId,
  addPeriod,
  setPeriodId,
  setAssetId,
  setDisabledAll,
} from '../../../../components/core/filter-dashboard/filterDashboardSlice';
import {
  addError,
  addSuccessMessage,
  resetErrors,
} from '../../../../app/errors/errorSlice';
import client from '../../../../app/clientConnect';
import { store } from '../../../../app/store';
import {
  incrementN,
  decrementN,
} from '../../../../components/shared/loader/redux/loadSlice';
import { addSub, removeSub } from '../../../../app/clientSubscribe';
const PeriodCreate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const assetId = useSelector(selectAssetId);
  dispatch(setTitle(t('periods.create.title')));
  const user = useSelector(selectUser);
  const { changeRoute } = useRoute();

  useEffect(() => {
    dispatch(setDisabledAll(true));
    dispatch(resetErrors());
    return () => {
      dispatch(setDisabledAll(false));
    };
  }, []);

  const submitHandler: UseFormSubmitModel = (event, data) => {
    savePeriod(data, user?.id, user?.roles[0]).then(
      res => {
        dispatch(incrementN());
        // const callback = () => {
        //   let periodSubscribe: any;
        //   periodSubscribe = client.subscribe(`/id/${user?.id}/db`, (res: any) => {
        //     const body = res && res.body ? JSON.parse(res.body) : {};
        //     removeSub(periodSubscribe);
        //     if (body.status === "ok") {
        //       const id = data.year + `0${data.month}`.slice(-2) + "01";
        //       dispatch(
        //           addPeriod({
        //             year: data.year,
        //             month: data.month,
        //             id,
        //           })
        //       );
        //       dispatch(setAssetId(data.assetId));
        //       dispatch(setPeriodId(id));
        //       changeRoute("home");
        //       dispatch(addSuccessMessage("CREATE_PERIOD"));
        //     } else {
        //       dispatch(addError("CREATE_PERIOD_ERROR"));
        //     }
        //     store.dispatch(decrementN());
        //   });
        // }
        let periodSubscribe: any;
        periodSubscribe = addSub(`/id/${user?.id}/db`, (res: any) => {
          const body = res && res.body ? JSON.parse(res.body) : {};
          removeSub(periodSubscribe);
          if (body.status === 'ok') {
            const id = parseInt(data.year + `0${data.month}`.slice(-2) + '01');
            dispatch(
              addPeriod({
                year: data.year,
                month: data.month,
                id,
              })
            );
            dispatch(setAssetId(data.assetId));
            dispatch(setPeriodId(id));
            changeRoute('home');
            dispatch(addSuccessMessage('CREATE_PERIOD'));
          } else {
            dispatch(addError('CREATE_PERIOD_ERROR'));
          }
          store.dispatch(decrementN());
        });
      },
      err => {
        dispatch(addError('CREATE_PERIOD_ERROR'));
      }
    );
  };
  const { validations, data, buttons } = usePeriodCreate(t, user);
  const { isSubmit, errors, submitAction, changeValue, dataForm } = useForm(
    submitHandler,
    validations,
    { assetId }
  );
  return (
    <div className="px-5">
      <Form className="2" onSubmit={submitAction}>
        <FormElements
          data={data}
          errors={errors}
          isSubmit={isSubmit}
          dataForm={dataForm}
          changeValue={changeValue}
        />
        <ButtonList buttons={buttons} />
      </Form>
    </div>
  );
};

export default PeriodCreate;
