import React, { useMemo, useState } from 'react';
import { useForm } from '../../../../../../../utils/forms/useForm';
import {
  ChangeValueEndType,
  UseFormSubmitModel,
} from '../../../../../../../utils/forms/models/UseFormModel';
import { FormElementModelNew } from '../../../../../../../utils/forms/models/FormElementModel';
import { Form } from 'react-bootstrap';
import PrimaryButton from '../../../../../../../components/shared/form/buttons/primary-button/PrimaryButton';
import FormElements from '../../../../../../../utils/forms/FormElements';
import {
  FactModel,
  flowNextStepModel,
  TableFactModel,
} from '../../../models/FlowNextStepModel';

const FormStep6Flow = ({
  selectedElement,
  action,
  backAction,
}: {
  selectedElement: flowNextStepModel;
  action: (data: any) => void;
  backAction: () => void;
}) => {
  const categories = useMemo(() => {
    return (
      selectedElement?.tables?.map(ele => ({
        value: ele.tableType + '-' + ele.tableSubType,
        label:
          ele.tableTypeLabel +
          (ele.tableSubTypeLabel !== ele.tableTypeLabel
            ? ' ' + ele.tableSubTypeLabel
            : ''),
      })) || []
    );
  }, [selectedElement]);

  const [tables, setTables] = useState<FactModel[]>([]);
  const validations = {
    category: {
      required: {
        params: { name: 'categoria' },
      },
    },
    tables: {
      required: {
        params: { name: 'tabella' },
      },
    },
  };
  const data: FormElementModelNew[] = [
    {
      typeElement: 'select',
      label: 'Categoria',
      name: 'category',
      col: { md: 12 },
      options: [{ label: 'Seleziona Categoria', value: '-1' }, ...categories],
      dataElement: {},
    },
    {
      typeElement: 'select',
      label: 'Tabelle',
      name: 'tables',
      col: { md: 12 },
      options: tables.map(ele => ({
        value: ele.id.toString(),
        label: ele.name,
      })),
      dataElement: {
        multiply: true,
      },
    },
  ];
  const submitHandler: UseFormSubmitModel = (event, data) => {
    const element = data?.tables?.map(
      (ele: string) => tables?.find(t => t.id === +ele)?.stored2Load
    );
    const req = {
      store2LoadList: element,
    };
    action && action(req);
  };

  const changeValueEnd: ChangeValueEndType = (data, lastChange) => {
    if (lastChange.newValue && lastChange.newValue.category) {
      const d: string[] = lastChange.newValue.category.split('-');
      const tableFact: TableFactModel | undefined =
        selectedElement?.tables?.find(
          ele => ele.tableType === +d[0] && ele.tableSubType === +d[1]
        );
      if (tableFact) {
        setTables(tableFact.facts);
      } else {
        setTables([]);
      }
    }
  };

  const { isSubmit, errors, submitAction, changeValue, dataForm } = useForm(
    submitHandler,
    validations,
    {},
    { changeValueEnd }
  );
  return (
    <div className="px-5">
      <Form className="pt-2" onSubmit={submitAction}>
        <FormElements
          data={data}
          errors={errors}
          isSubmit={isSubmit}
          dataForm={dataForm}
          changeValue={changeValue}
        />
        <PrimaryButton>Run</PrimaryButton>
      </Form>
    </div>
  );
};

export default FormStep6Flow;
