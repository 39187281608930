import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {
  addSuccessMessage,
  resetErrors as resetBeErrors,
} from '../../../app/errors/errorSlice';
import { changePasswordCall } from './change-password.service';

const initialState: {
  token?: string;
  isSubmit: boolean;
  isValid: boolean;
  errors: {
    [key: string]: { code: string; params: { [key: string]: string } };
  } | null;
} = {
  isSubmit: false,
  isValid: false,
  errors: null,
  token: undefined,
};

export const changePasswordSlice = createSlice({
  name: 'change-password',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});
export const { setToken } = changePasswordSlice.actions;

export const changePassword =
  (data: any, changeRoute: (name: string) => void): AppThunk =>
  (dispatch, getState) => {
    const resetToken = getState().changePwd.token;
    changePasswordCall(data.newPassword, resetToken).then(res => {
      dispatch(addSuccessMessage('CHANGE_PWD_SUCCESS'));
      changeRoute('login');
    });
  };

export const resetForm = (): AppThunk => dispatch => {
  dispatch(resetBeErrors());
};

export const selectError = (state: RootState) => state.changePwd.errors;

export default changePasswordSlice.reducer;
