import React, { useEffect } from 'react';
import { getParameterQuery } from '../../../utils/location';
import { useDispatch } from 'react-redux';
import { logoutAction } from '../../../app/auth/authSlice';
import { setRedirectToken } from '../../public/login/loginSlice';

const CheckSubscription = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const tokenData = getParameterQuery('token');
    tokenData && dispatch(setRedirectToken(tokenData));
    dispatch(logoutAction());
  }, []);

  return <h1>Caricamento in corso</h1>;
};

export default CheckSubscription;
