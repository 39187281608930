import { useEffect, useMemo, useState } from 'react';
import { FormElementModelNew } from '../../../utils/forms/models/FormElementModel';
import { getAssetsList } from '../users/services/users.service';
import { TFunction } from 'i18next';
import { useForm } from '../../../utils/forms/useForm';
import { createStructureForm } from '../../../utils/forms/useStructureForm';
import { UseFormSubmitModel } from '../../../utils/forms/models/UseFormModel';

export type UseDatebaseSearchFormProps = {
  t: TFunction;
  submitHandler: UseFormSubmitModel;
  init?: any;
};

export function useDatebaseSearchForm(props: UseDatebaseSearchFormProps) {
  const { t, submitHandler, init } = props;
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );

  useEffect(() => {
    getAssetsList().then(res => {
      setOptions(() =>
        res
          .sort((a, b) => (a.shortDescription > b.shortDescription ? 1 : -1))
          .map(ele => ({
            value: ele.shortDescription.split('-')[0].trim(),
            label: ele.shortDescription,
          }))
      );
    });
  }, []);

  const form = useForm(submitHandler, undefined, init);

  const dataStructure: FormElementModelNew[] = useMemo(
    () =>
      createStructureForm([
        {
          typeElement: 'select',
          label: t('database.fundCode'),
          name: 'fundCode',
          col: { md: 6 },
          options: [{ label: 'TUTTI I FONDI', value: '-1' }, ...options],
        },
      ]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  return {
    ...form,
    dataStructure,
  };
}
