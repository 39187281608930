import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../app/store';
import { deleteQuery } from '../service/table.service';
import { loadQuery, showModalType } from './models/LoadQuery';

const initialState: {
  listLoadQuery: loadQuery[];
  showModal?: showModalType;
  selectedTable?: string;
} = {
  listLoadQuery: [],
};

export const table1Slice = createSlice({
  name: 'table1',
  initialState,
  reducers: {
    setSelectedTable: (state, action: PayloadAction<string>) => {
      state.selectedTable = action.payload;
    },
    setListLoadQuery: (state, action: PayloadAction<loadQuery[]>) => {
      state.listLoadQuery = action.payload.map(ele => ({
        ...ele,
        label: ele.queryName,
      }));
    },
    setDeleteLoadQuery: (state, action: PayloadAction<number>) => {
      deleteQuery(action.payload);
      state.listLoadQuery = state.listLoadQuery.filter(
        el => el.id !== action.payload
      );
    },
    setShowModal: (state, action: PayloadAction<showModalType>) => {
      state.showModal = action.payload;
    },
    resetShowModal: state => {
      state.showModal = undefined;
    },
    resetSelectedTable: state => {
      state.selectedTable = undefined;
    },
  },
});

export const {
  setListLoadQuery,
  setDeleteLoadQuery,
  setShowModal,
  resetShowModal,
  setSelectedTable,
  resetSelectedTable,
} = table1Slice.actions;

export const selectListLoadQuery = (state: RootState) =>
  state.table1.listLoadQuery;
export const selectShowModalQuery = (state: RootState) =>
  state.table1.showModal;
export const selectSelectedTable = (state: RootState) =>
  state.table1.selectedTable;

export default table1Slice.reducer;
