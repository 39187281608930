import React from 'react';
import { Pagination as Pag } from 'react-bootstrap';
import { Pagination as PaginationModel } from '../../../models/TableProps';
import { usePagination } from './usePagination';

const Pagination = ({ page = 1, goPage, pages = 1 }: PaginationModel) => {
  const { items } = usePagination(pages);
  return (
    <div className="d-flex justify-content-end">
      <Pag size="sm">
        <Pag.First
          className="firstPage"
          disabled={page === 1}
          onClick={() => goPage && goPage(1)}
        />
        <Pag.Prev
          className="prevPage"
          disabled={page === 1}
          onClick={() => goPage && goPage(page - 1)}
        />
        {page > 5 ? <Pag.Ellipsis /> : null}
        {items.map(number =>
          (number <= page && page - number < 5) ||
          (number > page && number - page < 5) ? (
            <Pag.Item
              key={number}
              onClick={() => goPage && goPage(number)}
              active={number === page}
            >
              {number}
            </Pag.Item>
          ) : null
        )}
        {items.length - page >= 5 ? <Pag.Ellipsis /> : null}
        <Pag.Next
          className="nextPage"
          disabled={page === pages}
          onClick={() => goPage && goPage(page + 1)}
        />
        <Pag.Last
          className="lastPage"
          disabled={page === pages}
          onClick={() => goPage && goPage(items.length)}
        />
      </Pag>
    </div>
  );
};

export default Pagination;
