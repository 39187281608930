import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoaderV2 from '../shared/loader/loaderV2/LoaderV2';
import { Routes } from './models/routes';

interface DynamicRouteProps {
  routes: Routes;
}
const DynamicRoute = ({ routes }: DynamicRouteProps) => {
  return (
    <React.Suspense fallback={<LoaderV2 loading={true} />}>
      <Switch>
        {routes.list.map(ele => {
          const route =
            typeof ele.component === 'string'
              ? lazy(() => import('../../' + ele.component))
              : ele.component;
          return (
            <Route
              key={ele.path}
              exact
              path={ele.path}
              component={route}
            ></Route>
          );
        })}
        {routes.redirect ? <Redirect from="*" to={routes.redirect} /> : null}
      </Switch>
    </React.Suspense>
  );
};

export default DynamicRoute;
