import { Client } from '@stomp/stompjs';
import { restartSubscribe } from './clientSubscribe';

const client = new Client({
  brokerURL: `${window.location.protocol == 'https:' ? 'wss:' : 'ws:'}//${
    window.location.hostname
  }${process.env.REACT_APP_URL_QUEUE}`,
  heartbeatIncoming: 0,
  heartbeatOutgoing: 60000,
  logRawCommunication: true,
});
client.onConnect = () => {
  console.log('CONNECTED');
  restartSubscribe();
};
client.debug = test => {
  console.log(test);
};
client.onStompError = frame => {
  console.log('onStompError: ' + frame);
};
console.log('clientConnect pass');
client.activate();

export default client;
