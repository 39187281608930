import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIndexRow,
  selectIndexSelected,
  selectIsSelectedAllRow,
  selectLoadedFile,
  deselectAllIndexSelected,
  selectflowNextStep,
  selectToolbarIcon,
} from './DashboardSlice';
import SwitchTable from './SwitchTable';
import TabBtnDashboard from './components/TabBtnDashboard';
import { useDashboardBase } from './useDashboardBase';
import { usePermissions } from '../../usePemissions';
import { permissionType } from '../../../../app/rolePermissions';
import {
  selectAssetId,
  selectPeriodId,
  selectTenantId,
} from '../../../../components/core/filter-dashboard/filterDashboardSlice';
import { listLegend, tabsDataType } from './dashboardData';
import EmptyDashboard from './components/EmptyDashboard';
import { resetErrors } from '../../../../app/errors/errorSlice';
import vdsEvent from './vds/vdsEvent';
import { setSelectedVdsWithError } from './errors/errorDashboardSlice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const toolbarIcon = useSelector(selectToolbarIcon);
  const assetId = useSelector(selectAssetId);
  const periodId = useSelector(selectPeriodId);
  const loadedFile = useSelector(selectLoadedFile);
  const indexRow = useSelector(selectIndexRow);
  const indexSelected = useSelector(selectIndexSelected);
  const tenantId = useSelector(selectTenantId);
  const isSelectedAllRow = useSelector(selectIsSelectedAllRow);
  const flowNextStep = useSelector(selectflowNextStep);
  const { issetPermission } = usePermissions();
  const isPermissionFundCombo = issetPermission([
    permissionType.VIEW_FUND_COMBO,
  ]);
  useEffect(() => {
    dispatch(deselectAllIndexSelected());
  }, [assetId, periodId]);
  const { tabsData } = useDashboardBase(loadedFile && !!loadedFile.length);
  const [selectedTab, setSelectedTab] = useState(tabsData[0].name);
  const selectedTabHandler = useCallback((tabName: string) => {
    setSelectedTab(tabName);
    dispatch(resetErrors());
    if (vdsEvent.events.backVds) delete vdsEvent.events.backVds;
  }, []);

  const autoSelectTab = (tabName: string, vds: string) => {
    setSelectedTab(tabName);
    vdsEvent.events.backVds = () => {
      setSelectedTab(tabsDataType.errors.name);
      dispatch(setSelectedVdsWithError(vds));
      delete vdsEvent.events.backVds;
    };
  };
  return (
    <>
      {(isPermissionFundCombo && assetId && periodId && tenantId) ||
      (!isPermissionFundCombo && periodId) ? (
        <div>
          <TabBtnDashboard
            selectedTab={selectedTab}
            setSelectedTab={selectedTabHandler}
            toolBarIconFlussi={toolbarIcon}
            tabsData={tabsData}
          />
          <SwitchTable
            autoSelectTab={autoSelectTab}
            assetId={assetId}
            periodId={periodId}
            isSelectedAllRow={isSelectedAllRow}
            flowNextStep={flowNextStep}
            indexSelected={indexSelected}
            loadedFile={loadedFile}
            listLegend={listLegend}
            indexRow={indexRow}
            switchTab={selectedTab}
          />
        </div>
      ) : (
        <EmptyDashboard
          isPermissionFundCombo={isPermissionFundCombo}
          issetAssetAndPeriod={!!assetId && !!periodId}
        />
      )}
    </>
  );
};

export default Dashboard;
