import { TFunction } from 'i18next';
import { TableData } from '../../../../../components/shared/table/models/TableProps';

export function useErrorDashboard(t: TFunction, isSelectedAllRow: boolean) {
  const tableStructure: TableData[] = [
    // {
    //   name: "selector",
    //   label: "",
    //   action: !isSelectedAllRow ? "selectAll" : "deselectAll",
    //   width: "1%",
    // },
    {
      name: 'vds',
      label: 'Vds',
    },
    {
      name: 'totalError',
      label: 'Errori',
    },
    {
      name: 'resolvedError',
      label: 'Evasi',
    },
    {
      name: 'unsolvedError',
      label: 'Rimanenti',
    },
    {
      name: 'inProgressError',
      label: 'Lavorati',
    },
  ];

  /*   const boxToolsList: BoxToolsModel[] = [
    {
      label: !isSelectedAllRow ? "Seleziona tutti" : "Deseleziona tutti",
      action: !isSelectedAllRow ? "selectAll" : "deselectAll",
    },
    { label: "Esporta", action: "export" },
  ];
 */
  return {
    tableStructure,
  };
}
