import http, { httpBase } from '../../../../utils/http';
const url = 'dashboard/table';
export const getTableDashboard = (tenantId: string) =>
  http.get(url, {
    headers: { 'X-TenantID': tenantId },
  });
export const downloadExportLogVds = (tenantId: string) =>
  httpBase.get(`${url}/export`, {
    headers: { 'X-TenantID': tenantId },
    responseType: 'blob',
  });
