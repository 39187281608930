import React, { useState } from 'react';
import Table from '../../../../../components/shared/table/table/Table';

import { useDispatch, useSelector } from 'react-redux';
import {
  setOpenRow,
  runStepAction,
  deleteFlowFile,
  setDeleteModal,
  closeDeleteModal,
  selectDeleteModal,
  selectDeleteFileList,
  deleteDatabase,
} from '../DashboardSlice';

import { useFlow } from './useFlow';
import Chronology from './chronology/Chronology';
import StateLabel from '../../../../../components/shared/form/stateLabel/StateLabel';
import { resetErrors } from '../../../../../app/errors/errorSlice';
import { FlowTableProps } from '../models/FlowProps';
import ListStepHeaderFlow from './header-flow/modal-step-flow/ListStepHeaderFlow';
import LoadedFileModel from '../models/LoadedFileModel';
import Loader from '../../../../../components/shared/loader/loader/Loader';
import DropdownAction from './dropdownAction/DropdownAction';
import DeleteModal from './components/DeleteModal';
import LoaderV2 from '../../../../../components/shared/loader/loaderV2/LoaderV2';

const FlowTable = ({
  isSelectedAllRow,
  indexSelected,
  loadedFile,
  indexRow,
}: FlowTableProps) => {
  const dispatch = useDispatch();
  const [selectedSteps, setSelectedSteps] = useState<LoadedFileModel>();
  const { tableStructureFlussi, actionsType } = useFlow();
  const templateRow = (row: any) => <Chronology id={row.id} />;
  const deleteModal = useSelector(selectDeleteModal);
  const deleteFileList = useSelector(selectDeleteFileList);

  // const addRemoveSelection = (id: number, index: number) => {
  //   dispatch(addRemoveSelectionAction(id, index));
  // };
  const openRow = (index: number) => {
    dispatch(setOpenRow(index));
  };

  const clickActionRow = (action: string, row: any, index: number) => {
    dispatch(resetErrors());
    actionsType.run === action && setSelectedSteps(row);
    actionsType.history === action && openRow(index);
    actionsType.delete === action && deleteFile(row);
  };

  const deleteFile = (row: any) => {
    dispatch(
      setDeleteModal({
        ...row,
        text: {
          title: 'Eliminazione file',
          body: `Sei sicuro di voler eliminare il file ${row?.nameZip}?`,
        },
        action: 'deleteAction',
      })
    );
  };

  const templates = {
    /*  selector: (value: string, row: any, index: number) => (
      <SelectorToggle
        check={indexSelected.includes(row.id)}
        clickAction={() => addRemoveSelection(row.id, index)}
      ></SelectorToggle>
    ), */
    ico: (value: string, row: any) => {
      return !row.endDate || deleteFileList.includes(row.id) ? (
        <LoaderV2 loading={true} fullscreen={false} size={30} />
      ) : (
        <StateLabel
          className="d-inline-flex "
          colorStyle={row.color}
        ></StateLabel>
      );
    },
    modify: (value: string, row: any, index: number) => {
      return (
        <DropdownAction
          actionsType={actionsType}
          clickActionRow={action => {
            clickActionRow(action, row, index);
          }}
          disabledRun={
            row.nextSteps.length === 0 ||
            row.nextSteps.find((ele: any) => ele.inLoading)
          }
        ></DropdownAction>
      );
    },
  };
  // const selecteDeselectAll = () => {
  //   isSelectedAllRow
  //     ? dispatch(deselectAllIndexSelected())
  //     : dispatch(selectAllIndexSelected());
  // };

  const headerTemplates = {
    /*  selector: () => {
      return (
        <SelectorToggle
          check={isSelectedAllRow}
          disabled={loadedFile.length === 0}
          clickAction={selecteDeselectAll}
        ></SelectorToggle>
      );
    }, */
  };
  const closeModal = () => {
    setSelectedSteps(undefined);
  };
  const startRun = (req: any = {}) => {
    req.fileLoadedId = selectedSteps?.id;
    dispatch(runStepAction(req, closeModal));
  };

  const func: { [key: string]: () => void } = {
    deleteAction: () =>
      dispatch(
        deleteFlowFile(deleteModal.loadedId, deleteModal.id, closeModalDelete)
      ),
    deleteDBAction: () => dispatch(deleteDatabase(closeModalDelete)),
    closeAction: () => dispatch(closeDeleteModal()),
  };

  const closeModalDelete = () => {
    dispatch(closeDeleteModal());
  };

  const deleteAction = (action: string) => {
    func && func[action] && func[action]();
  };

  return (
    <>
      <DeleteModal
        close={closeModalDelete}
        action={deleteAction}
        data={deleteModal}
      />
      {selectedSteps &&
        selectedSteps.nextSteps &&
        !!selectedSteps.nextSteps.length && (
          <ListStepHeaderFlow
            list={selectedSteps?.nextSteps || []}
            closeAction={closeModal}
            action={startRun}
          />
        )}
      <Table
        disabledRow={(row: any) => deleteFileList.includes(row.id)}
        columns={tableStructureFlussi}
        data={loadedFile}
        templates={templates}
        templateRow={templateRow}
        rowIndexActive={indexSelected}
        rowIndexTemplate={indexRow}
        templatesHeader={headerTemplates}
      />
    </>
  );
};

export default FlowTable;
