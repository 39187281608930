import React from 'react';
import Icon from '../Icon';
interface IconProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  icon: string;
  label?: string;
  disabled?: boolean;
}

const BigIconBtn = ({ icon, label, disabled, ...rest }: IconProps) => {
  return (
    <>
      <Icon
        className="shared-bigIconBtn"
        disabled={disabled}
        icon={icon}
        label={label}
        {...rest}
      ></Icon>
    </>
  );
};

export default BigIconBtn;
