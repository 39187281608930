import React from 'react';
import { useDispatch } from 'react-redux';
import { addSub, removeSub } from '../../../../../../app/clientSubscribe';
import {
  addError,
  addSuccessMessage,
  resetErrors,
} from '../../../../../../app/errors/errorSlice';
import { loadLoadedFileCall } from '../../../../../../components/core/filter-dashboard/filterDashboardSlice';
import Dropzone from '../../../../../../components/shared/dropzone/Dropzone';
import {
  decrementN,
  incrementN,
} from '../../../../../../components/shared/loader/redux/loadSlice';
import { uploadFileDashboard } from '../../../services/dashboard.service';

const LoadFlowFile = ({ closeAction }: { closeAction: () => void }) => {
  const dispatch = useDispatch();
  const onUpload = (files: any) => {
    dispatch(resetErrors());
    files.forEach((file: any) => {
      const nameFile = file.name;
      const patt = /^[a-zA-Z0-9]{14}_M[a-zA-Z0-9]{15}_[a-zA-Z0-9]{8}[.]zip$/;
      const patt2 =
        /^[a-zA-Z0-9]{14}[.]CVP[.][a-zA-Z0-9]{6}[.][a-zA-Z0-9]{5}[.]zip$/;
      if (patt.test(nameFile) || patt2.test(nameFile)) {
        let formData = new FormData();
        formData.append('file', file);
        uploadFileDashboard(formData).then(() => {
          dispatch(incrementN());
          let uploadSubscribe: any;
          uploadSubscribe = addSub(`/id/${nameFile}/file`, res => {
            dispatch(decrementN());
            const body = res && res.body ? JSON.parse(res.body) : {};
            removeSub(uploadSubscribe);
            closeAction();
            if (body && body.status === 'ok') {
              dispatch(addSuccessMessage('FILE_SUCCESS_LOAD'));
              dispatch(loadLoadedFileCall());
            } else {
              dispatch(addError('FILE_ERROR_LOAD'));
            }
          });
        });
      } else {
        dispatch(addError('FILE_NAME_ERROR'));
      }
    });
  };

  return (
    <Dropzone
      onClose={closeAction}
      onDrop={files => {
        onUpload(files);
      }}
      accept="application/zip,application/octet-stream,application/x-zip-compressed,multipart/x-zip,.zip"
    />
  );
};

export default LoadFlowFile;
