import { TFunction } from 'i18next';
import { FormElementModelNew } from '../../../../utils/forms/models/FormElementModel';
import { User } from '../../../../app/auth/model/User';
import { ButtonModel } from '../../../../components/shared/form/buttons/models/ButtonModel';
import { useHistory } from 'react-router-dom';
import { createStructureForm } from '../../../../utils/forms/useStructureForm';

import { CompanyModel } from '../../../../models/CompanyModel';
import { CovipCodeModel } from '../../../../models/CovipCodeModel';
import { configurationCheck } from '../AssetFormData';

export function useAssetCreate(
  t: TFunction,
  user: Partial<User> = {},
  companyList: CompanyModel[],
  foundCode: string,
  covipList: CovipCodeModel[],
  edit: boolean = false
) {
  const history = useHistory();
  const months = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ];
  const years = () => {
    const now = new Date().getUTCFullYear();
    return Array(now - (now - 10))
      .fill('')
      .map((v, idx) => now - idx);
  };
  const data: FormElementModelNew[] = createStructureForm([
    {
      asset: [
        {
          typeElement: 'select',
          label: t('users.create.company'),
          name: 'companyId',
          col: { sm: 4 },
          options: [
            { label: t('users.create.company_placeholder'), value: '-1' },
            ...companyList.map(el => ({
              label: el.name,
              value: (el.id as number).toString(),
            })),
          ],
          dataElement: {
            disabled: user && !!user.companyId,
          },
        },
        {
          typeElement: 'input',
          label: t('ID Fondo'),
          name: 'fundCode',
          col: { sm: 4 },
          dataElement: {
            placeholder: t('ID Fondo'),
            type: 'text',
            disabled: edit,
            maxLength: 4,
          },
        },
        {
          typeElement: 'select',
          label: t('users.create.covip'),
          name: 'authorityId',
          col: { sm: 4 },
          options: [
            { label: t('users.create.covip_placeholder'), value: '-1' },
            ...covipList.map(el => ({
              label: el.covipCode,
              value: el.id?.toString(),
            })),
          ],
          dataElement: {
            disabled: !covipList.length,
          },
        },
        {
          typeElement: 'input',
          label: t('Descrizione'),
          name: 'description',
          col: { md: 6 },
          dataElement: {
            placeholder: t('Descrizione'),
            type: 'text',
          },
        },
        {
          typeElement: 'input',
          label: t('Descrizione breve'),
          name: 'shortDescription',
          col: { md: 6 },
          dataElement: {
            placeholder: t('Descrizione breve'),
            type: 'text',
            firstLabel: foundCode,
          },
        },
      ],
    },
  ]);

  const dataConfigurationPeriod: FormElementModelNew[] = createStructureForm([
    {
      asset: [
        {
          period: [
            {
              typeElement: 'select',
              label: t('periods.create.month'),
              name: 'month',
              col: { sm: 12, md: 6, lg: 6, xl: 4 },
              options: [
                ...Array.from(Array(12).keys()).map(ele => ({
                  value: '' + (ele + 1),
                  label: months[ele],
                })),
              ],
              dataElement: {},
            },
            {
              typeElement: 'select',
              label: t('periods.create.year'),
              name: 'year',
              col: { sm: 12, md: 6, lg: 6, xl: 4 },
              options: [
                ...years().map(ele => ({
                  value: ele.toString(),
                  label: ele.toString(),
                })),
              ],
              dataElement: {},
            },
          ],
        },
      ],
    },
  ]);
  const dataConfiguration: FormElementModelNew[] = createStructureForm([
    {
      configuration: [
        {
          typeElement: 'select',
          label: t('Tipologia di forma pensionistica'),
          name: 'c_9102',
          col: { sm: 12, md: 6, lg: 6, xl: 4 },
          options: [
            { label: 'Seleziona..', value: '-1' },
            {
              value: '01',
              label: 'Fondi pensione preesistenti',
            },
            {
              value: '02',
              label: 'Fondi pensione negoziali (chiusi)',
            },
            {
              value: '03',
              label: 'Fondi pensione aperti',
            },
            {
              value: '04',
              label: 'PIP “nuovi”',
            },
            {
              value: '05',
              label: 'PIP “vecchi”',
            },
            {
              value: '06',
              label: 'Fondi pensione esteri',
            },
            {
              value: '07',
              label: 'FONDINPS',
            },
            {
              value: '08',
              label:
                'Fondi interni alle Autorità di vigilanza in materia di risparmio, valutaria o assicurativa',
            },
          ],
          dataElement: {},
        },
        {
          typeElement: 'select',
          label: t('Regime previdenziale'),
          name: 'c_9106',
          col: { sm: 12, md: 6, lg: 6, xl: 4 },
          options: [
            { label: 'Seleziona..', value: '-1' },
            {
              value: '0',
              label: 'Contribuzione definita',
            },
            {
              value: '1',
              label: 'Prestazione definita',
            },
          ],
          dataElement: {},
        },
        {
          typeElement: 'select',
          label: t('Iscritti attivi e/o pensionati al 31 dicembre'),
          name: 'c_9108',
          col: { sm: 12, md: 6, lg: 6, xl: 4 },
          options: [
            { label: 'Seleziona..', value: '-1' },
            {
              value: '0',
              label: 'Meno di 100',
            },
            {
              value: '1',
              label: 'Più di 100',
            },
          ],
          dataElement: {},
        },
      ],
    },
  ]);
  const dataConfigurationCheck: FormElementModelNew[] = createStructureForm([
    {
      configuration: [...configurationCheck],
    },
  ]);

  const buttons: ButtonModel[] = [
    {
      typeButton: 'secondary',
      label: t('changePsw.cancel'),
      onClick: () => history.goBack(),
    },
    {
      typeButton: 'primary',
      label: t('changePsw.send'),
    },
  ];

  return {
    data,
    dataConfigurationPeriod,
    dataConfiguration,
    dataConfigurationCheck,
    buttons,
  };
}
