import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserPermissions } from '../../../../../../app/auth/authSlice';
import {
  issetPermissions,
  permissionType,
} from '../../../../../../app/rolePermissions';
import Dropdown from '../../../../../../components/shared/form/buttons/dropdown/Dropdown';
import ListActionTableRow from '../../../../../../components/shared/form/buttons/dropdown/models/ListActionTableRow';

const DropdownAction = ({
  actionsType,
  clickActionRow,
  disabledRun,
}: {
  actionsType: any;
  clickActionRow: (action: any) => any;
  disabledRun?: boolean;
}) => {
  const userPermissions = useSelector(selectUserPermissions);
  const editFlowPermission = issetPermissions(userPermissions, [
    permissionType.EDIT_FLOWS,
  ]);
  const viewFlowPermission = issetPermissions(userPermissions, [
    permissionType.VIEW_FLOWS,
  ]);
  const listActionTableRow: ListActionTableRow[] = [
    {
      icon: 'play',
      color: 'green',
      label: 'Run',
      action: 'run',
      disabled: disabledRun,
      hide: !editFlowPermission,
    },
    {
      icon: 'history',
      color: 'blue',
      label: 'Cronologia',
      action: actionsType.history,
      hide: !viewFlowPermission,
    },
    {
      icon: 'delete',
      color: 'red',
      label: 'Elimina',
      action: actionsType.delete,
      hide: !editFlowPermission,
    },
  ];
  return (
    <div className="d-flex align-items-center">
      <Dropdown
        drop="left"
        listMenu={listActionTableRow}
        clickAction={ele => {
          clickActionRow(ele.action);
        }}
      ></Dropdown>
    </div>
  );
};

export default DropdownAction;
