import React from 'react';
import { mergeClass } from '../../functions/function';
import StateIcon from './stateIcon/StateIcon';

interface StateLabelProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  state?: string;
  color?: string;
  colorStyle?: string;
  label?: string;
}

const StateLabel = ({
  children,
  state,
  color,
  colorStyle,
  label,
  className,
  ...rest
}: StateLabelProps) => {
  return (
    <div className={className + ' shared-stateLabel'}>
      <StateIcon
        colorStyle={colorStyle}
        color={state && state === 'active' ? 'green' : 'red'}
      ></StateIcon>
    </div>
  );
};

export default StateLabel;
