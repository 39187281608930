import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../app/store';
import LoadedFileModel from './models/LoadedFileModel';
import {
  deleteDatabaseDashboard,
  deleteFileDashboard,
  downloadFileDashboard,
  runStepCall,
} from '../services/dashboard.service';
import { flowNextStepModel } from './models/FlowNextStepModel';
import { loadLoadedFileCall } from '../../../../components/core/filter-dashboard/filterDashboardSlice';
import { ToolbarIconProps } from '../../../../components/shared/toolbarWithDrop/models/ToolbarIconProps';
import { setLoadManual } from '../../../../components/shared/loader/redux/loadSlice';
import { saveAs } from 'file-saver';
export interface DashboardSliceModel {
  loadedFile: LoadedFileModel[];
  deleteFileList: number[];
  indexRow: number[];
  indexSelected: number[];
  flowNextStep?: flowNextStepModel[];
  toolBarIconFlussi: ToolbarIconProps[];
  showFlowNextStepModal: boolean;
  deleteModal: any | undefined;
}
const initialState: DashboardSliceModel = {
  loadedFile: [],
  deleteFileList: [],
  indexRow: [],
  toolBarIconFlussi: [],
  indexSelected: [],
  flowNextStep: [],
  showFlowNextStepModal: false,
  deleteModal: undefined,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setFlowNextStep: (state, action: PayloadAction<flowNextStepModel[]>) => {
      state.flowNextStep = action.payload;
    },
    setToolBarIconFlussi: (
      state,
      action: PayloadAction<ToolbarIconProps[]>
    ) => {
      state.toolBarIconFlussi = action.payload;
    },
    setShowFlowNextStepModal: (state, action: PayloadAction<boolean>) => {
      state.showFlowNextStepModal = action.payload;
    },
    addDeleteFileList: (state, action: PayloadAction<number>) => {
      state.deleteFileList = [...state.deleteFileList, action.payload];
    },
    removeDeleteFileList: (state, action: PayloadAction<number>) => {
      state.deleteFileList = state.deleteFileList.filter(
        num => num !== action.payload
      );
    },
    resetDeleteFileList: state => {
      state.deleteFileList = [];
    },
    setLoadedFile: (state, action: PayloadAction<LoadedFileModel[]>) => {
      state.loadedFile = action.payload;
    },
    setLoadedFileInLoading: (state, action: PayloadAction<number>) => {
      state.loadedFile = state.loadedFile.map(ele =>
        ele.id === action.payload
          ? {
              ...ele,
              endDate: null,
              nextSteps:
                ele.nextSteps?.map(e => ({ ...e, inLoading: true })) || [],
            }
          : ele
      );
    },
    deleteLoadedFile: (state, action: PayloadAction<number[]>) => {
      state.loadedFile = state.loadedFile.filter(
        ele => !action.payload.includes(ele.id)
      );
    },
    setOpenRow: (state, action: PayloadAction<number>) => {
      state.indexRow = state.indexRow.includes(action.payload)
        ? []
        : [action.payload];
    },
    removeOpenRow: (state, action: PayloadAction<number>) => {
      state.indexRow = state.indexRow.filter(ele => ele !== action.payload);
    },
    setSelection: (state, action: PayloadAction<number[]>) => {
      state.indexSelected = action.payload;
    },
    addSelection: (state, action: PayloadAction<number>) => {
      state.indexSelected = [...state.indexSelected, action.payload];
    },
    removeSelection: (state, action: PayloadAction<number>) => {
      state.indexSelected = state.indexSelected.filter(
        ele => ele !== action.payload
      );
    },
    selectAllIndexSelected: state => {
      state.indexSelected = state.loadedFile.map(ele => ele.id);
    },
    deselectAllIndexSelected: state => {
      state.indexSelected = [];
    },
    resetDashboard: (state: any) => {
      Object.keys(state).forEach((ele: string) => {
        state[ele] =
          (initialState as any)[ele] !== undefined
            ? (initialState as any)[ele]
            : undefined;
      });
    },
    setDeleteModal: (state, action: PayloadAction<any>) => {
      state.deleteModal = action.payload;
    },
    closeDeleteModal: state => {
      state.deleteModal = undefined;
    },
  },
});
export const {
  setLoadedFile,
  setOpenRow,
  removeOpenRow,
  deselectAllIndexSelected,
  selectAllIndexSelected,
  setSelection,
  resetDashboard,
  setFlowNextStep,
  setShowFlowNextStepModal,
  addSelection,
  removeSelection,
  deleteLoadedFile,
  setDeleteModal,
  closeDeleteModal,
  addDeleteFileList,
  removeDeleteFileList,
  resetDeleteFileList,
  setLoadedFileInLoading,
  setToolBarIconFlussi,
} = dashboardSlice.actions;

export const addRemoveSelectionAction =
  (id: number, index: number): AppThunk =>
  (dispatch, getState) => {
    getState().dashboard.indexSelected.includes(id)
      ? dispatch(removeSelectionAction(id, index))
      : dispatch(addSelection(id));
  };

export const removeSelectionAction =
  (id: number, index: number): AppThunk =>
  dispatch => {
    dispatch(removeSelection(id));
    dispatch(removeOpenRow(index));
  };

export const exportDashboardAction = (): AppThunk => (dispatch, getState) => {
  const assetId = getState().filterDashboard.assetId;
  const periodId = getState().filterDashboard.periodId;
  const tenant = getState().filterDashboard.tenant;
  downloadFileDashboard(periodId, assetId, tenant).then(res => {
    const fileName = res.headers['content-disposition']
      ? res.headers['content-disposition'].split('filename=')[1]
      : 'undefined.xml';
    const blob = new Blob([res.data], {
      type: res.headers['content-type'],
    });
    saveAs(blob, fileName);
  });
};

export const deleteDatabase =
  (callback: () => void): AppThunk =>
  (dispatch, getState) => {
    const tenant = getState().filterDashboard.tenant;
    tenant &&
      deleteDatabaseDashboard(tenant).then(res => {
        dispatch(setLoadManual(true));
        callback && callback();
      });
  };

export const deleteFlowFile =
  (loadedId: number, id: number, callback: () => void): AppThunk =>
  (dispatch, getState) => {
    const tenantId = getState().filterDashboard.tenant;
    tenantId &&
      deleteFileDashboard(loadedId, id, tenantId).then(res => {
        dispatch(addDeleteFileList(id));
        callback && callback();
      });
  };

export const runStepAction =
  (data: any = {}, close?: () => void): AppThunk =>
  (dispatch, getState) => {
    const tenant = getState().filterDashboard.tenant;
    const req = {
      ...data,
      loadedId: getState().filterDashboard.loadedFileId,
      nomeDb: tenant,
    };

    tenant &&
      runStepCall(req, tenant).then(res => {
        if (req.fileLoadedId) {
          dispatch(setLoadedFileInLoading(req.fileLoadedId));
        } else {
          setTimeout(() => {
            dispatch(loadLoadedFileCall());
          }, 500);
        }
        close && close();
      });
  };

export const selectLoadedFile = (state: RootState) =>
  state.dashboard.loadedFile;
export const selectIndexRow = (state: RootState) => state.dashboard.indexRow;
export const selectflowNextStep = (state: RootState) =>
  state.dashboard.flowNextStep;
export const selectShowFlowNextStepModal = (state: RootState) =>
  state.dashboard.showFlowNextStepModal;
export const selectIndexSelected = (state: RootState) =>
  state.dashboard.indexSelected;
export const selectIsSelectedAllRow = (state: RootState) =>
  !state.dashboard.loadedFile.find(
    ele => !state.dashboard.indexSelected.includes(ele.id)
  );
export const selectDeleteFileList = (state: RootState) =>
  state.dashboard.deleteFileList;
export const selectDeleteModal = (state: RootState) =>
  state.dashboard.deleteModal;
export const selectToolbarIcon = (state: RootState) =>
  state.dashboard.toolBarIconFlussi;

export default dashboardSlice.reducer;
