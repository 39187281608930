import { AppThunk } from '../../../../../app/store';
import { httpBase } from '../../../../../utils/http';
import { padNumber } from '../../../../../utils/dateUtils';

export const exportGridAction =
  (surveyType: string): AppThunk =>
  (dispatch, getState) => {
    const assetId = getState().filterDashboard.assetId;
    const listPeriods = getState().filterDashboard.periods;
    const periodId = getState().filterDashboard.periodId;
    const indexPeriod = listPeriods.findIndex(ele => ele.id === periodId);
    let previousPeriodId =
      indexPeriod > 0 ? `${listPeriods[indexPeriod - 1].id}` : '0';
    const loadedId = getState().filterDashboard.loadedFileId;
    const tenantId = getState().filterDashboard.tenant;
    const lastChart = surveyType.charAt(1);
    const year = periodId?.toString()?.substring(0, 4);
    const month = periodId?.toString()?.substring(4, 6);
    const day = periodId?.toString()?.substring(6, 8);
    if (lastChart === 'T') {
      previousPeriodId =
        year && month && day
          ? (+month - 3 > 0 ? year : +(+year - 1)) +
            (+month - 3 > 0
              ? padNumber(+month - 3)
              : padNumber(12 + (+month - 3))) +
            day
          : '';
    } else if (lastChart === 'A' || surveyType === 'BL') {
      previousPeriodId = year && month && day ? +year - 1 + month + day : '';
    }

    httpBase
      .get(
        `grid/download/${surveyType}/${assetId}/${periodId}/${previousPeriodId}/${loadedId}`,
        {
          headers: { 'X-TenantID': tenantId },
          responseType: 'blob',
        }
      )
      .then(res => {
        const filename = res.headers['content-disposition']
          ? res.headers['content-disposition'].split('filename=')[1]
          : 'Griglia_controllo.xlsx';
        const blob = new Blob([res.data], {
          type: res.headers['content-type'],
        });
        saveAs(blob, filename);
      });
  };
