import React from 'react';
import AuthLayout from './AuthLayout';

interface AuthRouteProps {
  isLogged: boolean;
}
const AuthRoute = ({ isLogged }: AuthRouteProps) => {
  return <>{isLogged ? <AuthLayout /> : null}</>;
};

export default AuthRoute;
