import React from 'react';
import { ListElementProps } from '../../../../../../components/shared/listElement/models/ListElementProps';
import ModalSuccess from '../../../../../../components/shared/modal/modalSuccess/ModalSuccess';
import ListElement from '../../../../../../components/shared/listElement/ListElement';
import { FileVds, GroupFileVds } from '../models/SliceVdsModel';

const ListFileExportVds = ({
  list = [],
  action,
  closeAction,
}: Omit<ListElementProps, 'property' | 'list'> & {
  list: GroupFileVds[];
}) => {
  const actionHandler = (data: any) => {
    action && action(data);
  };

  return (
    <ModalSuccess
      closeAction={closeAction}
      text={{ title: 'Lista file pronti' }}
      templateBody={
        <>
          <ListElement
            // list={list.reduce((arr: FileVds[], ele) => {
            //   return [
            //     ...arr,
            //     ...ele.files.map((data) => ({
            //       ...data,
            //       bucketName: ele.bucketName,
            //     })),
            //   ];
            // }, [])}
            list={list}
            property="bucketName"
            action={actionHandler}
          />
        </>
      }
      showModal={true}
    />
  );
};

export default ListFileExportVds;
