import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { TabBtnModel } from '../../../../../components/shared/tab/TabBtns.model';
import TabBtns from '../../../../../components/shared/tab/TabBtns';
import toolBarEvent from './toolbarEvents';
import ToolbarIcons from '../../../../../components/shared/toolbarWithDrop/ToolbarIcons';
import { ToolbarIconProps } from '../../../../../components/shared/toolbarWithDrop/models/ToolbarIconProps';

const TabBtnDashboard = ({
  selectedTab,
  setSelectedTab,
  tabsData = [],
  toolBarIconFlussi,
}: {
  selectedTab: string;
  setSelectedTab: (name: string) => void;
  tabsData: TabBtnModel[];
  toolBarIconFlussi: ToolbarIconProps[];
}) => {
  const selectedTabHandler = useCallback((tabName: string) => {
    setSelectedTab(tabName);
  }, []);

  return (
    <Row className="align-items-start justify-content-between">
      <Col xs="auto">
        <TabBtns
          tabs={tabsData}
          activeTab={selectedTab}
          selectedTab={e => selectedTabHandler(e)}
        />
        <hr />
      </Col>
      <Col xs="auto">
        <ToolbarIcons
          listBtn={toolBarIconFlussi}
          clickAction={(action: string) => {
            toolBarEvent.events(action);
          }}
        ></ToolbarIcons>
      </Col>
    </Row>
  );
};

export default React.memo(TabBtnDashboard);
