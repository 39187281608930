import React from 'react';
import { CardProps } from 'react-bootstrap/Card';
import ContentBox from '../ContentBox';
import { useTranslation } from 'react-i18next';

interface CardBrandProps extends CardProps {
  label: string;
}
const ContentBoxEmpty = ({
  children,
  className,
  label,
  ...rest
}: CardBrandProps) => {
  const { t } = useTranslation();
  const labelData = label || t('contentBox.empty');

  return (
    <ContentBox>
      <div className="shared-contentBoxEmpty bg d-flex align-items-center justify-content-center h-100">
        <span>{labelData}</span>
      </div>
    </ContentBox>
  );
};

export default ContentBoxEmpty;
