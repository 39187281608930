import http, { httpBase } from '../../../../utils/http';
import { ResultVdsModel } from '../dashboard/vds/models/SliceVdsModel';
const urlBase = 'vds';
export const listVds = (tenantId: string) =>
  http.get(`${urlBase}`, {
    headers: { 'X-TenantID': tenantId },
  });

export const editVds = (data: any = {}, tenantId: string) =>
  http.put(`${urlBase}`, data, {
    headers: { 'X-TenantID': tenantId },
  });
export const insertVdsRow = (data: any = {}, tenantId: string) =>
  http.post(`${urlBase}`, data, {
    headers: { 'X-TenantID': tenantId },
  });
export const deleteVdsRow = (data: any = {}, tenantId: string) => {
  return http.delete(`${urlBase}`, {
    data,
    headers: { 'X-TenantID': tenantId, 'Content-Type': 'application/json' },
  });
};

export const configVds = (vds: string, tenantId: string) =>
  http.get(`${urlBase}/config?vds=${vds}`, {
    headers: { 'X-TenantID': tenantId },
  });

export const removeVds = (vds: string, loadedId: number, tenantId: string) =>
  http.delete(`${urlBase}/all?vds=${vds}&loadedId=${loadedId}`, {
    headers: { 'X-TenantID': tenantId },
  });

export const searchVds = (obj: any, tenantId: string) =>
  http.post<ResultVdsModel[]>(`${urlBase}/search`, obj, {
    headers: { 'X-TenantID': tenantId },
  });

export const vdsExportPrepare = (obj: any, tenantId: string) =>
  http.post(`${urlBase}/export`, obj, {
    headers: { 'X-TenantID': tenantId },
  });

export const vdsListFile = (tenantId: string) =>
  http.get(`${urlBase}/files`, {
    headers: { 'X-TenantID': tenantId },
  });

export const downloadFileVds = (bucketName: string, tenantId: string) =>
  httpBase.get(`${urlBase}/download?bucketName=${bucketName}`, {
    headers: { 'X-TenantID': tenantId },
    responseType: 'blob',
  });
