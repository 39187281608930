import React, { useEffect, useState } from 'react';
import { Pagination as Pag } from 'react-bootstrap';
import { usePaginationList } from './usePaginationList';

export interface PaginationModel {
  elementForPage?: number;
  page?: number;
  elements: number;
  goPage: (page: number) => void;
}

const PaginationList = ({
  elementForPage = 8,
  page = 1,
  elements,
  goPage,
}: PaginationModel) => {
  const { items, pages, changePage } = usePaginationList(
    elements,
    elementForPage,
    page,
    goPage
  );
  return (
    <div className="d-flex justify-content-center">
      <Pag size="sm">
        <Pag.First
          className="firstPage"
          disabled={page === 1}
          onClick={() => goPage(1)}
        />
        <Pag.Prev
          className="prevPage"
          disabled={page === 1}
          onClick={() => changePage()}
        />
        {page > 5 ? <Pag.Ellipsis /> : null}
        {items.map(number =>
          (number <= page && page - number < 5) ||
          (number > page && number - page < 5) ? (
            <Pag.Item
              key={number}
              onClick={() => goPage(number)}
              active={number === page}
            >
              {number}
            </Pag.Item>
          ) : null
        )}
        {items.length - page >= 5 ? <Pag.Ellipsis /> : null}
        <Pag.Next
          className="nextPage"
          disabled={page === pages}
          onClick={() => changePage(true)}
        />
        <Pag.Last
          className="lastPage"
          disabled={page === pages}
          onClick={() => goPage(items.length)}
        />
      </Pag>
    </div>
  );
};

export default PaginationList;
