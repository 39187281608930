import { TableData } from '../../../../../components/shared/table/models/TableProps';
import ListActionTableRow from '../../../../../components/shared/form/buttons/dropdown/models/ListActionTableRow';

export function useFlow() {
  const tableStructureFlussi: TableData[] = [
    /*  {
      name: "selector",
      label: "",
      width: "1%",
    }, */
    {
      name: 'ico',
      label: '',
      width: '1%',
    },
    {
      name: 'nameZip',
      label: 'Nome File',
      width: '35%',
      //showFilterOrder: true,
      //showFilterSearch: true,
    },
    {
      name: 'user',
      label: 'Utente',
      //showFilterOrder: true,
    },
    {
      name: 'date',
      label: 'Data',
      width: '4%',
      //showFilterSearch: true,
    },
    {
      name: 'startDate',
      label: 'Ora Inizio',
      width: '10%',
    },
    {
      name: 'endDate',
      label: 'Ora Fine',
      width: '10%',
    },
    {
      name: 'state',
      label: 'Stato',
      width: '3%',
    },
    {
      name: 'rowsNumberOutput',
      label: 'Record',
      format: 'number',
    },
    {
      name: 'modify',
      label: '',
      width: '1%',
    },
  ];

  const actionsType = {
    history: 'history',
    delete: 'delete',
    run: 'run',
  };

  return {
    tableStructureFlussi,
    actionsType,
  };
}
