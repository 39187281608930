import { TableData } from '../../../../../../../components/shared/table/models/TableProps';

export const tableStructureErrorDetail: TableData[] = [
  {
    name: 'field',
    label: 'Campo',
  },
  {
    name: 'descriptionField',
    label: 'Descrizione Campo',
  },
  {
    name: 'value',
    label: 'Valore',
  },
  {
    name: 'detailVds',
    label: '',
  },
];
