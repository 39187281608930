import { TFunction } from 'i18next';
import { FormElementModelNew } from '../../../../../utils/forms/models/FormElementModel';

export function useFiltersUsers(t: TFunction, companyList: any[]) {
  const validations = {};
  const data: FormElementModelNew[] = [
    {
      typeElement: 'select',
      label: t('users.create.company'),
      name: 'companyId',
      col: { md: '3' },
      options: [
        { label: t('users.create.company_placeholder'), value: '-1' },
        ...companyList,
      ],
      dataElement: {},
    },
    {
      typeElement: 'input',
      label: t('users.create.firstName'),
      name: 'firstName',
      col: { md: '3' },
      dataElement: {
        placeholder: t('users.create.firstName'),
        type: 'text',
      },
    },
    {
      typeElement: 'input',
      label: t('users.create.lastName'),
      name: 'lastName',
      col: { md: '3' },
      dataElement: {
        placeholder: t('users.create.lastName'),
        type: 'text',
      },
    },
    {
      typeElement: 'input',
      label: t('users.create.email'),
      name: 'email',
      col: { md: '3' },
      dataElement: {
        placeholder: t('users.create.email'),
        type: 'text',
      },
    },
  ];

  return {
    validations,
    data,
  };
}
