import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import FormGroup from '../../shared/form/form-group/FormGroup';
import Select from '../../shared/form/select/Select';
import { permissionType } from '../../../app/rolePermissions';
import { useRoute } from '../../../utils/useRoute';
import {
  loadInitData,
  loadLoadedFile,
  loadPeriods,
  selectAssetId,
  selectAssets,
  selectDisabledAll,
  selectPeriodId,
  selectPeriods,
  setPeriodId,
  setAssetId,
} from './filterDashboardSlice';
import * as routeName from '../../../routes/routeNameAuthType';
import IsPermission from '../../IsPermission';
import { usePermissions } from '../../../features/auth/usePemissions';

const FilterDashboard = () => {
  const { t } = useTranslation();
  const assets = useSelector(selectAssets);
  const periods = useSelector(selectPeriods);
  const assetId = useSelector(selectAssetId);
  const periodId = useSelector(selectPeriodId);
  const disabledAll = useSelector(selectDisabledAll);
  const { issetPermission } = usePermissions();
  const hasPermission = issetPermission([permissionType.VIEW_FUND_COMBO]);

  // const { data } = useFilterDashboard(t, user, assets);
  useEffect(() => {
    assetId && periodId && dispatch(loadLoadedFile());
  }, [assetId, periodId]);
  const data = {
    assets: {
      label: t('dashboard.filter.asset'),
      options: [
        { label: 'Seleziona Fondo', value: '-1' },
        ...assets.map(ele => ({
          label: ele.shortDescription,
          value: ele.id,
        })),
      ],
      disabled: assets.length == 1,
      visible: hasPermission,
    },
    periods: {
      label: t('dashboard.filter.period'),
      options: [
        { label: 'Seleziona Periodo', value: '-1' },
        ...periods.map(ele => ({
          label: ele.month + '/' + ele.year,
          value: ele.id,
        })),
      ],
    },
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadInitData(hasPermission));
  }, []);

  const getPeriods = (value: any) => {
    dispatch(setPeriodId(undefined));
    dispatch(setAssetId(+value));
  };

  useEffect(() => {
    dispatch(loadPeriods());
  }, [assetId]);

  const changePeriodSelected = (value: any) => {
    dispatch(setPeriodId(value));
  };
  const { changeRoute } = useRoute();

  const clickActionHandler = () => {
    if (assetId) {
      const asset = assets.find(a => a.id === assetId);
      asset &&
        changeRoute(
          asset.type === 1 ? routeName.assetEdit : routeName.assetEPEdit
        );
    } else {
      changeRoute(routeName.assetCreate);
    }
  };

  return (
    <div className="mb-3 pt-2">
      <Row className="searchBar justify-content-end mr-1">
        <IsPermission permissions={[permissionType.VIEW_FUND_COMBO]}>
          <Col className="firstFilter">
            <FormGroup label={{ label: data.assets.label }}>
              <Select
                value={assetId}
                iconAction={issetPermission([permissionType.MANAGEMENT_ASSETS])}
                customSelect
                options={data.assets.options}
                changeSelect={getPeriods}
                disabled={disabledAll}
                clickAction={() => clickActionHandler()}
              ></Select>
            </FormGroup>
          </Col>
        </IsPermission>
        <Col className="secondFilter">
          <FormGroup label={{ label: data.periods.label }}>
            <Select
              value={periodId}
              iconAction={issetPermission([permissionType.MANAGEMENT_PERIODS])}
              customSelect
              options={data.periods.options}
              changeSelect={changePeriodSelected}
              disabled={disabledAll}
              clickAction={() => {
                changeRoute(routeName.periodsCreate);
              }}
            ></Select>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default FilterDashboard;
