import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectVsd, resetSelectedVdsWithError } from './errorDashboardSlice';
import { useRoute } from '../../../../../utils/useRoute';
import { home } from '../../../../../routes/routeNameAuthType';
import BackBtn from '../../../../../components/shared/backBtn/BackBtn';

const ErrorBackDashboard = () => {
  const selectVds = useSelector(selectVsd);
  const dispatch = useDispatch();
  const { changeRoute } = useRoute();
  const goBack = () => {
    changeRoute(home);
  };

  const goBackAction = () => {
    selectVds ? dispatch(resetSelectedVdsWithError()) : goBack();
  };
  return <>{selectVds && <BackBtn onClick={goBackAction}></BackBtn>}</>;
};

export default ErrorBackDashboard;
