import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { selectUserEdit, loadUserInitData } from './userEditSlice';
import { setTitle } from '../../../../app/page/pageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { editUser } from '../services/users.service';
import { useRoute } from '../../../../utils/useRoute';
import {
  addSuccessMessage,
  resetErrors,
} from '../../../../app/errors/errorSlice';
import UserForm from '../UserForm';
import UserEditSingleChange from './components/UserEditSingleChange';

const UserEdit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userModify = useSelector(selectUserEdit);
  const { id } = useParams();

  useEffect(() => {
    dispatch(setTitle(t('users.edit.title')));
    dispatch(loadUserInitData(id));
  }, []);
  const { changeRoute } = useRoute();
  const editUserAction = (data: any) => {
    editUser({ ...data, id }).then(res => {
      successUpdate();
    });
  };

  const successUpdate = () => {
    dispatch(resetErrors());
    dispatch(addSuccessMessage('USER_EDIT_SUCCESS'));
    changeRoute('users.index');
  };

  return (
    <div className="px-5">
      <UserForm submitForm={editUserAction} user={userModify} />
      <UserEditSingleChange
        userModify={userModify}
        id={id}
        successUpdate={successUpdate}
      />
    </div>
  );
};

export default UserEdit;
