import React, { Children } from 'react';
import { mergeClass } from '../functions/function';

interface TagProps {
  className?: string;
  color?: string;
  bgColor?: string;
  label: string;
}

const Tag = ({
  className,
  color = '#fff',
  bgColor = '#7ED321',
  label,
}: TagProps) => {
  return (
    <div
      className={mergeClass('shared-tag', className)}
      style={{ backgroundColor: bgColor, color: color }}
    >
      {label}
    </div>
  );
};
export default Tag;
