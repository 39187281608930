import React from 'react';
import { mergeClass } from '../../../functions/function';
import {
  DropdownProps,
  DropdownButton,
  Dropdown as Drpdwn,
} from 'react-bootstrap';
import Icon from '../../../icon/Icon';
import ListActionTableRow from './models/ListActionTableRow';
import Caption from '../../../headings/caption/Caption';

interface DrpdwnProps extends DropdownProps {
  className?: string;
  listMenu: ListActionTableRow[];
  clickAction?: (action: ListActionTableRow) => void;
  iconDrop?: string;
  iconDropColor?: string;
  display?: string;
}
const Dropdown = ({
  children,
  className,
  drop = 'down',
  onToggle,
  listMenu,
  clickAction,
  iconDrop,
  iconDropColor,
  display,
  ...rest
}: DrpdwnProps) => {
  const toggleDrop = (isOpen: boolean, e: any) => {
    e.stopPropagation && e.stopPropagation();
  };

  let clickHandler = (action: ListActionTableRow) => {
    clickAction && clickAction(action);
  };

  const listItem = listMenu.map((ele, index) => {
    return !ele.hide ? (
      <Drpdwn.Item
        key={index}
        eventKey={'' + index}
        className="d-flex align-items-center"
        onClick={() => clickHandler(ele)}
        disabled={ele.disabled}
      >
        {ele.icon && <Icon icon={ele.icon} color={ele.color}></Icon>}
        <Caption className="sm medium">{ele.label}</Caption>
      </Drpdwn.Item>
    ) : null;
  });

  return (
    <DropdownButton
      onToggle={(t, e) => toggleDrop(t, e)}
      className={mergeClass('shared-dropdown', display ? display : '')}
      drop={drop}
      title={
        <Icon
          icon={iconDrop ? iconDrop : 'more'}
          color={iconDropColor ? iconDropColor : ''}
        ></Icon>
      }
    >
      {listItem}
    </DropdownButton>
  );
};

export default Dropdown;
