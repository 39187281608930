import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './config/i18n';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import {
  incrementN,
  decrementN,
} from './components/shared/loader/redux/loadSlice';
import authInterceptor from './utils/interceptors/authInterceptor';
import logInterceptor from './utils/interceptors/logInterceptor';
import status401Interceptor from './utils/interceptors/status401Interceptor';
import errorInterceptor from './utils/interceptors/errorInterceptor';
import client from './app/clientConnect';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

axios.interceptors.request.use(config => {
  store.dispatch(incrementN());
  return config;
});
axios.interceptors.response.use(
  (config: any) => {
    store.dispatch(decrementN());
    return config;
  },
  err => {
    store.dispatch(decrementN());
    return Promise.reject(err);
  }
);
axios.interceptors.request.use(authInterceptor);
axios.interceptors.response.use((config: any) => config, logInterceptor);
axios.interceptors.response.use((config: any) => config, status401Interceptor);
axios.interceptors.response.use((config: any) => config, errorInterceptor);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
