import React, { useEffect, useState } from 'react';
import { editUsername } from '../../services/users.service';
import InputEditWithIcon from './InputEditWithIcon';

const UserEditUsername = ({
  username,
  id,
  saved,
}: {
  username?: string;
  id?: number;
  saved: () => void;
}) => {
  const saveUsername = (usernameValue: string) => {
    editUsername(id, usernameValue).then(res => {
      saved && saved();
    });
  };
  return (
    <InputEditWithIcon
      label="Username"
      name="username"
      value={username}
      iconClick={saveUsername}
    />
  );
};

export default UserEditUsername;
