import { TFunction } from 'i18next';
import { ButtonModel } from '../../../components/shared/form/buttons/models/ButtonModel';
import { useHistory } from 'react-router';

export function useChangeAuthPassword(t: TFunction) {
  const history = useHistory();
  const validations = {
    oldPassword: {
      required: {
        params: { name: 'vecchia password' },
      },
    },
    newPassword: {
      required: {
        params: { name: 'password' },
      },
      minLength: {
        moreData: {
          min: 8,
        },
        params: { name: 'password', min: 8 },
      },
      upperCharacter: {
        params: { name: 'password' },
      },
      oneNumber: {
        params: { name: 'password' },
      },
      specialCharacter: {
        params: { name: 'password' },
      },
    },
    confirmPassword: {
      required: {
        params: { name: 'conferma password' },
      },
      confirm: {
        moreData: {
          fieldCompare: 'newPassword',
        },
        params: { name: 'password', fieldCompare: 'conferma password' },
      },
    },
  };
  const data: {
    typeElement?: 'input' | 'select' | 'textarea';
    label: string;
    name: string;
    value?: any;
    options?: { label: string; value: string; disabled?: boolean }[];
    dataElement: Partial<{
      placeholder: string;
      type: string;
      multiply?: boolean;
    }>;
  }[] = [
    {
      typeElement: 'input',
      label: t('changePsw.oldPassword'),
      name: 'oldPassword',
      value: '',
      dataElement: {
        placeholder: t('changePsw.oldPassword'),
        type: 'password',
      },
    },
    {
      typeElement: 'input',
      label: t('changePsw.newPassword'),
      name: 'newPassword',
      value: '',
      dataElement: {
        placeholder: t('changePsw.newPassword'),
        type: 'password',
      },
    },
    {
      typeElement: 'input',
      label: t('changePsw.confirmPassword'),
      name: 'confirmPassword',
      value: '',
      dataElement: {
        placeholder: t('changePsw.confirmPassword'),
        type: 'password',
      },
    },
  ];
  const buttons: ButtonModel[] = [
    {
      typeButton: 'secondary',
      label: t('changePsw.cancel'),
      onClick: () => history.goBack(),
    },
    {
      typeButton: 'primary',
      label: t('changePsw.send'),
    },
  ];

  return {
    validations,
    data,
    buttons,
  };
}
