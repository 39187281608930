import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { selectTitle } from '../../app/page/pageSlice';
import HeadingH3 from '../shared/headings/headingH3/HeadingH3';
import headerData from './HeaderData';
import { useHistory } from 'react-router';
const PageTitle = () => {
  const title = useSelector(selectTitle);
  const history = useHistory();
  useEffect(() => {
    history.listen(ele => {});
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between">
        <HeadingH3>{title}</HeadingH3>
        {headerData && headerData.headers ? headerData.headers : null}
      </div>
    </>
  );
};

export default PageTitle;
