export default {
  app: {
    logout: 'Logout',
    profile: 'Profile',
    title: 'App Name',
  },
  home: {
    welcome: 'Welcome {{name}}',
  },
  login: {
    username: 'Username',
    password: 'Password',
    email: 'Email',
    username_message: 'test username is admin',
    password_message: 'test username is admin',
    cancel: 'Cancel',
    login: 'Login',
  },
  forgotPsw: {
    email: 'Email',
    cancel: 'Cancel',
    send: 'Send',
  },
  changePsw: {
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    cancel: 'Cancel',
    send: 'Send',
  },
  errors: {
    be: {
      BAD_CREDENTIAL: 'Credenziali not valid',
      USER_NOT_FOUND: 'User not found',
    },
    fe: {
      REQUIRED: 'The field {{name}} is required',
    },
  },
};
