import React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';
import { Nav } from 'react-bootstrap';
import * as routeName from '../../../../routes/routeNameAuthType';
import userImg from './../../../../assets/user.svg';
import IsPermission from '../../../IsPermission';
import { permissionType } from '../../../../app/rolePermissions';
import HeaderFilterAssets from '../../HeaderFilterAssets';

interface MenuLoggedProps {
  changeRoute: (name: string) => void;
  logout: () => void;
  title: string;
}
const MenuLogged = ({ changeRoute, logout, title }: MenuLoggedProps) => {
  const { t } = useTranslation();

  return (
    <Nav className="menuLogged w-100">
      <HeaderFilterAssets />
      <div className="d-flex mt-3">
        <IsPermission
          permissions={[
            permissionType.MANAGEMENT_USERS,
            permissionType.MANAGEMENT_ASSETS,
          ]}
          or={true}
        >
          <NavDropdown
            className="manageUsers"
            title={
              <div>
                <span className="icon icon-setting"></span>
                <span className="visible-md ml-2 label-icon">
                  Gestione Utenti
                </span>
              </div>
            }
            id="collasible-nav-dropdown"
          >
            <IsPermission permissions={[permissionType.MANAGEMENT_USERS]}>
              <NavDropdown.Item
                onClick={() => changeRoute(routeName.usersIndex)}
              >
                Lista Utenti
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => changeRoute(routeName.usersCreate)}
              >
                Crea Nuovo Utente
              </NavDropdown.Item>
            </IsPermission>
            <IsPermission permissions={[permissionType.MANAGEMENT_ASSETS]}>
              <NavDropdown.Item
                onClick={() => changeRoute(routeName.assetCreate)}
              >
                Crea Nuovo Fondo
              </NavDropdown.Item>
            </IsPermission>
            <IsPermission permissions={[permissionType.MANAGEMENT_ASSETS]}>
              <NavDropdown.Item
                onClick={() => changeRoute(routeName.assetEPCreate)}
              >
                Crea Nuovo Fondo Ente Prev.
              </NavDropdown.Item>
            </IsPermission>
            <IsPermission
              permissions={[permissionType.MANAGEMENT_VERSIONING_DB]}
            >
              <NavDropdown.Item onClick={() => changeRoute(routeName.database)}>
                Database
              </NavDropdown.Item>
            </IsPermission>
            <IsPermission permissions={[permissionType.MANAGEMENT_REPORT]}>
              <NavDropdown.Item
                onClick={() => changeRoute(routeName.reportCreate)}
              >
                Report
              </NavDropdown.Item>
            </IsPermission>
          </NavDropdown>
        </IsPermission>

        <img className="userImg hide-md" src={userImg} alt="user img" />

        <NavDropdown
          className="manageProfile"
          title={title}
          id="collasible-nav-dropdown"
        >
          <NavDropdown.Item onClick={() => changeRoute('ChangePassword')}>
            {t('app.profile')}
          </NavDropdown.Item>
          <NavDropdown.Item onClick={logout}>
            {t('app.logout')}
          </NavDropdown.Item>
        </NavDropdown>
      </div>
    </Nav>
  );
};

export default MenuLogged;
