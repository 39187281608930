import { tabObject, tabsData } from './dashboardData';
import { usePermissions } from '../../usePemissions';

export function useDashboardBase(loadedFileActive: boolean) {
  const { issetPermission } = usePermissions();
  const tabsDataFilter = tabsData
    .filter(ele =>
      ele.permissions && ele.permissions.length
        ? issetPermission(ele.permissions)
        : true
    )
    .map(ele => {
      const copy = { ...ele };
      delete copy.permissions;
      return copy;
    });
  return {
    tabsData: tabsDataFilter.map(ele =>
      loadedFileActive ||
      ele.name === tabObject.flow ||
      ele.name === tabObject.vds ||
      ele.name === tabObject.reports
        ? ele
        : { ...ele, disabled: true }
    ),
  };
}
