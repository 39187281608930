import React from 'react';
import ListActionTableRow from '../../../../../../../components/shared/form/buttons/dropdown/models/ListActionTableRow';
import Dropdown from '../../../../../../../components/shared/form/buttons/dropdown/Dropdown';

const DropdownErrorAction = ({
  clickActionRow,
}: {
  clickActionRow: (action: any) => any;
}) => {
  const listActionTableRow: ListActionTableRow[] = [
    {
      icon: 'play',
      color: 'green',
      label: 'Dettaglio Vds',
      action: 'detail',
    },
  ];
  return (
    <div className="d-flex align-items-center">
      <Dropdown
        drop="left"
        listMenu={listActionTableRow}
        clickAction={(ele: ListActionTableRow) => {
          clickActionRow(ele.action);
        }}
      ></Dropdown>
    </div>
  );
};

export default DropdownErrorAction;
