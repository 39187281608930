import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../../app/page/pageSlice';
import {
  selectAssetId,
  selectPeriodId,
  selectPeriods,
  setDisabledAll,
} from '../../../../components/core/filter-dashboard/filterDashboardSlice';
import * as routeName from '../../../../routes/routeNameAuthType';
import { useRoute } from '../../../../utils/useRoute';
import { loadAssetEPInitData, reset } from '../AssetEPReducer';
import AssetEPForm from '../AssetEPForm';

const AssetEPEdit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const assetId = useSelector(selectAssetId);
  const periodId = useSelector(selectPeriodId);
  const periods = useSelector(selectPeriods);
  const { changeRoute } = useRoute();
  useEffect(() => {
    if (assetId) {
      dispatch(setTitle(t('Modifica fondo ente previdenziale')));
      dispatch(reset());
      dispatch(loadAssetEPInitData(assetId));
      dispatch(setDisabledAll(true));
    } else {
      changeRoute(routeName.home);
    }
    return () => {
      dispatch(setDisabledAll(false));
      dispatch(reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId, periodId, periods]);

  return <AssetEPForm />;
};

export default AssetEPEdit;
