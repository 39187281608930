import isNil from 'lodash.isnil';
import React, { useEffect, useMemo, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setTitle } from '../../../app/page/pageSlice';
import { selectFundCode } from '../../../components/core/filter-dashboard/filterDashboardSlice';
import Icon from '../../../components/shared/icon/Icon';
import TableWithAction, {
  SearchProps,
} from '../../../components/shared/table/table/TableWithAction';
import { database, databaseDetail } from '../../../routes/routeNameAuthType';
import FormElements from '../../../utils/forms/FormElements';
import { UseFormSubmitModel } from '../../../utils/forms/models/UseFormModel';
import { useRoute } from '../../../utils/useRoute';
import {
  databaseStatusAction,
  databaseUpdateAction,
  selectListDatabase,
  TableObjPropsDatabase,
} from './DatabaseSlice';
import { tableStructureDatabase } from './tableStructureDatabase';
import { useDatebaseSearchForm } from './useDatebaseSearchForm';

const Database = () => {
  const dispatch = useDispatch();
  const { changeRoute } = useRoute();
  const { t } = useTranslation();

  const currentFundCode = useSelector(selectFundCode);

  const { state: locationState } = useLocation<TableObjPropsDatabase>();

  const listDatabase = useSelector(selectListDatabase);

  const selectedFundCode = useMemo(() => {
    const fundCode = locationState?.search?.fundCode;
    if (!isNil(fundCode)) {
      return fundCode;
    } else {
      return currentFundCode;
    }
  }, [currentFundCode, locationState]);

  useEffect(() => {
    locationState &&
      dispatch(
        databaseStatusAction(
          locationState?.search?.fundCode ? locationState : undefined
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState]);

  useEffect(() => {
    dispatch(setTitle(t('Lista database')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const templates = {
    refresh: (value: string, row: any) => {
      return (
        <Icon
          title="Refresh DB"
          icon="covip"
          color="default"
          disabled={!row.isDbUpdatable}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            if (row.isDbUpdatable) {
              dispatch(databaseUpdateAction(row.databaseName, locationState));
            }
          }}
        ></Icon>
      );
    },
  };
  return selectedFundCode ? (
    <TableWithAction
      searchContent={props => (
        <SearchContent {...props} currentFund={selectedFundCode} />
      )}
      columns={tableStructureDatabase}
      objProps={listDatabase}
      templates={templates}
      changeObjProps={obj => {
        changeRoute(database, undefined, undefined, obj);
      }}
      clickRow={(event, row, index) =>
        changeRoute(databaseDetail, { name: row.databaseName })
      }
    />
  ) : (
    <></>
  );
};

export default Database;

const fundKey = 'fundCode';
type SearchContentProps = { currentFund: string } & SearchProps;

const SearchContent = (props: SearchContentProps) => {
  const { onSearch, currentFund } = props;

  const { t } = useTranslation();

  const formRef = useRef<HTMLFormElement>(null);

  const onceRef = useRef<boolean>(false);

  const submitHandler: UseFormSubmitModel = (ev: any, data: any) => {
    onSearch(data);
  };
  const { dataStructure, changeValue, dataForm, submitAction, resetForm } =
    useDatebaseSearchForm({
      t,
      submitHandler,
      init: { [fundKey]: currentFund },
    });

  useEffect(() => {
    return () => resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataForm && !onceRef.current) {
      onceRef.current = true;
      onChangeHandler(currentFund, fundKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm, currentFund]);

  const onChangeHandler = (value: any, key: string) => {
    changeValue(value, key);
    setTimeout(() => {
      formRef.current?.requestSubmit();
    }, 500);
  };

  return (
    <Form ref={formRef} onSubmit={submitAction}>
      <FormElements
        data={dataStructure}
        dataForm={dataForm}
        errors={{}}
        changeValue={onChangeHandler}
      />
    </Form>
  );
};
