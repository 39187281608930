import React, { useEffect } from 'react';
import { getParameterQuery } from '../../../utils/location';
import { useDispatch } from 'react-redux';
import { useRoute } from '../../../utils/useRoute';
import { logoutAction } from '../../../app/auth/authSlice';
import { addSuccessMessage } from '../../../app/errors/errorSlice';
import { checkSubscription } from './services/checkSubscription.service';
import { resetRedirectToken } from '../login/loginSlice';

const CheckSubscription = () => {
  const { changeRoute } = useRoute();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetRedirectToken());
    const tokenData = getParameterQuery('token');
    if (localStorage.getItem('token')) {
      dispatch(logoutAction());
    }
    if (tokenData) {
      checkSubscription(tokenData).then(
        res => {
          dispatch(addSuccessMessage('USER_ACTIVE_SUCCESS'));
          changeRoute('login');
        },
        err => {
          changeRoute('login');
        }
      );
    } else {
      changeRoute('login');
    }
  }, []);

  return <h1>Caricamento in corso</h1>;
};

export default CheckSubscription;
