import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addUser } from '../services/users.service';
import { useRoute } from '../../../../utils/useRoute';
import {
  addSuccessMessage,
  resetErrors,
} from '../../../../app/errors/errorSlice';
import UserForm from '../UserForm';
import { setTitle } from '../../../../app/page/pageSlice';
import { useTranslation } from 'react-i18next';

const UserCreate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { changeRoute } = useRoute();
  useEffect(() => {
    dispatch(setTitle(t('users.create.title')));
  }, []);
  const AddUserAction = (data: any) => {
    addUser(data).then(res => {
      dispatch(resetErrors());
      dispatch(addSuccessMessage('USER_CREATE'));
      changeRoute('users.index');
    });
  };

  return <UserForm submitForm={AddUserAction}></UserForm>;
};

export default UserCreate;
