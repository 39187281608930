import React from 'react';
import { FormControlProps } from 'react-bootstrap/FormControl';
import { mergeClass } from '../../functions/function';

interface FormProps extends FormControlProps {
  error?: boolean;
  onChangeAction?: (
    data: any,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const DateCustom: React.FC<FormProps & React.HTMLProps<HTMLInputElement>> = ({
  children,
  className,
  error = false,
  value,
  onChangeAction,
  ...rest
}) => {
  const classMerge: string = mergeClass(
    'shared-dataInput form-control',
    error ? ' error' : ''
  );
  const dataValue =
    value && typeof value === 'string' && value.indexOf('T') !== -1
      ? value.split('T')[0]
      : value;
  const changeAction = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeAction && onChangeAction(event.target.value || undefined, event);
  };
  return (
    <input
      className={mergeClass(classMerge, className)}
      value={dataValue || ''}
      onChange={changeAction}
      {...rest}
      type="date"
    />
  );
};

export default DateCustom;
