import React from 'react';
import ErrorView from './ErrorView';
import { Card as Crd } from 'react-bootstrap';
import PageTitle from './PageTitle';

const CardWithErrors = ({ children }: { children: React.ReactNode }) => {
  return (
    <Crd className="shared-card">
      <Crd.Header>
        <PageTitle />
      </Crd.Header>
      <Crd.Body>
        <ErrorView />
        {children}
      </Crd.Body>
    </Crd>
  );
};

export default CardWithErrors;
