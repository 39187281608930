import React, { useEffect, useState } from 'react';
import './table1.scss';
import { ListElementProps } from './models/ListElementProps';
import Icon from '../icon/Icon';
import Caption from '../headings/caption/Caption';
import PaginationList from './paginationList/PaginationList';
import { useSearch } from '../../../utils/useSearch';
import SearchFilter from './SearchFilter';

const ListElement = ({
  list = [],
  property,
  action,
  onDelete,
}: ListElementProps) => {
  const clickHandler = (event: React.MouseEvent, ele: any) => {
    event.preventDefault();
    action && action(ele);
  };
  const deleteHandler = (event: React.MouseEvent, ele: any) => {
    event.stopPropagation();
    onDelete && onDelete(ele);
  };

  const [page, setPage] = useState<number>(1);
  const [elementForPage] = useState<number>(8);
  const [filterData, setFilterData] = useState<{ [key: string]: any }[]>(list);
  const [filterDataPagination, setFilterDataPagination] =
    useState<{ [key: string]: any }[]>(filterData);

  useEffect(() => {
    goPage(1);
    searchFilter('');
  }, [list]);

  useEffect(() => {
    goPage(1);
  }, [filterData]);

  useEffect(() => {
    setFilterDataPagination(
      filterData.filter(
        (ele, index) =>
          index >= (page - 1) * elementForPage && index < page * elementForPage
      )
    );
  }, [filterData, elementForPage, page]);

  const goPage = (number: number) => {
    setPage(number);
  };
  const { filterArraySearch } = useSearch();

  const searchFilter = (value: string) => {
    setInputValue(value);
    setFilterData(filterArraySearch(value, list));
  };

  const [inputValue, setInputValue] = useState('');

  return (
    <div className="px-5">
      {list.length > elementForPage && (
        <SearchFilter
          searchFilter={val => searchFilter(val)}
          inputValue={inputValue}
        ></SearchFilter>
      )}
      <ul className="load-list mt-3">
        {filterDataPagination.map((ele, index) => (
          <li
            key={index}
            className={`d-flex mb-2 py-2 justify-content-between ${
              action && 'pointer'
            }`}
            onClick={(event: React.MouseEvent) => clickHandler(event, ele)}
          >
            <div className="d-flex">
              <Icon className="left" icon={ele.ico || 'play'} size="16"></Icon>
              <Caption className="link light">{ele[property] || ''}</Caption>
            </div>
            {onDelete && (
              <span
                onClick={(event: React.MouseEvent) => deleteHandler(event, ele)}
              >
                <Icon icon={'delete'} size="16"></Icon>
              </span>
            )}
          </li>
        ))}
      </ul>
      {filterData.length > elementForPage && (
        <PaginationList
          elements={filterData.length}
          page={page}
          elementForPage={elementForPage}
          goPage={goPage}
        ></PaginationList>
      )}
    </div>
  );
};

export default ListElement;
