import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DataGrid, Export } from 'devextreme-react/data-grid';
import { FieldChooser, FieldPanel } from 'devextreme-react/pivot-grid';
import { Popup } from 'devextreme-react/popup';
import { PivotGrid } from 'devextreme-react/ui/pivot-grid';
//devextreme
import 'devextreme/dist/css/dx.common.css';
import { exportDataGrid, exportPivotGrid } from 'devextreme/excel_exporter';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import ExcelJS, { Cell } from 'exceljs';
import { saveAs } from 'file-saver';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../../app/auth/authSlice';
import {
  selectAssetId,
  selectDashboardTables,
  selectFundCode,
  selectLoadedFileId,
  selectPeriodId,
  selectTenantId,
} from '../../../../components/core/filter-dashboard/filterDashboardSlice';
import { home } from '../../../../routes/routeNameAuthType';
import { useRoute } from '../../../../utils/useRoute';
import { Dropdown } from '../../home/services/dashboard.service';
import {
  getFact,
  loadStateCall,
  saveStateCall,
} from '../service/table.service';
import SaveLoadQueryTable1 from './components/SaveLoadQueryTable1';
import './css/custom-scheme-table.scss';
import {
  resetShowModal,
  selectSelectedTable,
  setListLoadQuery,
} from './table1Slice';

let dataDev: any = {
  dataSource: undefined,
};
const dataSourceAction = (tenantId: string, selectedTable: string) => {
  return new PivotGridDataSource({
    // filter: ["[Cod Fondo].[Id]", "=", "03535"],
    remoteOperations: true,
    store: createStore({
      loadUrl: `${process.env.REACT_APP_URL}pivot/FactTavola1`,
      key: 'id',
      onBeforeSend: (operation: string, ajaxSettings: any) => {
        ajaxSettings.headers = {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'X-TenantID': tenantId,
        };
        ajaxSettings.data.factId = selectedTable;
      },
    }),
    fields: [
      // { dataField: "numeroQuote", summaryType: "sum", isMeasure: true }, //da togliere
      {
        dataField: 'id',
        visible: false,
      },
    ],
  });
};

const Table1 = () => {
  const assetId = useSelector(selectAssetId);
  const fundCode = useSelector(selectFundCode);
  const periodId = useSelector(selectPeriodId);
  const selectedTable = useSelector(selectSelectedTable);
  const user = useSelector(selectUser);
  const loadFileId = useSelector(selectLoadedFileId);
  const tenantId = useSelector(selectTenantId);
  const dashboardTables = useSelector(selectDashboardTables);

  const dispatch = useDispatch();
  let dataGrid: any = useRef(null);
  let _pivotGrid: any = useRef(null);
  const [popupTitle, setPopupTitle] = useState('');
  const [drillDownDataSource, setDrillDownDataSource] = useState<any>(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [update, setUpdate] = useState(0);
  const { changeRoute } = useRoute();
  useEffect(() => {
    if (tenantId && selectedTable) {
      dataDev.dataSource = dataSourceAction(tenantId, selectedTable);
      dataDev.dataSource.filter([['iddelFlusso', '=', loadFileId]]);
      if (assetId) {
        getFact(selectedTable).then(res => {
          dataDev.dataSource.fields(JSON.parse(res.config).fields);
          dataDev.dataSource.state(JSON.parse(res.config));
          dataDev.dataSource.load();
        });
        loadListQuery();
        setUpdate(update + 1);
      } else {
        changeRoute(home);
      }
    }

    return () => {
      dataDev.dataSource.state({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveState = (queryName: string) => {
    const stateOe = dataDev.dataSource.state();
    const payload = {
      userId: user?.id,
      factId: selectedTable,
      queryName,
      configTable: JSON.stringify(stateOe),
    };
    saveStateCall(payload).then(res => {
      loadListQuery();
      closeModal();
    });
  };
  const loadListQuery = () => {
    loadStateCall(user?.id, selectedTable).then((res: any) => {
      if (res != null) {
        dispatch(setListLoadQuery(res));
      }
    });
  };

  const loadState = (data: any) => {
    dataDev.dataSource.state(JSON.parse(data.config));
    // dataSource.field("codiceFondo", {
    //   filterValues: [assetId],
    //   area: "filter",
    //   visible: false,
    // });
    dataDev.dataSource.load();
    closeModal();
  };

  const onCellClick = (e: any) => {
    // eslint-disable-next-line eqeqeq
    if (e.area == 'data') {
      var pivotGridDataSource = e.component.getDataSource();

      setPopupTitle('Drill Down Data ONE WELF');
      setDrillDownDataSource(
        pivotGridDataSource.createDrillDownDataSource(e.cell)
      );
      setPopupVisible(true);
    }
  };

  const closeModal = () => {
    dispatch(resetShowModal());
  };

  const onHiding = () => {
    setPopupVisible(false);
  };
  const onShown = () => {
    dataGrid.current?.instance?.updateDimensions();
  };

  const onExporting = (e: any) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      customizeCell: function (options: any) {
        const excelCell = options;
        excelCell.font = { name: 'Arial', size: 12 };
        excelCell.alignment = { horizontal: 'left' };
      },
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'DataGrid.xlsx'
        );
      });
    });
    e.cancel = true;
  };

  const onExportingPivotGrid = (e: any) => {
    let dataDash: Dropdown | undefined;
    dashboardTables.forEach(table => {
      if (!dataDash) {
        dataDash = table.dropdown.find(
          drop => drop.id === Number(selectedTable)
        );
      }
    });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(
      `${dataDash?.name}-${fundCode}-${periodId}`
    );
    exportPivotGrid({
      component: e.component,
      worksheet: worksheet,
    }).then(function (cellRange: any) {
      var fields = e.component.getDataSource().fields();
      fields = fields.filter((field: any) => {
        // eslint-disable-next-line eqeqeq
        return field.area == 'row';
      });

      fields = fields.sort((a: any, b: any) => {
        return a.areaIndex - b.areaIndex;
      });

      var fieldsRow = fields.map((field: any) => {
        return field.caption;
      });

      // worksheet.views[0] = {};

      if ((worksheet as any)._merges.A1) {
        const cellCount = (worksheet as any)._merges.A1.model.right;

        worksheet.unMergeCells('A1');
        const firstRow = worksheet.getRow(1);

        for (let index = 0; index < cellCount; index++) {
          const rowHeaderCell = firstRow.getCell(index + 1);
          rowHeaderCell.value = fieldsRow[index];
        }

        //format HEADER
        firstRow.eachCell((cell: Cell, colNumber: number) => {
          if (cell.value) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '5cb9f7' },
            };
            cell.font = {
              bold: true,
            };
          }
        });
        const rowHeader = worksheet.insertRow(1, [
          `${dataDash?.name} - ${dataDash?.description} - Fondo ${fundCode} - Periodo ${periodId}`,
        ]);

        //format HEADER
        rowHeader.eachCell((cell: Cell, colNumber: number) => {
          if (cell.value) {
            cell.font = {
              bold: true,
              size: 24,
            };
          }
        });
      }

      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `${dataDash?.name}-${fundCode}-${periodId}.xlsx`
        );
      });
    });
    e.cancel = true;
  };

  return (
    <div className="App">
      {dataDev.dataSource && (
        <header className="App-header">
          <PivotGrid
            id="pivotgrid"
            dataSource={dataDev.dataSource}
            allowSortingBySummary={true}
            allowSorting={true}
            allowFiltering={false}
            showBorders={true}
            showColumnTotals={false}
            showColumnGrandTotals={true}
            showRowTotals={false}
            showRowGrandTotals={false}
            onCellClick={onCellClick}
            allowExpandAll={true}
            ref={_pivotGrid}
            onExporting={onExportingPivotGrid}
          >
            <FieldPanel
              showColumnFields={true}
              showDataFields={true}
              showFilterFields={false}
              showRowFields={true}
              allowFieldDragging={true}
              visible={true}
            />
            <FieldChooser enabled={true} height={600} allowSearch={true} />
            <Export enabled="true"></Export>
          </PivotGrid>
          <Popup
            visible={popupVisible}
            width={'90%'}
            height={'90%'}
            title={popupTitle}
            onHiding={onHiding}
            onShown={onShown}
          >
            <DataGrid
              width={'100%'}
              height={'100%'}
              dataSource={drillDownDataSource}
              ref={dataGrid}
              columnAutoWidth={true}
              onExporting={onExporting}
            >
              <Export enabled={true} />
            </DataGrid>
          </Popup>
          <SaveLoadQueryTable1 loadState={loadState} saveState={saveState} />
        </header>
      )}
    </div>
  );
};

export default Table1;
