import { Routes } from '../components/routes/models/routes';
import Login from '../features/public/login/Login';
import ForgotPassword from '../features/public/forgot-password/ForgotPassword';
import ChangePassword from '../features/public/change-password/ChangePassword';
import CheckSubscription from '../features/public/check-subscription/CheckSubscription';
export const routes: Routes = {
  list: [
    {
      path: '/',
      component: Login,
      name: 'Login',
    },
    {
      path: '/forgot-password',
      component: ForgotPassword,
      name: 'ForgotPassword',
    },
    {
      path: '/reset/password',
      component: ChangePassword,
      name: 'ChangePassword',
    },
    {
      path: '/enable/user',
      component: CheckSubscription,
      name: 'CheckSubscription',
    },
  ],
  redirect: '/',
};

export default routes;
