import React, { ChangeEvent } from 'react';
import Checkbox from '../checkbox/Checkbox';
import { checkboxListType } from '../../../../utils/forms/models/FormElementModel';

const CheckboxList: React.FC<checkboxListType> = ({
  value = [],
  error = false,
  name: string = '',
  options = [],
  changeChecked = () => {},
  ...rest
}) => {
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    changeChecked(
      event.target.checked
        ? [...value, event.target.value]
        : value.filter(e => e !== event.target.value)
    );
  };
  return (
    <>
      {options.map((ele, index) => {
        return (
          <Checkbox
            key={ele.value}
            checked={value.map(ele => ele.toString()).includes(ele.value)}
            value={ele.value}
            label={ele.label}
            onChange={changeHandler}
          />
        );
      })}
    </>
  );
};

export default CheckboxList;
