import React from 'react';
import FormGroupCustom from './FormGroupCustom';
import { FormElementModelNew } from './models/FormElementModel';
import { Col, Row } from 'react-bootstrap';

const FormElements = ({
  data = [],
  errors,
  isSubmit = false,
  changeValue = () => {},
  dataForm,
}: {
  data: FormElementModelNew[];
  isSubmit?: boolean;
  errors: { [key: string]: any };
  changeValue?: (value: any, name: string) => void;
  dataForm: { [key: string]: any };
}) => {
  let issetCol = !!data.find(ele => ele.col);
  const formGroup = ({
    label,
    name,
    dataElement = {},
    typeElement,
    options,
  }: FormElementModelNew) => (
    <FormGroupCustom
      key={name}
      isSubmit={isSubmit}
      name={name}
      errors={errors}
      label={label}
      display={dataElement.display}
      dataElement={dataElement}
      typeElement={typeElement}
      value={dataForm[name]}
      options={options}
      changeValue={value => changeValue(value, name)}
    />
  );
  const form = () =>
    data.map((ele: FormElementModelNew, index) => ColContainer(ele, index));
  const ColContainer = (ele: FormElementModelNew, index: number) => {
    return issetCol ? (
      <Col key={index} {...ele.col}>
        {formGroup(ele)}
      </Col>
    ) : (
      formGroup(ele)
    );
  };

  return <>{issetCol ? <Row>{form()}</Row> : form()}</>;
};

export default FormElements;
