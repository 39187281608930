import React, { useEffect, useState } from 'react';
import { TableData } from '../../../../../../components/shared/table/models/TableProps';
import Table from '../../../../../../components/shared/table/table/Table';
import StateLabel from '../../../../../../components/shared/form/stateLabel/StateLabel';
import { listLoadedFile } from '../../../../asset/services/asset.service';
import { useSelector } from 'react-redux';
import { selectTenantId } from '../../../../../../components/core/filter-dashboard/filterDashboardSlice';
import { getLegendColor } from '../../dashboardData';

const Chronology = ({ id }: { id: any }) => {
  const [data, setData] = useState<any[]>([]);
  const tenantId = useSelector(selectTenantId);
  useEffect(() => {
    tenantId &&
      listLoadedFile(id, tenantId).then(res => {
        setData(
          res.map((loaded: any) => ({
            ico: loaded.status?.id,
            id: loaded.id,
            color: getLegendColor(loaded.result?.id),
            description: loaded.resultDescription,
            activity: loaded.status?.attivita,
            user: loaded.user,
            date: loaded.date?.substring(0, 10),
            startDate: loaded.startDate?.substring(11),
            endDate: loaded.endDate?.substring(11),
            state: loaded.status?.name,
          }))
        );
      });
  }, [id, tenantId]);

  const tableStructure: TableData[] = [
    {
      name: 'ico',
      label: '',
      width: '1%',
    },
    {
      name: 'description',
      label: 'Descrizione',
    },
    {
      name: 'date',
      label: 'Data',
    },
    {
      name: 'startDate',
      label: 'Ora inizio',
    },
    {
      name: 'endDate',
      label: 'Ora fine',
    },
    {
      name: 'state',
      label: 'Stato',
    },
    /*     {
      name: "modify",
      label: "",
    }, */
  ];

  const templates = {
    ico: (value: string, row: any) => {
      return (
        <StateLabel
          className="d-inline-flex"
          state="active"
          colorStyle={row.color}
        ></StateLabel>
      );
    },
    /*     modify: (value: string, row: any, index: number) => (
      <div className="d-flex align-items-center">
        <Dropdown drop="left" listMenu={listActionTableRow}></Dropdown>
      </div>
    ), */
  };
  return (
    <Table
      styleTable="solid"
      columns={tableStructure}
      data={data}
      templates={templates}
      labelEmpty="Nessuna cronologia"
      hideHeaderInEmptyTable={!(data && !!data?.length)}
    />
  );
};

export default Chronology;
