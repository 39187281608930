import { TFunction } from 'i18next';
import { useHistory } from 'react-router';
import { ButtonModel } from '../../../../components/shared/form/buttons/models/ButtonModel';
import { FormElementModelNew } from '../../../../utils/forms/models/FormElementModel';
import { useEffect, useState } from 'react';
import { User } from '../../../../app/auth/model/User';
import { getAssetsList } from '../../users/services/users.service';

export function usePeriodCreate(t: TFunction, user: Partial<User> = {}) {
  const history = useHistory();
  const months = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ];
  const [assets, setAssets] = useState<
    { id: number; shortDescription: string }[]
  >([]);
  useEffect(() => {
    getAssetsList().then(res => {
      setAssets(res);
    });
  }, []);
  const years = () => {
    const now = new Date().getUTCFullYear();
    return Array(now - (now - 10))
      .fill('')
      .map((v, idx) => now - idx);
  };
  const validations = {
    month: {
      required: {
        params: { name: 'mese' },
      },
    },
    year: {
      required: {
        params: { name: 'anno' },
      },
    },
    assetId: {
      required: {
        params: { name: 'Fondo' },
      },
    },
  };
  const data: FormElementModelNew[] = [
    {
      typeElement: 'select',
      label: t('periods.create.month'),
      name: 'month',
      col: { md: 6 },
      options: [
        { label: 'Seleziona Mese', value: '-1' },
        ...Array.from(Array(12).keys()).map(ele => ({
          value: '' + (ele + 1),
          label: months[ele],
        })),
      ],
      dataElement: {},
    },
    {
      typeElement: 'select',
      label: t('periods.create.year'),
      name: 'year',
      col: { md: 6 },
      options: [
        { label: 'Seleziona Anno', value: '-1' },
        ...years().map(ele => ({
          value: ele.toString(),
          label: ele.toString(),
        })),
      ],
      dataElement: {},
    },
    {
      typeElement: 'select',
      label: t('periods.create.assets'),
      name: 'assetId',
      col: { md: 12 },
      options: [
        { label: 'Seleziona fondo', value: '-1' },
        ...assets.map(ele => ({
          value: ele.id.toString(),
          label: ele.shortDescription,
        })),
      ],
      dataElement: {},
    },
  ];
  const buttons: ButtonModel[] = [
    {
      typeButton: 'secondary',
      label: t('changePsw.cancel'),
      onClick: () => history.goBack(),
    },
    {
      typeButton: 'primary',
      label: t('changePsw.send'),
    },
  ];

  return {
    validations,
    data,
    buttons,
  };
}
