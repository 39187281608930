import React from 'react';
import { useSelector } from 'react-redux';
import DetailRowVds from './DetailRowVds';
import FilterSummary from './FilterSummary';
import { ResultVdsModel } from './models/SliceVdsModel';
import ResultSearchMoreVds from './ResultSearchMoreVds';
import { selectLastRequest, selectSelectedRowFile } from './vdsSlice';
interface ResultSearchVdsModel {
  resultMoreVds?: ResultVdsModel[];
}

const ResultSearch = ({ resultMoreVds }: ResultSearchVdsModel) => {
  const lastRequest = useSelector(selectLastRequest);
  const selectedFileRow = useSelector(selectSelectedRowFile);

  return (
    <>
      {!selectedFileRow ? (
        <>
          <FilterSummary data={lastRequest}></FilterSummary>
          {resultMoreVds && (
            <ResultSearchMoreVds
              resultMoreVds={resultMoreVds}
              lastRequest={lastRequest}
            />
          )}
        </>
      ) : (
        <DetailRowVds rowFile={selectedFileRow} />
      )}
    </>
  );
};

export default ResultSearch;
