import http, { httpBase } from '../../../../utils/http';

const url = 'dashboard/';

export const uploadFileDashboard = (file: FormData) =>
  http.post(`${url}file`, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const downloadFileDashboard = (
  periodId: any,
  assetId: any,
  tenantId: any
) =>
  httpBase.get(`${url}export?periodId=${periodId}&assetId=${assetId}`, {
    headers: { 'X-TenantID': tenantId },
    responseType: 'blob',
  });

export const getSessionSearchDashboard = (userId: number = 0) =>
  http.get(`${url}operation`);

export const putSessionSearchDashboard = (
  userId: number = 0,
  dashboard: any = {}
) =>
  http.put(`${url}operation`, {
    ...dashboard,
  });

export const loadedFileCall = (
  periodId: number,
  assetId: number,
  tenantId: string
) =>
  http.get(`${url}fileLoaded?periodId=${periodId}&assetId=${assetId}`, {
    headers: { 'X-TenantID': tenantId },
  });

export const runStepCall = (data: any = {}, tenantId: string) =>
  http.post(`queue`, data, { headers: { 'X-TenantID': tenantId } });

export const getTenant = (periodId: number, assetId: number) =>
  http.get(`${url}tenant/${assetId}/${periodId}`);

export const deleteFileDashboard = (
  loadedId: number,
  fileLoadedId: number,
  tenantId: string
) =>
  http.delete(`${url}file?fileLoadedId=${fileLoadedId}&loadedId=${loadedId}`, {
    headers: { 'X-TenantID': tenantId },
  });
export const deleteDatabaseDashboard = (tenantId: string) =>
  http.delete(`${url}database`, {
    headers: { 'X-TenantID': tenantId },
  });

export type Dropdown = {
  id: number;
  name: string;
  label: string;
  tableCode: string;
  stored2Load: string;
  description: string;
};

export type DashboardTable = {
  aggrId: number;
  aggrIdLabel: string;
  subAggrId: number;
  subAggrIdLabel: string;
  fullLabel: string;
  surveyType: string;
  dropdown: Dropdown[];
};

export const getTablesDashboard = (tenantId: string) =>
  http.get<DashboardTable[]>(`${url}tables`, {
    headers: { 'X-TenantID': tenantId },
  });
