import { TFunction } from 'i18next';
import { User } from '../../../../app/auth/model/User';
import { ButtonModel } from '../../../../components/shared/form/buttons/models/ButtonModel';
import { useHistory } from 'react-router';
import { FormElementModelNew } from '../../../../utils/forms/models/FormElementModel';
import RoleMap from '../../../../models/RoleMap';
import { AuthorityModel } from '../../../../models/AuthorityModel';

export function useUserCreate(
  t: TFunction,
  companyList: any[],
  assets: any[],
  roleList: any[],
  user?: User | undefined,
  dataForm: any = {},
  authorities: AuthorityModel[] = [],
  userModify?: Partial<User>
) {
  const history = useHistory();
  // const isMoreAssets = () =>
  //   [RoleMap.ROLE_ADMIN.id, RoleMap.ROLE_AUTHORITY_USER.id].includes(
  //     +dataForm.role
  //   );

  const getSelectAutority: () => FormElementModelNew[] = () => {
    return dataForm.role &&
      dataForm.role.toString() === RoleMap.ROLE_AUTHORITY_USER.id.toString()
      ? [
          {
            typeElement: 'select',
            label: t('users.create.authorityId'),
            name: 'authorityId',
            col: { sm: 12, md: 4 },
            options: [
              { label: t('users.create.authorityId'), value: '-1' },
              ...authorities.map(el => ({
                label: el.covipCode,
                value: el.id.toString(),
              })),
            ],
            otherData: {
              edit: true,
            },
          },
        ]
      : [];
  };

  let dataFirstRow: FormElementModelNew[] = [
    {
      typeElement: 'select',
      label: t('users.create.company'),
      name: 'company',
      col: { sm: 12, md: 4 },
      options: [
        { label: t('users.create.company_placeholder'), value: '-1' },
        ...companyList.map(el => ({
          label: el.name,
          value: el.id?.toString(),
        })),
      ],
      dataElement: {
        disabled: user && !!user.companyId,
      },
    },
    {
      typeElement: 'select',
      label: t('users.create.role'),
      name: 'role',
      col: { sm: 12, md: 4 },
      options: [
        { label: t('users.create.role_placeholder'), value: '-1' },
        ...roleList.map(el => ({
          label: t('users.create.roles.' + el.description),
          value: el.id?.toString(),
        })),
      ],
      otherData: {
        edit: true,
      },
      dataElement: {
        disabled: !dataForm.company,
      },
    },
    ...getSelectAutority(),
    {
      typeElement: 'select',
      label: t('users.create.assets'),
      name: 'assets',
      col: { sm: 12, md: 4 },
      options: assets.map((el: any) => ({
        label: el.shortDescription,
        value: el.id.toString(),
      })),
      otherData: {
        edit: true,
      },
      dataElement: {
        multiply: true,
        disabled: !assets.length || !dataForm.company || !dataForm.role,
      },
    },
  ];
  let dataSecondRow: FormElementModelNew[] = [
    {
      typeElement: 'input',
      label: t('users.create.firstName'),
      name: 'firstName',
      col: { sm: 12, md: 4 },
      dataElement: {
        placeholder: t('users.create.firstName'),
        type: 'text',
      },
      otherData: {
        edit: true,
      },
    },
    {
      typeElement: 'input',
      label: t('users.create.lastName'),
      name: 'lastName',
      col: { sm: 12, md: 4 },
      dataElement: {
        placeholder: t('users.create.lastName'),
        type: 'text',
      },
      otherData: {
        edit: true,
      },
    },
    {
      typeElement: 'input',
      label: t('users.create.username'),
      name: 'username',
      col: { sm: 12, md: 4 },
      dataElement: {
        placeholder: t('users.create.username'),
        type: 'text',
      },
    },
    {
      typeElement: 'input',
      label: t('users.create.email'),
      name: 'email',
      col: { sm: 12, md: 4 },
      dataElement: {
        placeholder: t('users.create.email'),
        type: 'text',
      },
    },
    {
      typeElement: 'input',
      label: t('users.create.password'),
      name: 'password',
      col: { sm: 12, md: 4 },
      dataElement: {
        placeholder: t('users.create.password'),
        type: 'password',
      },
    },
    {
      typeElement: 'input',
      label: t('changePsw.confirmPassword'),
      name: 'confirmPassword',
      col: { sm: 12, md: 4 },
      dataElement: {
        placeholder: t('changePsw.confirmPassword'),
        type: 'password',
      },
    },
  ];

  const buttons: ButtonModel[] = [
    {
      typeButton: 'secondary',
      label: t('changePsw.cancel'),
      onClick: () => history.goBack(),
    },
    {
      typeButton: 'primary',
      label: t('changePsw.send'),
    },
  ];
  if (userModify) {
    dataFirstRow = dataFirstRow.filter(
      ele => ele.otherData && ele.otherData.edit
    );
    dataSecondRow = dataSecondRow.filter(
      ele => ele.otherData && ele.otherData.edit
    );
  }
  return {
    dataFirstRow,
    dataSecondRow,
    buttons,
  };
}
