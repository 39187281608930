import React from 'react';
import Caption from '../../../../../headings/caption/Caption';

const TableDataEmpty = ({
  labelEmpty,
  colWidth,
  templateEmpty,
}: {
  labelEmpty?: string;
  colWidth?: number;
  templateEmpty?: React.ReactNode;
}) => {
  return (
    <tr className="shared-tableDataEmpty">
      <td className="w-100" colSpan={colWidth}>
        <div className="d-flex justify-content-center">
          {templateEmpty ? (
            templateEmpty
          ) : (
            <Caption>{labelEmpty ? labelEmpty : 'Nessun Dato'}</Caption>
          )}
        </div>
      </td>
    </tr>
  );
};
export default TableDataEmpty;
