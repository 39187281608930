import ListCardWithDropdown from '../../../../../components/shared/cards/cardWithDropdown/models/ListCardWithDropdown';
import ListActionTableRow from '../../../../../components/shared/form/buttons/dropdown/models/ListActionTableRow';
import { DashboardTable } from '../../services/dashboard.service';
const actionTable = 'table';

const tavoleBase: ReadonlyArray<Partial<ListCardWithDropdown>> = [
  {
    icon: 'cal-month',
    surveyType: '1',
    surveySubType: '1',
    dropdown: [
      {
        labelBtn: 'Griglia di controllo',
        icon: 'grid',
        listDropdown: [{ label: 'Scarica', action: 'grid' }],
      },
    ],
  },
  {
    icon: 'cal-trim',
    surveyType: '2',
    surveySubType: '2',
    dropdown: [
      {
        labelBtn: 'Griglia di controllo',
        icon: 'grid',
        listDropdown: [{ label: 'Scarica', action: 'grid' }],
      },
    ],
  },
  {
    icon: 'cal-trim',
    surveyType: '2',
    surveySubType: '3',
    dropdown: [
      {
        labelBtn: 'Griglia di controllo',
        icon: 'grid',
        listDropdown: [{ label: 'Scarica', action: 'grid' }],
      },
    ],
  },
  {
    icon: 'cal-annual',
    surveyType: '3',
    surveySubType: '2',
    dropdown: [],
  },
  {
    icon: 'cal-annual',
    surveyType: '3',
    surveySubType: '3',
    dropdown: [],
  },
  {
    icon: 'cal-annual',
    surveyType: '3',
    surveySubType: '4',
    dropdown: [],
  },
];

export const createTavoleList = (
  dashboardTables: DashboardTable[],
  disabled: boolean = false
) => {
  return dashboardTables.map(data => {
    const index = tavoleBase.findIndex(
      s =>
        s.surveyType === data.aggrId.toString() &&
        s.surveySubType === data.subAggrId.toString()
    );
    if (index < 0) {
      throw new Error('tavola non trovata');
    }
    const tavola = { ...tavoleBase[index] } as ListCardWithDropdown;
    tavola.label = data.fullLabel;
    tavola.disabled = disabled;
    tavola.dropdown = [
      {
        labelBtn: 'Tavole',
        icon: 'schema',
        listDropdown: data.dropdown.map(
          ({ id, label, tableCode, description }) =>
            ({
              id: `${id}`,
              label,
              tableCode,
              action: actionTable,
              description,
            } as ListActionTableRow)
        ),
      },
      ...(tavola.dropdown || []).map(d => ({
        ...d,
        listDropdown: d.listDropdown.map(ld => ({
          ...ld,
          surveyType: data.surveyType,
        })),
      })),
    ];
    return tavola;
  });
};
