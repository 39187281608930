import React from 'react';
import { useDispatch } from 'react-redux';
import { TableData } from '../../../../../components/shared/table/models/TableProps';
import Table from '../../../../../components/shared/table/table/Table';
import { fixedData } from './components/tableDetailRowData';
import { RequestVds, ResultVdsModel } from './models/SliceVdsModel';
import {
  setSelectedRowFileAction,
  updateSearchVdsActionFilter,
  updateSearchVdsActionPagination,
} from './vdsSlice';
interface ResultSearchVdsModel {
  resultVds: ResultVdsModel;
  lastRequest?: RequestVds;
}

const ResultSearchVds = ({ resultVds, lastRequest }: ResultSearchVdsModel) => {
  const dispatch = useDispatch();

  const hilightField: string[] = lastRequest?.propToFilter
    ? Object.keys(lastRequest.propToFilter)
    : [];
  const stileHiglight: React.CSSProperties = {
    fontWeight: 'bolder',
    color: '#7ed321',
    background: '#f5f6f9',
    textDecoration: 'underline',
  };
  const tableStructure: TableData[] = Object.keys(resultVds.header)
    .map(name => ({
      name,
      label: resultVds.header[name],
      showFilterOrder: true,
      styleCol: hilightField.includes(name) ? stileHiglight : undefined,
    }))
    .filter(ele => ele.name !== fixedData.loadedId);
  const changePage = (page: number) => {
    dispatch(updateSearchVdsActionPagination(resultVds.vds, page));
  };
  const filterData = (field: string) => {
    dispatch(
      updateSearchVdsActionFilter(resultVds.vds, {
        key: field,
        type: resultVds.sort?.type === 'ASC' ? 'DESC' : 'ASC',
      })
    );
  };

  return (
    <Table
      columns={tableStructure}
      data={resultVds.body.rows}
      clickRow={(event, row, index) => {
        dispatch(
          setSelectedRowFileAction(
            { row: row, header: resultVds.header },
            resultVds.vds
          )
        );
      }}
      goPage={changePage}
      page={resultVds.page || 1}
      pages={resultVds.pages || 1}
      clickOrder={filterData}
      order={
        resultVds.sort && {
          field: resultVds.sort.key.replace('id.', ''),
          order: resultVds.sort.type,
        }
      }
    />
  );
};

export default ResultSearchVds;
