export const home = 'Home';
export const changePassword = 'ChangePassword';
export const periodsCreate = 'periods.create';
export const periodsEdit = 'periods.edit';
export const assetCreate = 'asset.create';
export const assetEdit = 'asset.edit';
export const usersIndex = 'users.index';
export const usersCreate = 'users.create';
export const usersEdit = 'users.edit';
export const database = 'database';
export const databaseDetail = 'databaseDetail';
export const errorsIndex = 'errors.index';
export const table1Index = 'table1.index';
export const redirectPath = '/home';
export const reportCreate = 'reports.create';
export const assetEPCreate = 'assetep.create';
export const assetEPEdit = 'assetep.edit';
